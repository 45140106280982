import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import formatNumber from "../../../utils/formatNumber";
import { Spinner } from "react-bootstrap";

const GraphMoyenneParAgent = ({ data }) => {

  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  useEffect(() => {
    // Simuler un délai de chargement pour les données, vous pouvez remplacer ceci par un appel de données réel
    setTimeout(() => {
      setLoading(false); // Une fois que le composant est monté et que les données sont prêtes, mettre loading à false
      updateChart();
    }, 1000); // Vous pouvez ajuster le délai selon vos besoins ou supprimer ce délai si vos données sont prêtes immédiatement
  }, [data]);

  const updateChart = () => {
   

    // Trier les données par averageByAgent de manière décroissante
    const sortedData = data.sort((a, b) => b.averageByAgent - a.averageByAgent);

    // Extraire les valeurs de pseudo, averageByAgent, totalSum et NumContract
    const categories = sortedData.map(item => item.pseudo);
    const seriesData = sortedData.map(item => ({
      x: item.pseudo,
      y: Number(item.averageByAgent),
      totalSum: Number(item.totalSum),
      NumContract: item.NumContract
    }));

    setSeries([
      {
        name: "Moyenne par Agent",
        data: seriesData,
      },
    ]);

    setOptions({
      chart: {
        type: "bar",
        height: 350,
      },
      colors: ["#65c9ff"], // Définir la couleur des barres
      xaxis: {
        categories: categories,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "14px",
                fontWeight: 600,
                colors: ["#000"],
              },
              formatter: function (val) {
                return `${formatNumber(val)}$`;
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#000"], // Couleur noire pour les données sur les barres
        },
        formatter: function (val) {
          return `${formatNumber(val)}$`;
        },
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          return `<div>
                    <span>Nbr contrat:<strong> ${data.NumContract}</strong></span>
                    <span>Total signe: <strong>${formatNumber(data.totalSum)}$</strong></span><br/>
                  </div>`;
        }
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
    });
  };

  // Afficher un loader tant que loading est true
  if (loading) {
    return (
      <div className="chargement-graph-agent">
        <Spinner animation="border" role="status">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    ); // Utilisez votre propre composant ou classe CSS pour styliser le loader
  }

  return (
    <div id="chart" className="line-chart-style bar-chart h-100">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default GraphMoyenneParAgent;