import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { CircleLoader } from "react-spinners";
import { useSelector } from "react-redux";
// import { getAdminDetails } from "../../PagesCrm/Redux/slices/admin.slice";
import { getNotifs } from "../../PagesCrm/Redux/slices/notifSocket.slice";

const Notifs = () => {
  // const adminDetails = useSelector(getAdminDetails);
  const GET_NOTIFS = useSelector(getNotifs);

  // console.log("adminDetails", adminDetails)
  // const [allNotifs, setAllNotifs] = useState([]);
  // const [catchError, setCatchError] = useState();
  const [loading, 
    // setLoading
  ] = useState(true);

  // useEffect(() => {
  //   setLoading(true)
  //   setCatchError()
  //   console.log("token", adminDetails?.token)


  //   axios
  //     .get(
  //       `${config.apiAdmin}/sadmin/notifs/read_all`,

  //       {    headers: {
  //         authorization: `Bearer ${adminDetails?.token}`,
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },}
  //     )
  //     .then((response) => {
  //       setAllNotifs(response?.data);
  //       setLoading(false)
  //       console.log(response?.data)
  //       setCatchError()
  //       // setListFunction(response.data);
  //     })
  //     .catch((error) => {
  //       setLoading(false)
  //       console.error("There was an error!", error);
  //       console.log(error?.response?.data?.message)
  //       setCatchError(error?.response?.data?.message)
  //     });



  // }, []);

  return (
    <>    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th style={{ width: 'auto' }}>#</th>
          <th style={{ width: 'auto' }}>Type</th>
          <th style={{ width: '100%' }}>Content</th>
          <th style={{ width: 'auto' }}>Date</th>
        </tr>
      </thead>
      <tbody>

        {GET_NOTIFS.length < 1 ?
          <CircleLoader
            color="#36d7b7"
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          :
          GET_NOTIFS.map((notif, index) => {
            return (
              <tr key={notif.idnotifs}>
                <td>{notif.idnotifs}</td>
                <td>{notif.type}</td>
                <td>{ notif.isSocket && "🔴" } <div dangerouslySetInnerHTML={{__html : notif.content }}  ></div>   </td>
                <td>{notif?.timestamp?.slice(0, 10)}</td>
              </tr>
            );
          })

        }
      </tbody>

    </Table>
    {/* {catchError &&
        <div className="alert alert-danger">{catchError}</div>
      } */}
    </>
  );
}

export default Notifs;