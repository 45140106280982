import React, {  } from "react";
import CardsAdmin from "./Dashboard/CardsAdmin";

const PageDashboard = () => {

  return (
    <>
       <CardsAdmin /> 
    </>
  );
};

export default PageDashboard;
