import { createSlice } from "@reduxjs/toolkit";

export const tauxReducer = createSlice({
  name: "taux",
  initialState: {
    eur_usd: 5.5555 ,
    usd_eur: 8.8888
  },
  reducers: {
    setTaux: (state, { payload }) => {
      state.eur_usd = payload.eur_usd;
      state.usd_eur = payload.usd_eur;
    },
  },
});

export const { setTaux } = tauxReducer.actions;

export const getEuroUsd = (state) => {
  return state.taux.eur_usd;
};
export const getUsdEur = (state) => {
  return state.taux.usd_eur;
};

export default tauxReducer.reducer;