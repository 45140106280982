function generatePassword() {
    const lowerCases = 'abcdefghijklmnopqrstuvwxyz';
    const upperCases = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const specialChars = '!@#$%^&*()_+-=[]{}|;:,.<>?';
    const allChars = lowerCases + upperCases + numbers + specialChars;
    let password = '';
  
    // S'assurer que le mot de passe contient au moins un caractère de chaque type requis
    password += lowerCases.charAt(Math.floor(Math.random() * lowerCases.length));
    password += upperCases.charAt(Math.floor(Math.random() * upperCases.length));
    password += numbers.charAt(Math.floor(Math.random() * numbers.length));
    password += specialChars.charAt(Math.floor(Math.random() * specialChars.length));
  
    // Remplir le reste du mot de passe avec des caractères aléatoires jusqu'à atteindre 10 caractères
    for (let i = password.length; i < 10; i++) {
      password += allChars.charAt(Math.floor(Math.random() * allChars.length));
    }
  
    // Mélanger les caractères du mot de passe généré
    password = password.split('').sort(() => 0.5 - Math.random()).join('');
  
    return password;
}

  export default generatePassword