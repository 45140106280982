import React, { useState, useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import DropAddContrat from "./DropAddContrat";
import DropUpdateContrat from "./DropUpdateContrat";
import axios from "axios";
import config from "../../../config/config";
import { useParams, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import TableauCrmFilters from "./TableauCrmFilters";
import {
  getAllContracts,
  setAllContracts,
  getFilters,
} from "../../Redux/slices/contract.slice";
import formatNumber from "../../../utils/formatNumber";
import sortTable from "../../../utils/Table/sortTable";
import TrTable from "../../Global/Table/TrTable";
import AddContrat from "../../Global/Dropdowns/AddContracts";
import UpdateContrat from "../../Global/Table/UpdateContracts";

const TableauCrm = () => {

  const { desk, idcontract, mode } = useParams();
  const navigate = useNavigate()


  const initRow = {
    created_at: { etat: 3 },
    desk: { etat: 3 },
    brand: { etat: 3 },
    cust: { etat: 3 },
    amount: { etat: 3 },
    agent: { etat: 3 },
    alias: { etat: 3 },
    date_ech: { etat: 3 },
    support: { etat: 3 },
    psp: { etat: 3 },
    Plateforme: { etat: 3 },
    arrivee: { etat: 3 },
    bank: { etat: 3 },
    en_preuve: { etat: 3 },
    date_vir: { etat: 3 },
    encaisse: { etat: 3 },
    appel_pro: { etat: 3 },
    heure: { etat: 3 },
    reporter: { etat: 3 },
    annuler: { etat: 3 },
    encash_usd: { etat: 3 },
    customerbank: { etat: 3 },
    neobank: { etat: 3 },
    customer_id: { etat: 3 },
    seller_id: { etat: 3 },
    tlead_id: { etat: 3 },
    agt_supp_id: { etat: 3 },
    mng_supp_id: { etat: 3 },
    is_active: { etat: 3 },
    conversion: { etat: 3 },
    paymentdudate: { etat: 3 },
    end_contract: { etat: 3 },
    argent_dispo: { etat: 3 },
    preuve: { etat: 3 },
    date_encaisse: { etat: 3 },
    next_call: { etat: 3 },
    hours: { etat: 3 },
    is_report: { etat: 3 },
    is_canceled: { etat: 3 },
    pseudo_agt_supp: { etat: 3 },
    pseudo_seller: { etat: 3 },
    comments: { etat: 3 },
    cust_fname: { etat: 3 },
    cust_lname: { etat: 3 },
    alias_lname: { etat: 3 },
    alias_fname: { etat: 3 },
  };

  const AdminDetails = useSelector(getAdminDetails);
  const desksToAdmin = AdminDetails?.desk;
  const profilToAdmin = AdminDetails?.profil;
  let DeskParams = useParams().desk;
  let Desk = DeskParams || desksToAdmin;

  const AllContracts = useSelector(getAllContracts);

  const dispatch = useDispatch();

  const filters = useSelector(getFilters);

  const [showFilters, setShowFilters] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [isVisibleDropCreate, setIsVisibleDropCreate] = useState(false);

  const [contractSel, setContractSel] = useState([]);

  const [closeFilters, setCloseFilters] = useState(false);

  const [sortRow, setSortRow] = useState(initRow);

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    if (idcontract) { // cas ou j'ai cliquer sur le message dans le dropdown
      setShowModalUpdate(true);
      setContractSel(AllContracts[0]);
      return;
    }
    // cas normal
    setShowModalUpdate(false);
    setContractSel([]);
    GetAllContrats();
    // setLoaderStart(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idcontract, AllContracts]);

  const GetAllContrats = (data) => {
    if (idcontract) {
      let route = '';
      switch (profilToAdmin) {
        case 'sell':
          route = `/sell_tab`;
          break;
        case 'tlead':
          route = `/tl_tab`;
          break;
        case 'ass_mng':
          route = `/s_tab`;
          break;
        case 'manager':
          route = `/m_tab`;
          break;
        case 'agt_supp':
          route = `/supp_tab`;
          break;
        case 'mng_supp':
          route = `/ms_tab`;
          break;
        case 'boss_supp':
          route = `/cs_tab`;
          break;
        case 'admin':
          route = `/admin_tab`;
          break;
        default:
          console.log('Unknown profile');
      }
      if (route) {
        navigate(route); // Navigate to the determined route
      }
   }   
    if (data) {
      axios
        .post(
          `${config.apiAdmin}/sadmin/contracts/search_and_sort`,
          filters,
          Header
        )
        .then((response) => {
          dispatch(setAllContracts(response.data.data.results));
          // setLoaderStart(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // setLoaderStart(false);
        });
    }
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
    setCloseFilters(!closeFilters);
    // dispatch(
    //   setFilters({
    //     filters: {
    //       desk: Desk,
    //     },
    //     filters_date: {},
    //     sorts: {
    //     },
    //     limit: "",
    //   })
    // );
  };

  const handleRowClick = (row, index) => {
    console.log(row);
    setShowModalUpdate(!showModalUpdate);
    setContractSel(row);
  };

  const handleLastColumnClick = (event, row, index) => {
    event.stopPropagation();
    setContractSel(row);
    setShowModalUpdate(!showModalUpdate);
  };

  const stateRow = (row, type) => {
    if (sortRow[row].etat === 3) {
      setSortRow({ ...initRow, [row]: { etat: 1 } });
      sortTable(row, type, AllContracts, 1, dispatch, setAllContracts);
    } else if (sortRow[row].etat === 1) {
      setSortRow({ ...initRow, [row]: { etat: 2 } });
      sortTable(row, type, AllContracts, 2, dispatch, setAllContracts);
    } else if (sortRow[row].etat === 2) {
      setSortRow({ ...initRow, [row]: { etat: 3 } });
      sortTable(row, type, AllContracts, 3, dispatch, setAllContracts);
    }
  };
  const totalAmount2 = AllContracts?.reduce((sum, contract) => {
    if (
      (contract.is_report === 0 || contract.is_report === null) &&
      (contract.is_canceled === 0 || contract.is_canceled === null) &&
      (contract.encaisse === 0 || contract.encaisse === null)
    ) {
      return sum + (contract.amount || 0);
    }
    return sum;
  }, 0);
  const totalEncash2 = AllContracts?.reduce((sum, contract) => {
    if (
      (contract.is_report === 0 || contract.is_report === null) &&
      (contract.is_canceled === 0 || contract.is_canceled === null)
    ) {
      return sum + (contract.encash_usd || 0);
    }
    return sum;
  }, 0);
  const totalPreuve = AllContracts?.reduce((sum, contract) => {
    if (
      contract.preuve === 1 &&
      (contract.encaisse === 0 || contract.ecnaisse === null) &&
      (contract.is_report === 0 || contract.is_report === null) &&
      (contract.is_canceled === 0 || contract.is_canceled === null)
    ) {
      return sum + (contract.amount || 0);
    }
    return sum;
  }, 0);
  const totalReport = AllContracts?.reduce((sum, contract) => {
    if (
      contract.is_report === 1 &&
      (contract.is_canceled === 0 || contract.is_canceled === null)
    ) {
      return sum + (contract.amount || 0);
    }
    return sum;
  }, 0);

  const totalAnnul = AllContracts?.reduce((sum, contract) => {
    if (contract.is_canceled === 1) {
      return sum + (contract.amount || 0);
    }
    return sum;
  }, 0);

  const nbcontrats = AllContracts?.filter(
    (contract) => contract?.idcontracts?.length === 36
  )?.length;

  function toTitleCase(str) {
    if (!str) {
      return ""; // Retourne une chaîne vide si str est null, undefined ou une chaîne vide
    }

    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  function formatDate(dateString) {
    if (!dateString) return "";

    // Créer un objet Date en se basant sur le fuseau horaire de Jérusalem
    const date = new Date(
      new Date(dateString).toLocaleString("en-US", {
        timeZone: "Asia/Jerusalem",
      })
    );

    // Options pour formater la date
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
      timeZone: "Europe/Paris", // Fuseau horaire de Jérusalem
    };

    // Convertir la date en string formatée en tenant compte du fuseau horaire de Jérusalem
    return date.toLocaleDateString("fr-FR", options);
  }

  function getDateClass(dateString) {
    if (!dateString) return "";

    const date = new Date(dateString);
    const today = new Date();

    // Comparer les jours (ignorer l'heure)
    const isBeforeToday =
      date.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);

    return isBeforeToday ? "bg-green-table" : "bg-red-table";
  }

  function transformDate(dateString) {
    // Vérifie que la chaîne de date est bien au format "MM-DD"
    if (typeof dateString !== "string" || dateString.length !== 5) {
      return dateString; // Retourne la chaîne telle quelle si elle ne correspond pas au format attendu
    }

    // Sépare les parties mois et jour
    const [month, day] = dateString.split("-");

    // Inverse les positions et retourne le nouveau format "DD-MM"
    return `${day}-${month}`;
  }

  return (
    <div>
      {/* {LoaderStart ? (
        <div className="sweet-loading" style={{ marginTop: "300px" }}>
          <CircleLoader
            color="#36d7b7"
            cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : ( */}
      <>
        <Card className="col-12">
          <Card.Header>
            <Card.Title className="col-12">
              <div className="d-flex align-items-center justify-content-between ">
                <div>Tableau contrats</div>
                <div className="d-flex">
                  <div className="fs-14 me-3">
                    nb lignes : {AllContracts?.length}
                  </div>
                  <div className="fs-14 me-3">nb contrats : {nbcontrats}</div>
                  <div className="fs-14 me-3">
                    Reste a encaissé : {formatNumber(totalAmount2)} €{" "}
                  </div>
                  <div className="fs-14 me-3">
                    Preuve : {formatNumber(totalPreuve)} €{" "}
                  </div>
                  <div className="fs-14 me-3">
                    Encaissé : {formatNumber(totalEncash2)} ${" "}
                  </div>
                  <div className="fs-14 me-3">
                    Reporté : {formatNumber(totalReport)} €{" "}
                  </div>
                  <div className="fs-14">
                    Annulé : {formatNumber(totalAnnul)} €{" "}
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    className="btn btn-primary py-0"
                    onClick={() => GetAllContrats({ data: true })}
                  >
                    refresh
                  </button>

                  <div
                    className="border bg-light rounded-1 mx-1"
                    onClick={() => {
                      handleShowFilters();
                    }}
                  >
                    {closeFilters ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        fill="currentColor"
                        className="bi bi-x pointer"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="secondary"
                        className="bi bi-funnel pointer"
                        viewBox="0 0 16 16"
                      >
                        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                      </svg>
                    )}
                  </div>
                  {(desksToAdmin.length == 1 || DeskParams !== undefined) &&
                    !closeFilters && (
                      <div
                        className="border bg-light rounded-1 mx-1 pointer"
                        onClick={() => {
                          // setIsContractToCreate(true);
                          setIsVisibleDropCreate(!showFilters);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="secondary"
                          className="bi bi-plus-lg"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                          />
                        </svg>
                      </div>
                    )}
                </div>
              </div>

              {showFilters && <TableauCrmFilters showFilters={showFilters} />}
            </Card.Title>
          </Card.Header>
          <Card.Body className="p-0">
            <div className="table-scroll">
              <Table striped bordered hover>
                <thead>
                  {sortRow && <TrTable sortRow={sortRow} stateRow={stateRow} />}
                </thead>
                <tbody className="table-container">
                  {AllContracts?.map((row, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        if (profilToAdmin !== "sell") {
                          handleRowClick(row, index);
                        }
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                      className="tableau-content text-center"
                    >
                      <td className="fixed-column-td" title={row?.created_at}>
                        {row?.created_at
                          ? transformDate(row.created_at.slice(5, 10))
                          : ""}
                      </td>
                      <td className="fixed-column-td-2" title={row?.desk}>
                        {row?.desk}
                      </td>
                      <td
                        className="fixed-column-td-3 text-start"
                        title={row?.brand}
                      >
                        {toTitleCase(row?.brand)}
                      </td>
                      <td
                        className="fixed-column-td-4 fw-bold text-start text-black "
                        title={row?.cust_fname + " " + row?.cust_lname}
                      >
                        {`${row?.cust_lname} ${row?.cust_fname}`.trim().length >
                        40
                          ? `${toTitleCase(row?.cust_lname)} ${toTitleCase(
                              row?.cust_fname
                            )}`
                              .trim()
                              .substring(0, 30) + "..."
                          : `${toTitleCase(row?.cust_lname)} ${toTitleCase(
                              row?.cust_fname
                            )}`}
                      </td>
                      <td
                        title={row?.amount}
                        className="fixed-column-td-5 fw-bold text-end text-black"
                      >
                        {formatNumber(row?.amount)} €
                      </td>
                      <td title={row?.pseudo_seller} className="fixed-column-td-6 text-start border-right">
                        {toTitleCase(row?.pseudo_seller)}
                      </td>
                      <td
                        title={row?.alias_lname + " " + row?.alias_fname}
                        className="text-start"
                      >
                        {`${row?.alias_lname} ${row?.alias_fname}`.trim()
                          .length > 15
                          ? `${toTitleCase(row?.alias_lname)} ${toTitleCase(
                              row?.alias_fname
                            )}`
                              .trim()
                              .substring(0, 12) + "..."
                          : `${toTitleCase(row?.alias_lname)} ${toTitleCase(
                              row?.alias_fname
                            )}`}
                      </td>
                      <td
                        title={row?.paymentdudate}
                        className={`text-black text-end ${getDateClass(
                          row?.paymentdudate
                        )}`}
                      >
                        {formatDate(row?.paymentdudate)}
                      </td>
                      <td title={row?.pseudo_agt_supp} className="text-start">
                        {toTitleCase(row?.pseudo_agt_supp)}
                      </td>
                      <td title={row?.psp} className="text-start">
                        {toTitleCase(row?.psp)}
                      </td>
                      <td title={row?.neobank} className="text-start">
                        {toTitleCase(row?.neobank)}
                      </td>
                      <td
                        title={
                          row?.argent_dispo
                            ? "il es true"
                            : "il é pas encore cauché"
                        }
                        style={{ backgroundColor: "var(--bs-warning)" }}
                        className="text-center"
                      >
                        <input
                          type="checkbox"
                          id={`checkbox2-1`}
                          name={`checkbox1-${index}`}
                          checked={row?.argent_dispo ? 1 : 0}
                          readOnly
                        />
                        <label htmlFor={`checkbox2-${index}`}></label>
                      </td>
                      <td className="text-start">
                        {toTitleCase(row?.customerbank?.slice(0, 11))}
                      </td>
                      <td
                        style={{ backgroundColor: "var(--bs-yellow)" }}
                        className="text-center"
                      >
                        <input
                          type="checkbox"
                          id={`checkbox2-2`}
                          name={`checkbox1-${index}`}
                          checked={row?.preuve ? 1 : 0}
                          readOnly
                        />
                      </td>
                      <td>
                        {row?.date_vir
                          ? 
                          transformDate(row.date_vir.slice(5, 10))
                          // new Date(row.date_vir)
                          //     .toLocaleDateString("fr-FR", {
                          //       year: "numeric",
                          //       month: "2-digit",
                          //       day: "2-digit",
                          //     })
                          //     ?.slice(0, 5)
                          : ""}
                      </td>
                      <td
                        style={{ backgroundColor: "var(--bs-success)" }}
                        className="text-center"
                      >
                        <input
                          type="checkbox"
                          id={`checkbox2-3`}
                          name={`checkbox1-${index}`}
                          checked={row?.encaisse ? 1 : 0}
                          readOnly
                        />
                        <label htmlFor={`checkbox2-${index}`}></label>
                      </td>
                      <td>
                        {row?.date_encaisse
                          ? transformDate(row.date_encaisse.slice(5, 10))
                          : ""}
                      </td>
                      <td className="text-black fw-bold text-end">
                        {formatNumber(row?.encash_usd)} $
                      </td>
                      <td className="">
                        {row?.next_call
                          ?
                          transformDate(row.next_call.slice(5, 10))
                          //  new Date(row.next_call)
                          //     .toLocaleDateString("fr-FR", {
                          //       year: "numeric",
                          //       month: "2-digit",
                          //       day: "2-digit",
                          //     })
                          //     ?.slice(0, 5)
                          : ""}
                      </td>
                      <td>{row?.hours === "00:00:00" ? "" : row?.hours?.slice(0, 5)}</td>
                      <td
                        style={{ backgroundColor: "var(--bs-purple)" }}
                        className="text-center"
                      >
                        <input
                          type="checkbox"
                          id={`checkbox2-4`}
                          name={`checkbox1-${index}`}
                          readOnly
                          checked={row?.is_report ? 1 : 0}
                        />
                        <label htmlFor={`checkbox2-${index}`}></label>
                      </td>
                      <td
                        style={{ backgroundColor: "var(--bs-danger)" }}
                        className="text-center"
                      >
                        <input
                          type="checkbox"
                          id={`checkbox2-5`}
                          name={`checkbox2-${index}`}
                          readOnly
                          checked={row?.is_canceled ? 1 : 0}
                        />
                        <label htmlFor={`checkbox2-${index}`}></label>
                      </td>
                      <td
                        onClick={(event) =>
                          handleLastColumnClick(event, row, index)
                        }
                      >
                        <div className="text-start">{row?.comments}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
        <AddContrat
          isVisible={isVisibleDropCreate}
          onHide={() => setIsVisibleDropCreate(false)}
          data={AdminDetails}
          GetAllContrats={GetAllContrats}
        />
        {/* {contractSel && (
          <DropUpdateContrat
            isVisible={showModalUpdate}
            onHide={() => {
              setShowModalUpdate(false);
              setContractSel();
            }}
            data={contractSel}
            IsForComments={forComments}
            GetAllContrats={GetAllContrats}
          />
        )} */}
        {contractSel && (
          <UpdateContrat
            isVisible={showModalUpdate}
            onHide={() => {
              setShowModalUpdate(false);
              setContractSel();
              GetAllContrats({data:true})
            }}
            data={contractSel}
            GetAllContrats={GetAllContrats}
          />
        )}
      </>
      {/* )} */}
    </div>
  );
};

export default TableauCrm;
