import React, {useState} from 'react'
import Targetperso from './Targetperso';
import TargetsGeneral from './TargetsGeneral';

const Targets = () => {
    const [activeComponent, setActiveComponent] = useState('Targetperso');
    const [activeButton, setActiveButton] = useState('Targetperso');
  
    return (
      <div className="" style={{height:"700px"}}>
        <div className="d-flex border-bottom">
          <div 
            className={`col-auto mx-5 text-center cursor-pointer fs-16 h-100 ${activeButton === 'Targetperso' ? 'underline' : ''}`} 
            onClick={() => {setActiveComponent('Targetperso'); setActiveButton('Targetperso');}}
          >
            Targets personnel
          </div>
          {/* <div 
            className={`col-auto mx-5 text-center cursor-pointer fs-16 h-100 ${activeButton === 'TargetsGeneral' ? 'underline' : ''}`} 
            onClick={() => {setActiveComponent('TargetsGeneral'); setActiveButton('TargetsGeneral');}}
          >
             Targets desk
          </div> */}
        </div>
        {activeComponent === 'Targetperso' && <Targetperso />}
        {/* {activeComponent === 'TargetsGeneral' && <TargetsGeneral />} */}
      </div>
  )
}

export default Targets
