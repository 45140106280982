import React, { useState } from "react";
import axios from "axios";
import config from "../../../config/config";
import ScrollToBottom from "react-scroll-to-bottom";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { useSelector } from "react-redux";
import SvgNotes from "../../../images/square-top-up-svgrepo-com.svg";
import SvgUpdate from "../../../images/update-svgrepo-com.svg"
import SvgDelete from "../../../images/delete-svgrepo-com.svg"
import SvgSave from "../../../images/save-svgrepo-com.svg"
const ConvNotes = ({ data, fetchData }) => {

  const detailUser = useSelector(getAdminDetails);
  const pseudo_id = detailUser?.pseudo;
  const userId = detailUser?.idusers;

  const detailContrat = data?.contrat;
  const IdContrat = detailContrat?.idcontracts;
  const customer_id = detailContrat?.customer_id;

  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [showInput, setShowInput] = useState(false);

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const DesactivateMessage = () => {
    let confirResponse = window.confirm("Etes vous sur ?");
    if (confirResponse) {
    let obj = {
      id: selectedCommentId,
    };
    axios
      .patch(`${config.apiAdmin}/sadmin/comments/desactivate`, obj, Header)
      .then((response) => {
        fetchData()
        setSelectedCommentId(null);
        setShowInput(false)
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

  return (
    <div
      id="messages-container"
      style={{
        zIndex: 1,
        position: "relative",
        margin: "0 30px 15px 30px",
        overflowY: "auto",
      }}
    >
      <ScrollToBottom className="conv-container">
        {data?.map((comment) => {
          return(
          <div
            key={comment?.idcomments}
            className={`message rounded fs-14 my-3 bg-white border-orange p-3`}
          >
            <div className="d-flex justify-content-between">
              <div className="px-2 fs-14 fw-bold text-black">
                {comment.pseudo}
              </div>
              <img
                src={SvgNotes}
                className="pointer"
                alt=""
                onClick={() => {
                  setSelectedCommentId(comment?.idcomments);
                  setShowInput(!showInput)
                }}
              />
            </div>

            <div className="fs-12 px-2 col-12 text-secondary">
              {new Date(comment?.timestamp).toLocaleString()}
            </div>
            {selectedCommentId === comment?.idcomments && showInput === true ? (
              <>
                <div className="d-flex justify-content-end">
                  <div className="d-flex m-1">
                    <div
                      className="text-black text-center px-1 btn-notes-update"
                      onClick={DesactivateMessage}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={SvgDelete} alt="" className="mb-1"/>
                    </div>
                  </div>
                </div>
                <div className="p-2 m-0 comment-content text-secondary me-5">
                {comment?.content}
              </div>
              </>
            ) : (
              <div className="p-2 m-0 comment-content text-secondary me-5">
                {comment?.content}
              </div>
            )}
          </div>
        )
      
        }
      )}
      </ScrollToBottom>
    </div>
  );
};

export default ConvNotes;
