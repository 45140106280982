import React from "react";
import ReactApexChart from "react-apexcharts";

class GraphNombreContrat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "jours",
          data: props?.arrContracts,
        },
      ],
      options: this.getOptions(props.arrCategories),
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.arrContracts ) !== JSON.stringify(prevProps.arrContracts)) {
      this.setState({
        series: [
          {
            name: "jours",
            data: this.props.arrContracts,
          },
        ],
        options: this.getOptions(this.props.arrCategories),
      });
    }
  }

  getOptions(arrCategories) {
    console.log("data", arrCategories)
    return {
      chart: {
        type: "bar",
        // height: 230,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 3,
          dataLabels: {
            position: "top",
          },
        },
      },
      colors: ["var(--bs-purple)"],
      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
      },
      dataLabels: {
        enabled: true,
        offsetX: 0,
        style: {
          fontSize: "12px",
          colors: ["black"],
        },
      },
      stroke: {
        show: false,
      },
      yaxis: {
        lines: {
          show: false,
        },
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
      },
      xaxis: {
        show: false,
        categories: arrCategories,
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
      },
    };
  }

  render() {
    return (
      <div id="chart" className="line-chart-style bar-chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height="200%"
          className="h-100 w-100"
        />
      </div>
    );
  }
}
export default GraphNombreContrat;