import React from "react";
import ReactApexChart from "react-apexcharts";

class GrowthDounut extends React.Component {
  constructor(props) {
   
    super(props);
    this.state = {
        series: this.props.arrSerie,

      options: {
        chart: {
			type: "donut",
			width: 150,
		},
        colors: ['#10D078', '#10D078'],
        // colors: ['#3AC977', 'var(--primary-light)', '#f0c8a3', 'var(--danger)', '#bb6bd9'],
        labels: this.props.arrLabel,
        dataLabels: {
            enabled: false,
        },      
        
        legend: {
          show: false,
        },
        plotOptions: {          
            pie: {
                donut: {
                    size: '70%',
                    labels: {
                        show: true,
                        name: {
                          show: true,
                          offsetY: 12,
                        },
                        value: {
                          show: true,
                          fontSize: '22px',
                          fontFamily:'Arial',
                          fontWeight:'500',
                          offsetY: -17,
                        },
                        total: {
                          show: true,
                          fontSize: '14px',
                          fontWeight:'500',
                          fontFamily:'Arial',
                          label: 'Dollars', 
                          // color: 'var(--primary)',                                            
                          formatter: function (w) {
                            return w.globals.seriesTotals.reduce((a, b) => {
                              return a + b
                            }, 0)
                          }
                        }
                    }
                }
            }

        },
       
      },
    };
  }

  componentDidUpdate(prevProps) {
    // Vérifiez si arrSerie a changé
    if (this.props.arrSerie !== prevProps.arrSerie) {
      this.setState({ series: this.props.arrSerie });
    }
  
    // Vérifiez si arrLabel a changé
    if (this.props.arrLabel !== prevProps.arrLabel) {
      this.setState({
        options: {
          ...this.state.options,
          labels: this.props.arrLabel,
        },
      });
    }
  }

  render() {
    return (
      <div id="AllProject" className="ms-0">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width="70%"
        />
      </div>
    );
  }
}

export default GrowthDounut;
