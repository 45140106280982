import React from 'react'

const Dashboard = () => {
  return (
    <div>
      dash super admin
    </div>
  )
}

export default Dashboard
