import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Col, Card, Row } from "react-bootstrap";

import GraphDernierContrat from "./Graph/GraphDernierContrat";
import GraphNombreContrat from "./Graph/GraphNombreContrat";
import GraphRatioByAgent from "./Graph/GraphRatioByAgent";
import TableauConvertion from "./Graph/TableauConvertion";
import NewExpChart from "./Graph/NewExpChart";
import ImpressionBar from "./Graph/ImpressionBar";

import formatNumber from "../../utils/formatNumber";
import { SVGICON } from "../../jsx/constant/theme";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { getSockets } from "../Redux/slices/notifSocket.slice";
import { getToken, getAdminDesks } from "../Redux/slices/admin.slice";
import { getEuroUsd } from "../Redux/slices/taux.slice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config/config";
import useHeaderRest from "../../utils/useHeader";
import Table from "react-bootstrap/Table";
import BarChart from "./BarChart";
import BarChartReportAndCancelled from "./BarChartReportAndCancelled";

const GET_RATION_AND_CONVERSION = gql`
  query GetRationAndConversion {
    getRationAndConversion {
      desk
      total_amount_encaisse
      preuve_count
      total_amount_signed
    }
  }
`;

const GET_CONVERSION_BY_DESK = gql`
  query getConversionByDesk {
    getConversionByDesk {
      sum_conversion
      desk
    }
  }
`;

const GET_MONTLY_CONTRACT_BY_DESK = gql`
  query GetMonthlyContractByDesk {
    getMonthlyContractByDesk {
      total_contracts
      desk
    }
  }
`;

const GET_DAYLY_CONTRACT_BY_DESK = gql`
  query GetDaylyContractByDesk {
    getDaylyContractByDesk {
      total_contracts
      desk
    }
  }
`;

const DeskVsDesk = () => {
  const useHeader = useHeaderRest();

  const GET_SOCKETS = useSelector(getSockets);
  const GET_TOKEN = useSelector(getToken);
  const GET_EURO_USD = useSelector(getEuroUsd);
  const [dataAveragePerAgentSigned, setDataAveragePerAgentSigned] = useState();
  const [dataAveragePerDeskSigned, setDataAveragePerDeskSigned] = useState();
  const [dataConversionPerDesk, setDataConversionPerDesk] = useState([]);
  const [dataMonthlyContractByDeskk, setDataMonthlyContractByDeskk] =
    useState();
  const [dataDaylyContractByDesk, setDataDaylyContractByDesk] = useState();
  const [dataRatioConversionn, setDataRatioConversionn] = useState();
  const [dataAveragePerAgentPerDesk, setDataAveragePerAgentPerDesk] =
    useState();

    console.log(dataRatioConversionn)

  const DatAveragePerAgentSigned = () => {
    axios
      .get(
        `${config.apiAdmin}/statsNode/manager/average_per_agent_signed`,
        useHeader
      )
      .then((response) => {
        // console.log(response.data.data.averagePerAgent);
        setDataAveragePerAgentSigned(response.data.data.averagePerAgent);
      })
      .catch((error) => {
        setDataConversionPerDesk();
        console.log(error);
      });
  };

  const DatAveragePerDeskSigned = () => {
    axios
      .get(
        `${config.apiAdmin}/statsNode/manager/average_per_desk_signed`,
        useHeader
      )
      .then((response) => {
        // console.log(response.data.data.averagePerDesk);
        setDataAveragePerDeskSigned(response.data.data.averagePerDesk);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DatConversionPerDesk = () => {
    axios
      .get(
        `${config.apiAdmin}/statsNode/manager/conversion_per_desk`,
        useHeader
      )
      .then((response) => {
        //  console.log(response.data.data.conversionArr);
        setDataConversionPerDesk(response.data.data.conversionArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DataMonthlyContractPerDesk = () => {
    axios
      .get(
        `${config.apiAdmin}/statsNode/manager/monthly_contract_per_desk`,
        useHeader
      )
      .then((response) => {
        // console.log("response.data.data.conversionArr", response.data.data.conversionArr);
        setDataMonthlyContractByDeskk(response.data.data.conversionArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DataDaylyContractPerDesk = () => {
    axios
      .get(
        `${config.apiAdmin}/statsNode/manager/daily_contract_per_desk`,
        useHeader
      )
      .then((response) => {
        // console.log("DataDaylyContractPerDesk", response.data.data.conversionArr);
        setDataDaylyContractByDesk(response.data.data.conversionArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DataRatioConversion = () => {
    axios
      .get(`${config.apiAdmin}/statsNode/manager/report_by_desk`, useHeader)
      .then((response) => {
        console.log("RatioPerformance", response.data.data.RatioPerformance);
        setDataRatioConversionn(response.data.data.RatioPerformance);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const AveragePerAgent = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/card_average_per_agent`,
        {},
        useHeader
      )
      .then((response) => {
        console.log("response ===>", response.data.data.averagePerAgent);
        setDataAveragePerAgentPerDesk(response.data.data.averagePerAgent);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    DataMonthlyContractPerDesk();
    DatAveragePerAgentSigned();
    DatAveragePerDeskSigned();
    DatConversionPerDesk();
    DataDaylyContractPerDesk();
    DataRatioConversion();
    AveragePerAgent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GET_SOCKETS]);

  const calculateTotal = (data) => {
    const encashed = parseInt(data.encashed, 10) || 0;
    const proof = parseInt(data.proof, 10) || 0;
    const signed = parseInt(data.signed, 10) || 0;

    const total = 0 + encashed + proof + signed;

    return total.toLocaleString("fr-FR");
  };

  return (
    <>
      {/* Premiere ligne */}
      <div className="d-flex align-items-stretch premier-ligne-dash">
        <Col sm={3} className="p-2">
          <Card>
            <Card.Header>
              <div className="card-title">Moyenne par contrat / desk</div>
            </Card.Header>
            <Card.Body className="p-0">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Desk</th>
                    <th>Nombre Contrats</th>
                    <th>Moyenne</th>
                  </tr>
                </thead>
                <tbody className="table-average">
                  {dataAveragePerAgentPerDesk &&
                    dataAveragePerAgentPerDesk.map((desk, index) => (
                      <tr key={index}>
                        <td>{desk.desk}</td>
                        <td>{desk.total_contracts}</td>
                        <td>{formatNumber(desk.average)} €</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={3} className="p-2">
          <Card>
            <Card.Header>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2">
                    Il s'agit de la somme des contrats que chaque desk a
                    réalisée aujourd'hui même.
                  </Tooltip>
                }
              >
                <h4 className="card-title">Nombre Contrats (jour)/Desk </h4>
              </OverlayTrigger>
            </Card.Header>
            <Card.Body className="p-0">
              {dataDaylyContractByDesk && (
                <GraphDernierContrat
                  arrCategories={dataDaylyContractByDesk.map(
                    (desk) => `D${desk.desk}`
                  )}
                  arrCount={dataDaylyContractByDesk.map(
                    (desk) => desk.contract_count
                  )}
                />
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col sm={3} className="p-2">
          <Card>
            <Card.Header>
              <h4 className="card-title">Nombre contrats (mois) / Desk</h4>
            </Card.Header>
            <Card.Body className="p-0">
              {dataMonthlyContractByDeskk && (
                <GraphNombreContrat
                  arrCategories={dataMonthlyContractByDeskk.map(
                    (desk) => `D${desk.desk}`
                  )}
                  arrContracts={dataMonthlyContractByDeskk.map(
                    (desk) => desk.contract_count
                  )}
                />
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col sm={3} className="p-2">
          <Card>
            <Card.Header>
              <div className="card-title">Nombre de conversion Par Desk</div>
            </Card.Header>
            <Card.Body className="p-0">
              {dataConversionPerDesk && (
                <TableauConvertion
                  horizontal={false}
                  arrCategories={dataConversionPerDesk.map(
                    (desk) => `D${desk.desk}`
                  )}
                  data={dataConversionPerDesk.map((desk) => desk.conversion)}
                />
              )}
            </Card.Body>
          </Card>
        </Col>

        
      </div>

      <Row>
        <Col xl={6} xxl={6} sm={6} className="p-2">
          <Card className="crm-cart">
            <Card.Body className="">
              <div className="crm-cart-data">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="button-tooltip-3">
                      Dans cette moyenne, nous ne prenons pas en compte : les
                      retraits, les annulations ainsi que les contrats reportés.
                      <br />
                      Nous récupérons tous les sellers ainsi que les team
                      leaders, et nous faisons la moyenne de leur chiffre.
                      <br />
                      la somme final est ensuite convertit en dollars au taux
                      suivant : {GET_EURO_USD}
                      <br />( Prenez en compte que les contrats d'un manager ne
                      seront pas pris en compte dans cette moyenne. )
                    </Tooltip>
                  }
                >
                  <h5 className="d-block mb-1 text-black">
                    Moyenne chiffre par Agent (signé)
                  </h5>
                </OverlayTrigger>
                <p>
                  <span>
                    {formatNumber(
                      (Number(dataAveragePerAgentSigned) || 0) * (Number(GET_EURO_USD) || 0)
                    )}
                  </span>{" "}
                  $
                </p>
              </div>
            </Card.Body>
            <NewExpChart color={"green"} />
          </Card>
        </Col>
        <Col xl={6} xxl={6} sm={6} className="p-2">
          <Card className="crm-cart">
            <Card.Header className="border-0 pb-0 diposit-bg">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-4">
                    ce chiffre prend en compte les commandes du mois, ne prenant
                    pas en compte des annulations, des retraits, des contrats
                    reportes.
                    <br />
                    ici nous cherchons tous les desks sans prendre en compte
                    leur nombres d'agents, et nous faisons la moyenne de leur
                    chiffre.
                    <br />
                    la somme final est ensuite convertit en dollars au taux
                    suivant : {GET_EURO_USD}
                  </Tooltip>
                }
              >
                <h5 className="d-block text-black">
                  Moyenne par Desk (Signer)
                </h5>
              </OverlayTrigger>

              <div className="icon-box">{SVGICON.DollerBlack}</div>
            </Card.Header>
            <Card.Body className="py-0">
              <div className="crm-cart-data d-flex align-items-center">
                <p>
                  <span>
                    {formatNumber(
                      (Number(dataAveragePerDeskSigned) || 0) * (Number(GET_EURO_USD) || 0)
                    )}
                  </span>
                  $
                </p>
              </div>
              <ImpressionBar />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Seconde ligne */}
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header>
              <div className="card-title col-12 ">
                <div className="d-flex align-iterms-center">
                  <div className="text-start col-6">
                    Ratio chiffre total par Desk
                  </div>
                  <div className="col-5 d-flex justify-content-around align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Preuve me-1"></div>
                      <div>Preuve</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Signé me-1"></div>
                      <div>Signé</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Encaissé me-1"></div>
                      <div>Encaissé</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-5">
                          Tous les chiffres ici ne prenent pas en compte les
                          retraits.
                          <br />
                          <br />
                          Ici tous les contracts sont des contracts actifs
                          <br />
                          <br />
                          Chiffre signE veut dire : n'est pas annulE, n'est pas
                          reportE, pas encaisser, et sans preuve.
                          <br />
                          <br />
                          Preuve veut dire : pas annule , ni reporte, ni
                          encaisser, et dont la case preuve a EtE cauchE.
                          <br />
                          <br />
                          Encaisse veut dire : pas annule , ni reporte, et dont
                          la case encaissE a EtE cauchE.
                        </Tooltip>
                      }
                    >
                      <h5 className="d-block text-black">?</h5>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </Card.Header>

            <Card.Body className="p-0">
              <BarChart
                height={"350px"}
                arrCategories={dataRatioConversionn?.map((agent) => {
                  const total = calculateTotal(agent);
                  return { agent, total };
                })}
                arrEncaisse={dataRatioConversionn?.map((desk) => desk.encashed)}
                arrSigned={dataRatioConversionn?.map((desk) => desk.signed)}
                arrPreuve={dataRatioConversionn?.map((desk) => desk.proof)}
                arrReported={dataRatioConversionn?.map((desk) => desk.reported)}
                arrCanceled={dataRatioConversionn?.map((desk) => desk.canceled)}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
{/* ///////////////////////////////////////// ///////////////////////////////////////////////////////// */}
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header>
              <div className="card-title col-12 ">
                <div className="d-flex align-iterms-center">
                  <div className="text-start col-6">
                    Ratio chiffre total annulé/reporté par Desk
                  </div>
                  <div className="col-5 d-flex justify-content-around align-items-center">

                    <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Report me-1"></div>
                      <div>Reporté</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Annulé me-1"></div>
                      <div>Annulé</div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="button-tooltip-5">
                          Tous les chiffres ici ne prenent pas en compte les
                          retraits.
                          <br />
                          <br />
                          Ici tous les contracts sont des contracts actifs
                          <br />
                          <br />
                          Chiffre signE veut dire : n'est pas annulE, n'est pas
                          reportE, pas encaisser, et sans preuve.
                          <br />
                          <br />
                          Preuve veut dire : pas annule , ni reporte, ni
                          encaisser, et dont la case preuve a EtE cauchE.
                          <br />
                          <br />
                          Encaisse veut dire : pas annule , ni reporte, et dont
                          la case encaissE a EtE cauchE.
                        </Tooltip>
                      }
                    >
                      <h5 className="d-block text-black">?</h5>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </Card.Header>

            <Card.Body className="p-0">
              <BarChartReportAndCancelled
                height={"350px"}
                arrCategories={dataRatioConversionn?.map((agent) => {
                  const total = calculateTotal(agent);
                  return { agent, total };
                })}
                arrEncaisse={dataRatioConversionn?.map((desk) => desk.encashed)}
                arrSigned={dataRatioConversionn?.map((desk) => desk.signed)}
                arrPreuve={dataRatioConversionn?.map((desk) => desk.proof)}
                arrReported={dataRatioConversionn?.map((desk) => desk.reported)}
                arrCanceled={dataRatioConversionn?.map((desk) => desk.canceled)}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DeskVsDesk;

// let Header = {
//   headers: {
//     authorization: `Bearer ${localStorage.getItem('tokenadmin')}`,
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//   },
// };


{/* <div className="d-flex text-center input-adapt text-black p-0">
<div
  className={`col-6 border-bottom border-end bg-encaisse-stats-agent p-0 ${
    index === 0 ? "first-div-top" : ""
  }`}
>
  {formatNumber(
    item?.month_encashed +
      item?.prev_month_encashed
  )}{" "}
  €
</div>
<div className="col-6 border-bottom p-0">
  {Math.round(
    ((item?.month_encashed +
      item?.prev_month_encashed) *
      100) /
      item?.month_total_curr_and_prev
  ) || 0}
  %
</div>
</div>
<div className="d-flex justify-content-center align-items-center input-adapt p-0">
<div
  className={`col-12 text-center fw-bold ${
    item.tlead_id === "" || item.tlead_id === null
      ? "text-black"
      : "text-white"
  }`}
  style={{
    backgroundColor: uuidToColor(item.tlead_id),
  }}
>
  {item?.pseudo?.charAt(0).toUpperCase() +
    item?.pseudo?.slice(1)}
</div>
</div>
<div className="d-flex text-end bg-signer-stats-agent p-1 input-adapt text-black p-0 p-0">
<div className="col-6 text-black p-0">
  {formatNumber(item?.month_signed)} €
</div>
<div className="col-6 border-start p-0">
  {formatNumber(item?.prev_month_signed)} €
</div>
</div>
<div className="d-flex text-end bg-preuve-stats-agent p-1 input-adapt text-black p-0">
<div className="col-6 p-0">
  {formatNumber(item?.month_proof)}€
</div>
<div className="col-6 border-start p-0">
  {formatNumber(item?.prev_month_proof)} €
</div>
</div>
<div className="d-flex text-end bg-encaisse-stats-agent p-1 input-adapt text-black p-0">
<div className="col-6 p-0">
  {formatNumber(item?.month_encashed)}€
</div>
<div className="col-6 border-start p-0">
  {formatNumber(item?.prev_month_encashed)} €
</div>
</div>
<div className="d-flex justify-content-center bg-dark input-adapt p-0">
<div className="text-white fw-bold p-0">
  {formatNumber(
    item?.month_total_curr_and_prev
  )}{" "}
  €
</div>
</div>
<div className="d-flex justify-content-center input-adapt p-0">
<div>
  $ {formatNumber(item.month_total_usd_curr_and_prev)}
</div>
</div> */}