import React from "react";
import ReactApexChart from "react-apexcharts";
import formatNumber from "../../utils/formatNumber";
import { Spinner } from "react-bootstrap";

class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        ...this.getChartStateFromProps(props),
        loading: true, // Initialiser l'état de chargement
      };
  }

  componentDidMount() {
    // Simuler un délai de chargement pour les données, vous pouvez remplacer ceci par un appel de données réel
    setTimeout(() => {
      this.setState({ loading: false }); // Une fois que le composant est monté et que les données sont prêtes, mettre loading à false
    }, 1000); // Vous pouvez ajuster le délai selon vos besoins ou supprimer ce délai si vos données sont prêtes immédiatement
  }

  getChartStateFromProps(props) {
    const extractedNames =
      props.arrCategories?.length > 0
        ? props.arrCategories.map((item) => item?.agent?.desk)
        : [];

    const stringArray = extractedNames.map((number) => number?.toString());
    const stringArrayWithD = stringArray.map((title) => `D${title}`);

    return {
      series: [
        {
          name: "Encaisse",
          data: props.arrEncaisse || [],
        },
        {
          name: "Preuve",
          data: props.arrPreuve || [],
        },
        {
          name: "Signe",
          data: props.arrSigned || [],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        colors: [
          "rgb(16, 208, 120)",
          "rgb(254, 254, 174)",
          "rgb(255, 165, 0)",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "14px",
                  fontWeight: 600,
                  colors: ["#000"],
                },
                formatter: function (val) {
                  return `${formatNumber(val)}$`; // Ajouter le symbole $ après le nombre formaté
                },
              },
            },
          },
        },
        xaxis: {
          categories: stringArrayWithD,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#000"], // Couleur noire pour les données sur les barres
          },
          formatter: function (val) {
            return `${formatNumber(val)}$`; // Ajouter le symbole $ après le nombre formaté
          },
        },
      },
    };
  }

  componentDidUpdate(prevProps) {
    // Vérifier si les données ont changé pour éviter des mises à jour inutiles
    if (
      prevProps.arrCategories !== this.props.arrCategories ||
      prevProps.arrEncaisse !== this.props.arrEncaisse ||
      prevProps.arrPreuve !== this.props.arrPreuve ||
      prevProps.arrSigned !== this.props.arrSigned ||
      prevProps.arrReported !== this.props.arrReported
    ) {
      // Mettre à jour l'état avec les nouvelles props
      this.setState(this.getChartStateFromProps(this.props));
    }
  }

  render() {
    if (this.state.loading) {
        return (
          <div className="chargement-graph-agent">
            <Spinner>
            </Spinner>
          </div>
        ); // Utilisez votre propre composant ou classe CSS pour styliser le loader
      }
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}

export default BarChart;
