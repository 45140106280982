import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config/config";
import svgUpdate from "../../../images/cycle-svgrepo-com.svg";

const Activite = (user) => {
  const [listActivite, setListActivite] = useState([]);

  const idContrat = user?.contrat.idcontracts;

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    let obj = { idContrats: idContrat };
    axios
      .post(
        `${config.apiAdmin}/sadmin/notifs/read_one_by_id_contract`,
        obj,
        Header
      )
      .then((response) => {
        setListActivite(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const objSvg = {
    Dollar: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="black"
        className="bi bi-currency-dollar"
        viewBox="0 0 16 16"
      >
        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z" />
      </svg>
    ),
    Add: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="black"
        className="bi bi-plus"
        viewBox="0 0 16 16"
      >
        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
      </svg>
    ),
    Update: (
      <img src={svgUpdate} alt="Update" className=" m-auto" />
    ),
  };

  function getSvgByType(type) {
    switch (type) {
      case "new-contract":
        return objSvg.Add;
      case "update-contract":
        return objSvg.Update;
      case "encaisse":
        return objSvg.Dollar;
      default:
        return null; // Ou vous pouvez retourner un SVG par défaut si nécessaire
    }
  }

  const formatDate = (dateString) => {
    if (!dateString) return null;

    const date = new Date(dateString); // Crée un objet Date à partir de la chaîne de date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0, donc +1
    const day = String(date.getDate()).padStart(2, "0");
    // Formater la date et l'heure dans l'ordre souhaité
    return `${day}-${month}-${year}`;
  };
  const formatHours = (dateString) => {
    if (!dateString) return null;

    const date = new Date(dateString); // Crée un objet Date à partir de la chaîne de date
    const hours = String(date.getHours()).padStart(2, "0"); // Pour les heures
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Pour les minutes

    // Formater la date et l'heure dans l'ordre souhaité
    return `${hours}-${minutes}`;
  };

  return (
    <div
      className="m-4 rounded bg-white border-0"
      style={{
        display:"flex",
        flexDirection:"column",
        justifyContent:"flex-start",
        height: "100%", // Set a fixed height for the scrollable container
        backgroundColor: "rgb(238, 238, 238)",
        overflowY: "auto", 
      }}
    >
      {listActivite?.map((notif, index) => {
        return (
          <div key={index} className="bg-white p-2 mb-2 my-5 rounded">
            <div className="d-flex align-items-start">
              <div className="col-3 text-center fw-bold text-black">
                <div className="pt-1 text-center">{formatDate(notif?.timestamp?.slice(0, 16))}</div>
                <div className="fw-light">
                { notif?.timestamp
                 ? new Date(notif.timestamp).toLocaleTimeString([], { 
                  hour: '2-digit', 
                   minute: '2-digit', 
                    hour12: false }):'Heure inconnue'}</div>
              </div>
              <div className="col-1 d-flex align-item-center justify-content-center me-3" style={{height:"25px", width:"25px", backgroundColor:"#d9d9d9", borderRadius:"50%"}}>
                {getSvgByType(notif.type)}
              </div>
              <div className="col-6">
                <p
                  dangerouslySetInnerHTML={{ __html: notif.content }}
                  className="text-black"
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Activite;
