import React, { useState, useEffect } from "react";
import { Card, Table, ProgressBar } from "react-bootstrap";
import axios from "axios";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { CircleLoader } from "react-spinners";
import { getSockets } from "../../Redux/slices/notifSocket.slice";
import { useParams } from "react-router-dom";
import TrTableSupport from "./TrTableSupport";
import TrTableSupport2 from "./TrTableSupport2";
import { useDispatch } from "react-redux";
import sortTableSupp from "./sortTableSupp";
import { getListSupport, setListSupport } from "../../Redux/slices/contract.slice";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const TableauChefSupportHorizontal = () => {



  const initRow = {
    pseudo: { etat: 3 },
    total_sum_amount: { etat: 3 },
    total_count: { etat: 3 },
    sum_amount_argent_dispo: { etat: 3 },
    sum_amount_report: { etat: 3 },
    sum_amount_preuve: { etat: 3 },
    sum_amount_encaisse: { etat: 3 },
    canceled_count: { etat: 3 },
    total_encaisse: { etat: 3 },
  };


  const [Loader, setLoader] = useState(true);
  const [sortRow, setSortRow] = useState(initRow);

  const dispatch = useDispatch();
  const listSupport = useSelector(getListSupport);

  const GET_SOCKETS = useSelector(getSockets);
  const AdminDetails = useSelector(getAdminDetails);
  const deskAdmin = AdminDetails?.desk;
  const { desk } = useParams();



  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const GetAllAgt_supp = () => {
    let obj = {
      profil: "agt_supp",
      profil2: "mng_supp",
      desk: desk === undefined ? deskAdmin : [desk],
    };
    axios
      .post(
        `${config.apiAdmin}/sadmin/users/users_by_profil_desk_for_support`,
        obj,
        Header
      )
      .then((response) => {
        // setListAgt_supp(response.data);
        dispatch(setListSupport(response.data));
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    GetAllAgt_supp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GET_SOCKETS, desk]);

  function getBackgroundClass(ratio) {
    const value = parseFloat(ratio);

    if (value === 0) {
      return "bg-dash-very-dark-green"; // Vert très foncé
    } else if (value > 0 && value <= 2) {
      return "bg-dash-dark-green"; // Vert foncé
    } else if (value > 2 && value <= 4) {
      return "bg-dash-light-green"; // Vert clair
    } else if (value > 4 && value <= 6) {
      return "bg-dash-white"; // Blanc
    } else if (value > 6 && value <= 8) {
      return "bg-dash-light-red"; // Rouge clair
    } else if (value > 8 && value <= 10) {
      return "bg-dash-medium-red"; // Rouge moyen
    } else if (value > 10) {
      return "bg-dash-very-dark-red"; // Rouge très foncé
    } else {
      return ""; // Classe par défaut si aucune condition n'est remplie
    }
  }

  const stateRow = (row, type) => {
    if (sortRow[row].etat === 3) {
      setSortRow({ ...initRow, [row]: { etat: 1 } });
      sortTableSupp(row, type, listSupport, 1, dispatch, setListSupport);
    } else if (sortRow[row].etat === 1) {
      setSortRow({ ...initRow, [row]: { etat: 2 } });
      sortTableSupp(row, type, listSupport, 2, dispatch, setListSupport);
    } else if (sortRow[row].etat === 2) {
      setSortRow({ ...initRow, [row]: { etat: 3 } });
      sortTableSupp(row, type, listSupport, 3, dispatch, setListSupport);
    }
  };

  return (
    <div>
      {Loader ? (
        <div className="sweet-loading" style={{ marginTop: "300px" }}>
          <CircleLoader
            color="#36d7b7"
            cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="row">
          {desk === undefined ? (
            <div className="col-12">
              <Card>
                <Card.Body className="p-0">
                  <Table responsive bordered striped>
                    <thead className="text-center rounded">
                    {sortRow && <TrTableSupport sortRow={sortRow} stateRow={stateRow} />}
                      {/* <tr className="rounded">
                        <th className="fw-bold text-black fs-5 rounded">
                          Desk
                        </th>
                        <th className="fw-bold text-black fs-5">Total</th>
                        <th className="fw-bold text-black fs-5">Nb lignes</th>
                        <th className="fw-bold text-black fs-5 bg-orange">
                          Signer
                        </th>
                        <th className="fw-bold text-black fs-5 bg-purple">
                          Report
                        </th>
                        <th className="fw-bold text-black fs-5 bg-warning">
                          Preuve
                        </th>
                        <th className="fw-bold text-black fs-5 bg-success">
                          Encaisser
                        </th>
                        <th className="fw-bold text-black fs-5">Mort</th>
                        <th className="fw-bold text-black fs-5 rounded"></th>
                      </tr> */}
                    </thead>
                    <tbody className="text-center tableau-supp">
                      {listSupport
                        // ?.filter((agent_supp) => {
                        //   return agent_supp?.desk_name?.join(" ") === agent;
                        // })
                        ?.map((agent, j) => (
                          <React.Fragment key={j}>
                            <tr className="fw-bold">
                              <td rowSpan={2} className={`fs-5 col-1`}>
                                {agent.pseudo}
                              </td>
                              <td className="col-1 text-black">
                                {agent.total_sum_amount
                                  ? `${parseFloat(
                                      agent.total_sum_amount
                                    )?.toLocaleString("fr-FR")} €`
                                  : "0"}
                              </td>
                              <td className="col-1 text-black">
                                {agent.total_count > 0
                                  ? agent.total_count?.toLocaleString(
                                      "fr-FR"
                                    )
                                  : "0"}
                              </td>
                              <td className="col-1 text-black bg-signer-stats">
                                {agent.sum_amount_argent_dispo
                                  ? `${parseFloat(
                                      agent.sum_amount_argent_dispo
                                    )?.toLocaleString("fr-FR")} €`
                                  : "0"}
                              </td>
                              <td className="col-1 text-black bg-report-stats">
                                {agent.sum_amount_report
                                  ? `${parseFloat(
                                      agent.sum_amount_report
                                    )?.toLocaleString("fr-FR")} €`
                                  : "0"}
                              </td>
                              <td className="col-1 text-black bg-preuve-stats">
                                {agent.sum_amount_preuve
                                  ? `${parseFloat(
                                      agent.sum_amount_preuve
                                    )?.toLocaleString("fr-FR")} €`
                                  : "0"}
                              </td>
                              <td className="col-1 text-black bg-encaisse-stats">
                                {agent.sum_amount_encaisse
                                  ? `${parseFloat(
                                      agent.sum_amount_encaisse
                                    )?.toLocaleString("fr-FR")} $`
                                  : "0"}
                              </td>
                              <td className="col-1 text-black">
                                {agent.canceled_count > 0
                                  ? agent.canceled_count?.toLocaleString(
                                      "fr-FR"
                                    )
                                  : "0"}
                              </td>
                              <td rowSpan={2} className="p-2 ">
                                <ProgressBar
                                  className="border fs-5"
                                  style={{ height: "1.5rem" }}
                                  variant={"purple"}
                                  now={
                                    agent?.total_encaisse
                                      ? parseFloat(agent.total_encaisse)
                                      : 0
                                  }
                                  max={100}
                                  label={`${
                                    agent?.total_encaisse
                                      ? parseFloat(agent?.total_encaisse)
                                          .toFixed(1)
                                          ?.toLocaleString("fr-FR")
                                      : "0,0"
                                  }%`}
                                />
                              </td>
                            </tr>
                            <tr className="">
                              <td>{agent.totalP}</td>
                              <td className="col-1"></td>
                              <td className=" bg-signer-stats fw-bold">
                                {agent.total_argent_dispo
                                  ? `${parseFloat(
                                      agent.total_argent_dispo
                                    )?.toFixed(1)} %`
                                  : "0"}
                              </td>
                              <td className="bg-report-stats fw-bold">
                                {agent.total_report_ratio &&
                                agent.total_report_ratio !== "0.0000"
                                  ? `${parseFloat(
                                      agent.total_report_ratio
                                    )?.toFixed(1)} %`
                                  : "0"}
                              </td>
                              <td className="bg-preuve-stats fw-bold">
                                {agent.total_preuve
                                  ? `${parseFloat(agent.total_preuve)?.toFixed(
                                      1
                                    )} %`
                                  : "0"}
                              </td>
                              <td className="bg-encaisse-stats fw-bold">
                                {agent.total_encaisse
                                  ? `${parseFloat(
                                      agent.total_encaisse
                                    )?.toFixed(1)} %`
                                  : "0"}
                              </td>
                              <td
                                className={`${getBackgroundClass(
                                  agent.canceled_contract_ratio
                                )} text-black fw-bold`}
                              >
                                {parseFloat(agent.canceled_contract_ratio) > 0
                                  ? `${parseFloat(
                                      agent.canceled_contract_ratio
                                    )?.toFixed(1)} %`
                                  : "0.0%"}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>
          ) : (
            [desk]?.map((agent, i) => (
              <div className="col-12" key={i}>
                <Card>
                  <Card.Body className="p-0">
                    <Table responsive bordered striped>
                      <thead className="text-center rounded">
                      {sortRow && <TrTableSupport2 sortRow={sortRow} stateRow={stateRow} />}
                      </thead>
                      <tbody className="text-center tableau-supp">
                        {listSupport
                          // ?.filter((agent_supp) => {
                          //   return agent_supp?.desk_name?.join(" ") === agent;
                          // })
                          ?.map((agent, j) => (
                            <React.Fragment key={j}>
                            <tr className="fw-bold">
                              <td rowSpan={2} className={`fs-5 col-1`}>
                                {agent.pseudo}
                              </td>
                              <td className="col-1 text-black">
                                {agent.total_sum_amount
                                  ? `${parseFloat(
                                      agent.total_sum_amount
                                    )?.toLocaleString("fr-FR")} €`
                                  : "0"}
                              </td>
                              <td className="col-1 text-black">
                                {agent.total_count > 0
                                  ? agent.total_count?.toLocaleString(
                                      "fr-FR"
                                    )
                                  : "0"}
                              </td>
                              <td className="col-1 text-black bg-signer-stats">
                                {agent.sum_amount_argent_dispo
                                  ? `${parseFloat(
                                      agent.sum_amount_argent_dispo
                                    )?.toLocaleString("fr-FR")} €`
                                  : "0"}
                              </td>
                              <td className="col-1 text-black bg-report-stats">
                                {agent.sum_amount_report
                                  ? `${parseFloat(
                                      agent.sum_amount_report
                                    )?.toLocaleString("fr-FR")} €`
                                  : "0"}
                              </td>
                              <td className="col-1 text-black bg-preuve-stats">
                                {agent.sum_amount_preuve
                                  ? `${parseFloat(
                                      agent.sum_amount_preuve
                                    )?.toLocaleString("fr-FR")} €`
                                  : "0"}
                              </td>
                              <td className="col-1 text-black bg-encaisse-stats">
                                {agent.sum_amount_encaisse
                                  ? `${parseFloat(
                                      agent.sum_amount_encaisse
                                    )?.toLocaleString("fr-FR")} $`
                                  : "0"}
                              </td>
                              <td className="col-1 text-black">
                                {agent.canceled_count > 0
                                  ? agent.canceled_count?.toLocaleString(
                                      "fr-FR"
                                    )
                                  : "0"}
                              </td>
                              <td rowSpan={2} className="p-2 ">
                                <ProgressBar
                                  className="border fs-5"
                                  style={{ height: "1.5rem" }}
                                  variant={"purple"}
                                  now={
                                    agent?.total_encaisse
                                      ? parseFloat(agent.total_encaisse)
                                      : 0
                                  }
                                  max={100}
                                  label={`${
                                    agent?.total_encaisse
                                      ? parseFloat(agent?.total_encaisse)
                                          .toFixed(1)
                                          ?.toLocaleString("fr-FR")
                                      : "0,0"
                                  }%`}
                                />
                              </td>
                            </tr>
                            <tr className="">
                              <td>{agent.totalP}</td>
                              <td className="col-1"></td>
                              <td className=" bg-signer-stats fw-bold">
                                {agent.total_argent_dispo
                                  ? `${parseFloat(
                                      agent.total_argent_dispo
                                    )?.toFixed(1)} %`
                                  : "0"}
                              </td>
                              <td className="bg-report-stats fw-bold">
                                {agent.total_report_ratio &&
                                agent.total_report_ratio !== "0.0000"
                                  ? `${parseFloat(
                                      agent.total_report_ratio
                                    )?.toFixed(1)} %`
                                  : "0"}
                              </td>
                              <td className="bg-preuve-stats fw-bold">
                                {agent.total_preuve
                                  ? `${parseFloat(agent.total_preuve)?.toFixed(
                                      1
                                    )} %`
                                  : "0"}
                              </td>
                              <td className="bg-encaisse-stats fw-bold">
                                {agent.total_encaisse
                                  ? `${parseFloat(
                                      agent.total_encaisse
                                    )?.toFixed(1)} %`
                                  : "0"}
                              </td>
                              <td
                                className={`${getBackgroundClass(
                                  agent.canceled_contract_ratio
                                )} text-black fw-bold`}
                              >
                                {parseFloat(agent.canceled_contract_ratio) > 0
                                  ? `${parseFloat(
                                      agent.canceled_contract_ratio
                                    )?.toFixed(1)} %`
                                  : "0.0%"}
                              </td>
                            </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default TableauChefSupportHorizontal;
