import React, { useEffect } from 'react'
import CardsChefSupport from './Graph/CardsMngSupport'
import ActiveProjects from './Graph/ActiveProjects'
import { Table } from 'react-bootstrap'
import { useQuery, gql } from "@apollo/client";
import { getToken } from '../Redux/slices/admin.slice'
import { getSockets } from '../Redux/slices/notifSocket.slice'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';

const GET_PART_PSP = gql`
  query GetPartPsp {
    GetPartPsp {
      nombre_de_lignes
      part_encaisse_total
      pourcentage_in
      psp
      sum_amount
    }
  }
`;

const GET_REPORT_CONTRACT = gql`
query GetReportContractByProfilDesk($desks: [String]) {
  GetReportContractByProfilDesk(desks: $desks) {
    total_amount
    total_amount_with_preuve
    total_amount_with_encaisse
    total_amount_reported_not_encashed
  }
}
`;

const GET_LIST_RECAP_SUPPORT = gql`
query GetListRecapSupport {
  GetListRecapSupport {
    idcontracts
    pseudo
    fname
    lname
    argent_dispo
    preuve
    encaisse
    is_report
    is_canceled
    paymentdudate
  }
}
`;

const CONTRACT_TODO = gql`
query MngSuppContractsToDo($desks: [String]) {
  mngSuppContractsToDo(desks: $desks) {
    total
    tot_without_psp
    tot_without_bank
    tot_without_rdv
    tot_report
  }
}
`;

const Cards = () => {

  const { desk } = useParams();
 
  const [goodDesk, setGoodDesk] = React.useState(desk ? [desk] : null);
  const [refreshKey, setRefreshKey] = React.useState(0);

  useEffect(() => {
    setGoodDesk(desk ? [desk] : null);
    setRefreshKey(prevKey => prevKey + 1); // Force le re-rendu du composant
  }, [desk]);

  const GET_TOKEN = useSelector(getToken)
  const GET_SOCKETS = useSelector(getSockets)
  const Header = {
    context: {
      headers: {
        Authorization: `Bearer ${GET_TOKEN}`,
      }
    }
  }

  const { data: dataPartPsp, refetch: refetchPartPsp } = useQuery(GET_PART_PSP, Header)

  // ici on aura besoin d'envoyer le good desk
  const { data: dataReportContract, refetch: refetchReportContract } = useQuery(
    GET_REPORT_CONTRACT,{
      context: {
        headers: {
          Authorization: `Bearer ${GET_TOKEN}`,
        },
      },
      variables: { desks: goodDesk },
    } );
  const { data: dataRecapBossSupp, refetch: refetchRecapBossSupp } = useQuery(GET_LIST_RECAP_SUPPORT, {
    context: {
      headers: {
        Authorization: `Bearer ${GET_TOKEN}`,
      },
    },
    variables: { desks: goodDesk },
  } )
  const { data: dataContractToDo, refetch: refetchContractToDo } = useQuery(CONTRACT_TODO, {
    context: {
      headers: {
        Authorization: `Bearer ${GET_TOKEN}`,
      },
    },
    variables: { desks: goodDesk },
  } )

   console.log("dataReportContract", dataReportContract)

  useEffect(() => {
    if (dataPartPsp) {
      refetchPartPsp();
      refetchReportContract();
      refetchRecapBossSupp();
      refetchContractToDo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GET_SOCKETS, desk, refreshKey]);

  return (
    <div className="row">
      <div className="col-9">
        <div>
          { dataReportContract?.GetReportContractByProfilDesk && dataContractToDo?.mngSuppContractsToDo &&
            <CardsChefSupport 
              dataReportContract={dataReportContract?.GetReportContractByProfilDesk} 
              dataContractToDo={dataContractToDo?.mngSuppContractsToDo}
            />
          }

        </div>
        <div>
        {dataRecapBossSupp?.GetListRecapSupport &&
          <ActiveProjects dataListRecapSupport={dataRecapBossSupp?.GetListRecapSupport} />
        }
        </div>

      </div>
      <div className="col-3 p-0 custom-table-container">
        <Table  responsive bordered hover variant={"secondary"} className={"custom-table"} >
          <thead>
            <tr>
              <th>PSP</th>
              <th title={"Total Amount"} >Amount</th>
              <th>IN</th>
              <th title={"Nombres de transactions"} >Nbre</th>
              <th title={"Part sur le chiffre"}>Part</th>
            </tr>
          </thead>
          <tbody>
            {dataPartPsp?.GetPartPsp?.map((row, index) => (
              <tr key={index}>
                <td className="fw-bold" title={row.psp}>{row?.psp?.slice(0, 10)}</td>
                <td title={row.sum_amount}>{row?.sum_amount}</td>
                <td title={row.pourcentage_in}>{row.pourcentage_in}%</td>
                <td title={row.nombre_de_lignes}>{row.nombre_de_lignes}</td>
                <td title={row.part_encaisse_total}>{row.part_encaisse_total}%</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default Cards