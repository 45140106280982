import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config/config";
import ReactSelect from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { useParams } from "react-router-dom";
import { setPopup } from "../../Redux/slices/actions.slice";
import * as yup from "yup";
import { Modal, Button, Form } from "react-bootstrap";

const DropCreateRetrait = ({ show, onHide }) => {
  const [brandClient, setBrandClient] = useState("");
  const [amountClient, setAmountClient] = useState("");
  const [deskClient, setDeskClient] = useState(""); // État pour le desk sélectionné
  const [error, setError] = useState(false);
  const [catchErrors, setCatchErrors] = useState([]);
  const [loaderButton, setLoaderButton] = useState(false);
  const [errorVirgule, setErrorVirgule] = useState(false);
  const [optionsBrands, setOptionsBrands] = useState([]); // État pour les options de ReactSelect

  const AdminDetails = useSelector(getAdminDetails);
  const IdToAdmin = AdminDetails?.idusers;
  const deskToAdmin = AdminDetails?.desk;
  const brandsAdmin = AdminDetails?.brand;

  const dispatch = useDispatch();

  let DesksAdmin = useParams().desk;

  const gooddesk = DesksAdmin ? DesksAdmin : deskToAdmin; 


  useEffect(() => {
    if (brandsAdmin) {
      const brands = brandsAdmin.map((brand) => ({
        value: brand,
        label: brand,
      }));
      setOptionsBrands(brands);
    }
  }, [brandsAdmin]);

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const contractSchema = yup.object().shape({
    amount: yup
      .number()
      .test(
        "not-negative-zero",
        "Amount cannot be -0",
        (value) => value !== -0
      ),
    brand: yup.string().min(2).max(36),
    // is_active: yup.boolean().default(true),
  });

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
  };

  const CreateRetrait = async () => {
    setLoaderButton(true);
    let obj = {
      amount: -amountClient,
      desk: DesksAdmin ? DesksAdmin : deskClient,
      brand: brandClient,
      // user_id: IdToAdmin,
      // is_active: 1,
    };
    try {
      await contractSchema.validate(obj, { abortEarly: false });

      axios
        .put(
          `${config.apiAdmin}/sadmin/contracts/create-retrait-for-page`,
          obj,
          Header
        )
        .then((response) => {
          setLoaderButton(false);
          SendPopup({
            message: "confirm",
            info: "create-retrait-and-customer.",
            isActive: true,
          });
          onHide();
          setError(false);
        })
        .catch((error) => {
          setLoaderButton(false);
          SendPopup({
            message: "error",
            info: "create-retrait-and-customer.",
            isActive: true,
          });
          setError(true);
          console.error("There was an error!", error);
        });
    } catch (validationError) {
      setLoaderButton(false);
      console.log(validationError.errors);
      setCatchErrors(validationError.errors);
      SendPopup({
        message: "error",
        info: "Validation failed.",
        isActive: true,
      });
      setError(true);
    }
  };



  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un Retrait</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {catchErrors.length > 0 && (
          <div>
            <ul
              style={{ listStyleType: "disc", paddingLeft: "20px" }}
              className="text-danger"
            >
              <li className="fw-bold">champs a remplir :</li>
              {catchErrors.includes(
                'amount must be a `number` type, but the final value was: `NaN` (cast from the value `""`).'
              ) ||
                (catchErrors.includes("Amount cannot be -0") && (
                  <li>montant</li>
                ))}
              {catchErrors.includes("brand must be at least 2 characters") && (
                <li>brand</li>
              )}
            </ul>
          </div>
        )}
        <div className="p-1 grid-container text-black">
          {!DesksAdmin && (
            <div className="grid-item p-0 me-1">
              <div className="mx-2 mb-1">Desk</div>
              <ReactSelect
                options={deskToAdmin.map((desk) => ({
                  value: desk,
                  label: desk,
                }))}
                className="basic-single"
                classNamePrefix="select"
                style={{ height: "45px" }}
                name="desk"
                placeholder=""
                onChange={(selectedOption) =>
                  setDeskClient(selectedOption ? selectedOption.value : null)
                }
                isClearable={true}
              />
            </div>
          )}
          <div className="grid-item p-0 me-1">
            <div className="mx-2 mb-1">Brand</div>
            <ReactSelect
              options={optionsBrands}
              className="basic-single"
              classNamePrefix="select"
              style={{ height: "45px" }}
              name="brand"
              placeholder=""
              onChange={(selectedOption) =>
                setBrandClient(selectedOption ? selectedOption.value : null)
              }
              isClearable={true}
            />
          </div>
          <div className="grid-item p-0 me-1">
            <div className="mx-2 mb-1">Montant $</div>
            <input
              type="number"
              className="form-control"
              style={{ height: "45px" }}
              placeholder=""
              required
              onChange={(e) => {
                const value = e.target.value;
                if (value.includes(",") || value.includes(".")) {
                  setErrorVirgule(true);
                } else {
                  setErrorVirgule(false);
                }
                setAmountClient(value);
              }}
            />
            {errorVirgule && (
              <div className="text-danger fs-16">uniquement un nombre</div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn border-0 text-black col-12 bg-btn-retrait fw-bold"
          onClick={CreateRetrait}
        >
          Ajouter
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DropCreateRetrait;
