import React from "react";
import TableauCrm from "./Tableau/TableauCrm";

const Tableau = () => {

  return (
    <div>
      <TableauCrm/>
    </div>
  );
};

export default Tableau; 