import React, { useEffect, useState } from "react";
import { Card, Form, Table } from "react-bootstrap";
import axios from "axios";
import config from "../../../config/config";
import { useDispatch } from "react-redux";
import { setPopup } from "../../Redux/slices/actions.slice";

const Brand = () => {
  const [allBrands, setAllBrands] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [updateIndex, setUpdateIndex] = useState(null);
  const dispatch = useDispatch();

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
  };

  // read
  const fetchBrands = () => {
    axios
      .get(`${config.apiAdmin}/sadmin/brands/read_all_admin`, Header)
      .then((response) => {
        setAllBrands(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  useEffect(() => {
    fetchBrands(); // Appeler la fonction lors du montage du composant
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // create
  const CreateBrand = (inputValue) => {
    const obj = {
      name: inputValue,
      is_active: "1",
    };
    axios
      .put(`${config.apiAdmin}/sadmin/brands/create`, obj, Header) // Remplacez ceci par votre URL et vos paramètres
      .then((response) => {
        fetchBrands();
        SendPopup({ message: "confirm", info: "create.", isActive: true });
      })
      .catch((error) => {
        SendPopup({ message: "error", info: "create.", isActive: true });

        console.error("There was an error! create Brand", error);
      });
  };
  // ajoute ligne ds le tableau
  const handleClick = () => {
    setAllBrands((prevBrands) => {
      const newBrands = [
        ...prevBrands,
        {
          idbrands: "",
          name: "",
          is_active: "",
          isEditing: true,
          inputValue: "", // Ajoutez cette ligne
        },
      ];

      return newBrands;
    });
    window.location.href = "#lastElement";
  };

  const UpadteBrand = (nameBrand, isActive) => {

      setUpdateIndex(null);
      axios
        .patch(
          `${config.apiAdmin}/sadmin/brands/toggleActivation`,
          {id: nameBrand, is_active: isActive},
          Header
        )
        .then((response) => {
          SendPopup({ message: 'confirm', info: 'toggleActivation.', isActive: true })
          fetchBrands();
        })
        .catch((error) => {
          SendPopup({ message: 'error', info: 'toggleActivation.', isActive: true })
          console.error("There was an error! delete desk", error);
        });
    
  };

  return (
    <div>
      <div className="d-flex border-bottom">
        <div
          className={`col-auto mx-5 text-center cursor-pointer fs-16 h-100 ${"underline"}`}
        >
          Brands
        </div>
      </div>
      <div className="mt-4 pt-2 pb-2 border-bottom d-flex align-items-center justify-content-between">
        <div>
          <div className="ms-5 fw-bold text-black">
            Ajouter, Activer ou desactiver des brands
          </div>
          <div className="ms-5 text-black">
            Cette page vous permet d’ajouter, d’activer et de désactiver des
            brands.
          </div>
        </div>
        <div
          className="border me-5 px-4 rounded-3 bg-green-target text-black fw-bold"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          + Ajouter
        </div>
      </div>
      <Card className="mt-5">
        <Table bordered className="table-no-vertical-borders">
          <thead className="border-0">
            <tr className="border-0">
              <th className="col-1 border-0 rounded"></th>
              <th className="col-2">Date Ajout</th>
              <th className="col-6 text-start">Nom</th>
              <th className="col-2">Statut</th>
              <th className="col-1 border-0 rounded">Action</th>
            </tr>
          </thead>
          <tbody>
            {allBrands?.map((member, index) => (
              <tr
                key={member?.idbrands}
                style={{ height: "80px" }}
                id={index === allBrands?.length - 1 ? "lastElement" : undefined}
              >
                <td className="col-1 text-end"></td>
                <td className="col-2 fs-12">
                  {member?.created_at && !isNaN(Date.parse(member?.created_at))
                    ? new Date(member?.created_at).toISOString().split("T")[0]
                    : new Date().toISOString().split("T")[0]}
                </td>
                <td className="text-start text-black fw-bold col-6">
                  {member.isEditing ? (
                    <input
                      style={{ height: "40px" }}
                      className="bg-green-input rounded-3"
                      type="text"
                      value={member.inputValue}
                      onChange={(e) => {
                        const value = e.target.value;
                        setInputValue(value);
                        setAllBrands((brands) =>
                          brands?.map((brand) =>
                            brand?.idbrands === member?.idbrands
                              ? { ...brand, inputValue: value }
                              : brand
                          )
                        );
                      }}
                    />
                  ) : (
                    <>{member?.name}</>
                  )}
                </td>
                <td className="col-2">
                  <Form.Check
                    type="switch"
                    className={`custom-switch ${
                      updateIndex === index ? "bg-green-input" : ""
                    } rounded-3 pb-1`}
                    defaultChecked={member?.is_active !== 0}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      let check;
                      if (isChecked) {
                        check = 1;
                      } else {
                        check = 0;
                      }
                      UpadteBrand(member?.idbrands, check)
                    }}
                  />
                </td>
                {member?.isEditing ? (
                  <td>
                    <button
                      type="button"
                      className="px-4 py-2 rounded-3 bg-green-target text-black fw-bold border-0"
                      onClick={() => CreateBrand(inputValue)}
                    >
                      Valider
                    </button>
                  </td>
                ) : (
                  <td
                    className="d-flex justify-content-center col-12 align-items-center"
                    style={{ height: "80px" }}
                  >
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default Brand;
