import React, {useEffect}  from "react";
import TableauResultat from "./Graph/TableauResultat";
import TableauGeneral from "./Graph/TableauGeneral";
import RecapAllDesk from "./Graph/RecapAllDesk";
// import ActiveProjects from "../../jsx/components/Dashboard/elements/ActiveProjects";
import { Col, Row } from "react-bootstrap";
import { useQuery, gql } from "@apollo/client";
import { getSockets } from "../Redux/slices/notifSocket.slice"
import { getToken } from "../Redux/slices/admin.slice"
import { useSelector } from "react-redux";

const STAT_AMOUNT_BY_DESK = gql`
  query StatAmountByDesk {
    statAmountByDesk {
      amount_sum
    canceled_sum
    contract_count
    conversion_rate
    conversion_sum
    desk
    report_sum
    encaisse_rate
    mix_rate
    }
  }
`;

const STAT_BRUT_NET = gql`
  query StatBrutNet {
    statBrutNet {
    moyenne
    percent_canceled
    percent_reported
    sum_is_canceled
    sum_is_report
    sum_preuve
    total_contracts
    total_amount_net
    total_amount_brut
    total_amount_encaisse
  }
  }
`;

const WEEKLY_DATA = gql`
  query WeeklyData {
    weeklyData {
      totalGeneral

      totalsByDay {
        jeudi
        lundi
        mardi
        mercredi
        vendredi
      }

      weeklyDataArray {
        jeudi
        lundi
        mardi
        mercredi
        semaine
        vendredi
        totaux
      }
    
    }
  }
`;

const PageStatistiques = () => {

  const GET_TOKEN = useSelector(getToken);

  const HelpRequest = {
    context: {
      headers: {
        Authorization: `Bearer ${GET_TOKEN}`,
      }
    }
  }

  const GET_SOCKET = useSelector(getSockets);
  
  const { data: datAmountByDesk, refetch: refetchAmountByDesk } = useQuery(STAT_AMOUNT_BY_DESK, HelpRequest);
  const { data: dataStatBrutNet, refetch: refetchStatBrutNet } = useQuery(STAT_BRUT_NET, HelpRequest);
  const { data: dataWeeklyData, refetch: refetchWeeklyData } = useQuery(WEEKLY_DATA, HelpRequest);


  // console.log("datAmountByDesk",datAmountByDesk)

  useEffect(() => {
    refetchAmountByDesk();
    refetchStatBrutNet();
    refetchWeeklyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GET_SOCKET]);

  // console.log("datAmountByDesk", datAmountByDesk);
  // console.log("dataStatBrutNet", dataStatBrutNet);
  // console.log("dataWeeklyData", dataWeeklyData);

  return (
    <>
    <h1>Affichage d'un compte rendu de tous les desks</h1>
      <Row>
        <Col>
          { dataStatBrutNet &&
            <TableauResultat
              dataStatBrutNet={dataStatBrutNet?.statBrutNet}
            />
          }
        </Col>

        <Col>
          { dataWeeklyData &&
            <TableauGeneral
              dataWeeklyData={dataWeeklyData?.weeklyData}
            />
          }
        </Col>
      </Row>
      <Row>
        { datAmountByDesk &&
          <RecapAllDesk
            datAmountByDesk={datAmountByDesk}
          />
        }
      </Row>
    </>
  );
};

export default PageStatistiques;
