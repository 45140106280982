import React, { useEffect, useState } from "react";
import { Table, Card, Button } from "react-bootstrap";
import useHeaderRest from "../../../utils/useHeader";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import config from "../../../config/config";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import "bootstrap-icons/font/bootstrap-icons.css";
import formatNumber from "../../../utils/formatNumber";
import DropCreateRetrait from "../../Secretaire/Tableau/DropCreateRetrait";

const Retraits = () => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [filter, setFilter] = useState("");
  const [allRetraits, setAllRetraits] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false); // État pour le modal

  const useHeader = useHeaderRest();

  const { desk } = useParams();

  const AdminDetail = useSelector(getAdminDetails);
  const deskAdmin = AdminDetail.desk;

  const deskValue = desk !== undefined ? [desk] : deskAdmin;

  const FetchRetraits = () => {
    setLoader(true);
    axios
      .post(
        `${config.apiAdmin}/sadmin/whsheet/retraitRead`,
        {
          desk: deskValue,
        },
        useHeader
      )
      .then((response) => {
        setAllRetraits(response.data);
        setLoader(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setAllRetraits([]);
        setLoader(false);
      });
  };

  useEffect(() => {
    FetchRetraits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedData = React.useMemo(() => {
    let sortableData = [...allRetraits];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData.filter((item) =>
      Object.values(item).some((val) =>
        val.toString().toLowerCase().includes(filter.toLowerCase())
      )
    );
  }, [allRetraits, sortConfig, filter]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig.key) {
      return <i className="bi bi-arrow-down-up"></i>; // Icône par défaut pour le tri
    }
    if (sortConfig.key === name) {
      if (sortConfig.direction === "ascending") {
        return <i className="bi bi-sort-up"></i>; // Icône pour le tri ascendant
      } else {
        return <i className="bi bi-sort-down"></i>; // Icône pour le tri descendant
      }
    }
    return <i className="bi bi-arrow-down-up"></i>; // Icône par défaut pour les autres colonnes
  };

  return (
    <Card>
      <Card.Header className="fw-bold text-black">
        <div className="fs-4">Retraits</div>
        <div
          className="border me-2 px-4 rounded-3 bg-green-target text-black fw-bold pointer"
          onClick={() => setShowModal(true)}
        >
          + Ajouter un retrait
        </div>
      </Card.Header>
      <div className="d-flex justify-content-between">
        <input
          placeholder="Recherche"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="my-3 col-3 ms-5 p-2 rounded border"
        />
        <button
          className="btn btn-dark p-2 my-3 border-rounded fs-14 me-5"
          onClick={() => FetchRetraits()}
        >
          {loader ? "Loading" : "Refresh"}
        </button>
      </div>
      <Table bordered hover>
        <thead className="pointer">
          <tr>
            <th
              onClick={() => requestSort("idsheet_retraits")}
              className={getClassNamesFor("idsheet_retraits")}
            >
              idsheet_retraits {getClassNamesFor("idsheet_retraits")}
            </th>
            <th
              onClick={() => requestSort("brand")}
              className={getClassNamesFor("brand")}
            >
              brand {getClassNamesFor("brand")}
            </th>
            <th
              onClick={() => requestSort("desk")}
              className={getClassNamesFor("desk")}
            >
              desk {getClassNamesFor("desk")}
            </th>
            <th
              onClick={() => requestSort("amount")}
              className={getClassNamesFor("amount")}
            >
              amount {getClassNamesFor("amount")}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index} className="pointer">
              <td>{item.idsheet_retraits}</td>
              <td>{item.brand}</td>
              <td>{item.desk}</td>
              <td>{formatNumber(item.amount)} $</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal */}
      <DropCreateRetrait
        show={showModal}
        onHide={() => {
          setShowModal(false);
          FetchRetraits();
        }}
      />
    </Card>
  );
};

export default Retraits;
