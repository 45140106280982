import React from "react";
// import icon from '../../../images/browser/10596559.png';

import ReactApexChart from "react-apexcharts";

class GraphDernierContrat extends React.Component {
  constructor(props) {
    super(props);
     console.log("props.dataaaa", props);
    this.state = {
      series: [
        {
          name: "Nombre de contrat",
          data: props.arrCount
          ,
        },
      ],
      options: this.getOptions(this.props.arrCategories),
    };
  }

  componentDidUpdate(prevProps) {
    // Vérifie si data a changé
    if (JSON.stringify(this.props.arrCategories) !== JSON.stringify(prevProps.arrCategories)) {
      this.setState({
        series: [
          {
            name: "Nombre de contrat",
            data: this.props.arrCount
          },
        ],
        options: this.getOptions(this.props.arrCategories
        ),
      });
    }
  }

  getOptions(dataArrCategories) {
    return {
      chart: {
        type: "bar",
        // height: 230,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 3,
          dataLabels: {
            position: "top",
          },
        },
      },
      colors: ["var(--bs-danger)"],
      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
      },
      dataLabels: {
        enabled: true,
        offsetX: 0,
        style: {
          fontSize: "12px",
          colors: ["black"],
        },
      },
      stroke: {
        show: false,
      },
      yaxis: {
        lines: {
          show: false,
        },
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
      },
      xaxis: {
        show: false,
        categories: dataArrCategories,
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
      },
    };
  }

  render() {
    return (
      <div 
      // style={{
      //   backgroundImage: `url(${icon})`,
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "160px 160px",
      //   backgroundPosition: "top",
      //   backgroundBlendMode: "overlay",
      //   width: '600px',
      // }}
      >
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height="200%"
          className="h-100 w-100"
        />
      </div>
    );
  }
}
export default GraphDernierContrat;