import React from "react";
import objRowTable from "../../../utils/Table/objRowTable";
import arrowSort from "../../../utils/Table/arrowSort";

const TrTable = ({ sortRow, stateRow }) => {
  return (
    <tr className="titre-tableau text-center">
      <th
        className="fixed-column fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.created_at.key, objRowTable.created_at.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>DATE</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.created_at.key].etat)}
          </div>
        </div>
      </th>

      <th
        className="fixed-column-2 fs-14 fw-bold text-black m-auto"
        onClick={() => {
          stateRow(objRowTable.desk.key, objRowTable.desk.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">DESK</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.desk.key].etat)}
          </div>
        </div>
      </th>

      <th
        className="fixed-column-3 fs-14 fw-bold text-black text-center"
        onClick={() => {
          stateRow(objRowTable.brand.key, objRowTable.brand.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">BRAND</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.brand.key].etat)}
          </div>
        </div>
      </th>

      <th
        className="fixed-column-4 fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.cust_lname.key, objRowTable.cust_lname.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">CLIENT</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.cust_lname.key].etat)}
          </div>
        </div>
      </th>

      <th
        className="fixed-column-5 fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.amount.key, objRowTable.amount.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">€</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.amount.key].etat)}
          </div>
        </div>
      </th>

      <th
        className="fixed-column-6 fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.pseudo_seller.key,
            objRowTable.pseudo_seller.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">AGENT</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.pseudo_seller.key].etat)}
          </div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.alias_lname.key, objRowTable.alias_lname.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">ALIAS</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.alias_lname.key].etat)}
          </div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.paymentdudate.key,
            objRowTable.paymentdudate.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">ECHEANCE</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.paymentdudate.key].etat)}
          </div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.pseudo_agt_supp.key,
            objRowTable.pseudo_agt_supp.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">SUPPORT</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.pseudo_agt_supp.key].etat)}
          </div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.psp.key, objRowTable.psp.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">PSP</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.psp.key].etat)}
          </div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.neobank.key, objRowTable.neobank.type);
        }}
      >
        {" "}
        <div className="d-flex align-items-center justify-content-center">
          <div className="">PLATEFORME</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.neobank.key].etat)}
          </div>
        </div>
      </th>

      <th className="vertical-text fs-14 fw-bold text-black">Arrivée </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.customerbank.key, objRowTable.customerbank.type);
        }}
      >
        {" "}
        <div className="d-flex align-items-center justify-content-center">
          <div className="">BANK CLIENT</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.customerbank.key].etat)}
          </div>
        </div>
      </th>

      <th className="vertical-text fs-14 fw-bold text-black">En preuve </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.date_vir.key, objRowTable.date_vir.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">DATE</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.date_vir.key].etat)}
          </div>
        </div>
      </th>

      <th className="vertical-text fs-14 fw-bold text-black">
        ENCAISSEE <br />{" "}
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.date_encaisse.key, objRowTable.date_encaisse.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">DATE</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.date_encaisse.key].etat)}
          </div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.encash_usd.key, objRowTable.encash_usd.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">$</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.encash_usd.key].etat)}
          </div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.next_call.key, objRowTable.next_call.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">APPEL</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.next_call.key].etat)}
          </div>
        </div>
      </th>
      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.hours.key, objRowTable.hours.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">HEURE</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.hours.key].etat)}
          </div>
        </div>
      </th>
      <th className="vertical-text fs-14 fw-bold text-black">Reporter</th>
      <th className="vertical-text fs-14 fw-bold text-black">Annuler</th>
      <th className=" fs-14 fw-bold text-black">COMMENTAIRE</th>
    </tr>
  );
};

export default TrTable;
