import React, { useEffect, useState } from "react";
import { Card, Form, Table, Modal } from "react-bootstrap";
// import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config/config";
import ReactSelect from "react-select";
// import { set } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { setPopup } from "../../Redux/slices/actions.slice";
const Alerts = () => {
  const [ShowModalUpdate, setShowModalUpdate] = useState(false);
  const [listAlertsByDesk, setListAlertsByDesk] = useState([]);
  const [loadingId, setLoadingId] = useState(null);
  // const [catSelected, setCatSelected] = useState(null);
  const [allUsersBySadmin, setAllUsersBySadmin] = useState([]);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [listAdmin, setListAdmin] = useState([]);
  const [ModalToUpdate, setModalToUpdate] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  // const [activeAgents, setActiveAgents] = useState(false);
  const [noAlerts, setNoAlerts] = useState(false);

  const admin = useSelector(getAdminDetails);
  const Deskadmin = admin?.desk;
  const catDefault = admin?.categorie;

  const dispatch = useDispatch();

  const { desk } = useParams();

  const Desk = desk || Deskadmin

  const handleClick = () => {
    setModalToUpdate(false);
    setShowModalUpdate(true);
    setTitle("");
    setMessage("");
    setSelectedAgent(null);
  };

  useEffect(() => {
    if (ModalToUpdate) {
      setTitle(selectedAgent?.title);
      setMessage(selectedAgent?.content);
      setSelectedAgent({
        value: selectedAgent?.idalerts,
        label: selectedAgent?.pseudo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ModalToUpdate]);

  const fetchUsers = () => {
    let obj = {
      desk: Desk,
      categories: "SELLER",
      profil: "",
    };
    if (catDefault) {
      axios
        .post(
          `${config.apiAdmin}/sadmin/users/Users_by_desk_categories`,
          obj,
          Header
        )
        .then((response) => {
          setAllUsersBySadmin(response.data);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
 }

  useEffect(() => {
    if (ShowModalUpdate) {
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ShowModalUpdate]);

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const FetchAlertsByDesk = () => {
    let obj = {
      desk: Desk,
    };
    axios
      .post(`${config.apiAdmin}/sadmin/alerts/alert_by_desk`, obj, Header)
      .then((response) => {
        setListAlertsByDesk(response.data);
      })
      .catch((error) => {
        setNoAlerts(true);
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    FetchAlertsByDesk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBooleanActivate = (event, idalerts) => {
    setLoadingId(idalerts);
    let bool = event.target.checked ? 1 : 0;
    let obj = {
      id: idalerts,
      bool: bool,
    };
    axios
      .patch(`${config.apiAdmin}/sadmin/alerts/bool_activate`, obj, Header)
      .then((response) => {
        
        // setListAlertsByDesk(response.data);
        setLoadingId(null);
        FetchAlertsByDesk();
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoadingId(null);
      });
  };

  const selectUsers = allUsersBySadmin?.map((user) => ({
    label: user.pseudo,
    value: user.idusers,
  }));

  const SendAlert = () => {
    let obj = {
      content: message,
      title: title,
      mng_supp_id: "",
      agt_supp_id: "",
      tlead_id: "",
      sell_id: "",
      users_id: listAdmin,
      desk: Desk,
    };
    console.log(obj);
    axios
      .put(`${config.apiAdmin}/sadmin/alerts/create_by_array`, obj, Header)
      .then((response) => {
        SendPopup({ message: 'confirm', info: 'create_by_array.', isActive: true })
        setShowModalUpdate(false);
        FetchAlertsByDesk();
      })
      .catch((error) => {
        SendPopup({ message: 'error', info: 'create_by_array.', isActive: true })
        console.error("There was an error!", error);
      });
  };

  const OpenModalToUpdate = (member) => () => {
    setSelectedAgent(member);
    setShowModalUpdate(true);
    setModalToUpdate(true);
  };

  const UpdateAlert = () => {
    const idalerts = selectedAgent?.value;
    let obj = {
      idalerts: idalerts,
      content: message,
      title: title,
      mng_supp_id: "",
      agt_supp_id: "",
      tlead_id: "",
      sell_id: "",
    };
    axios
      .post(`${config.apiAdmin}/sadmin/alerts/update_all`, obj, Header)
      .then((response) => {
        SendPopup({ message: 'confirm', info: 'update_all.', isActive: true })
        FetchAlertsByDesk();
        setShowModalUpdate(false);
      })
      .catch((error) => {
        SendPopup({ message: 'error', info: 'update_all.', isActive: true })
      });
  };

  const CloseModal = () => {
    setShowModalUpdate(false);
    setModalToUpdate(false);
    setTitle("");
    setMessage("");
    setSelectedAgent(null);
  };

  const DeleteAlert = (idAlert) => {
    let obj = {
      id: idAlert,
    };
    const configw = {
      headers: Header, // Assuming 'Header' is an object containing the necessary headers
      data: obj, // This is where you need to pass the data object for a DELETE request
    };
    axios
      .delete(`${config.apiAdmin}/sadmin/alerts/delete`, configw)
      .then((response) => {
        SendPopup({ message: 'confirm', info: 'delete.', isActive: true })

        FetchAlertsByDesk();
        // setAllUsersBySadmin(response.data);
      })
      .catch((error) => {
        SendPopup({ message: 'error', info: 'delete.', isActive: true })

        console.error("There was an error!", error);
      });
  };

  return (
    <div>
      <div className="d-flex border-bottom">
        <div
          className={`col-auto mx-5 text-center cursor-pointer fs-16 h-100 ${"underline"}`}
        >
          Alerts
        </div>
      </div>
      <div className="mt-4 pt-2 pb-2 border-bottom d-flex align-items-center justify-content-between">
        <div>
          <div className="ms-5 fw-bold text-black">
            Ajouter, Activer ou desactiver des Alertes
          </div>
          <div className="ms-5 text-black">
            Cette page vous permet d’ajouter, d’activer et de désactiver des
            alertes.
          </div>
        </div>
        <div
          className="border me-5 px-4 rounded-3 bg-green-target text-black fw-bold"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          + Ajouter
        </div>
      </div>
      <Card className="mt-5">
        <Table bordered className="table-no-vertical-borders">
          <thead>
            <tr>
              <th className="col-1 border-0 rounded"></th>
              <th className="col-1">Titre</th>
              <th className="col-2">Pour</th>
              <th className="col-5">Message</th>
              <th className="col-2">Statut de l’alerte</th>
              <th className="col-1 border-0 rounded">Action</th>
            </tr>
          </thead>
          <tbody>
            {[...listAlertsByDesk]?.reverse().map((member) => (
              <tr key={member?.idalerts}>
                <td className="col-1 text-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-megaphone-fill me-3"
                    viewBox="0 0 16 16"
                    style={{ transform: "rotate(-45deg)" }}
                  >
                    <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25 25 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009l.496.008a64 64 0 0 1 1.51.048m1.39 1.081q.428.032.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a66 66 0 0 1 1.692.064q.491.026.966.06" />
                  </svg>
                </td>
                <td className="col-1">{member?.title}</td>
                <td className="col-2">
                  <div className="fw-bold fs-18">{member?.pseudo}</div>
                  <div className={`badge badge-${member?.profil}`}>
                    {member?.profil}
                  </div>
                </td>
                <td className="d-flex w-100 h-100 col-5">
                  <span
                    className="border rounded-5 w-100 d-flex justify-content-start align-items-center ps-3"
                    style={{ height: "60px" }}
                  >
                    {member?.content.length > 100
                      ? member?.content.substring(0, 100) + "..."
                      : member?.content}
                  </span>
                </td>
                <td className="col-2">
                  {loadingId === member?.idalerts ? (
                    <div className="spinner-border text-success" role="status">
                      <span className="sr-only"></span>
                    </div>
                  ) : (
                    <Form.Check
                      type="switch"
                      id={`switch-${member?.idalerts}`}
                      className="custom-switch"
                      checked={member?.is_active === 1}
                      onChange={(event) =>
                        handleBooleanActivate(event, member?.idalerts)
                      }
                    />
                  )}
                </td>
                <td className="d-flex justify-content-center col-1 w-100">
                  <div onClick={OpenModalToUpdate(member)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-pencil-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                    </svg>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => DeleteAlert(member?.idalerts)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="red"
                      className="bi bi-trash3 ms-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                    </svg>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {noAlerts && (<div className="text-black text-center py-3 fs-3">Pas d'alerts</div>)}
      </Card>
      {ShowModalUpdate && (
        <Modal
          className="modal fade"
          show={ShowModalUpdate}
          onHide={handleClick}
          centered
        >
          <Modal.Body
            style={{ backgroundColor: "#F4F4F5" }}
            className="rounded"
          >
            <p className="fw-bold text-black fs-20">Parametrage de l’alerte</p>
            <div className="mt-4">
              {!ModalToUpdate && (
                <>
                  {/* <div className="form-group col-6 me-1 mb-3">
                    <label htmlFor="pseudo" className="text-black">
                      Categories
                    </label>
                    <ReactSelect
                      id="categorie"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="seller/support"
                      options={[
                        { value: "SUPPORT", label: "Support" },
                        { value: "SELLER", label: "Seller" },
                      ]}
                      onChange={(e) => {
                        setCatSelected(e.value);
                        setActiveAgents(true)
                      }}
                    />
                  </div> */}
                  <div className="col-6">
                    <label htmlFor="pseudo" className="text-black">
                      Agents
                    </label>
                    <div className="form-group ms-1">
                      <ReactSelect
                        isMulti
                        id="agent"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={selectUsers}
                        // value={selectedAgent}
                        onChange={(selectedOptions) => {
                          setListAdmin(
                            selectedOptions?.map((option) => option.value)
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="d-flex justify-content-start align-items-center mt-3 ms-1">
                <div className="form-group me-1 col-6">
                  <label htmlFor="titre" className="text-black">
                    Titre
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="titre"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-2 ms-1">
                <label htmlFor="" className="text-black">
                  message
                </label>
                <textarea
                  name="write-email"
                  id="write-email"
                  cols="30"
                  rows="5"
                  className="form-control"
                  placeholder="..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="mt-3 ms-2 d-flex justify-content-end me-2 btn-modal-footer">
              <div
                className="border rounded-1 px-5 py-1 text-black fw-bold"
                onClick={CloseModal}
              >
                Annuler
              </div>
              {!ModalToUpdate ? (
                <div
                  className="border-0 rounded-1 px-5 py-1 text-black fw-bold ms-3"
                  style={{ backgroundColor: "#25E87F" }}
                  onClick={SendAlert}
                >
                  Creer
                </div>
              ) : (
                <div
                  className="border-0 rounded-1 px-5 py-1 text-black fw-bold ms-3"
                  style={{ backgroundColor: "#25E87F" }}
                  onClick={UpdateAlert}
                >
                  Update
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Alerts;
