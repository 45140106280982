import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "../slices/admin.slice"
import contractReducer from "../slices/contract.slice"
import  notifSocketReducer  from "../slices/notifSocket.slice";
import  actionsReducer  from "../slices/actions.slice";
import  tauxReducer  from "../slices/taux.slice";


export default configureStore({
    reducer: {
        admin: adminReducer,
        contract: contractReducer,
        notifSocket: notifSocketReducer,
        actions: actionsReducer,
        taux: tauxReducer
    }});