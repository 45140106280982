import React from "react";
import TableauCrm from "./Tableau/TableauCrm";

const PageTableau = () => {

  return (
    <div>
      <TableauCrm/>
    </div>
  );
};

export default PageTableau; 