import { createSlice } from "@reduxjs/toolkit";

export const adminReducer = createSlice({
  name: "admin",
  initialState: {
    authentified: false,
    profil: "",
    navigate:"",
    desks: [],
    brands: [],
    details: {},
    closeModalAddAdmin: false,
    modalUpdated: false,
    sidebarFin: false
  },
  reducers: {
    setAdminProfil: (state, { payload }) => {
      state.profil = payload;
    },
    setAdminAuthentified: (state, { payload }) => {
      state.authentified = payload;
    },
    setAdminNavigate: (state, { payload }) => {
      state.navigate = payload;
    },
    setAdminDesks: (state, { payload }) => {
      state.desks = payload;
    },
    setAdminBrands: (state, { payload }) => {
      state.brands = payload
    },
    setAdminDetails: (state, { payload }) => {
      state.details = payload
    },
    setCloseModalAddAdmin: (state, { payload }) => {
      // console.log(payload, "je suis payload")
      state.closeModalAddAdmin = payload
    },
    setModalUpdated: (state, { payload }) => {
      state.modalUpdated = payload
    },
    setSidebarFin: (state, { payload }) => {
      state.sidebarFin = payload
    }
  },
});

export const { setAdminProfil, setSidebarFin, setAdminAuthentified, setAdminNavigate, setAdminDesks, setAdminBrands, setAdminDetails, setCloseModalAddAdmin, setModalUpdated } = adminReducer.actions;

export const getAdminProfil = (state) => {
  // console.log(state.admin.profil, "je suis la ")
  return state.admin.profil;
};

export const getAdminAuthentified = (state) => {
  // console.log(state.admin.authentified)
  return state.admin.authentified
}

export const getAdminNavigate = (state) => {
  return state.admin.navigate
}

export const getAdminDesks = (state) => {
  return state.admin.desks
}

export const getAdminBrands = (state) => {
  return state.admin.brands
}

export const getAdminDetails = (state) => {
  return state.admin.details
}

export const getIdUsers = (state) => {
  return state.admin.details.idusers
}

export const getCloseModalAddAdmin = (state) => {
  return state.admin.closeModalAddAdmin
}
export const getToken = (state) => {
  return state.admin.details.token
}

export const getModalUpdated = (state) => {
  return state.admin.modalUpdated
}

export const getSidebarFin = (state) => {
  return state.admin.sidebarFin
}
export const getPseudo = (state) => {
  return state.admin.details.pseudo
}

export default adminReducer.reducer;
