import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import ReactSelect, { components } from "react-select";

const Commentaire = (user) => {
  const [comsByContrat, setComsByContrat] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [messageSend, setMessageSend] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [editedContent, setEditedContent] = useState("");
  const [ListUserToAssign, SetListUserToAssign] = useState([]);
  const [showReactSelect, setShowReactSelect] = useState(false);
  // const [ListUserToSelect, setListUserToSelect] = useState([]);
  // const [selectedLabel, setSelectedLabel] = useState("");
  const [ListUserAssigned, SetListUserAssigned] = useState([]);

  const lastMessageRef = useRef(newMessage);

  const detailUser = useSelector(getAdminDetails);
  const userId = detailUser?.idusers;
  const mng_supp_id = detailUser?.mng_supp_id;
  const agt_supp_id = detailUser?.agt_supp_id;
  const tlead_id = detailUser?.tlead_id;
  // const sell_id = detailUser?.sell_id;

  const detailContrat = user?.contrat;
  const IdContrat = detailContrat?.idcontracts;
  // const IdContrat = "04f26ab2-d79d-4984-9642-12aa74105021";
  const customer_id = detailContrat?.customer_id;
  const seller_id = detailContrat?.seller_id;
  const deskContrat = detailContrat?.desk;

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    let obj = {
      id_contract: IdContrat,
    };
    axios
      .post(
        `${config.apiAdmin}/sadmin/comments/read_all_by_contracts_with_pseudo`,
        obj,
        Header
      )
      .then((response) => {
        setComsByContrat(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageSend]);

  useEffect(() => {
    const objSeller = {
      desk: deskContrat,
      categories: "SELLER",
      profil: "",
    };

    const objSupport = {
      desk: deskContrat,
      categories: "SUPPORT",
      profil: "",
    };

    const requestSeller = axios.post(
      `${config.apiAdmin}/sadmin/users/Users_by_desk_categories`,
      objSeller,
      Header
    );
    const requestSupport = axios.post(
      `${config.apiAdmin}/sadmin/users/Users_by_desk_categories`,
      objSupport,
      Header
    );

    Promise.all([requestSeller, requestSupport])
      .then((responses) => {
        const combinedData = [...responses[0].data, ...responses[1].data];
        const filteredAndMappedData = combinedData
          .filter((item) => item.is_active === 1)
          .map((item) => ({
            idusers: item.idusers,
            pseudo: item.pseudo,
            profil: item.profil,
          }));
        SetListUserToAssign(filteredAndMappedData);
      })
      .catch((error) => {
        console.log(error);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    lastMessageRef.current = newMessage;
  }, [newMessage]);

  const handleMessageChange = (event) => {
    const value = event.target.value;
    setNewMessage(value);

    const lastCharAdded = value.substr(-1);
    const wasAtSymbolAdded =
      lastCharAdded === "@" && lastMessageRef.current !== value;

    if (wasAtSymbolAdded) {
      setShowReactSelect(true);
    } else {
      setShowReactSelect(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (newMessage?.includes("@")) {
      let mng_supp_idC = "";
      let tlead_idC = "";
      // eslint-disable-next-line no-unused-vars
      let mng_idC = "";

      ListUserAssigned.forEach((user) => {
        if (user.profil === "mng_supp") {
          mng_supp_idC = user.value;
        } else if (user.profil === "tlead") {
          tlead_idC = user.value;
        } else if (user.profil === "manager") {
          mng_idC = user.value;
        }
      });


    }
    let obj = {
      content: newMessage,
      user_id: userId,
      contract_id: IdContrat,
      customer_id: customer_id,
      mng_supp_id: "",
      agt_supp_id: "",
      tlead_id: "",
      sell_id: "",
    };
    SetListUserAssigned([]);
    axios
      .put(`${config.apiAdmin}/sadmin/comments/create`, obj, Header)
      .then((response) => {
        setComsByContrat([...comsByContrat, response.data]);
        setMessageSend(true);
      })
      .catch((error) => {
        console.log(error);
      });
    setNewMessage("");
  };

  const handleSelectChange = (selectedOption) => {
    // Supposons que ReactSelect est configuré pour ne sélectionner qu'une seule option
    const selectedValue = selectedOption ? selectedOption.label : "";
    // setSelectedLabel(selectedValue);
    setNewMessage(newMessage + selectedValue);
    setShowReactSelect(false);
    SetListUserAssigned((prevList) => {
      const isOptionAlreadySelected = prevList?.some(
        (option) => option.value === selectedOption.value
      );
      if (!isOptionAlreadySelected) {
        return [...prevList, selectedOption];
      }
      return prevList;
    });
  };

  const handleEditChange = (e) => {
    setEditedContent(e.target.value);
  };

  const UpdateMessage = (event) => {
    event.stopPropagation();
    let obj = {
      idcomments: selectedCommentId,
      content: editedContent,
      user_id: userId,
      contract_id: IdContrat,
      customer_id: customer_id,
      // mng_supp_id: mng_supp_id,
      // agt_supp_id: agt_supp_id,
      // tlead_id: tlead_id,
      // sell_id: seller_id,
    };
    axios
      .post(`${config.apiAdmin}/sadmin/comments/update_all`, obj, Header)
      .then((response) => {
        setComsByContrat(
          comsByContrat?.map((comment) =>
            comment.idcomments === selectedCommentId
              ? { ...comment, content: editedContent }
              : comment
          )
        );
        setSelectedCommentId(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DesactivateMessage = () => {
    let obj = {
      id: selectedCommentId,
    };
    axios
      .patch(`${config.apiAdmin}/sadmin/comments/desactivate`, obj, Header)
      .then((response) => {
        setComsByContrat(
          comsByContrat?.filter(
            (comment) => comment.idcomments !== selectedCommentId
          )
        );
        setSelectedCommentId(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ListUserToSelect = ListUserToAssign?.map((item) => ({
    value: item.idusers,
    label: `${item.pseudo}`,
    profil: item.profil,
    pseudo: item.pseudo,
  }));

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div className="d-flex justify-content-between align-items-center col-12 px-2 text-black">
          <span>{props.data.pseudo}</span>{" "}
          <span
            style={{ color: "white" }}
            className={`badge badge-${props.data.profil}`}
          >
            {props.data.profil}
          </span>
        </div>
      </components.Option>
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // Permet d'utiliser Shift+Enter pour sauter une ligne
      e.preventDefault(); // Empêche le saut de ligne dans le textarea
      handleSubmit(e); // Appelle la fonction de soumission du formulaire
    }
  };

  return (
    <div
      className="h-100 d-flex flex-column justify-content-end pb-3 text-white"
      style={{ maxHeight: "100%", overflow: "auto" }}
    >
      <div
        className="h-100 d-flex flex-column justify-content-end pb-3 text-white"
        style={{
          maxHeight: "100%",
          backgroundColor: "rgb(238, 238, 238)",
          // overflow: "auto",
          // backgroundImage: `url(${imgBlue})`, // Utilisez des littéraux de gabarits pour insérer la variable
          // backgroundSize: "cover", // Assure que l'image de fond couvre entièrement le div
          // backgroundPosition: "center", // Centre l'image de fond
        }}
      >
        {comsByContrat?.map((comment) => (
          <div
            key={comment?.idcomments}
            className="border rounded p-2 m-3 bg-white color_bluefonce my-5"
            onClick={() => {
              setSelectedCommentId(comment?.idcomments);
              setEditedContent(comment?.content);
            }}
            style={{
              boxShadow: "0px 2px 8px 0 rgba(255, 255, 255, 0.5)",
              position: "relative",
            }}
          >
            {selectedCommentId === comment?.idcomments ? (
              <>
                <div className="d-flex justify-content-end">
                  <div className="d-flex m-1">
                    <div
                      className="rounded-5 border text-center px-1 bg-success"
                      onClick={UpdateMessage}
                      style={{ cursor: "pointer" }}
                    >
                      v
                    </div>
                  </div>
                  <div className="d-flex m-1">
                    <div
                      className="rounded-5 border text-center px-1 bg-danger"
                      onClick={DesactivateMessage}
                      style={{ cursor: "pointer" }}
                    >
                      x
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  value={editedContent}
                  onChange={(e) => handleEditChange(e)}
                  className="form-control"
                />
              </>
            ) : (
              <p className="py-2">{comment?.content}</p>
            )}
            <div
              style={{ position: "absolute", top: -20, right: 0 }}
              className="border bg-info-top text-white rounded p-1 fw-bold"
            >
              {comment?.pseudo}
            </div>
            <div className="d-flex align-items-center col-12 justify-content-between">
              <div className="text-end fs-12 border rounded px-2 col-12">
                {new Date(comment?.timestamp).toLocaleString()}
              </div>
            </div>
          </div>
        ))}      <div className="d-flex px-3"
      >
        <form onSubmit={handleSubmit} className="border rounded p-2 col-12">
          <textarea
            className="border-0 p-2 col-10 bg-transparent text-black message-textarea"
            value={newMessage}
            onChange={handleMessageChange}
            onKeyPress={handleKeyPress}
            placeholder="Écrivez un message..."
            style={{
              resize: "none",
              overflow: "auto",
              minHeight: "50px",
              maxHeight: "150px",
              fontSize: "14px",
            }}
          />
          <button
            type="submit"
            className="border-0 p-1 rounded color_bluefonce"
          >
            Envoyer
          </button>
        </form>
      </div>
      </div>

      {showReactSelect && (
        <div className="px-3">
          <ReactSelect
            options={ListUserToSelect}
            placeholder="Personne à assigner pour ce message"
            onChange={handleSelectChange}
            components={{ Option: CustomOption }}
            menuIsOpen={true}
            menuPlacement="top"
            styles={{
              menu: (base) => ({
                ...base,
                backgroundColor: "#e1e1e1",
              }),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Commentaire;
