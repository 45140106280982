import React from "react";
import CardsAgent from "./Graph/CardsSeller";

const Dashboard = () => {
  return (
    <>
      <CardsAgent />
    </>
  );
};

export default Dashboard;
