import React from 'react'

const Droits = () => {
  return (
    <div>
      droits super admin
    </div>
  )
}

export default Droits
