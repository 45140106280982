import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config/config";
import ReactSelect from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { setAllContracts, getFilters } from "../../Redux/slices/contract.slice";
import { Offcanvas, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, addMinutes } from "date-fns";
import { setPopup } from "../../Redux/slices/actions.slice";
import Activite from "../../Global/Table/Activite";
import Chat from "../../Global/Table/Chat";
import Notes from "../../Global/Table/Notes";
import { useParams } from "react-router-dom";
import { set } from "react-hook-form";

const DropUpdateContrat = ({
  isVisible,
  onHide,
  data,
  IsForComments,
  GetAllContrats,
}) => {
  // DATA RECUPERER POUR LE CONTRAT SELECTIONNE

  const { desk, idcontract, mode } = useParams();

  useEffect(() => {
    if (mode) {
      setSelectedTitle(mode);
    }
  }, [mode]);

  const [prenomC, setPrenomC] = useState(data?.cust_fname);
  const [nomC, setNomC] = useState(data?.cust_lname);
  const [brandC, setBrandC] = useState(data?.brand);
  const [amountC, setAmountC] = useState(data?.amount);
  const [pspC, setPspC] = useState(data?.psp);
  const [deskC, setDeskC] = useState(data?.desk);
  const [neoBankC, setNeoBankC] = useState(data?.neobank);
  const [customerbankC, setCustomerbankC] = useState(data?.customerbank);
  const [sellerC, setSellerC] = useState(data?.seller_id);
  const [supportC, setSupportC] = useState(data?.agt_supp_id);
  const [amountEncaisseC, setAmountEncaisseC] = useState(data?.encash_usd);

  // dates et heures

  const [date_virC, setDate_virC] = useState(
    data?.date_vir ? new Date(data?.date_vir) : null
  );
  const [dateEncaissementC, setDateEncaissementC] = useState(
    data?.date_encaisse ? new Date(data?.date_encaisse) : null
  );
  const [next_callC, setNext_callC] = useState(
    data.next_call ? new Date(data?.next_call) : null
  );
  const [duDateC, setDuDateC] = useState(
    data?.paymentdudate ? new Date(data?.paymentdudate) : null
  );
  const [hoursCSel, setHoursCSel] = useState(data?.hours);

  // status

  const [argent_dispoC, setArgent_dispoC] = useState(data?.argent_dispo);
  const [preuveC, setPreuveC] = useState(data?.preuve);
  const [encaisseC, setEncaisseC] = useState(data?.encaisse);
  const [is_reportC, setIs_reportC] = useState(data?.is_report);
  const [is_canceledC, setIs_canceledC] = useState(data?.is_canceled);

  // DATA POUR AFFICHAGE DES SELECTS

  const [psp, setPsp] = useState([]);
  const [neoBank, setNeoBank] = useState([]);
  const [customerBank, setCustomerBank] = useState([]);
  // const [supports, setSupports] = useState([]);

  // autres

  const [error, setError] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("Contrat");
  const [errorEncaisse, setErrorEncaisse] = useState(false);
  const [errorVirgule, setErrorVirgule] = useState(false);
  const [errorVirguleEncai, setErrorVirguleEncai] = useState(false);
  const [loaderButton, setLoaderButton] = useState(false);

  // const [isConversionC, setIsConversionC] = useState(data?.conversion);
  const AdminDetails = useSelector(getAdminDetails);
  // const desksToAdmin = AdminDetails?.desk;
  const brandToAdmin = AdminDetails?.brand;
  const filters = useSelector(getFilters);

  const [errorDateEncaissementC, setErrorDateEncaissementC] = useState("");
  const [errorAmountEncaissementC, setErrorAmountEncaissementC] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setPrenomC(data?.cust_fname);
    setNomC(data?.cust_lname);
    setBrandC(data?.brand);
    setAmountC(data?.amount);
    // setIsConversionC(data?.conversion);
    // setPaymentdudateC(data?.paymentdudate);
    setArgent_dispoC(data?.argent_dispo);
    setPreuveC(data?.preuve);
    setEncaisseC(data?.encaisse);
    setDate_virC(data?.date_vir ? new Date(data?.date_vir) : null);
    setNext_callC(data.next_call ? new Date(data?.next_call) : null);
    setDuDateC(data?.paymentdudate ? new Date(data?.paymentdudate) : null);
    // setHoursC(data?.hours);
    setIs_reportC(data?.is_report);
    setIs_canceledC(data?.is_canceled);
    setPspC(data?.psp);
    setNeoBankC(data?.neobank);
    setCustomerbankC(data?.customerbank);
    setSellerC(data?.seller_id);
    setSupportC(data?.agt_supp_id);
    setDeskC(data?.desk);
    setHoursCSel(data?.hours);
    setAmountEncaisseC(data?.encash_usd);
    setDateEncaissementC(
      data?.date_encaisse ? new Date(data?.date_encaisse) : null
    );
  }, [data]);

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    if (isVisible) {
      FetchFiltersGet("/psp/read_all", setPsp);
      FetchFiltersGet("/neobanks/read_all", setNeoBank);
      FetchFiltersGet("/customerbanks/read_all", setCustomerBank);
      // FetchFiltersPost("/users/users_by_profil_desk", setSellers, "sell");
      // FetchFiltersPost("/users/users_by_profil_desk", setSupports, "agt_supp");
      // FetchFiltersPost("/users/all_agt_supp_and_mng_supp", setSupports);
    }
    // eslint-disable-next-line
  }, [isVisible]);
  const FetchFiltersGet = (url, fonction) => {
    axios
      .get(`${config.apiAdmin}/sadmin${url}`, Header)
      .then((response) => {
        fonction(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  // const FetchFiltersPost = (url, fonction, obj) => {
  //   axios
  //     .post(`${config.apiAdmin}/sadmin${url}`, obj, Header)
  //     .then((response) => {
  //       fonction(response.data);
  //       // console.log(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("There was an error!", error);
  //     });
  // };

  const defaultValueBrand = {
    value: brandC,
    label: brandC,
  };

  const optionsBrands = brandToAdmin?.map((item) => ({
    value: item,
    label: item,
  }));

  const defaultValuePsp = {
    value: pspC,
    label: pspC,
  };

  const pspSelect = psp?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const defaultValueNeo = {
    value: neoBankC,
    label: neoBankC,
  };

  const neoSelect = neoBank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const defaultValueBank = {
    value: customerbankC,
    label: customerbankC,
  };

  const BankSelect = customerBank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
  };

  const UpdateContract = (data) => {
    setLoaderButton(true);
    if (encaisseC === 1) {
      if (!amountEncaisseC || amountEncaisseC === 0) {
        // setErrorEncaisse(true);
        setErrorAmountEncaissementC("merci de renseigenr un montant");
        return;
      }
    }
    // datePicker ne prend que de new date, qui est une fonction, alors comme on besoin de la renvoyer en string,
    // on la retransforme a la fin, juste avant de l'envoyer
    let next_callCStr = next_callC?.toISOString();
    next_callCStr = next_callCStr?.slice(0, 10);

    // datePicker ne prend que de new date, qui est une fonction, alors comme on besoin de la renvoyer en string,
    // on la retransforme a la fin, juste avant de l'envoyer
    let date_virCStr = date_virC?.toISOString();
    date_virCStr = date_virCStr?.slice(0, 10);

    let date_encaissementStr = dateEncaissementC?.toISOString();
    date_encaissementStr = date_encaissementStr?.slice(0, 10);

    let date_duDateStr = duDateC?.toISOString();
    date_duDateStr = date_duDateStr?.slice(0, 10);

    let obj = {
      pseudo: AdminDetails?.pseudo,
      oldState: data,
      newState: {
        idcontracts: data?.idcontracts,
        created_at: data?.created_at,
        brand: brandC,
        desk: deskC,
        amount: amountC,
        conversion: data.conversion,
        end_contract: data?.end_contract?.slice(0, 10),
        psp: pspC,
        neobank: neoBankC,
        customerbank: customerbankC,
        customer_id: data?.customer_id,
        seller_id: sellerC,
        agt_supp_id: supportC,
        paymentdudate: date_duDateStr,
        argent_dispo: argent_dispoC,
        preuve: preuveC,
        date_vir: date_virCStr,
        encaisse: encaisseC,
        next_call: next_callCStr,
        hours: hoursCSel,
        date_encaisse: date_encaissementStr,
        encash_usd: amountEncaisseC,
        is_report: is_reportC,
        is_canceled: is_canceledC,
        filters: filters,
        customer: {
          firstname: prenomC,
          lastname: nomC,
          crm_id: data?.customer_id,
        },
      },
    };
    axios
      .patch(`${config.apiAdmin}/sadmin/contracts/updateAll`, obj, Header)
      .then((response) => {
        dispatch(setAllContracts(response.data.data.results));
        SendPopup({ message: "confirm", info: "updateAll.", isActive: true });
        onHide();
        setLoaderButton(false);
      })
      .catch((error) => {
        setError(true);
        setLoaderButton(false);
        SendPopup({ message: "error", info: "updateAll.", isActive: true });
        // reject(error); // Reject the promise on error
      });
    setLoaderButton(false);
  };

  const handleTitleClick = (title) => {
    setSelectedTitle(title);
    setLoaderButton(false)
    setErrorAmountEncaissementC("")
    setErrorDateEncaissementC("")

  };

  const handleHours = (date) => {
    // const formattedDate = format(date, "HH:mm:ss");
    setHoursCSel(date);
  };

  const handleNextCall = (date) => {
    if (date === null) {
      setNext_callC(null);
    } else {
      const selectedDate = new Date(date);
      const timezoneOffset = new Date().getTimezoneOffset();
      const dateInUTC = addMinutes(selectedDate, -timezoneOffset);
      console.log(dateInUTC);
      setNext_callC(dateInUTC);
    }
  };

  const handleDate_vir = (date) => {
    if (date === null) {
      setDate_virC(null); // ou "" si vous préférez une chaîne vide
      return;
    }
    const selectedDate = new Date(date);
    const timezoneOffset = new Date().getTimezoneOffset();
    const dateInUTC = addMinutes(selectedDate, -timezoneOffset);
    setDate_virC(dateInUTC);
  };

  const handleDateEncaissement = (date) => {
    if (date === null) {
      setDateEncaissementC(null);
      return;
    }
    const selectedDate = new Date(date);
    const timezoneOffset = new Date().getTimezoneOffset();
    const dateInUTC = addMinutes(selectedDate, -timezoneOffset);

    // Obtenir l'année courante
    const currentYear = new Date().getFullYear();
    // Définir les bornes de l'année acceptable (année -1 et année +1)
    const minYear = currentYear - 1;
    const maxYear = currentYear + 1;

    // Vérifier si la date sélectionnée est dans l'intervalle de l'année
    const selectedYear = dateInUTC.getFullYear();
    if (selectedYear < minYear || selectedYear > maxYear) {
      setErrorDateEncaissementC("La date sélectionnée doit être cohérente");
      setLoaderButton(true); // Si hors intervalle, on met une erreur
    } else {
      setErrorDateEncaissementC(false); // Pas d'erreur, on continue
      setDateEncaissementC(dateInUTC);
      setLoaderButton(false);
    }
  };

  const handleDuDateC = (date) => {
    if (date === null) {
      setDuDateC(null); // ou "" si vous préférez une chaîne vide
      return;
    }
    const selectedDate = new Date(date);
    const timezoneOffset = new Date().getTimezoneOffset();
    const dateInUTC = addMinutes(selectedDate, -timezoneOffset);
    // const formattedDate = format(dateInUTC, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
    setDuDateC(dateInUTC);
  };

  return (
    <Offcanvas
      show={isVisible}
      onHide={() => {
        onHide();
      }}
      placement="end"
      style={{ width: "600px" }}
      className="bg-light"
    >
      <Offcanvas.Header>
        <div className="d-flex justify-content-around col-12 title-Modif border-bottom text-black fw-bold">
          <div
            style={{
              borderBottom:
                selectedTitle === "Contrat" ? "3px solid #25e87f" : "none",
              paddingBottom: selectedTitle === "Contrat" ? "10px" : "none",
            }}
            onClick={() => handleTitleClick("Contrat")}
            className="col-3 text-center"
          >
            Contrat
          </div>
          <div
            style={{
              borderBottom:
                selectedTitle === "Notes" ? "3px solid #E3B637" : "none",
            }}
            onClick={() => handleTitleClick("Notes")}
            className="col-3 text-center"
          >
            Notes
          </div>
          <div
            style={{
              borderBottom:
                selectedTitle === "Chat" ? "3px solid #25e87f" : "none",
            }}
            onClick={() => handleTitleClick("Chat")}
            className="col-3 text-center"
          >
            Chat
          </div>
          <div
            style={{
              borderBottom:
                selectedTitle === "Activite" ? "3px solid #25e87f" : "none",
            }}
            onClick={() => handleTitleClick("Activite")}
            className="col-3 text-center"
          >
            Activite
          </div>
        </div>
      </Offcanvas.Header>
      {selectedTitle === "Contrat" && (
        <Offcanvas.Body className="bg-light">
          <div className="text-black">
            {/* <div className="form-group mb-1">
              <ReactSelect
                options={optionsAlias}
                className="basic-single"
                classNamePrefix="select"
                name="alias"
                placeholder="Selectionnez pseudo du seller"
                defaultValue={defaultValueAlias}
                onChange={(selectedOption) => setSupportC(selectedOption.value)}
              />
            </div> */}
            {/* <div className="border my-2"></div> */}
            <div className="d-flex align-items-center mb-3">
              <div style={{ textDecoration: "underline" }} className="fw-bold">
                Informations Client
              </div>
              <div className="ms-2">Crm_Id : {data?.crm_id}</div>
            </div>
            <div className="grid-container">
              <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Prénom</div>
                <input
                  type="text"
                  name="firstName"
                  className="form-control"
                  required
                  defaultValue={prenomC}
                  onChange={(e) => setPrenomC(e.target.value.trimStart())}
                />
              </div>
              <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Nom</div>
                <input
                  type="text"
                  name="lastName"
                  className="form-control"
                  placeholder=""
                  required
                  defaultValue={nomC}
                  onChange={(e) => setNomC(e.target.value.trimStart())}
                />
              </div>
              <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Brand</div>
                <ReactSelect
                  options={optionsBrands}
                  className="basic-single"
                  classNamePrefix="select"
                  name="brand"
                  defaultValue={defaultValueBrand}
                  placeholder=""
                  onChange={(selectedOption) =>
                    setBrandC(selectedOption ? selectedOption.value : null)
                  }
                  isClearable={true}
                />
              </div>
              <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Montant €</div>
                <input
                  type="number"
                  className="form-control"
                  required
                  defaultValue={amountC}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.includes(",") || value.includes(".")) {
                      setErrorVirgule(true);
                    } else {
                      setErrorVirgule(false);
                    }
                    setAmountC(value);
                  }}
                />
                {errorVirgule && (
                  <div className="text-danger fs-16">uniquement un nombre</div>
                )}
              </div>
              <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Date d'échéance</div>
                <DatePicker
                  className="p-2 rounded border"
                  selected={duDateC ? new Date(duDateC) : null}
                  onChange={(date) => handleDuDateC(date)}
                  dateFormat="dd-MM-yyyy"
                />
              </div>
            </div>
            {/* <div className="form-group mb-1">
              <input
                type="text"
                name="firstName"
                className="form-control"
                placeholder="Prénom du Client"
                required
                defaultValue={prenomC}
                onChange={(e) => setPrenomC(e.target.value)}
              />
            </div>
            <div className="form-group mb-1">
              <input
                type="text"
                name="lastName"
                className="form-control"
                placeholder="Nom du Client"
                required
                defaultValue={nomC}
                onChange={(e) => setNomC(e.target.value)}
              />
            </div>
            <div className="form-group mb-1">
              <ReactSelect
                options={optionsBrands}
                className="basic-single"
                classNamePrefix="select"
                name="brand"
                placeholder="Selectionnez votre Brand"
                defaultValue={defaultValueBrand}
                onChange={(selectedOption) => setBrandC(selectedOption.value)}
              />
            </div>
            <div className="form-group mb-1">
              <input
                type="number"
                className="form-control"
                placeholder="MONTANT €"
                required
                defaultValue={amountC}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.includes(",") || value.includes(".")) {
                    setErrorVirgule(true);
                  } else {
                    setErrorVirgule(false);
                  }
                  setAmountC(value);
                }}
              />
              {errorVirgule && (
                <div className="text-danger fs-16">uniquement un nombre.</div>
              )}
            </div> */}

            {/* <div className="form-group mb-1">
              <label htmlFor="">Date d'échéance : </label>
              <DatePicker
                className="p-2 rounded border ms-2"
                placeholderText="Date d'échéance"
                selected={duDateC ? new Date(duDateC) : null}
                onChange={(date) => handleDuDateC(date)}
                dateFormat="yyyy-MM-dd"
              />
            </div> */}
          </div>
          <div className="m-2">
            <div className="border my-2"></div>
            <div className="text-black">
              <div
                style={{ textDecoration: "underline" }}
                className="mb-3 fw-bold mt-1"
              >
                Gestion support
              </div>
              <div className="grid-container">
                <div className="grid-item p-0 me-1">
                  <div className="mx-2 mb-1">PSP</div>
                  <ReactSelect
                    options={pspSelect}
                    className="basic-single"
                    classNamePrefix="select"
                    name="psp"
                    placeholder=""
                    defaultValue={defaultValuePsp}
                    onChange={(selectedOption) =>
                      setPspC(selectedOption ? selectedOption.value : null)
                    }
                    isClearable={true}
                  />
                </div>
                <div className="grid-item p-0 me-1">
                  <div className="mx-2 mb-1">Plateforme</div>
                  <ReactSelect
                    options={neoSelect}
                    className="basic-single"
                    classNamePrefix="select"
                    name="NeoBank"
                    placeholder=""
                    defaultValue={defaultValueNeo}
                    onChange={(selectedOption) =>
                      setNeoBankC(selectedOption ? selectedOption.value : null)
                    }
                    isClearable={true}
                  />
                </div>
                <div className="grid-item p-0 me-1">
                  <div className="mx-2 mb-1">Argent disponible</div>
                  <div className="d-flex m-2">
                    <div className="me-3 mt-2">NON / OUI</div>
                    <Form.Check
                      type="switch"
                      className="custom-switch-drop ms-3"
                      required
                      style={{ transform: "scale(1.5)" }}
                      checked={argent_dispoC}
                      onChange={(e) =>
                        setArgent_dispoC(e.target.checked ? 1 : 0)
                      }
                    />
                  </div>
                </div>
                <div className="grid-item p-0 me-1">
                  <div className="mx-2 mb-1">Banque client</div>
                  <ReactSelect
                    options={BankSelect}
                    className="basic-single"
                    classNamePrefix="select"
                    name="Banque"
                    placeholder=""
                    defaultValue={defaultValueBank}
                    onChange={(selectedOption) =>
                      setCustomerbankC(
                        selectedOption ? selectedOption.value : null
                      )
                    }
                    isClearable={true}
                  />
                </div>
                <div className="grid-item p-0 me-1">
                  <div className="mx-2 mb-1">Preuve de virement</div>
                  <div className="d-flex m-2">
                    <div className="me-3 mt-2">NON / OUI</div>
                    <Form.Check
                      type="switch"
                      className="custom-switch-drop ms-3"
                      required
                      style={{ transform: "scale(1.5)" }}
                      checked={preuveC}
                      onChange={(e) => setPreuveC(e.target.checked ? 1 : 0)}
                    />
                  </div>
                </div>
                <div className="grid-item p-0 me-1">
                  <div className="mx-2 mb-1">Date du virement</div>
                  <DatePicker
                    className="p-2 rounded border"
                    onChange={(date) => {
                      handleDate_vir(date);
                    }}
                    selected={date_virC}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="m-2">
            <div className="border my-2"></div>
            <div className="text-black">
              <div
                style={{ textDecoration: "underline" }}
                className="mb-3 fw-bold mt-1"
              >
                Rappel
              </div>
              <div className="grid-container">
                <div className="grid-item p-0 me-1">
                  <div className="mx-2 mb-1">Date de rappel</div>
                  <DatePicker
                    className="p-2 rounded border ms-2"
                    onChange={(date) => {
                      console.log(date);
                      handleNextCall(date);
                    }}
                    selected={next_callC}
                  />
                </div>
                <div className="grid-item p-0 me-1">
                  <div className="mx-2 mb-1">Heure de rappel</div>
                  <input
                    type="time"
                    name="time"
                    className="form-control"
                    value={hoursCSel === "00:00:00" ? "" : hoursCSel}
                    onChange={
                      (e) =>
                        // console.log(e.target.value)
                        handleHours(e.target.value)
                      // setHoursClient(e.target.value)
                    }
                  />
                  <button
                    // onClick={() => setHoursCSel(null)}
                    onClick={() => setHoursCSel("")}
                    className="btn text-center ms-4 py-0"
                  >
                    Clear Date
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="border m-2"></div>
          <div className="m-2 text-black">
            <div
              style={{ textDecoration: "underline" }}
              className="mb-3 fw-bold mt-1"
            >
              Choix
            </div>
          </div>
          <div className="m-2 d-flex text-black justify-content-around">
            <div>
              <div>Encaissé</div>
              <div className="d-flex m-2">
                <div className="me-3 mt-2">NON / OUI</div>
                <Form.Check
                  type="switch"
                  className="custom-switch-drop ms-3"
                  required
                  style={{ transform: "scale(1.5)" }}
                  checked={encaisseC}
                  onChange={(e) => {
                    setEncaisseC(e.target.checked ? 1 : 0);
                    if (!e.target.checked) {
                      setDateEncaissementC(null);
                      setAmountEncaisseC(0);
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <div>Reporté</div>
              <div className="d-flex m-2">
                <div className="me-3 mt-2">NON / OUI</div>
                <Form.Check
                  type="switch"
                  className="custom-switch-drop ms-3"
                  required
                  style={{ transform: "scale(1.5)" }}
                  checked={is_reportC}
                  onChange={(e) => setIs_reportC(e.target.checked ? 1 : 0)}
                />
              </div>
            </div>
            <div>
              <div>Annulé</div>
              <div className="d-flex m-2">
                <div className="me-3 mt-2">NON / OUI</div>
                <Form.Check
                  type="switch"
                  className="custom-switch-drop ms-3"
                  required
                  style={{ transform: "scale(1.5)" }}
                  checked={is_canceledC}
                  onChange={(e) => setIs_canceledC(e.target.checked ? 1 : 0)}
                />
              </div>
            </div>
          </div>
          {Number(encaisseC) !== 0 && (
            <>
              <div className="border m-2"></div>
              <div className="m-2 text-black">
                <div
                  style={{ textDecoration: "underline" }}
                  className="mb-3 fw-bold mt-1"
                >
                  Encaissement
                </div>
              </div>
              <div className="grid-container text-black mb-2">
                <div className="grid-item p-0 me-1">
                  <div className="mx-3 mb-1">Date encaissement</div>
                  <div className="d-flex flex-column">
                    <DatePicker
                      // className="p-2 rounded border ms-2"
                      className={`p-2 rounded border ms-2 ${
                        errorDateEncaissementC ? "border-danger" : ""
                      }`}
                      onChange={(date) => {
                        handleDateEncaissement(date);
                      }}
                      selected={dateEncaissementC}
                      dateFormat="dd-MM-yyyy"
                    />
                    <small className="text-danger">
                      {errorDateEncaissementC}
                    </small>
                  </div>
                </div>
                <div className="grid-item p-0 me-1">
                  <div className="mx-1 mb-1">Montant encaissé $</div>
                  <input
                    type="number"
                    // className="form-control w-50 py-0"
                    className={`form-control w-50 py-0 ${
                      (errorAmountEncaissementC || errorVirguleEncai) ? "border-danger" : ""
                    }`}
                    placeholder="En Dollars"
                    required
                    defaultValue={
                      amountEncaisseC === 0 ? null : amountEncaisseC
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!value || value === 0) {
                        setErrorAmountEncaissementC("merci de renseigenr un montant");
                        setLoaderButton(true)
                      } else {
                        setErrorAmountEncaissementC("");
                        setLoaderButton(false)
                      }
                      if (value.includes(",") || value.includes(".")) {
                        setErrorVirguleEncai(true);
                      } else {
                        setErrorVirguleEncai(false);
                      }
                      setAmountEncaisseC(value);
                    }}
                  />
                  <small className="text-danger">
                    {errorAmountEncaissementC}
                  </small>
                  {errorVirguleEncai && (
                    <small className="text-danger">
                      uniquement un nombre entier
                    </small>
                  )}
                </div>
              </div>
            </>
          )}
          <div className=" p-1 d-flex">
            <button
              disabled={loaderButton}
              className="border btn btn-green col-12 text-black h-50 me-2"
              onClick={() => UpdateContract(data)}
            >
              Valider
            </button>
          </div>
          {error && (
            <div className="text-red fs-16 m-2">Une erreur est survenue</div>
          )}
        </Offcanvas.Body>
      )}
      {selectedTitle === "Activite" && <Activite contrat={data} />}
      {selectedTitle === "Chat" && <Chat contrat={data} />}
      {selectedTitle === "Notes" && <Notes contrat={data} />}
    </Offcanvas>
  );
};

export default DropUpdateContrat;
