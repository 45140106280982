import React, { useState } from "react";
import {
  Card,
  Form,
  Table,
  Modal,
} from "react-bootstrap";
import ava1 from "../../images/avataaars (1).svg";
import ava2 from "../../images/avataaars (2).svg";
import ava3 from "../../images/avataaars (3).svg";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

const Alerts = () => {
  const [ShowModalUpdate, setShowModalUpdate] = useState(false);

  const teamMembers = [
    {
      id: 123456,
      firstName: "Alice",
      lastName: "PSP",
      role: "Bonjour à tous, je tien à preciser pour tous le monde de ne plus parler de binance. Merci",
      desk: "01",
      manager: "Clara Boucher",
      support: "David",
      avatar: ava1,
    },
    {
      id: 234567,
      firstName: "Bob",
      lastName: "Wake up!",
      role: "Allez Alain bouge toi tu n’ai pas loin de ton objectif !",
      desk: "02",
      manager: "François Moreau",
      support: "Eva",
      avatar: ava2,
    },
    {
      id: 345678,
      firstName: "Clara",
      lastName: "Last lap",
      role: "Il vous reste 2 jours pour atteindre votre target, ne prevoyez rien les deux soir a venir !!!",
      desk: "03",
      manager: "Eva Joly",
      support: "Alice",
      avatar: ava3,
    },
  ];

  const handleClick = () => {
    setShowModalUpdate(!ShowModalUpdate);
  };

  return (
    <div>
      <div className="d-flex border-bottom">
        <div
          className={`col-auto mx-5 text-center cursor-pointer fs-16 h-100 ${"underline"}`}
        >
          Alerts
        </div>
      </div>
      <div className="mt-4 pt-2 pb-2 border-bottom d-flex align-items-center justify-content-between">
        <div>
          <div className="ms-5 fw-bold text-black">
            Ajouter, Activer ou desactiver des Alertes
          </div>
          <div className="ms-5 text-black">
            Cette page vous permet d’ajouter, d’activer et de désactiver des
            alertes.
          </div>
        </div>
        <div
          className="border me-5 px-4 rounded-3 bg-green-target text-black fw-bold"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          + Ajouter
        </div>
      </div>
      <Card className="mt-5">
        <Table bordered className="table-no-vertical-borders">
          <thead>
            <tr>
              <th className="col-1"></th>
              <th className="col-2">Titre</th>
              <th className="col-6">Message</th>
              <th className="col-2">Statut de l’alerte</th>
              <th className="col-1">Action</th>
            </tr>
          </thead>
          <tbody>
            {teamMembers.map((member) => (
              <tr key={member?.id}>
                <td className="col-1 text-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-megaphone-fill"
                    viewBox="0 0 16 16"
                    style={{ transform: "rotate(-45deg)" }}
                  >
                    <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25 25 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009l.496.008a64 64 0 0 1 1.51.048m1.39 1.081q.428.032.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a66 66 0 0 1 1.692.064q.491.026.966.06" />
                  </svg>
                </td>
                <td className="col-2">{member.lastName}</td>
                <td className="d-flex w-100 h-100 col-6">
                  <span
                    className="border rounded-5 w-100 d-flex justify-content-start align-items-center ps-3"
                    style={{ height: "60px" }}
                  >
                    {member.role.length > 100
                      ? member.role.substring(0, 100) + "..."
                      : member.role}
                  </span>
                </td>
                <td className="col-2">
                  <Form.Check
                    type="switch"
                    id={`switch-${member.id}`}
                    className="custom-switch"
                    defaultChecked
                  />
                </td>
                <td className="d-flex justify-content-center col-1 w-100">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-pencil-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                    </svg>
                  </div>
                  <div>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="red"
                      className="bi bi-trash3 ms-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                    </svg>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
      {ShowModalUpdate && (
        <Modal
          className="modal fade"
          show={ShowModalUpdate}
          onHide={handleClick}
          centered
        >
          <Modal.Body
            style={{ backgroundColor: "#F4F4F5" }}
            className="rounded"
          >
            <p className="fw-bold text-black fs-20">Parametrage de l’alerte</p>
            <div className="mt-4">
              <div className="d-flex justify-content-center align-items-center">
                <div className="form-group col-6 me-1">
                  <label htmlFor="pseudo" className="text-black">
                    Titre
                  </label>
                  <input type="text" className="form-control" id="pseudo" />
                </div>
                <div className="form-group col-6 ms-1">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-group">
                        <label className="text-black">
                          Selectionner destinaires
                        </label>
                        <div id="multiselect" className="bg-white rounded">
                          <DropdownMultiselect
                            options={[
                              "Chloé Duffour",
                              "Eric desbois",
                              "Alain villier",
                              "Laura millaux",
                              "Delphine carron",
                            ]}
                            placeholder=""
                            className=""
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <label htmlFor="" className="text-black">message</label>
                <textarea
                  name="write-email"
                  id="write-email"
                  cols="30"
                  rows="5"
                  className="form-control"
                  placeholder="..."
                ></textarea>
              </div>
            </div>
            <div className="mt-3 ms-2 d-flex justify-content-end me-2 btn-modal-footer">
              <div
                className="border rounded-1 px-5 py-1 text-black fw-bold"
                onClick={handleClick}
              >
                Annuler
              </div>
              <div
                className="border-0 rounded-1 px-5 py-1 text-black fw-bold ms-3"
                style={{ backgroundColor: "#25E87F" }}
              >
                valider
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Alerts;
