import React, { useEffect, useState } from "react";
import { Card, Form, Table } from "react-bootstrap";
import axios from "axios";
import config from "../../../config/config";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails, setAdminDesks, getAdminDesks } from "../../../PagesCrm/Redux/slices/admin.slice";
import { setDeskNew } from "../../Redux/slices/actions.slice";
import { setPopup } from "../../Redux/slices/actions.slice";
// import UpdateAdminByManager from "../../Components/Modals/UpdateAdminBymanager";

const Desk = () => {
  const [allDesk, setAllDesks] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [updateIndex, setUpdateIndex] = useState(null);
  const dispatch = useDispatch();
  const adminDesks = useSelector(getAdminDesks);
  const adminDetails = useSelector(getAdminDetails);
  const id = adminDetails.idusers;

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
  };
  // read
  const fetchDesks = () => {
    axios
      .get(`${config.apiAdmin}/sadmin/desks/read_all_admin`, Header)
      .then((response) => {
        setAllDesks(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    fetchDesks(); // Appeler la fonction lors du montage du composant
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // create
  const handleCreate = (inputValue) => {
    const obj = {
      name: inputValue,
      is_active: "1",
    };
    axios
      .put(`${config.apiAdmin}/sadmin/desks/create`, obj, Header) // Remplacez ceci par votre URL et vos paramètres
      .then((response) => {
        // New axios call to /sadmin/deskarr/create with name and id
        const newObj = {
          name: inputValue,
          user_id: id, // Assuming the response contains the id of the newly created desk
        };
        axios
          .put(`${config.apiAdmin}/sadmin/deskarr/create`, newObj, Header)
          .then((response) => {
            SendPopup({ message: "confirm", info: "create.", isActive: true });
            fetchDesks();
            dispatchDeskNew()
            dispatchDeskForSideBard(inputValue)
            console.log("Desk array created successfully");
          })
          .catch((error) => {
            SendPopup({ message: "error", info: "create.", isActive: true });
            console.error("Error creating desk array", error);
          });
      })
      .catch((error) => {
        console.error("There was an error! create desk", error);
      });
  };

  const dispatchDeskNew = () => {
    dispatch(setDeskNew(true));
  }

  const dispatchDeskForSideBard = (deskName) => {
    dispatch(setAdminDesks([...adminDesks, deskName]));
}


  const handleClick = () => {
    setAllDesks((prevDesks) => {
      const newDesks = [
        ...prevDesks,
        {
          iddesks: "",
          name: "",
          is_active: "",
          isEditing: true,
          inputValue: "", // Ajoutez cette ligne
        },
      ];

      return newDesks;
    });
    window.location.href = "#lastElement";
  };

  const dispatchDeskForSideBarDel = (deskName) => {
    dispatch(setAdminDesks(adminDesks?.filter((desk) => desk !== deskName)));
  }

  // update
  const UpdateDesk = (nameDesk, isActive) => {

    setUpdateIndex(null);
    axios
      .patch(
        `${config.apiAdmin}/sadmin/desks/toggleActivation`,
        { id: nameDesk, is_active: isActive },
        Header
      )
      .then((response) => {
        fetchDesks();
        SendPopup({
          message: "confirm",
          info: "toggleActivation.",
          isActive: true,
        });
      })
      .catch((error) => {
        SendPopup({
          message: "error",
          info: "toggleActivation.",
          isActive: true,
        });

        console.error("There was an error! delete desk", error);
      });
    
  };

  return (
    <div>
      <div className="d-flex border-bottom">
        <div
          className={`col-auto mx-5 text-center cursor-pointer fs-16 h-100 ${"underline"}`}
        >
          Desks
        </div>
      </div>
      <div className="mt-4 pt-2 pb-2 border-bottom d-flex align-items-center justify-content-between">
        <div>
          <div className="ms-5 fw-bold text-black">
            Ajouter, Activer ou desactiver des Desks
          </div>
          <div className="ms-5 text-black">
            Cette page vous permet d’ajouter, d’activer et de désactiver des
            Desks.
          </div>
        </div>
        <div
          className="border me-5 px-4 rounded-3 bg-green-target text-black fw-bold"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          + Ajouter
        </div>
      </div>
      <Card className="mt-5">
        <Table bordered className="table-no-vertical-borders">
          <thead className="border-0">
            <tr className="border-0">
              <th className="col-1 rounded"></th>
              <th className="col-2">Date Ajout</th>
              <th className="col-6 text-start">Desk</th>
              <th className="col-2">Statut</th>
              <th className="col-1 rounded">Action</th>
            </tr>
          </thead>
          <tbody>
            {allDesk?.map((member, index) => (
              <tr
                key={member?.iddesks}
                id={index === allDesk.length - 1 ? "lastElement" : undefined}
                style={{ height: "80px" }}
              >
                <td className="col-1 text-end"></td>
                <td className="col-2 fs-12">
                  {member?.created_at && !isNaN(Date.parse(member?.created_at))
                    ? new Date(member?.created_at).toISOString().split("T")[0]
                    : new Date().toISOString().split("T")[0]}
                </td>
                <td className="text-start text-black fw-bold col-6">
                  {member?.isEditing ? (
                    <input
                      style={{ height: "40px" }}
                      className="bg-green-input rounded-3"
                      type="number"
                      value={member?.inputValue}
                      onChange={(e) => {
                        const value = e.target.value;
                        setInputValue(value);
                        setAllDesks((desks) =>
                          desks?.map((desk) =>
                            desk?.iddesks === member?.iddesks
                              ? { ...desk, inputValue: value }
                              : desk
                          )
                        );
                      }}
                    />
                  ) : (
                    <>{member?.name}</>
                  )}
                </td>
                <td className="col-2">
                  {member?.isEditing ? (
                    <>
                      <Form.Check
                        type="switch"
                        className="custom-switch"
                        defaultChecked={member?.is_active !== 0}
                        disabled
                      />
                    </>
                  ) : (
                    <>
                      <Form.Check
                        type="switch"
                        // id={`switch-${member?.name}`}
                        className={`custom-switch ${
                          updateIndex === index ? "bg-green-input" : ""
                        } rounded-3 pb-1`}
                        defaultChecked={member?.is_active !== 0}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          let check;
                          if (isChecked) {
                            check = 1;
                          } else {
                            check = 0;
                          }
                          UpdateDesk(member?.iddesks, check);
                        }}
                      />
                    </>
                  )}
                </td>
                {member?.isEditing ? (
                  <td>
                    <button
                      type="button"
                      className="px-4 py-2 rounded-3 bg-green-target text-black fw-bold border-0"
                      onClick={() => handleCreate(inputValue)}
                    >
                      Valider
                    </button>
                  </td>
                ) : (
                  <td
                    className="d-flex justify-content-center col-12 align-items-center"
                    style={{ height: "80px" }}
                  >
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default Desk;
