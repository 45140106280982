import React from "react";
import ReactApexChart from "react-apexcharts";
import chartmg2 from '../../../images/pt-3.png'


const ImpressionBar = () =>{
    const  series = [
        {
            name: 'dispo prochainement',
            data: [50, 90, 90],
        }, 
        {
          name: 'bzhm',
          data: [50, 60, 55]
        }, 
    ];
      
   const options = {
        chart: {
            type: 'bar',         
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '90%',
              endingShape: "rounded",
              borderRadius: 3,
            },            
        },
        states: {
            hover: {
              filter: 'none',
            }
        },
        colors:['#81A4F9', '#FFFFFF'],
        dataLabels: {
            enabled: false,
            offsetY: -10
        },        
		legend: {
            show: false,
            fontSize: '12px',
            labels: {
                colors: '#000000',                
            },
            markers: {
                width: 18,
                height: 18,
                strokeWidth: 8,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,	
            }
        },
        stroke: {
            show: true,
            width:14,
            curve: 'smooth',
            lineCap: 'round',
            colors: ['transparent']
        },
        grid: {
            show: false,
            xaxis: {
                lines: {
                    show: false,
                }
            },
             yaxis: {
                lines: {
                    show: false
                }
            },  				
        },
        xaxis: {
            categories: ['JAN', 'FEB', 'MAR'],
            labels: {
                show: false,
                style: {
                    colors: '#A5AAB4',
                    fontSize: '14px',
                    fontWeight: '500',
                    fontFamily: 'poppins',
                    cssClass: 'apexcharts-xaxis-label',
                },
            },
            crosshairs: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            }, 			
        },
        yaxis: {
            labels: {
            show: false,
            offsetX:-16,
            style: {
                colors: '#000000',
                fontSize: '13px',
                fontFamily: 'poppins',
                fontWeight: 100,
                cssClass: 'apexcharts-xaxis-label',
            },
          },
        },
        fill: {			  
            type: 'image',
            opacity:1,
            image: {
              src: [chartmg2],
               width: undefined,
               height: undefined
            },            
        }
   }   
    return (
      <div id="impressionChart" className="impression">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={105}          
        />
      </div>
    );
  
}
export default ImpressionBar;
