import React, { } from "react";
import DeskDetail from "../Manager/DeskDetail/DeskDetail";

const PageStatistiques = () => {
  // ici nous prenon strictement la page du manager car il sera absolument identique
  return (
       <DeskDetail />
  );
};

export default PageStatistiques;
