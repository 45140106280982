import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config/config";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { setPopup } from "../../Redux/slices/actions.slice";
import { useSelector, useDispatch } from "react-redux";
import formatNumber from "../../../utils/formatNumber";
import { useParams } from "react-router-dom";
import { Card, Table } from "react-bootstrap";

const TargetsGeneral = () => {
  const { desk } = useParams();
  const AdminDetails = useSelector(getAdminDetails);
  const desksToAdmin = AdminDetails?.desk;

  const deskFinal = desk || desksToAdmin;

  const [ShowInputUpdate, setShowInputUpdate] = useState(false);
  const [ShowInputUpdatePrime, setShowInputUpdatePrime] = useState(false);
  const [targetDesk, setTargetDesk] = useState([]);
  const initStateTargetDesk = [
    {
      objectif: "",
      cash: 0,
      idtargets: "",
      desk: desksToAdmin.length > 1 ? desk : desksToAdmin[0],
    },
    {
      objectif: "",
      cash: 0,
      idtargets: "",
      desk: desksToAdmin.length > 1 ? desk : desksToAdmin[0],
    },
    {
      objectif: "",
      cash: 0,
      idtargets: "",
      desk: desksToAdmin.length > 1 ? desk : desksToAdmin[0],
    },
  ];
  const [valueInputs, setValueInputs] = useState(initStateTargetDesk);

  const [clickedIndex, setClickedIndex] = useState(null);
  const [clickedIndexPrime, setClickedIndexPrime] = useState(null);

  const [newTarget, setNewTarget] = useState([]);
  const [newPrime, setNewPrime] = useState([]);
  const [toMuchTarget, setToMuchTarget] = useState(false);

  const dispatch = useDispatch();

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  useEffect(() => {
    readByDesk();
    // eslint-disable-next-line
  }, []);

  const readByDesk = () => {
    let obj = {
      // si l'admin a plus d'un desk on lui affiche le premier ( venat de UseParams  ) sinon on lui affiche le seul desk qu'il depuis le redux, en prenant la premiere position
      desk: desksToAdmin.length > 1 ? desk : desksToAdmin[0],
    };
    // console.log(obj)
    axios
      .post(
        `${config.apiAdmin}/sadmin/targetsDesks/read_by_desk_month_d`,
        obj,
        Header
      )
      .then((res) => {
        console.log(res.data[0].targets);
        setTargetDesk(res.data[0].targets);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.code);
        if (err.response.data.code === "tar87216") {
          setTargetDesk([]);
        }
      });
  };

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
  };

  const handleUpdate = (idx) => {
    axios
      .post(
        `${config.apiAdmin}/sadmin/targetsDesks/update_all_d`,
        valueInputs[idx],
        Header
      )
      .then((res) => {
        SendPopup({
          message: "confirm",
          info: "update_all_d.",
          isActive: true,
        });
        setShowInputUpdate(false);
        readByDesk();
        setClickedIndex(null);
        setValueInputs(initStateTargetDesk);
      })
      .catch((err) => {
        SendPopup({ message: "error", info: "update_all_d.", isActive: true });
        setClickedIndex(null);
        console.log(err);
        setValueInputs(initStateTargetDesk);
      });
  };
  const handleUpdatePrime = (idx) => {
    axios
      .post(
        `${config.apiAdmin}/sadmin/targetsDesks/update_all_d`,
        valueInputs[idx],
        Header
      )
      .then((res) => {
        SendPopup({
          message: "confirm",
          info: "update_all_d.",
          isActive: true,
        });
        setShowInputUpdatePrime(false);
        readByDesk();
        setClickedIndexPrime(null);
        setValueInputs(initStateTargetDesk);
      })
      .catch((err) => {
        SendPopup({ message: "error", info: "update_all_d.", isActive: true });
        setClickedIndex(null);
        console.log(err);
        setValueInputs(initStateTargetDesk);
      });
  };
  const handleCreate = () => {
    if (targetDesk?.length > 2) {
      setToMuchTarget(true);
      return;
    }
    let obj = {
      objectif: Number(newTarget),
      gift: "",
      cash: Number(newPrime),
      desk: deskFinal,
    };
    axios
      .put(`${config.apiAdmin}/sadmin/targetsDesks/create_d`, obj, Header)
      .then((res) => {
        SendPopup({ message: "confirm", info: "create_d.", isActive: true });
        setShowInputUpdate(false);
        readByDesk();
      })
      .catch((err) => {
        SendPopup({ message: "error", info: "create_d.", isActive: true });
        console.log(err);
      });
  };
  const handleDelete = (item) => {
    let confirResponse = window.confirm("Etes vous sur ?");
    if (confirResponse) {
      const configw = {
        headers: Header.headers, // Assuming 'Header' is an object containing the necessary headers
        data: {
          id: item.idtargets,
        }, // This is where you need to pass the data object for a DELETE request
      };
      // console.log("configw",configw)
      axios
        .delete(`${config.apiAdmin}/sadmin/targetsDesks/delete_d`, configw)
        .then((res) => {
          console.log("ici");
          SendPopup({ message: "confirm", info: "delete_d.", isActive: true });
          setShowInputUpdate(false);
          readByDesk();
        })
        .catch((err) => {
          SendPopup({ message: "error", info: "delete_d.", isActive: true });
          console.log(err);
        });
    }
  };

  const handleClick = (index, item) => {
    setClickedIndex(index);
    setShowInputUpdate(!ShowInputUpdate);
  };
  
  const handleClickPrime = (index, item) => {
    setClickedIndexPrime(index);
    setShowInputUpdatePrime(!ShowInputUpdatePrime);
  };
  return (
    <>
      <div>
        <div
          style={{ fontSize: "130px", fontWeight: "900", color: "black" }}
          className="text-center mt-5"
        >
          Desk {deskFinal}
        </div>
      </div>
      <div className="d-flex justify-content-around">
        <div>
          <h3>Targets actuelles</h3>
          <Card>
            <Table>
              <thead>
                <tr className="text-black">
                  <th className="rounded"></th>
                  <th>Objectif</th>
                  <th className="rounded">Nouvelle objectif</th>
                  <th className="rounded"></th>
                </tr>
              </thead>
              <tbody>
                {targetDesk?.map((item, index) => {
                  return (
                    <>
                      <tr key={item.idtargets} className="table no-border">
                        <td className="text-black">Target {index + 1}</td>
                        <td className="text-end fw-bold text-black">
                          {formatNumber(item.objectif)} $
                        </td>
                        <td>
                          <input
                            disabled={clickedIndex === index ? false : true}
                            className="rounded text-center"
                            type="number"
                            // value={valueInputs[index].objectif}
                            onChange={(e) => {
                              console.log("item.idtargets", item.idtargets);
                              const newValueInputs = [...valueInputs];
                              newValueInputs[index].objectif = e.target.value;
                              newValueInputs[index].cash = item.cash;
                              newValueInputs[index].idtargets = item.idtargets;
                              setValueInputs(newValueInputs);
                            }}
                          />
                        </td>
                        <td>
                        {clickedIndex === index ? (
                            <button
                              className="border me-5 px-4 rounded-3 bg-green-target text-black fw-bold fs-16"
                              onClick={() => handleUpdate(index)}
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              className="border me-5 px-4 rounded-3 bg-green-target text-black fw-bold fs-16"
                              onClick={() => handleClick(index)}
                            >
                              Modifier
                            </button>
                          )}

                          <button
                            onClick={() => handleDelete(item)}
                            className="border me-1 px-4 rounded-3 bg-danger text-black fw-bold fs-16"
                          >
                            delete
                          </button>
                        </td>
                      </tr>
                      <tr key={item.idtargets} className="">
                        <td className="text-black">Prime {index + 1}</td>
                        <td className="text-end fw-bold text-black">
                          {formatNumber(item.cash)} $
                        </td>
                        <td>
                          <input
                            disabled={clickedIndexPrime === index ? false : true}
                            className="rounded text-center"
                            type="number"
                            // value={valueInputs[index].cash}
                            onChange={(e) => {
                              console.log("item.idtargets", item.idtargets);
                              const newValueInputs = [...valueInputs];
                              newValueInputs[index].objectif = item.objectif;
                              newValueInputs[index].cash = e.target.value;
                              newValueInputs[index].idtargets = item.idtargets;
                              setValueInputs(newValueInputs);
                            }}
                          />
                        </td>
                        <td>
                          {clickedIndexPrime === index ? (
                            <button
                              className="border me-5 px-4 rounded-3 bg-green-target text-black fw-bold fs-16"
                              onClick={() => handleUpdatePrime(index)}
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              className="border me-5 px-4 rounded-3 bg-green-target text-black fw-bold fs-16"
                              onClick={() => handleClickPrime(index)}
                            >
                              Modifier
                            </button>
                          )}
                          <button
                            onClick={() => handleDelete(item)}
                            className="border me-1 px-4 rounded-3 bg-danger text-black fw-bold fs-16"
                          >
                            delete
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </div>
        <div>
          <h3>Targets à créer</h3>
          {toMuchTarget && (
            <div className="text-danger fw-bold fw-bold">
              ATTENTION!!! TROP DE TARGETS TUE LA TARGET
            </div>
          )}
          <Card className="h-auto">
            <Table>
              <thead>
                <tr className="text-black">
                  <th className="rounded"></th>
                  <th className="">Nouvelle objectif</th>
                  <th className="rounded">Nouvelle Prime</th>
                  <th className="rounded"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-black">Target</td>
                  <td>
                    <input
                      className="rounded text-center"
                      type="number"
                      onChange={(e) => {
                        setNewTarget(e.target.value);
                      }}
                    />{" "}
                    $
                  </td>
                  <td>
                    <input
                      className="rounded text-center"
                      type="number"
                      onChange={(e) => {
                        setNewPrime(e.target.value);
                      }}
                    />{" "}
                    $
                  </td>
                  <td>
                    <button
                      className="border me-1 px-4 rounded-3 bg-green-target text-black fw-bold fs-16"
                      onClick={() => handleCreate()}
                    >
                      Ajouter
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </div>
      </div>
    </>
  );
};

export default TargetsGeneral;
