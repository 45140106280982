import React from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import formatNumber from "../../../utils/formatNumber";
import { gql, useQuery } from "@apollo/client";
import { getToken } from "../../Redux/slices/admin.slice";
import { getSockets } from "../../Redux/slices/notifSocket.slice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import config from "../../../config/config";
import { setAllContracts } from "../../Redux/slices/contract.slice";
import { useNavigate } from "react-router-dom";
import { getAdminDesks, getAdminDetails } from "../../Redux/slices/admin.slice";

const NewExpChart = loadable(() => pMinDelay(import("./NewExpChart"), 1000));
const RevenueChartBar = loadable(() =>
  pMinDelay(import("./RevenueChartBar"), 1000)
);
const ExpensesBarChart = loadable(() =>
  pMinDelay(import("./ExpensesBarChart"), 1000)
);

const GET_AGT_SUPP_TODO = gql`
query MngSuppContractsToDo {
  mngSuppContractsToDo {
    total
    tot_without_psp
    tot_without_bank
    tot_without_rdv
    tot_report
  }
}
`;

const CardsSupport = ({ dataReportContract }) => {
  const GET_TOKEN = useSelector(getToken);
  const GET_SOCKET = useSelector(getSockets);

  const Header = {
    context: {
      headers: {
        Authorization: `Bearer ${GET_TOKEN}`,
      },
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminDesks = useSelector(getAdminDesks);

  const AdminDetails = useSelector(getAdminDetails);
  const IdToAdmin = AdminDetails?.idusers;
  const profilToAdmin = AdminDetails?.profil;

  let HeaderFetch = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const handleChangePage = (mode) => {
    let obj = {
      filters: {
        desk: adminDesks,
        agt_supp_id: profilToAdmin === "agt_supp" ? IdToAdmin : "",
        encaisse: 0,
        is_report: 0,
        is_canceled: 0
      },
      filters_date: {},
      sorts: {
        amount: "DESC",
      },
      limit: "",
      otherDateNull: mode,
    };
    axios
      .post(
        `${config.apiAdmin}/sadmin/contracts/search_and_sort`,
        obj,
        HeaderFetch
      )
      .then((res) => {
        dispatch(setAllContracts(res.data.data.results));
        navigate(`/supp_tab/${mode}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { data: dataAgtSuppTodo, refetch: refetchAgtSuppTodo  } = useQuery(GET_AGT_SUPP_TODO, Header);

  React.useEffect(() => {
    refetchAgtSuppTodo();
  }, [GET_SOCKET]);

  // console.log(dataReportContract)
  console.log( "dataAgtSuppTodo= >>>>>>>>>>> ", dataAgtSuppTodo);

  return (
    <div className="row">
      {/* premiere ligne */}
      <div className="col-sm-3">
        <div
          className="card bg-grey-light pointer"
          onClick={() => handleChangePage("psp")}
        >
          <div className="card-body">
            <div className=" d-flex justify-content-between">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div className="font-w500 mb-0 text-black fs-4">
                    Contrats Sans PSP
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="text-black col-6 font-w800">
                    {dataAgtSuppTodo?.mngSuppContractsToDo?.tot_without_psp}
                  </h3>
                  {/* <h3 className="border-start col-6">20</h3> */}
                </div>
              </div>
            </div>
            {/* <div className="progress-box mt-0 custome-progress">
              <div className="d-flex justify-content-between">
                <div className="text-black p-1">Complétude</div>
                <div className="text-black p-1">
                  {100 -
                    (dataAgtSuppTodo?.agtSuppContractsToDo?.tot_without_psp *
                      100) /
                      dataAgtSuppTodo?.agtSuppContractsToDo?.tot_psp}
                  %
                </div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-white"
                  style={{
                    width: `${
                      (dataAgtSuppTodo?.agtSuppContractsToDo?.tot_without_psp *
                        100) /
                      dataAgtSuppTodo?.agtSuppContractsToDo?.tot_psp
                    }`,
                    height: "12px",
                    borderRadius: "8px",
                  }}
                ></div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="col-sm-3">
      <div className="card bg-grey-light pointer" onClick={() => handleChangePage("neobank")}>
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div className="font-w500 mb-0 text-black fs-4">
                    Contrats sans plateformes
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="text-black col-6 font-w800">
                    {dataAgtSuppTodo?.mngSuppContractsToDo?.tot_without_bank}
                  </h3>
                  {/* <h3 className="border-start col-6">20</h3> */}
                </div>
              </div>
            </div>
            {/* <div className="progress-box mt-0 custome-progress">
              <div className="d-flex justify-content-between">
                <div className="text-black p-1">Complétude</div>
                <div className="text-black p-1">
                  {100 -
                    (dataAgtSuppTodo?.agtSuppContractsToDo?.tot_without_bank *
                      100) /
                      dataAgtSuppTodo?.agtSuppContractsToDo?.tot_bank}
                  %
                </div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-white"
                  style={{
                    width: `${
                      (dataAgtSuppTodo?.agtSuppContractsToDo?.tot_without_bank *
                        100) /
                      dataAgtSuppTodo?.agtSuppContractsToDo?.tot_bank
                    }`,
                    height: "12px",
                    borderRadius: "8px",
                  }}
                ></div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="col-sm-3">
      <div className="card bg-grey-light pointer" onClick={() => handleChangePage("next_call")}>
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div className="font-w500 mb-0 text-black fs-4">
                    Contrats Sans RDV
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="text-black col-6 font-w800">
                    {dataAgtSuppTodo?.mngSuppContractsToDo?.tot_without_rdv}
                  </h3>
                  {/* <h3 className="border-start col-6">20</h3> */}
                </div>
              </div>
            </div>
            {/* <div className="progress-box mt-0 custome-progress">
              <div className="d-flex justify-content-between">
                <div className="text-black p-1">Complétude</div>
                <div className="text-black p-1">
                  {" "}
                  {100 -
                    (
                      (dataAgtSuppTodo?.agtSuppContractsToDo?.tot_without_rdv *
                        100) /
                      dataAgtSuppTodo?.agtSuppContractsToDo?.tot_rdv
                    )?.toFixed(1)}{" "}
                  %{" "}
                </div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-white"
                  style={{
                    width: `${
                      100 -
                      (dataAgtSuppTodo?.agtSuppContractsToDo?.tot_without_rdv *
                        100) /
                        dataAgtSuppTodo?.agtSuppContractsToDo?.tot_rdv
                    }`,
                    height: "12px",
                    borderRadius: "8px",
                  }}
                ></div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="col-sm-3">
        <div className="card bg-grey-light">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div className="font-w500 mb-0 text-black fs-4">Reports</div>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="border-start col-6">{dataAgtSuppTodo?.mngSuppContractsToDo?.tot_report}</h3>
                </div>
              </div>
            </div>
            {/* <div className="progress-box mt-0 custome-progress">
            <div className="d-flex justify-content-between">
              <div className="text-black p-1">Complétude</div>
            </div>
            <div className="progress">
              <div
                className="progress-bar bg-white"
                style={{
                  width: "60%",
                  height: "12px",
                  borderRadius: "8px",
                }}
              ></div>
            </div>
          </div> */}
          </div>
        </div>
      </div>
      {/* seconde ligne */}
      <div className="col-sm-3" title="Ce chiffre correspond a tous les contrats dans lesquel nous retirons les contrats reportes, et les contrat annules">
        <div className="card same-card-chart bg-danger-light diposit-bg">
          <div className="card-body p-0">
            <div className=" d-flex justify-content-between p-4">
              <div>
                <div className="mb-0 font-w500 text-black fs-4">
                  Contrats Signés
                </div>
                <h3 className="text-black font-w800">
                  {formatNumber(dataReportContract?.total_amount)} €
                </h3>
              </div>
              <div className="icon-box rounded-circle">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 12 20"
                  fill="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4642 13.7074C11.4759 12.1252 10.8504 10.8738 9.60279 9.99009C8.6392 9.30968 7.46984 8.95476 6.33882 8.6137C3.98274 7.89943 3.29927 7.52321 3.29927 6.3965C3.29927 5.14147 4.93028 4.69493 6.32655 4.69493C7.34341 4.69493 8.51331 5.01109 9.23985 5.47964L10.6802 3.24887C9.73069 2.6333 8.43112 2.21342 7.14783 2.0831V0H4.49076V2.22918C2.12884 2.74876 0.640949 4.29246 0.640949 6.3965C0.640949 7.87005 1.25327 9.03865 2.45745 9.86289C3.37331 10.4921 4.49028 10.83 5.56927 11.1572C7.88027 11.8557 8.81873 12.2813 8.80805 13.691L8.80799 13.7014C8.80799 14.8845 7.24005 15.3051 5.89676 15.3051C4.62786 15.3051 3.248 14.749 2.46582 13.9222L0.535522 15.7481C1.52607 16.7957 2.96523 17.5364 4.4907 17.8267V20.0001H7.14783V17.8735C9.7724 17.4978 11.4616 15.9177 11.4642 13.7074Z"
                    fill="#0077b6"
                  />
                </svg>
              </div>
            </div>
            <NewExpChart color={"white"} />
          </div>
        </div>
      </div>
      <div className="col-sm-3" title="Ce chiffre est le cumul de tous les contrats dont la case preuve est coche, mais nous ne preons pas compte des contrats: encaisses, reportes, annules">
        <div className="card same-card-chart bg-warning-light diposit-bg">
          <div className="card-body p-0">
            <div className=" d-flex justify-content-between p-4 pb-0">
              <div>
                <div className="mb-0 font-w500 text-black fs-4">
                  Preuves de virements
                </div>
                <h3 className="text-black font-w800">
                  {formatNumber(dataReportContract?.total_amount_with_preuve)} €
                </h3>
              </div>
              <div className="icon-box rounded-circle">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 12 20"
                  fill="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4642 13.7074C11.4759 12.1252 10.8504 10.8738 9.60279 9.99009C8.6392 9.30968 7.46984 8.95476 6.33882 8.6137C3.98274 7.89943 3.29927 7.52321 3.29927 6.3965C3.29927 5.14147 4.93028 4.69493 6.32655 4.69493C7.34341 4.69493 8.51331 5.01109 9.23985 5.47964L10.6802 3.24887C9.73069 2.6333 8.43112 2.21342 7.14783 2.0831V0H4.49076V2.22918C2.12884 2.74876 0.640949 4.29246 0.640949 6.3965C0.640949 7.87005 1.25327 9.03865 2.45745 9.86289C3.37331 10.4921 4.49028 10.83 5.56927 11.1572C7.88027 11.8557 8.81873 12.2813 8.80805 13.691L8.80799 13.7014C8.80799 14.8845 7.24005 15.3051 5.89676 15.3051C4.62786 15.3051 3.248 14.749 2.46582 13.9222L0.535522 15.7481C1.52607 16.7957 2.96523 17.5364 4.4907 17.8267V20.0001H7.14783V17.8735C9.7724 17.4978 11.4616 15.9177 11.4642 13.7074Z"
                    fill="#0077b6"
                  />
                </svg>
              </div>
            </div>
            <RevenueChartBar />
          </div>
        </div>
      </div>
      <div className="col-sm-3" title="Ce chiffre est la somme de la case 'encaisse en dollars' de tous les contrats dont la casse 'encaisse' a ete coche. Nous y soustrayons les contrat reportes, et les contrats annules">
        <div className="card same-card-chart bg-turquoise-light diposit-bg">
          <div className="card-body p-0">
            <div className=" d-flex justify-content-between p-4 pb-0">
              <div>
                <div className="mb-0 font-w500 text-black fs-4">
                  Contrats Encaissés
                </div>
                <h3 className="text-black font-w800">
                  {formatNumber(dataReportContract?.total_amount_with_encaisse)}{" "}
                  $
                </h3>
              </div>
              <div className="icon-box rounded-circle">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 12 20"
                  fill="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4642 13.7074C11.4759 12.1252 10.8504 10.8738 9.60279 9.99009C8.6392 9.30968 7.46984 8.95476 6.33882 8.6137C3.98274 7.89943 3.29927 7.52321 3.29927 6.3965C3.29927 5.14147 4.93028 4.69493 6.32655 4.69493C7.34341 4.69493 8.51331 5.01109 9.23985 5.47964L10.6802 3.24887C9.73069 2.6333 8.43112 2.21342 7.14783 2.0831V0H4.49076V2.22918C2.12884 2.74876 0.640949 4.29246 0.640949 6.3965C0.640949 7.87005 1.25327 9.03865 2.45745 9.86289C3.37331 10.4921 4.49028 10.83 5.56927 11.1572C7.88027 11.8557 8.81873 12.2813 8.80805 13.691L8.80799 13.7014C8.80799 14.8845 7.24005 15.3051 5.89676 15.3051C4.62786 15.3051 3.248 14.749 2.46582 13.9222L0.535522 15.7481C1.52607 16.7957 2.96523 17.5364 4.4907 17.8267V20.0001H7.14783V17.8735C9.7724 17.4978 11.4616 15.9177 11.4642 13.7074Z"
                    fill="#0077b6"
                  />
                </svg>
              </div>
            </div>
            <ExpensesBarChart />
          </div>
        </div>
      </div>
      <div className="col-sm-3" title="ce calcul est la somme de tous les contrat dont la case reportes a ete coche et dont la case 'encaisse' n'a jamais ete coche">
        <div className="card same-card-chart bg-purple-light diposit-bg">
          <div className="card-body p-0">
            <div className=" d-flex justify-content-between p-4 pb-0">
              <div>
                <div className="mb-0 font-w500 text-black fs-4">Reports</div>
                <h3 className="text-black font-w800">
                  {formatNumber(
                    dataReportContract?.total_amount_reported_not_encashed
                  )}{" "}
                  €
                </h3>
              </div>
              <div className="icon-box rounded-circle">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 12 20"
                  fill="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4642 13.7074C11.4759 12.1252 10.8504 10.8738 9.60279 9.99009C8.6392 9.30968 7.46984 8.95476 6.33882 8.6137C3.98274 7.89943 3.29927 7.52321 3.29927 6.3965C3.29927 5.14147 4.93028 4.69493 6.32655 4.69493C7.34341 4.69493 8.51331 5.01109 9.23985 5.47964L10.6802 3.24887C9.73069 2.6333 8.43112 2.21342 7.14783 2.0831V0H4.49076V2.22918C2.12884 2.74876 0.640949 4.29246 0.640949 6.3965C0.640949 7.87005 1.25327 9.03865 2.45745 9.86289C3.37331 10.4921 4.49028 10.83 5.56927 11.1572C7.88027 11.8557 8.81873 12.2813 8.80805 13.691L8.80799 13.7014C8.80799 14.8845 7.24005 15.3051 5.89676 15.3051C4.62786 15.3051 3.248 14.749 2.46582 13.9222L0.535522 15.7481C1.52607 16.7957 2.96523 17.5364 4.4907 17.8267V20.0001H7.14783V17.8735C9.7724 17.4978 11.4616 15.9177 11.4642 13.7074Z"
                    fill="#0077b6"
                  />
                </svg>
              </div>
            </div>
            <ExpensesBarChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsSupport;
