import React, { useEffect, useRef, useState } from "react";

/// Link
import { Link } from "react-router-dom";
import { useMenuList } from "./Menu";
import Logoutbtn from "./Logoutbtn";
import { useSelector } from "react-redux";
import {
  getAdminDesks,
  getAdminProfil,
} from "../../../PagesCrm/Redux/slices/admin.slice";


const SideBar = () => {
  const myRef = useRef();

  const adminDesks = useSelector(getAdminDesks);
  const adminprofil = useSelector(getAdminProfil);
  const [activeDeskIndex, setActiveDeskIndex] = useState(null);
  const [activeItemIndex, setActiveItemIndex] = useState(null);
  const [activeMenuItemIndex, setActiveMenuItemIndex] = useState(null);
  const [isDesksClicked, setIsDesksClicked] = useState(false);
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(null);
  const [selectedLinkIndexDesk, setSelectedLinkIndexDesk] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (myRef.current && !myRef.current.contains(event.target)) {
        setActiveDeskIndex(null);
        setActiveItemIndex(null);
        setSelectedLinkIndexDesk(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const menuItems = useMenuList();

  const titleToPathAdmin = {
    Dashboard: "/admin",
    Statistiques: "/doug",
    Contrats: "/admin_tab",
    Utilisateurs: "/admin_params",
    Targets: "/admin_targets",
    Alerts: "/admin_alerts",
    Psp: "/admin_psp",
    "Platforme": "/admin_neobank",
    "Banque client": "/admin_bankclient",
    Brand: "/admin_brand",
    Desk: "/admin_desk",
  };
  const titleToPath = {
    Dashboard: "/m",
    Statistiques: "/m_stats",
    Contrats: "/m_tab",
    Utilisateurs: "/m_params",
    Targets: "/m_targets",
    Alerts: "/m_alerts",
    Psp: "/m_psp",
    "Platforme": "/m_neobank",
    "Banque client": "/m_bankclient",
  };
  const titleToPathCs = {
    Dashboard: "/cs",
    Statistiques: "/cs_cards",
    Contrats: "/cs_tab",
    Utilisateurs: "/cs_params",
    Targets: "/cs_targets",
    Alerts: "/cs_alerts",
    Psp: "/m_psp",
    "Platforme": "/m_neobank",
    "Banque client": "/m_bankclient",
  };

  return (
    <div className={`deznav`}>
      <div className="deznav-scroll d-flex flex-column">
        {menuItems?.map((item, itemIndex) => {
          return (
            <div className="d-flex flex-column" key={itemIndex}>
              {item?.content ? (
                adminprofil === "manager" &&
                adminprofil === "admin" &&
                adminDesks.length === 1 && (
                  <div className="d-flex align-items-center mx-4 my-2">
                    <div className="menu-icon border rounded-5 p-2 bg-light ">
                      {item?.iconStyle}
                    </div>
                    <div
                      onClick={() => {
                        setActiveMenuItemIndex(
                          activeMenuItemIndex === itemIndex ? null : itemIndex
                        );
                        setIsDesksClicked(false);
                        setSelectedLinkIndex(null);
                      }}
                      style={{ cursor: "pointer" }}
                      className="sidebar-title ms-3"
                    >
                      {item?.title}
                    </div>
                  </div>
                )
              ) : (
                <div className="d-flex align-items-center mx-4 my-2">
                  <div className="menu-icon border rounded-5 p-2 bg-light ">
                    {item?.iconStyle}
                  </div>
                  <Link
                    to={item?.to}
                    onClick={() => {
                      setActiveMenuItemIndex(
                        activeMenuItemIndex === itemIndex ? null : itemIndex
                      );
                      setIsDesksClicked(false);
                    }}
                    className="sidebar-title ms-3"
                  >
                    {item?.title}
                  </Link>
                </div>
              )}
              {item?.content && activeMenuItemIndex === itemIndex
                ? item?.content.map((subItem, subIndex) => {
                    const titlesToExclude =
                      adminDesks.length >= 1
                        ? []
                        : ["Targets", "Utilisateurs", "Alerts"];
                    if (titlesToExclude.includes(subItem?.title)) {
                      return null;
                    }
                    return (
                      <Link
                        to={subItem?.to || "#"}
                        key={subIndex}
                        className={`sidebar-config my-1 py-1 col-6 ps-3 ${
                          selectedLinkIndex === subIndex ? "selected" : ""
                        }`}
                        onClick={() => setSelectedLinkIndex(subIndex)}
                      >
                        <div>{subItem?.title}</div>
                      </Link>
                    );
                  })
                : null}
            </div>
          );
        })}

        {/* pour un user qui a plusieurs desk */}
        {adminDesks.length >= 2 &&
          (adminprofil === "ass_mng" ||
            adminprofil === "boss_supp" ||
            adminprofil === "manager" ||
            adminprofil === "mng_supp" ||
            adminprofil === "admin" ||
            adminprofil === "sadmin") && (
            <>
              <div className="d-flex align-items-center mx-4 my-2">
                <div className="menu-icon border rounded-5 p-2 bg-light ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="black"
                    className="bi bi-buildings"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z" />
                    <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z" />
                  </svg>
                </div>
                <div
                  onClick={() => {
                    setIsDesksClicked(!isDesksClicked);
                    setActiveMenuItemIndex(null);
                  }}
                  className="sidebar-title ms-3"
                >
                  Desks
                </div>
              </div>
              <div ref={myRef}>
                {isDesksClicked &&
                  adminDesks?.map((desk, deskIndex) => {
                    // console.log(desk)
                    // const path = titleToPath[item?.title];
                    return (
                      <li key={deskIndex}>
                        <Link
                          to={
                            (() => {
                              switch (adminprofil) {
                                case "boss_sup":
                                  return "cs";
                                case "manager":
                                  return "m";
                                case "admin":
                                  return "admin";
                                default:
                                  return ""; // Default case if none of the above
                              }
                            })() +
                            "/" +
                            desk
                          }
                        >
                          <div
                            onClick={() => {
                              const isSameDesk = activeDeskIndex === deskIndex;
                              setActiveDeskIndex(isSameDesk ? null : deskIndex);
                              setSelectedLinkIndexDesk(null);
                              setActiveItemIndex(null);
                            }}
                            className={`sidebar-desks my-1 py-1 col-6 ${
                              activeDeskIndex === deskIndex ? "active" : ""
                            }`}
                          >
                            Desk {desk}
                          </div>
                        </Link>
                        {activeDeskIndex === deskIndex &&
                          menuItems?.map((item, itemIndex) => {
                            console.log(item);
                            let path;
                            switch (adminprofil) {
                              case "boss_supp":
                              case "mng_supp":
                                path = titleToPathCs;
                                break;
                              case "manager":
                                path = titleToPath;
                                break;
                              case "admin":
                                path = titleToPathAdmin;
                                break;
                              default:
                                path = ""; // Default case if none of the above
                            }
                            console.log(path);
                            return (
                              <div
                                className="d-flex flex-column"
                                key={itemIndex}
                              >
                                {item?.title === "Configurations" ? (
                                  <div className="d-flex ms-5 py-1 ps-3">
                                    <div className="menu-icon rounded-5 p-2 bg-light">
                                      {item?.iconStyle}
                                    </div>
                                    <div
                                      onClick={() =>
                                        setActiveItemIndex(
                                          activeItemIndex === itemIndex
                                            ? null
                                            : itemIndex
                                        )
                                      }
                                      className={`sidebar-desks-title my-1 py-1 col-7`}
                                    >
                                      {item?.title}
                                    </div>
                                  </div>
                                ) : (
                                  <Link
                                    to={item?.to + "/" + desk}
                                    className="d-flex ms-5 py-1 ps-3"
                                  >
                                    <div className="menu-icon rounded-5 p-2 bg-light">
                                      {item?.iconStyle}
                                    </div>
                                    <div
                                      onClick={() => {
                                        const isSelectedItem =
                                          activeItemIndex === itemIndex;
                                        setActiveItemIndex(
                                          isSelectedItem ? null : itemIndex
                                        );
                                        setSelectedLinkIndexDesk(null);
                                      }}
                                      className={`sidebar-desks-title my-1 py-1 col-7`}
                                    >
                                      {item?.title}55
                                    </div>
                                  </Link>
                                )}
                                {item?.content && activeItemIndex === itemIndex
                                  ? item?.content?.map((subItem, subIndex) => {
                                      if (
                                        subItem?.title === "Brand" ||
                                        subItem?.title === "Banque client" ||
                                        subItem?.title === "Platforme" ||
                                        subItem?.title === "Psp" ||
                                        subItem?.title === "Desk"
                                      ) {
                                        return null;
                                      }
                                      // let path; 
                                      switch (adminprofil) {
                                        case "boss_supp":
                                        case "mng_supp":
                                          path = titleToPathCs;
                                          break;
                                        case "manager":
                                          path = titleToPath;
                                          break;
                                        case "admin":
                                          path = titleToPathAdmin;
                                          break;
                                        default:
                                          path = ""; // Default case if none of the above
                                      }
                                      return (
                                        <Link
                                          to={desk ? `${subItem?.to}/${desk}` : ""}
                                          key={subIndex}
                                          className={`sidebar-desk-config my-1 py-1 col-6  ${
                                            selectedLinkIndexDesk === subIndex
                                              ? "active-desk-config"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setSelectedLinkIndexDesk(subIndex);
                                          }}
                                        >
                                          <div>{subItem?.title}</div>
                                        </Link>
                                      );
                                    })
                                  : null}
                              </div>
                            );
                          })}
                      </li>
                    );
                  })}
              </div>
            </>
          )}

        {/* cas de l'admin */}
        {adminprofil === "admin" && adminDesks.length >= 2 && (
          <div className="">
            {menuItems[3]?.content
              .filter((_, index) => index > 2)
              .map((item, itemIndex) => (
                <div className="d-flex align-items-center mx-4 my-2">
                  <div className="menu-icon border rounded-5 p-2 bg-light ">
                    {item?.iconStyle}
                  </div>
                  <Link
                    to={item?.to}
                    onClick={() => {
                      setActiveMenuItemIndex(
                        activeMenuItemIndex === itemIndex ? null : itemIndex
                      );
                      setIsDesksClicked(false);
                    }}
                    className="sidebar-title ms-3"
                  >
                    {item?.title}
                  </Link>
                </div>
              ))}
          </div>
        )}

        {/* pour un user qui a un seul desk */}
        {adminDesks.length === 1 && (
          <>
            {menuItems[3]?.content.map((item, itemIndex) => {
              return (
                <div className="d-flex align-items-center mx-4 my-2">
                  <div className="menu-icon border rounded-5 p-2 bg-light ">
                    {item?.iconStyle}
                  </div>
                  <Link
                    to={item?.to}
                    onClick={() => {
                      setActiveMenuItemIndex(
                        activeMenuItemIndex === itemIndex ? null : itemIndex
                      );
                      setIsDesksClicked(false);
                    }}
                    className="sidebar-title ms-3"
                  >
                    {item?.title}
                  </Link>
                </div>
              );
            })}
          </>
        )}
        {/* {(adminprofil === "admin")  && (
          <>
            {menuItems[3].content.map((item, itemIndex) => {
              return (
                <div className="d-flex align-items-center mx-4 my-2">
                  <div className="menu-icon border rounded-5 p-2 bg-light ">
                    {item?.iconStyle}
                  </div>
                  <Link
                    to={item?.to}
                    onClick={() => {
                      setActiveMenuItemIndex(
                        activeMenuItemIndex === itemIndex ? null : itemIndex
                      );
                      setIsDesksClicked(false);
                    }}
                    className="sidebar-title ms-3"
                  >
                    {item?.title}
                  </Link>
                </div>
              );
            })}
          </>
        )} */}

        <div className="switch-btn mt-auto">
          <Logoutbtn />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
