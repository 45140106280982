import React from "react";
import TableauCrm from "./Tableau/TableauCrm";

const Tableau = () => {

  return (
    <>
      <TableauCrm/>
    </>
  );
};

export default Tableau; 
