import React from "react";
import { Card } from "react-bootstrap";
import formatNumber from "../../../utils/formatNumber";
import { getEuroUsd } from "../../Redux/slices/taux.slice";
import { useSelector } from "react-redux";

const ProgressBarSeller = ({ data, totalencaisse, totalnetprev }) => {


  const data1 = data;

  const index = data.length - 1;

  const objMax = data[index]?.objectif;

  const total = totalencaisse > objMax ? totalencaisse : objMax;

  const milestones = data1?.map((item) => ({
    objectif: item.objectif,
    pourcentage: (item.objectif * 100) / total,
  }));

  const encaissePercentage = Math.ceil(((totalencaisse || "") / total) * 100);
  const netPrevPercentage = Math.ceil(((totalnetprev || "") / total) * 100);

  const t1Percentage = Math.ceil(
    ((milestones[0].objectif || "") / total) * 100
  );
  const t2Percentage = Math.ceil(
    ((milestones[1]?.objectif || "") / total) * 100
  );
  const t3Percentage = Math.ceil(
    ((milestones[2]?.objectif || "") / total) * 100
  );

  const isTargetCompleted =
    (parseFloat(totalnetprev) || parseFloat("")) >= parseFloat(total);

  const segments = [
    {
      label: "NetPrev",
      percentage: Math.ceil(((totalnetprev || "") / total) * 100),
      color: "#10d078",
      //   color: "transparent",
      value: formatNumber(totalnetprev || 1),
    },
    {
      label: "encaisseMoinsPourcent",
      percentage: Math.ceil(((totalencaisse || "") / total) * 100),
      color: "#ffd55866",
      //   color: "transparent",
      value: formatNumber(totalencaisse || 1),
    },
    {
      label: "t1",
      percentage: Math.ceil(((milestones[0].objectif || "") / total) * 100),
      color: "transparent",
      value: formatNumber(milestones[0]?.objectif || 1),
    },
    {
      label: "t2",
      percentage: Math.ceil(((milestones[1]?.objectif || "") / total) * 100),
      color: "transparent",
      value: formatNumber(milestones[1]?.objectif || 0),
    },
    {
      label: "t3",
      percentage: Math.ceil(((milestones[2]?.objectif || "") / total) * 100),
      color: "transparent",
      value: formatNumber(milestones[2]?.objectif || 0),
    },
    // {
    //     label: "0",
    //     percentage: 0.1,
    //     color: "red",
    //     value: formatNumber(1),
    //   },
  ];

  const sortedSegments = [...segments].sort(
    (a, b) => a.percentage - b.percentage
  );


  const ProgressBar = ({ segments }) => (
    <div
      style={{
        height: "24px",
        backgroundColor: "#e9ecef",
        borderRadius: "12px",
        // position:"relative"
      }}
    >
      {sortedSegments?.map(
        (segment, index) =>
          segment.value != 0 && (
            <div
              key={index}
              style={{
                width: `${segment.percentage}%`,
                backgroundColor: `${segment.color}`,
                height: "100%",
                textAlign: "end",
                position: "absolute",
                color: "black",
                fontWeight: "bold",
                borderRadius: "12px",
                // left: "0",
                zIndex: index,
              }}
            >
              {/* <div style={{ position: "absolute" }}></div> */}
              <div
                style={{
                  position: "relative",
                  bottom: ["t1", "t2", "t3", "0"].includes(segment.label)
                    ? "32px"
                    : "0px",
                  fontSize: ["t1", "t2", "t3", "0"].includes(segment.label)
                    ? "12px"
                    : "14px",
                }}
              >
                <div
                  style={{ padding: "2px 4px 0 0" }}
                  className={`${segment.label === "NetPrev" 
                    ? "color-green-progress" 
                    : segment.label === "encaisseMoinsPourcent" 
                    ? "mt-4 color-orange-progress" 
                    : ""}`}
                >{`${segment.value} $`}</div>
                {["t1", "t2", "t3"].includes(segment.label) ? (
                  <div className="">|</div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )
      )}
    </div>
  );

  return (
    <>
      <div className="row">
        {isTargetCompleted && (
          <div className="d-flex col-12 align-items-center justify-content-center">
            <h5 className="ms-3 py-2 text-center">
              🎉 Toutes les objectifs ont étés atteints. 🎉
            </h5>
            <h5 className="mt-1 ms-3 d-flex">
              Montant Signé en cours :
              {totalencaisse
                ? formatNumber(totalencaisse)
                : formatNumber(objMax)}{" "}
              $ soit{" "}
              <div className="mx-1 text-success"> + {encaissePercentage}%</div>{" "}
              de l'object total
            </h5>
          </div>
        )}
        <Card.Body>
          <div style={{ position: "relative" }}>
            <ProgressBar />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
            }}
          >
            <div className="d-flex justify-content-center">
              <span className="text-black"></span>
            </div>
            <div className="d-flex justify-content-center">
              <span className="fs-12 text-black">
                {formatNumber(totalnetprev)} $ soit {netPrevPercentage}% de
                l'object total
              </span>
            </div>
            <div className="d-flex justify-content-center">
              {/* <span className="fs-12 text-black">{formatNumber(total)}$</span> */}
            </div>
          </div>
        </Card.Body>
      </div>
    </>
  );
};

export default ProgressBarSeller;



  // const highestObjective = data.res.reduce((max, target) =>
  //     target.objectif > max ? target.objectif : max, 0);

  // console.log(data);
  // console.log(totalencaisse);
  // console.log(totalnetprev);

  ////////////////////////////// mise en place pour une valeur negative

  // const totalencaisseNeg = 24154;
  // const totalnetprevNeg = 23500;

  // const data1Neg = data;

  // const indexNeg = data.length - 1;

  // const objMaxNeg = data1Neg[indexNeg]?.objectif;

  // console.log(objMaxNeg)
  // console.log(totalencaisseNeg)

  // const totalNeg = totalencaisseNeg < objMaxNeg ? totalencaisseNeg : objMaxNeg;

  // const milestonesNeg = data1Neg?.map((item) => ({
  //   objectif: item.objectif,
  //   pourcentage: (item.objectif * 100) / totalNeg,
  // }));

  // const encaissePercentageNeg = Math.ceil(
  //   ((totalencaisseNeg || "") / totalNeg) * 100
  // );
  // const netPrevPercentageNeg = Math.ceil(
  //   ((totalnetprevNeg || "") / totalNeg) * 100
  // );

  // const t1PercentageNeg = Math.ceil(
  //   ((milestonesNeg[0].objectif || "") / totalNeg) * 100
  // );
  // const t2PercentageNeg = Math.ceil(
  //   ((milestonesNeg[1]?.objectif || "") / totalNeg) * 100
  // );
  // const t3PercentageNeg = Math.ceil(
  //   ((milestonesNeg[2]?.objectif || "") / totalNeg) * 100
  // );

  // const isTargetCompletedNeg =
  //   (parseFloat(totalnetprevNeg) || parseFloat("")) >= parseFloat(totalNeg);


  //   console.log(totalNeg)
  //   console.log(totalnetprevNeg)

  // const segmentsNeg = [
  //   {
  //     label: "NetPrev",
  //     percentage: Math.ceil(((totalnetprevNeg || "") / totalNeg) * 100),
  //     color: "green",
  //     //   color: "transparent",
  //     value: formatNumber(totalnetprevNeg || 1),
  //   },
  //   {
  //     label: "encaisseMoinsPourcent",
  //     percentage: Math.ceil(((totalencaisseNeg || "") / totalNeg) * 100),
  //     color: "rgba(255, 216, 108, 0.5)",
  //     //   color: "transparent",
  //     value: formatNumber(totalencaisseNeg || 1),
  //   },
  //   {
  //     label: "t1",
  //     percentage: Math.ceil(
  //       ((milestonesNeg[0].objectif || 0) / totalNeg) * 100
  //     ),
  //     color: "transparent",
  //     value: formatNumber(milestonesNeg[0]?.objectif || 1),
  //   },
  //   {
  //     label: "t2",
  //     percentage: Math.ceil(
  //       ((milestonesNeg[1]?.objectif || 0) / totalNeg) * 100
  //     ),
  //     color: "transparent",
  //     value: formatNumber(milestonesNeg[1]?.objectif || 0),
  //   },
  //   {
  //     label: "t3",
  //     percentage: Math.ceil(
  //       ((milestonesNeg[2]?.objectif || 0) / totalNeg) * 100
  //     ),
  //     color: "transparent",
  //     value: formatNumber(milestonesNeg[2]?.objectif || 0),
  //   },
  //   {
  //       label: "0",
  //       percentage: 0.1,
  //       color: "red",
  //       value: formatNumber(1),
  //     },
  // ];

  // const sortedSegmentsNeg = [...segmentsNeg].sort(
  //   (a, b) => a.percentage - b.percentage
  // );

  // console.log(sortedSegmentsNeg)

  // const ProgressBarNeg = ({ segments }) => (
  //   <div
  //     style={{
  //       height: "24px",
  //       backgroundColor: "#e9ecef",
  //       borderRadius: "12px",
  //       // position:"relative"
  //     }}
  //   >
  //     {sortedSegmentsNeg?.map(
  //       (segment, index) =>
  //         segment.value != 0 && (
  //           <div
  //             key={index}
  //             style={{
  //               width: `${segment.percentage}%`,
  //               backgroundColor: `${segment.color}`,
  //               height: "100%",
  //               textAlign: "end",
  //               position: "absolute",
  //               color: "black",
  //               fontWeight: "bold",
  //               borderRadius: "12px",
  //               // left: "0",
  //               zIndex: index,
  //             }}
  //           >
  //             {/* <div style={{ position: "absolute" }}></div> */}
  //             <div
  //               style={{
  //                 position: "relative",
  //                 bottom: ["t1", "t2", "t3", "0"].includes(segment.label)
  //                   ? "32px"
  //                   : "0px",
  //                 fontSize: ["t1", "t2", "t3", "0"].includes(segment.label)
  //                   ? "12px"
  //                   : "14px",
  //               }}
  //             >
  //               <div
  //                 style={{ padding: "2px 4px 0 0" }}
  //                 className={`${segment.label === "NetPrev" 
  //                   ? "color-green-progress" 
  //                   : segment.label === "encaisseMoinsPourcent" 
  //                   ? "mt-4 color-orange-progress" 
  //                   : ""}`}
  //               >{`${segment.value} $`}</div>
  //               {["t1", "t2", "t3"].includes(segment.label) ? (
  //                 <div className="">|</div>
  //               ) : (
  //                 ""
  //               )}
  //             </div>
  //           </div>
  //         )
  //     )}
  //   </div>
  // );

  //////////////////////////////////////