import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Spinner } from "react-bootstrap";

const GraphLastContractsByDay = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  useEffect(() => {
    // Simuler un délai de chargement pour les données, vous pouvez remplacer ceci par un appel de données réel
    setTimeout(() => {
      setLoading(false); // Une fois que le composant est monté et que les données sont prêtes, mettre loading à false
      updateChart();
    }, 1000); // Vous pouvez ajuster le délai selon vos besoins ou supprimer ce délai si vos données sont prêtes immédiatement
  }, [data]);

  const updateChart = () => {
    // Extraire les valeurs de pseudo et days_since_last_contract
    const categories = data.map(item => item.pseudo);
    const seriesData = data.map(item => ({
      x: item.pseudo,
      y: Number(item.days_since_last_contract),
    }));

    setSeries([
      {
        name: "Days Since Last Contract",
        data: seriesData,
      },
    ]);

    setOptions({
      chart: {
        type: "bar",
        height: 350,
      },
      colors: ["rgba(255, 0, 0, 0.5)"], // Couleur rouge avec opacité
      xaxis: {
        categories: categories,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "14px",
                fontWeight: 600,
                colors: ["#000"],
              },
              formatter: function (val) {
                return `${val} days`;
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#000"], // Couleur noire pour les données sur les barres
        },
        formatter: function (val) {
          return `${val}`;
        },
      },
      legend: {
        position: "right",
        offsetY: 20,
      },
    });
  };

  // Afficher un loader tant que loading est true
  if (loading) {
    return (
      <div className="chargement-graph-agent">
        <Spinner animation="border" role="status">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    ); // Utilisez votre propre composant ou classe CSS pour styliser le loader
  }

  return (
    <div id="chart" className="line-chart-style bar-chart h-100 graph-background">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default GraphLastContractsByDay;