import React from "react";
import ReactApexChart from "react-apexcharts";

class TableauConvertion extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);

    const horizontal = this.props.horizontal;

    // Combiner data et arrCategories
    const combinedData = this.props.data.map((data, index) => ({
      data: parseInt(data, 10), // Convertir les valeurs en nombres
      category: this.props.arrCategories[index],
    }));

    // Trier les données combinées par data du plus grand au plus petit
    combinedData.sort((a, b) => b.data - a.data);

    // Séparer les données triées en data et arrCategories
    const sortedData = combinedData.map(item => item.data);
    const sortedArrCategories = combinedData.map(item => item.category);

    this.state = {
      series: [
        {
          name: "jours",
          data: sortedData,
        },
      ],
      options: {
        chart: {
          type: "bar",
          // height: 230,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: horizontal,
            borderRadius: 3,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["var(--bs-orange)"],
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: "12px",
            colors: ["black"],
          },
        },
        stroke: {
          show: false,
        },
        yaxis: {
          lines: {
            show: false,
          },
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          },
        },
        xaxis: {
          show: false,
          categories: sortedArrCategories,
          labels: {
            style: {
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
        },
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data || prevProps.arrCategories !== this.props.arrCategories) {
      // Combiner data et arrCategories
      const combinedData = this.props.data.map((data, index) => ({
        data: parseInt(data, 10), // Convertir les valeurs en nombres
        category: this.props.arrCategories[index],
      }));

      // Trier les données combinées par data du plus grand au plus petit
      combinedData.sort((a, b) => b.data - a.data);

      // Séparer les données triées en data et arrCategories
      const sortedData = combinedData.map(item => item.data);
      const sortedArrCategories = combinedData.map(item => item.category);

      this.setState({
        series: [
          {
            name: "jours",
            data: sortedData,
          },
        ],
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: sortedArrCategories,
          },
        },
      });
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height='110%'
          // style={{ width: '100%' }} 
        />
      </div>
    );
  }
}

export default TableauConvertion;