import React, { useState, useEffect } from "react";
import { Card, Table, } from "react-bootstrap";
import DropAddContrat from "../Forms/DropAddContrat";
import DropUpdateContrat from "../Forms/DropUpdateContrat";
import axios from "axios";
import config from "../../../config/config";
import { useParams } from "react-router-dom";
// import ReactSelect from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { CircleLoader } from "react-spinners";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import TableauCrmFilters from "./TableauCrmFilters";
import {
  getAllContracts,
  setAllContracts,
  setFilters,
} from "../../Redux/slices/contract.slice";
import DropComments from "../Forms/DropComments";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const TableauCrm = () => {
  const AdminDetails = useSelector(getAdminDetails);
  // console.log(AdminDetails)
  const IdToAdmin = AdminDetails?.idusers;
  const desksToAdmin = AdminDetails?.desk;
  const profilToAdmin = AdminDetails?.profil;
  // const brandToAdmin = AdminDetails?.brand;
  let DesksAdmin = useParams().desk;
  let Desk = (DesksAdmin = DesksAdmin || desksToAdmin);

  // const contractUpdated = useSelector(getContractUpdated);

  // const contractFiltered = useSelector(getContractFiltered);

  // const reinitFilters = useSelector(getReinitFilters);

  // const contractAdded = useSelector(getContractAdded);

  const AllContracts = useSelector(getAllContracts);

  // const filters = useSelector(getFilters);

  const dispatch = useDispatch();

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [isVisibleDropCreate, setIsVisibleDropCreate] = useState(false);
  const [isVisibleComments, setIsVisibleComments] = useState(false);
  // const [limitAllContrats, setLimitAllContrats] = useState("");

  const [LoaderStart, setLoaderStart] = useState(true);
  const [contractSel, setContractSel] = useState([]);

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const GetAllContrats = () => {
    let obj = {
      desk: Desk,
      limit: "",
      seller_id: profilToAdmin === "sell" ? IdToAdmin : "",
      // seller_id: "88ac888a-6378-40a4-a454-b545c0635592",
      agt_supp_id: profilToAdmin === "agt_supp" ? IdToAdmin : "",
      // agt_supp_id: "1937b6ce-bfdc-499c-b691-f7189c4dd62e",
    };
    axios
      .post(`${config.apiAdmin}/sadmin/contracts/read_table_arr`, obj, Header)
      .then((response) => {
        dispatch(setAllContracts(response.data.data.results));
        setLoaderStart(false);
      })
      .catch((error) => {
        setLoaderStart(false);
        console.error("There was an error!", error);
      });
  };
  useEffect(() => {
    GetAllContrats();
    setLoaderStart(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
    dispatch(
      setFilters({
        filters: {
          desk: DesksAdmin || Desk,
        },
        filters_date: {},
        sorts: {
        },
        limit: "",
      })
    );
  };

  const handleRowClick = (row, index) => {
    setSelectedRowIndex((prevState) => (prevState === index ? null : index));
    setShowModalUpdate(!showModalUpdate);
    setContractSel(row);
  };

  const handleLastColumnClick = (event, row, index) => {
    event.stopPropagation();
    setSelectedRowIndex((prevState) => (prevState === index ? null : index));
    setContractSel(row);
    setIsVisibleComments(!isVisibleComments);
  };

  return (
    <div>
      {LoaderStart ? (
        <div className="sweet-loading" style={{ marginTop: "300px" }}>
          <CircleLoader
            color="#36d7b7"
            cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <Card className="col-12">
            <Card.Header>
              <Card.Title className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <div>Tableau contrats</div>
                  <div className="d-flex">
                  <button className="btn btn-primary py-0" onClick={()=>GetAllContrats() }  >refresh</button>

                    <div
                      className="border bg-light rounded-1 mx-1"
                      onClick={() => {
                        handleShowFilters();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="secondary"
                        className="bi bi-funnel pointer"
                        viewBox="0 0 16 16"
                      >
                        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                      </svg>
                    </div>
                    {!(
                      (profilToAdmin === "boss_supp" || profilToAdmin === "admin" || profilToAdmin === "manager") && Array.isArray(DesksAdmin)
                    ) && (
                      <div
                        className="border bg-light rounded-1 mx-1"
                        onClick={() => {
                          // setIsContractToCreate(true);
                          setIsVisibleDropCreate(!showFilters);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="secondary"
                          className="bi bi-plus-lg"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
                {showFilters && <TableauCrmFilters showFilters={showFilters} />}
              </Card.Title>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-scroll">
                <Table bordered>
                  <thead>
                    <tr className="titre-tableau text-center">
                      <th>Date</th>
                      <th>Desk</th>
                      <th>Brand</th>
                      <th>Client</th>
                      <th>Amount</th>
                      <th>Agent</th>
                      <th>Alias</th>
                      <th>
                        Date
                        <br /> ech.
                      </th>
                      <th>Support</th>
                      <th>PSP</th>
                      <th>
                        Plateforme
                      </th>
                      <th className="vertical-text">Arrivée</th>
                      <th>
                        Bank
                        <br /> client
                      </th>
                      <th className="vertical-text">En preuve</th>
                      <th>Date vir.</th>
                      <th className="vertical-text">Encaissée</th>
                      <th>
                        Appel
                        <br /> pro.
                      </th>
                      <th>Heure</th>
                      <th className="vertical-text">Reporter</th>
                      <th className="vertical-text">Annuler</th>
                      <th>Com.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AllContracts?.map((row, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          if (profilToAdmin !== "sell") {
                            handleRowClick(row, index);
                          }
                        }}
                        onMouseOver={() => setHoveredRowIndex(index)}
                        onMouseOut={() => setHoveredRowIndex(null)}
                        style={{
                          backgroundColor:
                            selectedRowIndex === index
                              ? "var(--bs-gray-200)"
                              : hoveredRowIndex === index
                              ? "var(--bs-gray-100)"
                              : "white",
                          cursor: "pointer",
                        }}
                        className="tableau-content tr-tableau text-center"
                      >
                        <td title={row?.created_at}>
                          {row?.created_at?.slice(5, 10)}
                        </td>
                        <td title={row?.desk}>{row?.desk}</td>
                        <td title={row?.brand}>{row?.brand}</td>
                        <td title={row?.cust_fname + " " + row?.cust_lname}>
                        {`${row?.cust_lname} ${row?.cust_fname}`.trim()
                            .length > 15
                            ? `${row?.cust_lname} ${row?.cust_fname}`
                                .trim()
                                .substring(0, 15) + "..."
                            : `${row?.cust_lname} ${row?.cust_fname}`}
                        </td>
                        <td title={row?.amount}>{row?.amount}</td>
                        <td title={row?.pseudo_seller}>{row?.pseudo_seller}</td>
                        <td title={row?.alias_lname + " " + row?.alias_fname}>
                          {`${row?.alias_lname} ${row?.alias_fname}`.trim()
                            .length > 15
                            ? `${row?.alias_lname} ${row?.alias_fname}`
                                .trim()
                                .substring(0, 12) + "..."
                            : `${row?.alias_lname} ${row?.alias_fname}`}
                        </td>
                        <td title={row?.paymentdudate}>
                          {row?.paymentdudate?.slice(5, 10)}
                        </td>
                        <td title={row?.pseudo_agt_supp}>
                          {row?.pseudo_agt_supp}
                        </td>
                        <td title={row?.psp}>{row?.psp}</td>
                        <td title={row?.neobank}>
                          {row?.neobank?.slice(0, 10)}
                        </td>
                        <td
                          title={
                            row?.argent_dispo
                              ? "il es true"
                              : "il é pas encore cauché"
                          }
                          style={{ backgroundColor: "var(--bs-warning)" }}
                          className="text-center"
                        >
                          <input
                            type="checkbox"
                            id={`checkbox2-${index}`}
                            name={`checkbox2-${index}`}
                            checked={row?.argent_dispo ? 1 : 0}
                            readOnly
                          />
                          <label htmlFor={`checkbox2-${index}`}></label>
                        </td>
                        <td>{row?.customerbank?.slice(0, 10)}</td>
                        <td
                          style={{ backgroundColor: "var(--bs-yellow)" }}
                          className="text-center"
                        >
                          <input
                            type="checkbox"
                            id={`checkbox1-${index}`}
                            name={`checkbox1-${index}`}
                            checked={row?.preuve ? 1 : 0}
                            readOnly
                          />
                          <label htmlFor={`checkbox1-${index}`}></label>
                        </td>
                        <td>
                          {row?.date_vir
                            ? new Date(row.date_vir)
                                .toLocaleDateString("fr-FR", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                                ?.slice(0, 5)
                            : ""}
                        </td>
                        <td
                          style={{ backgroundColor: "var(--bs-success)" }}
                          className="text-center"
                        >
                          <input
                            type="checkbox"
                            id={`checkbox2-${index}`}
                            name={`checkbox2-${index}`}
                            checked={row?.encaisse ? 1 : 0}
                            readOnly
                          />
                          <label htmlFor={`checkbox2-${index}`}></label>
                        </td>
                        <td className="">
                          {row?.next_call
                            ? new Date(row.next_call)
                                .toLocaleDateString("fr-FR", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                                ?.slice(0, 5)
                            : ""}
                        </td>
                        <td>{row?.hours}</td>
                        <td
                          style={{ backgroundColor: "var(--bs-purple)" }}
                          className="text-center"
                        >
                          <input
                            type="checkbox"
                            id={`checkbox2-${index}`}
                            name={`checkbox2-${index}`}
                            readOnly
                            checked={row?.is_report ? 1 : 0}
                          />
                          <label htmlFor={`checkbox2-${index}`}></label>
                        </td>
                        <td
                          style={{ backgroundColor: "var(--bs-danger)" }}
                          className="text-center"
                        >
                          <input
                            type="checkbox"
                            id={`checkbox2-${index}`}
                            name={`checkbox2-${index}`}
                            readOnly
                            checked={row?.is_canceled ? 1 : 0}
                          />
                          <label htmlFor={`checkbox2-${index}`}></label>
                        </td>
                        <td
                          onClick={(event) =>
                            handleLastColumnClick(event, row, index)
                          }
                        >
                          <div>{row?.comments}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
          <DropAddContrat
            isVisible={isVisibleDropCreate}
            onHide={() => setIsVisibleDropCreate(false)}
            data={AdminDetails}
          />
          {contractSel && (
            <DropUpdateContrat
              isVisible={showModalUpdate}
              onHide={() => {
                setShowModalUpdate(false);
                setContractSel();
              }}
              data={contractSel}
            />
          )}
          <DropComments
            isVisible={isVisibleComments}
            onHide={() => setIsVisibleComments(false)}
            data={contractSel}
          />
        </>
      )}
    </div>
  );
};

export default TableauCrm;
