import React from "react";
import CardsManager from "./DeskDetail/DeskDetail";
// import CardsManager1 from "./DeskDetail/DeskDetail1";

const Statistiques = () => {
  return (
    <>
      <CardsManager />
    </>
  );
};

export default Statistiques;
