import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { SVGICON } from "../../../jsx/constant/theme";
import ExpensesBarChart from "../Graph/ExpensesBarChart";
import NewExpChart from "../Graph/NewExpChart2";
import ChartRadar from "../Graph/ChartRadar";
import avatar from "../../../images/avataaars.svg";
import avatar1 from "../../../images/avataaars (1).svg";
import avatar2 from "../../../images/avataaars (2).svg";
import FondPodiumOk from "../../../images/photo_2024-04-30_18-39-37.jpg";
import ImpressionBar from "../Graph/ImpressionBar2";
import TableauConvertion from "../Graph/TableauConvertion2";
import GraphRatioByAgent from "../Graph/GraphRatioByAgent2";
import GraphMoyenneParAgent from "../Graph/GraphMoyenneParAgent";
import ProgressBarDesk from "../Graph/ProgressBarDesk";
import { getSockets } from "../../Redux/slices/notifSocket.slice";
import {
  getAdminDesks,
  //  getPseudo
} from "../../Redux/slices/admin.slice";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, Row } from "react-bootstrap";
import GraphDernierContrat from "../Graph/GraphDernierContrat3";
import GraphNombreContrat from "../Graph/GraphNombreContrat4";
import TableauGeneral from "../Graph/TableauGeneral";
import formatNumber from "../../../utils/formatNumber";
import axios from "axios";
import config from "../../../config/config";
import useHeaderRest from "../../../utils/useHeader";
import Table from "react-bootstrap/Table";
import { setAllContracts } from "../../Redux/slices/contract.slice";
import CountUp from "react-countup";
import ProgressBarSeller from "../../Manager/Graph/ProgressBarDesk";
import LineChartEncaisse from "../../Manager/Graph/LineChartEncaisse";
import TableauAgentGlobal from "./TableauAgentGlobal.js";
import GraphLastContractsByDay from "../Graph/GraphLastContractsByDay";
import GraphPesAverageRetap from "../../Manager/Graph/GraphPesAverageRetap";
import GraphAverageRetap from "../../Manager/Graph/GraphAverageRetap";

const DeskDetail = () => {
  const useHeader = useHeaderRest();
  const [refreshKey, setRefreshKey] = React.useState(0);

  const { desk } = useParams();
  const deskIdAsNumber = parseInt(desk, 10);
  const [flash, setFlash] = useState(false);
  const GET_SOCKETS = useSelector(getSockets);
  const GET_ADMIN_DESKS = useSelector(getAdminDesks);
  // const GET_PSEUDO = useSelector(getPseudo);

  const [dataSignedEncashed, setDataSignedEncashed] = useState();
  const [dataConversion, setDataConversion] = useState();
  const [dataRatioAgent, setDataRatioAgent] = useState();
  const [dataDailyContracts, setDataDailyContracts] = useState();
  const [dataMonthlyContracts, setDataMonthlyContracts] = useState();
  const [dataWeeklyTable, setDataWeeklyTable] = useState();
  const [dataTargetByDesk, setDataTargetByDesk] = useState();
  const [dataBrutNetPrev, setDataBrutNetPrev] = useState();
  const [dataPrevVsCurrentMonthByAgent, setDataPrevVsCurrentMonthByAgent] =
    useState();
  const [
    dataPrevVsCurrentMonthByAgentTotal,
    setDataPrevVsCurrentMonthByAgentTotal,
  ] = useState();
  const [dataRetraitByBrandDesk, setDataRetraitByBrandDesk] = useState();
  const [dataRetraitSheet, setDataRetraitSheet] = useState();
  const [dataGraphEncashed, setDataGraphEncashed] = useState();

  const [averageByAgent, setAverageByAgent] = useState();
  const [lastContractsByDay, setLastContractsByDay] = useState();

  const [goodDesk, setGoodDesk] = useState();
  // const [goodDesk, setGoodDesk] = useState(deskIdAsNumber ? [deskIdAsNumber] : GET_ADMIN_DESKS);

  const [previousValue, setPreviousValue] = useState(0); // Valeur de départ
  const [currentValue, setCurrentValue] = useState(0); // Valeur finale
  const [isLoaded, setIsLoaded] = useState(false);

  const [previousValueEncaisse, setPreviousValueEncaisse] = useState(0); // Valeur de départ
  const [currentValueEncaisse, setCurrentValueEncaisse] = useState(0); // Valeur finale
  const [isLoadedEncaisse, setIsLoadedEncaisse] = useState(false);

  const [previousValuePreuve, setPreviousValuePreuve] = useState(0); // Valeur de départ
  const [currentValuePreuve, setCurrentValuePreuve] = useState(0); // Valeur finale
  const [isLoadedPreuve, setIsLoadedPreuve] = useState(false);

  const [previousValueNetBar, setPreviousValueNetBar] = useState(0); // Valeur de départ
  const [currentValueNetBar, setCurrentValueNetBar] = useState(0); // Valeur finale
  const [isLoadedNetBar, setIsLoadedNetBar] = useState(false);

  const [previousValueNetPrevBar, setPreviousValueNetPrevBar] = useState(0); // Valeur de départ
  const [currentValueNetPrevBar, setCurrentValueNetPrevBar] = useState(0); // Valeur finale
  const [isLoadedNetPrevBar, setIsLoadedNetPrevBar] = useState(false);

  const [averageRetap, setAverageRetap] = useState();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const DataSignedEncashedByDesk = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/card_signed_encashed_by_desk`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        setDataSignedEncashed(response?.data);
        // console.log(response.data);
        const newValueEncaisse = response.data.signed?.sum_encashed;
        const newValuePreuve = response.data.sum_proof;
        // const newValueNetBar =
        // const newValueNetPrevBar =

        setPreviousValueEncaisse(currentValueEncaisse);
        setCurrentValueEncaisse(newValueEncaisse);
        setIsLoadedEncaisse(true);

        setPreviousValuePreuve(currentValuePreuve);
        setCurrentValuePreuve(newValuePreuve);
        setIsLoadedPreuve(true);

        // setPreviousValueNetPrevBar(currentValueNetPrevBar);
        // setCurrentValueNetPrevBar(newValueNetPrevBar);
        // setIsLoadedNetPrevBar(true);

        // setPreviousValueNetBar(currentValueNetBar);
        // setCurrentValueNetBar(newValueNetBar);
        // setIsLoadedNetBar(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DataConversion = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/card_conversion_per_desk`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        setDataConversion(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DataRatioAgent = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/card_ration_agent`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        // console.log(response?.data?.data.length);
        // if (response?.data?.data.length < 70) {
        setDataRatioAgent(response?.data?.data);
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DataDailyContracts = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/card_daily_contracts`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        setDataDailyContracts(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DataMonthlyContracts = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/card_monthly_contracts`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        setDataMonthlyContracts(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dataWeeklyTableByDesk = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/card_weekly_table_by_desk`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        setDataWeeklyTable(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const targetByDesk = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/target_desk_by_desk`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        console.log(response?.data?.data);
        setDataTargetByDesk(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const brutnetPrev = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/brut_net_prev`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        setDataBrutNetPrev(response?.data?.data);
        const newValue = response.data.data.brutNetPrev.brut_previsionnel;
        setPreviousValue(currentValue);
        setCurrentValue(newValue);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const prevVsCurrentMonthByAgent = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/prev_vs_current_month_agent`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        console.log("response?.data?.data.data", response?.data);
        // console.log("response?.data?.data.length", response?.data.data);
        setDataPrevVsCurrentMonthByAgent(response?.data?.data);
        setDataPrevVsCurrentMonthByAgentTotal(response?.data?.totalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const retraitByBrandDesk = () => {
  //   console.log("goodDesk", goodDesk);
  //   axios
  //     .post(
  //       `${config.apiAdmin}/statsNode/manager/retrait_by_brand_and_desk`,
  //       { desk: goodDesk },
  //       useHeader
  //     )
  //     .then((response) => {
  //       // console.log("response?.data?.data.data", response?.data);
  //       // console.log("response?.data.data retraitByBrandDesk",response?.data.data.retraitByBrandDesk);
  //       setDataRetraitByBrandDesk(response?.data.data.retraitByBrandDesk);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setDataRetraitByBrandDesk();
  //     });
  // };

  const retraitSheet = () => {
    console.log("goodDesk", goodDesk);
    axios
      .post(
        `${config.apiAdmin}/sadmin/whsheet/sum_retrait`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        console.log("response?.data =====>", response?.data);
        setDataRetraitSheet(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GraphEncashedMonthlyByDesk = () => {
    console.log("goodDesk", goodDesk);
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/graph_encashed_monthly_by_desk`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        // console.log(
        //   "GraphEncashedMonthlyByDesk response?.data =====>",
        //   response?.data
        // );
        setDataGraphEncashed(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GraphMoyenneAgent = () => {
    // console.log("goodDesk", goodDesk);
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/graph_average_by_agent`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        // console.log(
        //   // "graph_average_by_agent response?.data =====>",
        //   response?.data.data
        // );
        setAverageByAgent(response?.data.data);
        // setDataGraphEncashed(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GraphLastcontratByday = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/graph_last_contrat_by_day`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        // console.log(
        //   // "graph_average_by_agent response?.data =====>",
        //   response?.data.data
        // );
        setLastContractsByDay(response?.data.data);
        // setDataGraphEncashed(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const AverageRetap = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/graph_average_retap`,
        { desk: [goodDesk] },
        useHeader
      )
      .then((response) => {
        // console.log(
        //   // "graph_average_by_agent response?.data =====>",
        //   response?.data.data
        // );
        setAverageRetap(response?.data.data);
        // setDataGraphEncashed(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setRefreshKey((prevKey) => prevKey + 1);
    setGoodDesk(deskIdAsNumber ? [deskIdAsNumber] : GET_ADMIN_DESKS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desk]);

  useEffect(() => {
    if (goodDesk) {
      setFlash(true);
      setTimeout(() => setFlash(false), 100);
      DataSignedEncashedByDesk();
      DataConversion();
      DataRatioAgent();
      DataDailyContracts();
      DataMonthlyContracts();
      dataWeeklyTableByDesk();
      targetByDesk();
      brutnetPrev();
      prevVsCurrentMonthByAgent();
      // retraitByBrandDesk();
      retraitSheet();
      GraphEncashedMonthlyByDesk();
      GraphMoyenneAgent();
      GraphLastcontratByday();
      AverageRetap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GET_SOCKETS, goodDesk]);

  const calculateTotal = (data) => {
    const total_esperance = parseInt(data.total_esperance, 10) || 0;
    const total = 0 + total_esperance;
    return total.toLocaleString("fr-FR");
  };

  function uuidToColor(uuid) {
    if (uuid === null) {
      let color = "#55434f";
      return color;
    }
    // On prend les 6 premiers caractères de l'UUID pour créer une couleur hexadécimale
    let color = "#" + uuid.slice(0, 6);
    return color;
  }

  const handleChangePage = (mode) => {
    console.log(goodDesk);
    const filters = {
      desk: goodDesk,
      encaisse: 0,
      is_report: 0,
      is_canceled: 0,
    };

    if (mode === "preuve") {
      filters.preuve = 1;
    } else if (mode === "encaisse") {
      filters.encaisse = 1;
    }
    // Pour 'reste', aucun changement supplémentaire n'est nécessaire

    const obj = {
      filters: filters,
      filters_date: {},
      sorts: {
        amount: "DESC",
      },
      limit: "",
    };

    const deskToPath = goodDesk[0];

    // Décommentez et utilisez l'appel axios une fois que obj est correctement configuré
    axios
      .post(
        `${config.apiAdmin}/sadmin/contracts/search_and_sort`,
        obj,
        useHeader
      )
      .then((response) => {
        dispatchData(response.data.data.results);
        navigate(`/m_tab${deskToPath ? `/${deskToPath}` : ""}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dispatchData = (data) => dispatch(setAllContracts(data));

  const handleChangePageGraph = (mode, user) => {
    const filters = {
      desk: goodDesk,
      encaisse: 0,
      is_report: 0,
      is_canceled: 0,
      seller_id: user,
    };

    if (mode === "Report") {
      filters.is_report = 1;
    } else if (mode === "Preuve") {
      filters.preuve = 1;
    } else if (mode === "Encaisse") {
      filters.encaisse = 1;
    }
    // Pour 'reste', aucun changement supplémentaire n'est nécessaire

    const obj = {
      filters: filters,
      filters_date: {},
      sorts: {
        amount: "DESC",
      },
      limit: "",
    };

    const deskToPath = goodDesk[0];

    // Décommentez et utilisez l'appel axios une fois que obj est correctement configuré
    axios
      .post(
        `${config.apiAdmin}/sadmin/contracts/search_and_sort`,
        obj,
        useHeader
      )
      .then((response) => {
        dispatchData(response.data.data.results);
        navigate(`/m_tab${deskToPath ? `/${deskToPath}` : ""}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="row p-0">
        {dataTargetByDesk &&
          dataSignedEncashed &&
          dataRetraitSheet &&
          GET_ADMIN_DESKS.length === 1 &&
          dataBrutNetPrev && (
            <ProgressBarDesk
              data={dataTargetByDesk}
              totalencaisse={
                Number(dataBrutNetPrev?.brutNetPrev.brut_previsionnel || 0) +
                  Number(dataRetraitSheet[0].total_amount || 0) || 0
              }
              totalnetprev={
                Number(dataBrutNetPrev?.brutNetPrev?.net_previsionnel || 0) +
                  Number(dataRetraitSheet[0].total_amount || 0) || 0
              }
            />
          )}

        {dataTargetByDesk &&
          dataSignedEncashed &&
          desk &&
          dataBrutNetPrev &&
          dataRetraitSheet && (
            <ProgressBarDesk
              data={dataTargetByDesk}
              totalencaisse={
                Number(dataBrutNetPrev?.brutNetPrev.brut_previsionnel || 0) +
                  (Number(dataRetraitSheet[0].total_amount) || 0) || 0
              }
              totalnetprev={
                Number(dataBrutNetPrev?.brutNetPrev?.net_previsionnel || 0) +
                  Number(dataRetraitSheet[0].total_amount) || 0
              }
            />
          )}

        <div className="col-5">
          <div className="row">
            <div className="col-12 p-0">
              {dataWeeklyTable && (
                <TableauGeneral dataWeeklyData={dataWeeklyTable} />
              )}
            </div>
          </div>
        </div>

        <div className="col-7">
          <div className="row">
            <div className="col-4">
              <div className="card crm-cart">
                <div className="card-body h-50 p-1 py-4">
                  <div className="crm-cart-data d-flex">
                    <div className="col">
                      <div>
                        <h5 className="d-block mb-1 text-black">Brut Prev</h5>
                        {isLoaded && (
                          <span
                            style={{
                              backgroundColor: "transparent",
                              fontSize: "1.3rem",
                              color: "black",
                              fontWeight: "700",
                            }}
                          >
                            <CountUp
                              start={previousValue} // Utiliser l'ancienne valeur
                              end={currentValue} // Utiliser la nouvelle valeur
                              duration={3.5} // Ajuster la durée si nécessaire
                              separator=" " // Nombre de décimales si nécessaire
                            />
                            $
                          </span>
                        )}
                      </div>
                      <div title="update du net Prev , il ne s'agit plus que des contrats encaisses, moins les retraits effectues">
                        <h5 className="d-block mb-1 text-black">Net Prev</h5>
                        <span
                          style={{
                            backgroundColor: "transparent",
                            fontSize: "1.3rem",
                            color: "black",
                            fontWeight: "700",
                          }}
                        >
                          {dataBrutNetPrev &&
                            dataRetraitSheet &&
                            formatNumber(
                              Number(
                                dataBrutNetPrev?.brutNetPrev.brut_previsionnel
                              ) +
                              Number(dataRetraitSheet[0].total_amount || 0) || 0
                            )}
                          $
                        </span>
                      </div>
                    </div>
                    <div className="col">
                      <h5 className="d-block mb-1 text-black">Net</h5>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          fontSize: "1.3rem",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        {dataBrutNetPrev &&
                          dataRetraitSheet &&
                          formatNumber(
                            Number(
                              dataBrutNetPrev?.brutNetPrev?.net_previsionnel ||
                                0
                            ) +
                            Number(dataRetraitSheet[0].total_amount || 0) || 0
                          )}
                        $
                        <Badge bg="warning">
                          {Math.round(
                            ((Number(
                              dataBrutNetPrev?.brutNetPrev?.net_previsionnel ||
                                0
                            ) +
                              Number(
                                dataRetraitSheet?.[0]?.total_amount || 0
                              )) *
                              100) /
                              (dataBrutNetPrev?.brutNetPrev
                                ?.brut_previsionnel || 0) || 0
                          )}
                          %
                        </Badge>
                      </span>
                    </div>
                  </div>
                </div>
                {/* <NewExpChart color={"green"} /> */}
              </div>

              <div
                onClick={() => handleChangePage("reste")}
                className="card crm-cart pointer hover-effect"
                title="tous les contrats signes , sauf ceux qui sont plus petit que 2euros, et les annulés ( nous gardons donc les reportés ) "
              >
                <div className="card-header border-0 pb-0 diposit-bg">
                  <h5 className="d-block text-black">Reste a encaissé</h5>
                </div>

                <div className="card-body py-0">
                  <div className="crm-cart-data d-flex align-items-center">
                    <p>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          fontSize: "1.3rem",
                        }}
                      >
                        {dataSignedEncashed &&
                          formatNumber(
                            dataSignedEncashed?.signed.sum_signed || 0
                          )}
                        €
                      </span>
                    </p>
                  </div>
                  <ImpressionBar />
                </div>

                <div className="row justify-content-between mt-3 mx-3">
                  <div
                    className="col-2 d-none"
                    title={` 
                    IL S'AGIT DE SUM SIGNED QUI EST PRISE ICI
                    SELECT 
                      COUNT(DISTINCT contracts.seller_id) AS distinct_users,
                      
                      COUNT(amount) as count_legit_contract,

                      COALESCE( SUM(
                        CASE 
                          WHEN encaisse != 1
                          AND is_report !=1
                          
                      THEN amount
                      ELSE 0 END), 0) as sum_signed,


                      SUM(encash_usd) as sum_encashed,
                      COALESCE(SUM(CASE
                            WHEN preuve = 1   
                            AND (encaisse IS NULL OR encaisse = 0)
                            AND (is_report IS NULL OR is_report = 0)          
                            THEN amount 
                            ELSE 0 END), 0) AS proof
                    FROM contracts
                    INNER JOIN users ON contracts.seller_id = users.idusers  AND users.is_active = 1  AND (users.profil = 'sell' OR users.profil = 'tlead')
                    WHERE desk IN (${desk})
                      AND contracts.is_active = 1
                      AND amount > 1
                      AND is_canceled !=1  `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-code-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                      <path d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0m2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0" />
                    </svg>
                  </div>

                  <div
                    className="col-2 "
                    title={`En excluant ce qui est deja encaissé, et ce qui est annulé, vous trouverez ici tous les contrats  `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4">
              <div
                onClick={() => handleChangePage("preuve")}
                className="card crm-cart pointer hover-effect"
                title=" ici nous prenons toutes les commandes ou la case 'preuve' a ete coché, il faut que la case encaissé soit vide, que la commande n'est pas ete annulé ni reporté, le contrats doit etre superieur a 1euros, et ne doit pas avoir été supprimé  "
              >
                <div className="card-body">
                  <div className="crm-cart-data">
                    <h5 className="d-block mb-1 text-black">
                      Total (en preuve)
                    </h5>
                    {isLoadedPreuve && (
                      <span
                        style={{
                          backgroundColor: "transparent",
                          fontSize: "1.3rem",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        <CountUp
                          start={previousValuePreuve} // Utiliser l'ancienne valeur
                          end={currentValuePreuve} // Utiliser la nouvelle valeur
                          duration={3.5} // Ajuster la durée si nécessaire
                          separator=" " // Nombre de décimales si nécessaire
                        />
                        $
                      </span>
                    )}
                  </div>
                </div>
                <NewExpChart color={"blue"} />
              </div>

              <div
                onClick={() => handleChangePage("encaisse")}
                className="card crm-cart pointer hover-effect"
                title="total encaissé , nous enlevons uniquement toutes les commandes plus petite que 2euros, et les contracts annulé"
              >
                <div className="card-header border-0 pb-0 diposit-bg">
                  <h5 className="d-block text-black">Total (encaissé)</h5>
                  <div className="icon-box">{SVGICON.DollerBlack}</div>
                </div>
                <div className="card-body py-0">
                  <div className="crm-cart-data d-flex align-items-center">
                    {isLoadedEncaisse && (
                      <span
                        style={{
                          backgroundColor: "transparent",
                          fontSize: "1.3rem",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        <CountUp
                          start={previousValueEncaisse} // Utiliser l'ancienne valeur
                          end={currentValueEncaisse} // Utiliser la nouvelle valeur
                          duration={3.5} // Ajuster la durée si nécessaire
                          separator=" " // Nombre de décimales si nécessaire
                        />
                        $
                      </span>
                    )}
                  </div>
                  <ExpensesBarChart />
                </div>
              </div>
            </div>
            <div className="col-4">
              {dataRatioAgent && (
                <div
                  className="card crm-cart"
                  style={{
                    backgroundImage: `url(${FondPodiumOk})`,
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    className="d-flex text-white justify-content-around align-items-end mx-4 px-2 col-10"
                    style={{ height: "65%" }}
                  >
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={dataRatioAgent[1]?.avatar || avatar1}
                        title={
                          dataRatioAgent &&
                          dataRatioAgent[1]?.pseudo +
                            "\n " +
                            formatNumber(
                              dataRatioAgent[1]?.total_esperance || 0
                            ) +
                            " €"
                        }
                        alt=""
                        style={{
                          height: "70px",
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                          borderRadius: "50%",
                          marginBottom: "10px",
                        }}
                      />
                      <span
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "black", // Couleur du texte
                          fontSize: "14px", // Taille du texte
                          fontWeight: "bold",
                          textAlign: "center",
                          width: "100%", // Pour s'assurer que le texte est centré
                          fontFamily: "Bangers, cursive",
                          letterSpacing: "1.5px",
                          backgroundColor: "white",
                          borderRadius: "50px",
                          border: "1px solid grey",
                        }}
                      >
                        {dataRatioAgent && dataRatioAgent[1]?.pseudo}
                      </span>
                    </div>

                    <div className="mb-3">
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img
                          src={dataRatioAgent[0]?.avatar || avatar}
                          alt=""
                          style={{
                            height: "70px",
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                            borderRadius: "50%",
                            marginBottom: "10px",
                          }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "45%",
                            transform: "translate(-50%, -50%)",
                            color: "black", // Couleur du texte
                            fontSize: "12px", // Taille du texte
                            fontWeight: "bold",
                            textAlign: "center",
                            padding: "0px 5px 0px 5px",
                            fontFamily: "Bangers, cursive",
                            letterSpacing: "1.5px",
                            backgroundColor: "white",
                            borderRadius: "50px",
                            border: "1px solid grey",
                          }}
                        >
                          {dataRatioAgent && dataRatioAgent[0]?.pseudo}
                        </span>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img
                          src={dataRatioAgent[2]?.avatar || avatar}
                          alt=""
                          style={{
                            height: "70px",
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                            borderRadius: "50%",
                            marginBottom: "10px",
                          }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "black",
                            fontSize: "12px",
                            textAlign: "center",
                            fontWeight: "bold",
                            width: "100%",
                            fontFamily: "Bangers, cursive",
                            letterSpacing: "1.5px",
                            backgroundColor: "white",
                            borderRadius: "50px",
                            border: "1px solid grey",
                          }}
                        >
                          {dataRatioAgent && dataRatioAgent[2]?.pseudo}
                        </span>
                      </div>
                    </div>

                    {/* <div className="mb-1">
                      <div
                        style={{ position: "relative", display: "inline-block" }}
                      >
                        <img
                          src={dataRatioAgent[2]?.avatar || avatar2}
                          title={
                            dataRatioAgent &&
                            dataRatioAgent[2]?.pseudo +
                            "\n " +
                            formatNumber(
                              dataRatioAgent[2]?.total_esperance || 0
                            ) +
                            " €"
                          }
                          alt=""
                          style={{
                            height: "60px",
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                            borderRadius: "50%",
                            marginBottom: "10px",

                          }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "black",
                            fontSize: "12px",
                            textAlign: "center",
                            fontWeight: "bold",
                            padding: "0px 5px 0px 5px",
                            fontFamily: "Bangers, cursive",
                            letterSpacing: "1.5px",
                            backgroundColor: "white",
                            borderRadius: "50px",
                            border: "1px solid grey",
                          }}
                        >
                          {dataRatioAgent && dataRatioAgent[2]?.pseudo}
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}

              <div>
                <Card className="crm-cart">
                  <div className="card-header border-0 pb-0 diposit-bg">
                    <h5 className="d-block text-black">Retraits en cours</h5>
                  </div>

                  <div className="card-body py-0">
                    <div className="crm-cart-data d-flex align-items-center">
                      <p>
                        <span
                          style={{
                            backgroundColor: "transparent",
                            fontSize: "1.3rem",
                          }}
                        >
                          {dataRetraitSheet &&
                            formatNumber(dataRetraitSheet[0].total_amount)}
                          $
                        </span>
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      {dataRatioAgent && (
        <div className="d-flex">
          <Card className="col-12 p-0">
            <Card.Header>
              <div className="card-title col-12 ">
                <div className="d-flex align-iterms-center">
                  <div className="text-start col-6">
                    Ratio chiffre total par agent
                  </div>
                  <div className="col-5 d-flex justify-content-around align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Preuve me-1"></div>
                      <div>Preuve</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Signé me-1"></div>
                      <div>Signé</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Encaissé me-1"></div>
                      <div>Encaissé</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Report me-1"></div>
                      <div>Report</div>
                    </div>
                    {/* <div className="d-flex align-items-center">
                      <div className="p-2 border rounded-1 h-50 bg-Annulé me-1"></div>
                      <div>Annulé</div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Card.Header>

            {dataRatioAgent && (
              <>
                <GraphRatioByAgent
                  height={"400px"}
                  arrEncaisse={dataRatioAgent?.map(
                    (agent) => agent?.sum_encashed
                  )}
                  arrSigned={dataRatioAgent?.map((agent) => agent?.totalSigned)}
                  arrProof={dataRatioAgent?.map((agent) => agent?.sum_proof)}
                  arrReported={dataRatioAgent?.map((agent) => agent?.reported)}
                  arrCanceled={dataRatioAgent?.map((agent) => agent?.canceled)}
                  // arrCategories={dataRatioAgent?.map((agent) => agent?.pseudo + " " + agent?.total_esperance)}
                  arrCategories={dataRatioAgent?.map((agent) => {
                    const total = calculateTotal(agent);
                    return { agent, total };
                  })}
                  goodDesk={goodDesk}
                  handlePageGraph={handleChangePageGraph}
                />
              </>
            )}
          </Card>
        </div>
      )}
      {averageByAgent && (
        <div className="d-flex">
          <Card className="col-12 p-0">
            <Card.Header>
              <div className="card-title col-12 ">
                <div className="d-flex align-iterms-center">
                  <div className="text-start col-6">
                    Moyenne contrat par agent (uniquement les agents ayant faits
                    des contrats)
                  </div>
                </div>
              </div>
            </Card.Header>
            <GraphMoyenneParAgent height={"400px"} data={averageByAgent} />
          </Card>
        </div>
      )}
      {lastContractsByDay && (
        <div className="d-flex">
          <Card className="col-12 p-0">
            <Card.Header>
              <div className="card-title col-12 ">
                <div className="d-flex align-iterms-center">
                  <div className="text-start col-6">
                    Jours écoulés depuis le dernier contrat
                  </div>
                </div>
              </div>
            </Card.Header>
            <GraphLastContractsByDay
              height={"400px"}
              data={lastContractsByDay}
            />
          </Card>
        </div>
      )}

      {/* const data = 
    [ { pseudo: 'herved2', totalSum: '45000', NumContract: 3, averageByAgent: '15000' },{ pseudo: 'herved2', totalSum: '99000', NumContract: 9, averageByAgent: '11000' },{ pseudo: 'herved2', totalSum: '63000', NumContract: 7, averageByAgent: '9000' } ] */}

      <Row className=" ">
        {dataPrevVsCurrentMonthByAgentTotal && (
          <>
            <Card className="col h-100 mx-2 p-0">
              <Card.Header>
                <h5 className="fw-bold">Mois en cours</h5>
              </Card.Header>
              <div className="d-flex">
                <div className="col-6 ">
                  <div className="d-flex align-items-center p-2 ">
                    <div className="fs-14 fw-bold text-black">
                      Total signe :
                    </div>
                    <div className="d-flex align-items-center mx-2">
                      {formatNumber(
                        dataPrevVsCurrentMonthByAgentTotal?.month_signed
                      )}
                      €{" "}
                      <Badge bg="warning" className="ms-2">
                        {dataPrevVsCurrentMonthByAgentTotal?.pourcent_signed}%
                      </Badge>
                    </div>
                  </div>
                  <div className="d-flex align-items-center p-2">
                    <div className=" fw-bold text-black">Total en preuve :</div>
                    <div className="d-flex align-items-center mx-2">
                      {formatNumber(
                        dataPrevVsCurrentMonthByAgentTotal?.month_proof
                      )}
                      €{" "}
                      <Badge bg="warning" className="ms-2">
                        {dataPrevVsCurrentMonthByAgentTotal?.pourcent_proof}%
                      </Badge>
                    </div>
                  </div>
                  <div className="d-flex align-items-center p-2">
                    <div className=" fw-bold text-black">Total encaisse :</div>
                    <div className="d-flex align-items-center mx-2">
                      {formatNumber(
                        dataPrevVsCurrentMonthByAgentTotal?.month_encashed
                      )}
                      €{" "}
                      <Badge bg="warning" className="ms-2">
                        {dataPrevVsCurrentMonthByAgentTotal?.pourcent_encashed}%
                      </Badge>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-3">
                  <div className="d-flex align-items-center py-3">
                    <div className=" fw-bold text-black">TOTAL eur :</div>
                    <div className="d-flex align-items-center mx-2">
                      {formatNumber(
                        dataPrevVsCurrentMonthByAgentTotal?.month_total_curr
                      )}
                      €
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-3">
                    <div className=" fw-bold text-black">TOTAL usd :</div>
                    <div className="d-flex align-items-center mx-2">
                      {formatNumber(
                        dataPrevVsCurrentMonthByAgentTotal?.month_total_usd_curr
                      )}
                      $
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            <Card className="col h-100 mx-2 p-0">
              <Card.Header>
                <h5 className="fw-bold">Mois précédent</h5>
              </Card.Header>

              <div className="d-flex">
                <div className="col-6 ">
                  <div className="d-flex align-items-center p-2">
                    <div className="fs-14 fw-bold text-black">
                      Total signé :
                    </div>
                    <div className="d-flex align-items-center mx-2">
                      {formatNumber(
                        dataPrevVsCurrentMonthByAgentTotal?.prev_month_signed
                      )}
                      €{" "}
                      <Badge bg="warning" className="ms-2">
                        {
                          dataPrevVsCurrentMonthByAgentTotal?.pourcent_prev_signed
                        }
                        %
                      </Badge>
                    </div>
                  </div>
                  <div className="d-flex align-items-center p-2">
                    <div className=" fw-bold text-black">Total en preuve :</div>
                    <div className="d-flex align-items-center mx-2">
                      {formatNumber(
                        dataPrevVsCurrentMonthByAgentTotal?.prev_month_proof
                      )}
                      €{" "}
                      <Badge bg="warning" className="ms-2">
                        {
                          dataPrevVsCurrentMonthByAgentTotal?.pourcent_prev_proof
                        }
                        %
                      </Badge>
                    </div>
                  </div>
                  <div className="d-flex align-items-center p-2">
                    <div className=" fw-bold text-black">Total encaisse :</div>
                    <div className="d-flex align-items-center mx-2">
                      {formatNumber(
                        dataPrevVsCurrentMonthByAgentTotal?.prev_month_encashed
                      )}
                      €{" "}
                      <Badge bg="warning" className="ms-2">
                        {
                          dataPrevVsCurrentMonthByAgentTotal?.pourcent_prev_encashed
                        }
                        %
                      </Badge>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-3">
                  <div className="d-flex align-items-center py-3">
                    <div className=" fw-bold text-black">TOTAL eur :</div>
                    <div className="d-flex align-items-center mx-2">
                      {formatNumber(
                        dataPrevVsCurrentMonthByAgentTotal?.prev_month_total
                      )}
                      €
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-3">
                    <div className=" fw-bold text-black">TOTAL usd :</div>
                    <div className="d-flex align-items-center mx-2">
                      {formatNumber(
                        dataPrevVsCurrentMonthByAgentTotal?.prev_month_total_usd
                      )}
                      $
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </>
        )}
      </Row>

      <TableauAgentGlobal
        dataPrevVsCurrentMonthByAgent={dataPrevVsCurrentMonthByAgent}
      />

      <Card className="col-12 p-0">
        <Card.Header>
          <div className="card-title col-12 p-0">
            <div className="d-flex align-iterms-center">
              <div className="text-start">Conversion par agent</div>
            </div>
          </div>
        </Card.Header>
        {dataConversion && (
          <TableauConvertion
            horizontal={false}
            data={dataConversion?.map(
              (conversion) => conversion?.sum_conversion
            )}
            arrCategories={dataConversion?.map(
              (conversion) => conversion?.pseudo
            )}
          />
        )}
      </Card>

      <div className="d-flex">
        <Col sm={6} className="p-0 pe-2">
          <Card>
            <Card.Header>
              <h4 className="card-title">Nombre Contrats(jour)</h4>
            </Card.Header>
            <Card.Body className="p-2 d-flex align-items-end">
              {dataDailyContracts && (
                <GraphDernierContrat
                  arrCategorie={dataDailyContracts?.map(
                    (agent) => agent?.pseudo
                  )}
                  arrData={dataDailyContracts?.map(
                    (agent) => agent?.total_contracts
                  )}
                />
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col sm={6} className="ps-2 p-0">
          <Card>
            <Card.Header>
              <h4 className="card-title">Nombre contrats (mois)</h4>
            </Card.Header>

            <Card.Body className="p-2">
              {dataMonthlyContracts && (
                <GraphNombreContrat
                  arrData={dataMonthlyContracts?.map(
                    (agent) => agent?.total_contracts
                  )}
                  arrCategorie={dataMonthlyContracts?.map(
                    (agent) => agent?.pseudo
                  )}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </div>

      {dataRetraitSheet && goodDesk.length === 1 && (
        <Card className="col-12">
          <Card.Header>
            <div className="card-title col-12">
              <div className="d-flex align-iterms-center">
                <div className="text-start col-6">Retrait par brand</div>
              </div>
            </div>
          </Card.Header>
          <ChartRadar
            // data={[
            //     { name: "liv", data: [55555] },
            //     { name: "wio", data: [22313] }
            //   ]}
            desk={["Desk 7"]}
            dataPur={dataRetraitSheet}
          />
          {/* <ChartRadar
           desk={[deskIdAsNumber]} 
           data={dataRetraitSheet} 
           dataBrand={dataRetraitSheet?.map((brand) => brand?.brand)}
           dataAmount={dataRetraitSheet?.map((amount) => Math.abs(Number(amount?.total_amount)))}
           dataDesk={dataRetraitSheet?.map((desk) => desk?.desk)}
           /> */}
        </Card>
      )}
      {dataGraphEncashed && (
        <div className="d-flex">
          <Col sm={12} className="p-0">
            <Card>
              <Card.Header>
                <h4 className="card-title">
                  Encaissements par jour du/des Desk(s)
                </h4>
              </Card.Header>
              <Card.Body className="p-2 d-flex align-items-end">
                <LineChartEncaisse data={dataGraphEncashed} />
              </Card.Body>
            </Card>
          </Col>
        </div>
      )}

      <div className="">
        {averageRetap && (
          <div className="d-flex col p-0">
            <Card className="col-12 p-0">
              <Card.Header>
                <div className="card-title col-12">
                  <div className="d-flex align-items-center">
                    <div className="text-start col-6">
                      Moyenne en jour de retap par agent
                      <span
                        title="La requête calcule la moyenne de nombre de jours entre chaque encaissement pour ses clients. Si 0, les encaissements se sont faits le même jour."
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-info-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </Card.Header>
              {/* {goodDesk.length === 1 ? (
                <GraphPesAverageRetap height={"400px"} data={averageRetap} />
              ) : ( */}
                <GraphAverageRetap height={"400px"} data={averageRetap} />
              {/* )} */}
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

export default DeskDetail;
