import React, { useEffect, useState } from "react";
import { Card, Form, Table } from "react-bootstrap";
import axios from "axios";
import config from "../../../config/config";
import { useDispatch } from "react-redux";
import { setPopup } from "../../Redux/slices/actions.slice";

const Psp = () => {
  const [allPsp, setAllPsp] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [updateIndex, setUpdateIndex] = useState(null);
  const dispatch = useDispatch();


  const handleClick = () => {
    setAllPsp((prevPsp) => {
      const newPsp = [
        ...prevPsp,
        {
          idpsp: "",
          name: "",
          is_active: "",
          isEditing: true,
          created_at: "", // Ajoutez cette ligne
        },
      ];

      return newPsp;
    });
    window.location.href = "#lastElement";
  };

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
 }

  const fetchPsp = () => {
    axios
      .get(`${config.apiAdmin}/sadmin/psp/read_all_admin`,Header)
      .then((response) => {
        console.log(response.data)
        setAllPsp(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const CreatePsp = (inputValue) => {
    const obj = {
      name: inputValue,
      is_active: "1",
    };
    axios
      .put(`${config.apiAdmin}/sadmin/psp/create`, obj, Header) // Remplacez ceci par votre URL et vos paramètres
      .then((response) => {
        SendPopup({ message: 'confirm', info: 'create.', isActive: true })

        fetchPsp();
      })
      .catch((error) => {
        SendPopup({ message: 'error', info: 'create.', isActive: true })

        console.error("There was an error! create Brand", error);
      });
  };
  useEffect(() => {
    fetchPsp(); // Appeler la fonction lors du montage du composant
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UpadtePsp = (Psp, isActive) => {

    setUpdateIndex(null);
    axios
      .patch(
        `${config.apiAdmin}/sadmin/psp/toggleActivation`,
        { id: Psp, is_active: isActive },
        Header
      )
      .then((response) => {
        SendPopup({ message: 'confirm', info: 'toggleActivation.', isActive: true })

        fetchPsp();
      })
      .catch((error) => {
        SendPopup({ message: 'error', info: 'toggleActivation.', isActive: true })

        console.error("There was an error! delete desk", error);
      });
    
  };

  return (
    <div>
      <div className="d-flex border-bottom">
        <div
          className={`col-auto mx-5 text-center cursor-pointer fs-16 h-100 ${"underline"}`}
        >
          PSP
        </div>
      </div>
      <div className="mt-4 pt-2 pb-2 border-bottom d-flex align-items-center justify-content-between">
        <div>
          <div className="ms-5 fw-bold text-black">
            Ajouter, Activer ou desactiver des psp
          </div>
          <div className="ms-5 text-black">
            Cette page vous permet d’ajouter, d’activer et de désactiver des
            psp.
          </div>
        </div>
        <div
          className="border me-5 px-4 rounded-3 bg-green-target text-black fw-bold"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          + Ajouter
        </div>
      </div>
      <Card className="mt-5">
        <Table bordered className="table-no-vertical-borders">
          <thead className="border-0">
            <tr className="border-0">
              <th className="col-1 rounded"></th>
              <th className="col-2">Date Ajout</th>
              <th className="col-6 text-start">Nom</th>
              <th className="col-2">Statut</th>
            </tr>
          </thead>
          <tbody>
            {allPsp?.map((member, index) => (
              <tr
                key={member?.idpsp}
                style={{ height: "80px" }}
                id={index === allPsp?.length - 1 ? "lastElement" : undefined}
              >
                <td className="col-1 text-end"></td>
                <td className="col-2 fs-12">
                  {member?.created_at && !isNaN(Date.parse(member?.created_at))
                    ? new Date(member?.created_at).toISOString().split("T")[0]
                    : new Date().toISOString().split("T")[0]}
                </td>
                <td className="text-start text-black fw-bold col-6">
                  {member.isEditing ? (
                    <input
                      style={{ height: "40px" }}
                      className="bg-green-input rounded-3"
                      type="text"
                      value={member.lastName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setInputValue(value);
                        setAllPsp((Psps) =>
                          Psps?.map((Psp) =>
                            Psp?.idpsp === member?.idpsp
                              ? { ...Psp, inputValue: value }
                              : Psp
                          )
                        );
                      }}
                    />
                  ) : (
                    member.name
                  )}
                </td>
                <td className="col-2">
                  {member?.isEditing ? (
                    <>
                      <Form.Check
                        type="switch"
                        className="custom-switch"
                        defaultChecked={member?.is_active !== 0}
                        disabled
                      />
                    </>
                  ) : (
                    <>
                      <Form.Check
                        type="switch"
                        // id={`switch-${member?.name}`}
                        className={`custom-switch ${
                          updateIndex === index ? "bg-green-input" : ""
                        } rounded-3 pb-1`}
                        defaultChecked={member?.is_active !== 0}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          let check;
                          if (isChecked) {
                            check = 1;
                          } else {
                            check = 0;
                          }
                          UpadtePsp(member?.idpsp, check);
                        }}
                      />
                    </>
                  )}
                </td>
                {member.isEditing ? (
                  <td>
                    <button
                      type="button"
                      className="px-4 py-2 rounded-3 bg-green-target text-black fw-bold border-0"
                      onClick={() => CreatePsp(inputValue)}
                    >
                      Valider
                    </button>
                  </td>
                ) : (
                  <td
                    className="d-flex justify-content-center col-12 align-items-center"
                    style={{ height: "80px" }}
                  >
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default Psp;
