import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
// eslint-disable-next-line no-unused-vars
import ReactSelect, { components } from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import axios from "axios";
import config from "../../../config/config";
import { setPopup } from "../../Redux/slices/actions.slice";
import { GridHeaderComp } from "ag-grid-community";
import { useParams } from "react-router-dom";

const UpdateAdminByManager = ({ show, closeModal, user }) => {
  console.log(user);
  const [profilsDB, setProfilsDB] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [brandUpdated, setBrandUpdated] = useState(false);
  const [deskUpdated, setDeskUpdated] = useState(false);
  const [Brands, setBrands] = useState([]);
  const [Desks, setDesks] = useState([]);
  // const [createAliasArr, setCreateAliasArr] = useState([{}, {}]);
  const [listAlias, setListAlias] = useState([]);
  const [step, setStep] = useState("1");
  const [brandNew, setBrandNew] = useState("");
  const [prenomNew, setPrenomNew] = useState("");
  const [nomNew, setNomNew] = useState("");
  const [tleadSel, setTleadSel] = useState([]);
  const [listSellAndTlead, setListSellAndTlead] = useState([]);
  const [sareponsealui, setSareponsealui] = useState();

  const dispatch = useDispatch();

  const [pseudo] = useState(user?.pseudo);

  const { desk } = useParams()

  console.log(desk)

  const adminDetails = useSelector(getAdminDetails);

  const profilToAdmin = adminDetails.profil;
  const categorieToAdmin = adminDetails.categorie;
  const desksToAdmin = adminDetails.desk;

  // console.log(object)

  let desks = user.desk;

  if (!Array.isArray(desks)) {
    desks = [desks];
  }

  const customComponents = {
    ClearIndicator: null,
  };
  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  useEffect(() => {
    readAll();
    readAlias();
    FetchFiltersPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //////////// alias ////////////

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
  };

  const readAlias = () => {
    axios
      .post(
        `${config.apiAdmin}/sadmin/alias/read_by_idusers`,
        { idusers: user.idusers },
        Header
      )
      .then((res) => {
        setListAlias(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteAlias = (alias) => {
    let confirResponse = window.confirm("Etes vous sur ?");
    // console.log("alis", alias);
    if (confirResponse) {
      axios
        .patch(
          `${config.apiAdmin}/sadmin/alias/desactivate`,
          { id: alias?.idalias },
          Header
        )
        .then((res) => {
          SendPopup({
            message: "confirm",
            info: "desactivate.",
            isActive: true,
          });

          readAlias();
          // console.log(res)
        })
        .catch((err) => {
          SendPopup({ message: "error", info: "desactivate.", isActive: true });
          console.log(err);
        });
    }
  };

  ////////////// create alias /////////////////

  const CreateNewAlias = () => {
    let obj = {
      lname: prenomNew,
      fname: nomNew,
      user_id: user.idusers,
      is_active: "1",
      brand: brandNew,
    };
    // console.log(obj);
    axios
      .put(`${config.apiAdmin}/sadmin/alias/create`, obj, Header)
      .then((res) => {
        SendPopup({ message: "confirm", info: "create.", isActive: true });
        readAlias();
        setStep("1");
        // console.log(res)
      })
      .catch((err) => {
        SendPopup({ message: "error", info: "create.", isActive: true });
        // console.log(err)
      });
  };

  /////////////// profil /////////////////

  const readAll = () => {
    axios
      .post(
        `${config.apiAdmin}/sadmin/profils/read_by_categories`,
        { categorie: categorieToAdmin },
        Header
      )
      .then((response) => {
        setProfilsDB(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const profilsDBOptions = profilsDB?.map((profil) => ({
    value: profil?.name,
    label: profil?.name,
  }));

  const userProfil = {
    value: user?.profil,
    label: user?.profil,
  };

  const UpdateInUsers = (option) => {
    let obj = {
      idusers: user?.idusers,
      profil: option,
    };
    axios
      .patch(
        `${config.apiAdmin}/sadmin/users/update_pseudo_profil`,
        obj,
        Header
      )
      .then((response) => {
        SendPopup({
          message: "confirm",
          info: "update_pseudo_profil.",
          isActive: true,
        });
        readAll();
        // setProfilUpdated(true);
        // console.log(response.data);
      })
      .catch((error) => {
        SendPopup({
          message: "error",
          info: "update_pseudo_profil.",
          isActive: true,
        });
        console.error("There was an error!", error);
      });
  };

  /////////////// desk /////////////////

  useEffect(() => {
    if (profilToAdmin === "admin") {
      axios
        .get(`${config.apiAdmin}/sadmin/desks/read_all`, Header)
        .then((response) => {
          const desksData = response?.data?.map((desk) => ({
            value: desk.name,
            label: desk.name,
          }));
          setDesks(desksData);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      // Gérer le cas où profilToAdmin n'est pas "admin", si nécessaire
      const deskOptionsAdmin = adminDetails?.desk?.map((desk) => ({
        value: desk,
        label: desk,
      }));
      setDesks(deskOptionsAdmin);
    }

    if (profilToAdmin === "admin") {
      axios
        .get(`${config.apiAdmin}/sadmin/brands/read_all`, Header)
        .then((response) => {
          const brandsData = response?.data?.map((brand) => ({
            value: brand.name,
            label: brand.name,
          }));
          setBrands(brandsData);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      // Gérer le cas où profilToAdmin n'est pas "admin", si nécessaire
      const brandOptions = adminDetails?.brand?.map((brand) => ({
        value: brand,
        label: `${brand}`,
      }));
      setBrands(brandOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilToAdmin, adminDetails.desk]);

  const deleteDesk = (value) => {
    let obj = {
      user_id: user.idusers,
      name: value,
    };
    axios
      .patch(
        `${config.apiAdmin}/sadmin/deskarr/desactivate_by_userid_and_Deskname`,
        obj,
        Header
      )
      .then((response) => {
        SendPopup({
          message: "confirm",
          info: "desactivate_by_userid_and_Deskname.",
          isActive: true,
        });
        setDeskUpdated(true);
      })
      .catch((error) => {
        SendPopup({
          message: "error",
          info: "desactivate_by_userid_and_Deskname.",
          isActive: true,
        });
        console.error("There was an error!", error);
      });
  };

  const addDesk = (value) => {
    let obj = {
      name: value,
      user_id: user.idusers,
    };
    axios
      .post(`${config.apiAdmin}/sadmin/deskarr/search_and_insert`, obj, Header)
      .then((response) => {
        SendPopup({
          message: "confirm",
          info: "search_and_insert.",
          isActive: true,
        });
        setDeskUpdated(true);
      })
      .catch((error) => {
        SendPopup({
          message: "error",
          info: "search_and_insert.",
          isActive: true,
        });
        console.error("There was an error!", error);
      });
  };

  const handleDeskChange = (selectedOptions, actionMeta) => {
    // retourne l array des desks selectionnes
    if (actionMeta.action === "remove-value") {
      deleteDesk(actionMeta.removedValue.value);
    } else if (actionMeta.action === "select-option") {
      addDesk(actionMeta.option.value);
    }
  };
  const deskOptionsUser = desks?.map((desk) => ({
    value: desk,
    label: desk,
  }));

  /////////////// brand /////////////////

  const deleteBrand = (value) => {
    let obj = {
      user_id: user.idusers,
      name: value,
    };
    axios
      .patch(
        `${config.apiAdmin}/sadmin/brandarr/desactivate_by_userid_and_deskname`,
        obj,
        Header
      )
      .then((response) => {
        SendPopup({
          message: "confirm",
          info: "desactivate_by_userid_and_deskname.",
          isActive: true,
        });

        setBrandUpdated(true);
      })
      .catch((error) => {
        SendPopup({
          message: "error",
          info: "desactivate_by_userid_and_deskname.",
          isActive: true,
        });

        console.error("There was an error!", error);
      });
  };

  const addbrand = (value) => {
    console.log("ici");
    let obj = {
      name: value,
      user_id: user.idusers,
    };
    axios
      .post(`${config.apiAdmin}/sadmin/brandarr/search_and_insert`, obj, Header)
      .then((response) => {
        SendPopup({
          message: "confirm",
          info: "search_and_insert.",
          isActive: true,
        });

        setBrandUpdated(true);
      })
      .catch((error) => {
        SendPopup({
          message: "error",
          info: "search_and_insert.",
          isActive: true,
        });

        console.error("There was an error!", error);
      });
  };

  const handlebrandChange = (selectedOptions, actionMeta) => {
    // retourne l array des desks selectionnes
    // const selectedValues = selectedOptions ? selectedOptions.map(opt => opt.value) : [];
    console.log("1", actionMeta);
    if (actionMeta.action === "remove-value") {
      console.log("2");
      deleteBrand(actionMeta.removedValue.value);
    } else if (actionMeta.action === "select-option") {
      console.log("3");
      addbrand(actionMeta.option.value);
    }
  };

  const brandOptionsUser = user?.brand?.map((brand) => ({
    value: brand,
    label: brand,
  }));

  const brandsByAdmin = adminDetails?.brand.map((brand) => ({
    value: brand,
    label: brand,
  }));

  ///////////////////add or update tlead ////////////////////

  const userTlead = tleadSel?.map((user) => ({
    value: user.idusers || user.value, // Utilisez la propriété correcte de l'objet
    label: user.pseudo || user.label, // Vous pouvez choisir d'afficher le pseudo comme étiquette
  }));

  const FetchFiltersPost = () => {
    axios
      .post(
        `${config.apiAdmin}/sadmin/users/all_sell_and_tlead`,
        {
          desk: desksToAdmin.length === 1 ? desksToAdmin : [desk] ,
        },
        Header
      )
      .then((response) => {
        const tLeadOnly = response.data?.filter(
          (obj) => obj.profil === "tlead"
        );
        setListSellAndTlead(tLeadOnly);
        const tleadIfExist = response.data?.filter(
          (obj) => obj.idusers === user.tlead_id
        );
        setTleadSel(tleadIfExist);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const optionsPseudo = listSellAndTlead?.map((pseudo) => {
    return {
      value: pseudo.idusers,
      label: pseudo.pseudo,
      profil:
        pseudo.profil === "sell"
          ? "Vendeur"
          : pseudo.profil === "tlead"
          ? "TeamLeader"
          : pseudo.profil,
    };
  });

  const handleTleadChange = (selectedOptions, actionMeta) => {
    console.log(selectedOptions);
    const selectArray = [selectedOptions];
    const tleadSelected = selectArray[0].value;
    setTleadSel(selectArray);

    axios
      .post(
        `${config.apiAdmin}/sadmin/users/UpdateTleadForAgent`,
        { idusers: user.idusers, tlead_id: tleadSelected },
        Header
      )
      .then((response) => {
        SendPopup({
          message: "confirm",
          info: "UpdateTleadForAgent",
          isActive: true,
        });
      })
      .catch((error) => {
        SendPopup({
          message: "error",
          info: "UpdateTleadForAgent",
          isActive: true,
        });
        console.error("There was an error!", error);
      });
  };

  return (
    <>
      <Modal className="modal fade" show={show} onHide={closeModal} centered>
        <Modal.Body className="border rounded w-100">
          {step === "1" && (
            <>
              <button className="btn btn-info" onClick={() => setStep("2")}>
                add alias
              </button>
              <div className="my-2 d-flex justify-content-between align-items-center border rounded-3 bg-light">
                <div className="fs-20 ms-5 text-black">{pseudo}</div>
                <ReactSelect
                  options={profilsDBOptions}
                  className="col-4"
                  defaultValue={userProfil}
                  onChange={(option) => {
                    UpdateInUsers(option.value);
                  }}
                />
              </div>
              <div className="rounded-3 my-2">
                <div
                  style={{ maxHeight: "300px", overflow: "auto" }}
                  className="border rounded-3 bg-light p-2"
                >
                  {listAlias?.map((alias, index) => (
                    <React.Fragment key={alias.idalias}>
                      <div className="d-flex justify-content-between p-1 fs-14 text-black">
                        <div>prenom</div>
                        <div>nom</div>
                        <div>brand</div>
                        <div></div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <input
                          type="text"
                          value={alias.fname}
                          readOnly
                          className="rounded-3 border text-center"
                          style={{ width: "100px" }}
                        />
                        <input
                          type="text"
                          value={alias.lname}
                          readOnly
                          className="rounded-3 border text-center"
                          style={{ width: "100px" }}
                        />
                        <input
                          type="text"
                          value={alias.brand}
                          readOnly
                          className="rounded-3 border text-center"
                        />
                        <button
                          className="btn btn-danger my-0"
                          onClick={() => deleteAlias(alias)}
                        >
                          delete
                        </button>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className="my-2 p-2 d-flex align-items-end justify-content-between border rounded-3 p-2 bg-light">
                <div>
                  <label htmlFor="" className="text-black fs-14 ms-2">
                    Desks selectionnés
                  </label>
                  <ReactSelect
                    isMulti
                    options={Desks}
                    defaultValue={deskOptionsUser}
                    onChange={handleDeskChange}
                    classNamePrefix="select"
                    components={customComponents}
                  />
                </div>
                {deskUpdated && (
                  <div className="h-50 p-2 bg-white border-0 text-info rounded">
                    Updated
                  </div>
                )}
              </div>
              <div className="my-2 p-2 d-flex align-items-end justify-content-between border rounded-3 p-2 bg-light">
                <div>
                  <label htmlFor="" className="text-black fs-14 ms-2">
                    Brands séléctionnées
                  </label>
                  <ReactSelect
                    isMulti
                    options={Brands}
                    defaultValue={brandOptionsUser}
                    onChange={handlebrandChange}
                    classNamePrefix="select"
                    components={customComponents}
                  />
                </div>
                {brandUpdated && (
                  <div className="h-50 p-2 bg-white border-0 text-info rounded">
                    Updated
                  </div>
                )}
              </div>
              <div className="my-2 p-2 d-flex align-items-end justify-content-between border rounded-3 p-2 bg-light">
                <div>
                  <label htmlFor="" className="text-black fs-14 ms-2">
                    Team Leader séléctionné
                  </label>
                  <ReactSelect
                    options={optionsPseudo}
                    // value={[{label: "helly", value: "hello"}]}userTlead
                    value={userTlead}
                    onChange={handleTleadChange}
                    classNamePrefix="select"
                    components={customComponents}
                  />
                </div>
                {/* {brandUpdated && (
                  <div className="h-50 p-2 bg-white border-0 text-info rounded">
                    Updated
                  </div>
                )} */}
              </div>
              {/* <Button type="submit">Update</Button> */}
            </>
          )}
          {step === "2" && (
            <div className="d-flex align-items-center justify-content-center">
              <div className="border p-2 rounded-3 bg-light">
                <input
                  type="text"
                  className="p-2 rounded my-2 border"
                  placeholder="prenom"
                  id="prenom"
                  value={prenomNew}
                  onChange={(e) => setPrenomNew(e.target.value.trimStart())}
                />
                <input
                  type="text"
                  className="p-2 rounded my-2 border"
                  placeholder="nom"
                  id="nom"
                  value={nomNew}
                  onChange={(e) => setNomNew(e.target.value.trimStart())}
                />
                <ReactSelect
                  id="brand"
                  placeholder="Brand"
                  options={brandsByAdmin}
                  onChange={(selectedOption) => {
                    setBrandNew(selectedOption.value);
                  }}
                />
              </div>
              <button
                className="btn btn-info ms-5"
                onClick={() => CreateNewAlias()}
              >
                create new alias
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateAdminByManager;
