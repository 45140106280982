import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../../../config/config";
import useHeaderRest from "../../../utils/useHeader";
import cardfifaX from "../../../images/badgefifa.png";
import Mutliplex from "../../../son/Multiplex-Canal.mp3";
import formatNumber from "../../../utils/formatNumber";
import { getAdminDesks } from "../../Redux/slices/admin.slice";
import { useSelector } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { useParams } from 'react-router-dom';

export default function Component() {
  const useHeader = useHeaderRest();
  const [prevOrder, setPrevOrder] = useState([]);
  const [animationDone, setAnimationDone] = useState(true);
  const [data, setData] = useState([]);
  const audioRef = useRef(null);

  const deskAdmin = useSelector(getAdminDesks);
  const adminDetails = useSelector(getAdminDetails);
  const { desk } = useParams();
  console.log("deskId", desk)
  const gooddesk = desk ? [desk] : adminDetails.desk;
  // console.log(deskAdmin);

  useEffect(() => {
    getDataRanking(); // Initial data fetch
  }, []);

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       getDataRanking();
  //     }, 5000); // Polling every 5 seconds

  //     return () => clearInterval(interval);
  //   }, []);

  useEffect(() => {
    if (!animationDone) {
      const timer = setTimeout(() => {
        setAnimationDone(true);
      }, 2700); // Duration of the animation (2 seconds)
      return () => clearTimeout(timer);
    }
  }, [animationDone]);

  const getDataRanking = () => {
    let obj = {
      desk: gooddesk,
    };

    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/get_data_ranking_fifa_agent`,
        obj,
        useHeader
      )
      .then((response) => {
        console.log(response.data.data);
        const newRankingData = response.data.data.sort(
          (a, b) => b.total - a.total
        );
        setData(newRankingData);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    // Only update the previous order if the new data is different
    if (data.length && !areArraysEqual(prevOrder, data)) {
      updateScores(data);
    }
  }, [data]);

  const updateScores = (sortedPeople) => {
    setPrevOrder(sortedPeople);
    setAnimationDone(false);
    if (audioRef.current) {
      audioRef.current.volume = 1;
      audioRef.current.play();
    }
  };

  const areArraysEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false;
    }
    for (let i = 0; i < array1.length; i++) {
      if (array1[i].id !== array2[i].id) {
        return false;
      }
    }
    return true;
  };

  return (
    <div className="container mt-5">
      <div
        style={{ backgroundColor: "#0c1a31" }}
        className="d-flex flex-column align-items-center pb-3"
      >
        <div className="d-flex col-12">
          <div className="col-1"></div>
          <div
            style={{ color: "#d2bb81" }}
            className="fs-22 fw-bold col-10 text-center"
          >
            GOAT CUP
          </div>
          <div className="circle-fifa mt-2 ms-0"></div>
        </div>
        <div className="fs-14 text-white text-center">
          CLASSEMENT JOURNALIER CHIFFRE/AGENT
        </div>
      </div>
      <div
        style={{
          position: "relative",
          height: `${data.length * 50}px`,
        }}
      >
        {data?.sort((a, b) => b.total_amount_today - a.total_amount_today).slice(0, 9)?.map((person, index) => {
          // Find previous index in prevOrder for this person
          const prevIndex = prevOrder.findIndex((p) => p.id === person.id);
          const yPosition = index * 50;
          const isMoving = prevIndex !== -1 && prevIndex !== index;
          const positionChange = prevIndex === -1 ? 0 : prevIndex - index;

          return (
            <div
              key={person.user_id}
              className="person-item d-flex align-items-center"
              style={{
                transform: `translateY(${yPosition}px)`,
                backgroundColor: "rgba(12, 26, 49, 0.4)",
                zIndex: isMoving ? 10000 : 0,
                color: "white",
              }}
            >
              <div
                className="col-4 fw-bold fs-14 text-start pointer p-0"
                style={{ color: "#d2bb81" }}
                title="Total des signés de la journée"
              >
                € {formatNumber(person.total_amount_today)}
              </div>{" "}
              <div className="col-1">{/* { person.rankMonthly } */}</div>{" "}
              <div
                className="d-flex col-4 align-items-center justify-content-end text-uppercase fs-14 fw-bold p-0"
                style={{ height: "100%" }}
              >
                {/* <div className="me-2">{person.name}</div> */}
                <div>{person.pseudo}</div>
              </div>{" "}
              <div className="col-2 p-0">
                <div
                  className="d-flex align-items-center position-relative"
                  style={{ height: "60px", width: "50px" }}
                >
                  <div
                    className="position-absolute"
                    style={{
                      height: "60px",
                      width: "40px",
                      backgroundImage: `url(${cardfifaX})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      marginLeft: "5px",
                    }}
                  />
                  <img
                    src={person.url}
                    style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                      position: "relative",
                      zIndex: 1,
                    }}
                    className="m-auto"
                  />
                </div>
              </div>
              <div
                className="col-1 p-0 d-flex align-items-center justify-content-center h-100 fw-bold fs-20"
                style={{
                  backgroundColor: index % 2 === 0 ? "#d2bb81" : "#a78e60",
                }}
              >
                {index + 1}
              </div>
            </div>
          );
        })}
      </div>
      <audio ref={audioRef} src={Mutliplex} />
    </div>
  );
}
