import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Importation de Bootstrap CSS
import formatNumber from "../../../utils/formatNumber";

const ProgressBarSeller = ({
  valeurActuelle,
  objectif,
  index,
  gains,
  lastobjectif,
  containsValue,
  IsInTarget,
  TargetCurrent,
}) => {
  console.log(valeurActuelle);
  console.log(objectif);
  console.log(index);
  console.log(gains);
  console.log(lastobjectif);
  console.log(containsValue);
  console.log(IsInTarget);
  console.log(TargetCurrent);

  const indexMore1 = index + 1;
  const pourcentage = IsInTarget
    ? objectif !== 0
      ? Math.min(Math.round((valeurActuelle / objectif) * 100), 100)
      : 0
    : 0;

  const displayValeurActuelle = IsInTarget
    ? valeurActuelle
    : lastobjectif?.objectif;

  // console.log(pourcentage);
  const Notargets = objectif !== 0 ? "Target" + indexMore1 : "Pas de target";

  return (
    <div className="mb-2 px-3">
      <div
        className={`d-flex justify-content-between align-items-center fw-bold ${
          pourcentage === 100 ? "text-grey-target" : "text-black"
        }`}
      >
        <label className="form-label m-1">{Notargets}</label>
        <div className={`text-end `}>{pourcentage}%</div>
        <span className="text-pbar">
          {formatNumber(displayValeurActuelle)} / {formatNumber(objectif)}
        </span>
        <div className="text-end">{gains}</div>
      </div>
      <div className="progress">
        <div
          className={`progress-bar ${
            index === 1
              ? "bg-warning-seller"
              : index === 2
              ? "bg-success-seller"
              : "bg-danger-seller"
          }`}
          role="progressbar"
          style={{ width: `${pourcentage}%` }}
          aria-valuenow={pourcentage}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
    </div>
  );
};

class Component extends React.Component {
  render() {
    const { TargetsPerso, findUserOnRation } = this.props;

    // console.log(TargetsPerso);

    const totalSigned = 0;
    const sumProof = 0;
    const sumEncashed = Number(findUserOnRation?.sum_encashed || 0);
    let value = totalSigned + sumProof + sumEncashed;

    // Function to update targets with lastobjectif and TargetCurrent
    const updateTargets = (targets, value) => {
      return targets.map((target, index) => {
        // Check if this target contains the value (assuming target has a values array)
        const containsValue = value;

        // Update second and third targets with additional keys
        if (index === 1 || index === 2) {
          const lastobjectif =
            index > 0
              ? { objectif: targets[index - 1].objectif }
              : { objectif: 0 }; // object from the previous index
          const TargetCurrent = value - lastobjectif.objectif; // Calculate the difference
          const IsInTarget =
            value > lastobjectif.objectif && value <= target.objectif; // Check if value is in target range

          return {
            ...target,
            lastobjectif, // Assign the object from the previous index
            TargetCurrent, // Assign the calculated difference
            containsValue, // Optional: track if this target contains the value
            IsInTarget, // Assign if value is in target range
          };
        }

        const lastobjectif =
          index > 0
            ? { objectif: targets[index - 1].objectif }
            : { objectif: 0 }; // object from the previous index
        const IsInTarget =
          value > lastobjectif.objectif && value <= target.objectif; // Check if value is in target range

        return {
          ...target,
          containsValue, // Optional: track if this target contains the value
          lastobjectif, // Assign the object from the previous index
          IsInTarget, // Assign if value is in target range
        };
      });
    };

    const updatedTargetsPerso = updateTargets(TargetsPerso, value);
    console.log(updatedTargetsPerso)

    // Si TargetsPerso est vide, définir des valeurs par défaut
    const defaultTargets = [
      { label: "", objectif: 0, gift: 0, cash: 0 },
      { label: "", objectif: 0, gift: 0, cash: 0 },
      { label: "", objectif: 0, gift: 0, cash: 0 },
    ];

    // console.log(updatedTargetsPerso.length)
    // console.log(value)

    const targetsToRender =
      updatedTargetsPerso.length > 0 ? updatedTargetsPerso : defaultTargets;
    const valueToRender = updatedTargetsPerso.length > 0 ? value : 0;

    // console.log(targetsToRender)
    // console.log(valueToRender)

    return (
      <div className="container m-0">
        {targetsToRender.map((item, index) => (
          <ProgressBarSeller
            key={index}
            label={item.label}
            valeurActuelle={valueToRender}
            objectif={item.objectif}
            index={index}
            gains={item.cash === 0 ? item.gift : item.cash + "$"}
            lastobjectif={item.lastobjectif}
            containsValue={item.containsValue}
            IsInTarget={item.IsInTarget}
            TargetCurrent={item.TargetCurrent}
          />
        ))}
      </div>
    );
  }
}

export default Component;
