import { useSelector } from 'react-redux';

const useHeader = () => {
    const token = useSelector((state) => state.admin.details.token); // Remplacez 'auth.token' par le chemin correct dans votre état Redux
    // console.log("token", token)

    return {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    };
  };
  
  export default useHeader;