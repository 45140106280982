import React, { useState } from "react";
import rolex from "../../../images/rolex-logo.svg"; // Importation du logo Rolex
import photoRolex from "../../../images/hq720_(1).jpg"; // Importation de l'image de fond
import watches from "../../../images/watch-round-svgrepo-com.svg";
import chest from "../../../images/chest-gold-svgrepo-com.svg"

const CircleOpacityAnimation = ({ findUserOnRation }) => {
  const totalSigned = Number(findUserOnRation?.totalSigned || 0);
  const sumProof = Number(findUserOnRation?.sum_proof || 0);
  const sumEncashed = Number(findUserOnRation?.sum_encashed || 0);
  let roro = totalSigned + sumProof + sumEncashed;

  if (roro > 500000) {
    roro = 500000;
  }

  const percentage = Math.floor((roro * 100) / 500000);
  const angle = (360 * percentage) / 100;

  const containerStyle = {
    position: "relative", // Conteneur parent avec position relative pour gérer les éléments enfants superposés
    width: "100%",
    height: "170px",
    margin: "0 auto", // Centre l'élément horizontalement dans la page
  };

  const overlayStyle = {
    width: "100%",
    height: "100%",
    background: `conic-gradient(transparent ${angle}deg, rgba(255, 255, 255, 0.95) 0deg)`,
    transition: "background 0.5s ease",
    position: "absolute", // Permet de superposer cet élément sur l'image
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const pictureStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
  };

  const logoContainerStyle = {
    position: "absolute",
    width: "100px",
    height: "100px",
    zIndex: 2,
    background: `conic-gradient(transparent ${angle}deg, rgba(255, 255, 255, 0.95) 0deg)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const logoStyle = {
    width: "80%", // Taille du logo Rolex
    height: "80%",
    objectFit: "contain", // Pour que le logo garde ses proportions
  };

  const percentageContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '10px',
  };

  const percentageStyle = {
    color: 'gold',
    fontSize: '24px',
    fontWeight: 'bold',
  };

  const styleRatio = {
    fontSize: "10px",
    color: "black"
  };

  const topLeftStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    color: 'black',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '5px',
    borderRadius: '50%',
    border: '2px solid #b8b230',
    fontSize: "11px",
    height: "35px",
    width: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div className="">
      <div style={containerStyle}>
        {/* Image de fond */}
        <img src={photoRolex} alt="Background" style={pictureStyle} />
        {/* Overlay avec pourcentage et logo superposé */}
        <div style={overlayStyle} className="">
          <div style={logoContainerStyle}>
            <img src={rolex} alt="Rolex Logo" style={logoStyle} />
          </div>
          <div style={topLeftStyle} className="m-1 ms-3 mt-3">{percentage}%</div>
        </div>
      </div>
    </div>
  );
};

export default CircleOpacityAnimation;