import React, { useState, useEffect } from "react";
import BossSuppStat from "../../BossSupport/Stats/GraphChefSupportHorizontal"
const TableauChefSupportHorizontal = () => {
 
  return (
    <>
      {/* <h1>Ici nous allons chercher le stat du boss sup</h1> */}
      <BossSuppStat />
    </>
  );
};

export default TableauChefSupportHorizontal;
