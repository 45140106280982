import React from "react";

import ReactApexChart from "react-apexcharts";

class GraphRatioByAgent extends React.Component {
  constructor(props) {
    super(props);
console.log(props)



// by Douglas adapate la data de merde d elie au format pour ReactApexChart
    const extractedNames = props.arrCategories?.map(item => item.agent.desk);
    const stringArray = extractedNames?.map(number => number.toString());
    const extractedSumTotal = props.arrCategories?.map(item => item.total);
    const convertToNumber = (str) => {
      // Remplacer les espaces insécables (non-breaking spaces) par des espaces normaux
      const normalizedStr = str?.replace(/\s/g, '');
      // Convertir en nombre
      return Number(normalizedStr);
    };
    const numberArray = extractedSumTotal?.map(convertToNumber);


    const annotations = {
      points: numberArray?.map((sum, index) => ({
        x: stringArray[index], // Position above the corresponding category
        y: sum, // Align the annotation above the bar
        marker: {
          size: 1, // No marker point
        },
        label: {
          style: {
            color: 'black',
            background: 'white',
          },
          text: `${sum.toLocaleString('fr-FR')} $`,
          position: 'top', // Position the label above the bar
          offsetY: -10, // Adjust the offset to place it neatly above the bar
        },
      })),
    };

    const stringArrayWithD = stringArray.map(title => `D${title}`);

    this.state = {
      taille: this.props.height,
      series: [
        {
          name: "Encaissé",
          data: this.props?.arrEncaisse || [],
        },
        {
          name: "Preuve",
          data: this.props?.arrPreuve  || [] ,
        },
        {
          name: "Signé",
          data: this.props?.arrSigned || [] ,
        },
        {
          name: "Reporté",
          data: this.props?.arrReported || [] ,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 230,
          toolbar: {
            show: false,
          },
          stacked: true,
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            horizontal: false,
            dataLabels: {
              position: "top",
            },
            stacked: true,
          },
        },
        colors: ["#10d078", "#fefeae", "#ffa500" , "#a569bd", "#5d6d7e"],
        //  #fefeae
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val?.toLocaleString('fr-FR') + " $";
          },
          style: {
            fontSize: '12px',
            colors: ['black'],
          },
          offsetX: 0,
          offsetY: 0,
          // offsetY: function({ seriesIndex, dataPointIndex, w }) {
          //   // Positionner les labels en fonction de la hauteur de la barre
          //   const val = w.config.series[seriesIndex].data[dataPointIndex];
          //   return val > 10 ? 0 : 10; // Ajustez cette valeur en fonction de la taille de vos données
          // },
          textAnchor: 'middle',
          inside: true, // Pour s'assurer que les labels sont à l'intérieur de la barre
        },
        
        stroke: {
          show: false,
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return "$ " + val?.toLocaleString('fr-FR');
            },
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
               // Modifiez la taille de la police des labels de l'axe x
            },
          },
          lines: {
            show: false,
          },
        },
        xaxis: {
          show: false,
          categories:  stringArrayWithD,
          labels: {
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
            },
          },
        },
        annotations: annotations,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (     prevProps.arrEncaisse !== this.props.arrEncaisse 
          || prevProps.arrSigned !== this.props.arrSigned 
          ||  prevProps.arrPreuve !== this.props.arrPreuve 
          || prevProps.arrReported !== this.props.arrReported
          || prevProps.arrCanceled !== this.props.arrCanceled
        ) {

          const extractedNames = this.props.arrCategories?.map(item => item.agent.desk);
          const stringArray = extractedNames?.map(number => number.toString());
          const extractedSumTotal = this.props.arrCategories?.map(item => item.total);
          const convertToNumber = (str) => {
            // Remplacer les espaces insécables (non-breaking spaces) par des espaces normaux
            const normalizedStr = str?.replace(/\s/g, '');
            // Convertir en nombre
            return Number(normalizedStr);
          };
          const numberArray = extractedSumTotal.map(convertToNumber);
          const annotations = {
            points: numberArray?.map((sum, index) => ({
              x: stringArray[index], // Position above the corresponding category
              y: sum, // Align the annotation above the bar
              marker: {
                size: 1, // No marker point
              },
              label: {
                style: {
                  color: 'black',
                  background: 'white',
                },
                text: `${sum.toLocaleString('fr-FR')} $`,
                position: 'top', // Position the label above the bar
                offsetY: -10, // Adjust the offset to place it neatly above the bar
              },
            })),
          };

      this.setState({
        series:[
        {
          name: "Encaissé",
          data: this.props?.arrEncaisse || [],
        },
        {
          name: "Preuve",
          data: this.props?.arrPreuve  || [] ,
        },
        {
          name: "Signé",
          data: this.props?.arrSigned || [] ,
        },
        {
          name: "Reporté",
          data: this.props?.arrReported || [] ,
        },
      ],
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: stringArray || [],
          },
          annotations: annotations,
        },
      });
    }
  }

  render() {
    return (
      <div id="chart" className="line-chart-style bar-chart h-100">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={this.state.taille}
        />
      </div>
    );
  }
}
export default GraphRatioByAgent;
