import React from "react";
import { Card, ProgressBar} from "react-bootstrap";

const ProgressBarDesk = ({data}) => {
  console.log(data)
  let Amountstep = 3000000;
  let numberStep = 6;

  function formatNumber(num) {
    let response;
    num = parseFloat(num); // Convert string to number

    if (isNaN(num)) {
      response = num;
      console.log(response);
      return response;
    }

    response = num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    console.log(response);
    return response;
  }


  return (
    <div className="row">
      <Card.Body>
        <ProgressBar
          now={( data?.total_amount*100 )/( Amountstep*numberStep )}
          label={ data && formatNumber(data?.total_amount) +" €"   }
          className="fs-5 fw-bold bg-white "
          style={{ height: "24px", backgroundColor: "#10d078" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "5px",
          }}
        >
          {Array.from({ length: numberStep }, (_, i) => i * Amountstep).map(
            (mark, index) => (
              <div className="d-flex justify-content-center" key={index}>
                <span key={index} className="fs-12 text-black">
                   {mark}
                </span>
              </div>
            )
          )}
        </div>
      </Card.Body>
    </div>
  );
};

export default ProgressBarDesk;
