import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config/config";
import ReactSelect, { components } from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { CircleLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import {
  setAllContracts,
  setFilters,
  getFilters,
} from "../../Redux/slices/contract.slice";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const TableauCrmFilters = (props) => {
  const AdminDetails = useSelector(getAdminDetails);
  const desksToAdmin = AdminDetails?.desk;
  const brandsToAdmin = AdminDetails?.brand;
  const idToAdmin = AdminDetails?.idusers;
  const deskParams = useParams().desk;

  let Desk = deskParams ? deskParams : desksToAdmin;

  const dispatch = useDispatch();

  const filters = useSelector(getFilters);

  const initialObjToFetch = {
    filters: {
      desk: "",
      seller_id: [idToAdmin],
    },
    filters_date: {},
    sorts: {},
    limit: "",
  };

  const [inputSearchCustomerfname, setInputSearchCustomerfname] = useState("");
  const [inputSearchCustomerlname, setInputSearchCustomerlname] = useState("");
  const [inputSearchCustomerlnamefname, setInputSearchCustomerlnamefname] =
  useState("");
  const [inputSearchSeller, setInputSearchSeller] = useState("");
  const [inputSearchSupport, setInputSearchSupport] = useState("");
  const [ObjToFetch, setObjToFetch] = useState(initialObjToFetch);
  const [psp, setPsp] = useState([]);
  const [neobank, setNeoBank] = useState([]);
  const [customerBank, setCustomerBank] = useState([]);

  const [dropDispo, setDropDispo] = useState(null);
  const [dropPreuve, setDropPreuve] = useState(null);
  const [dropEncaisse, setDropEncaisse] = useState(null);
  const [dropReport, setDropReport] = useState(null);
  const [dropAnnul, setDropAnnul] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [listSupport, setListSupport] = useState([]);
  const [listSellAndTlead, setListSellAndTlead] = useState([]);

  const [LoaderStart, setLoaderStart] = useState(false);

  const [showBtnFilter, setShowBtnFilter] = useState(false);

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const optionsSatutsDispo = [
    {
      value: "argent_dispo_check",
      label: "argent dispo_oui",
      checked: "1",
      Tofetch: "argent_dispo",
      rep: "oui",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      ),
    },
    {
      value: "argent_dispo",
      label: "argent dispo_non",
      checked: "0",
      Tofetch: "argent_dispo",
      rep: "non",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
        </svg>
      ),
    },
  ];
  const optionsSatutsPreuve = [
    {
      value: "preuve_check",
      label: "preuve_oui",
      checked: "1",
      Tofetch: "preuve",
      rep: "oui",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      ),
    },
    {
      value: "preuve",
      label: "preuve_non",
      checked: "0",
      Tofetch: "preuve",
      rep: "non",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
        </svg>
      ),
    },
  ];
  const optionsSatutsEncaisse = [
    {
      value: "encaisse_check",
      label: "encaisse_oui",
      checked: "1",
      Tofetch: "encaisse",
      rep: "oui",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      ),
    },
    {
      value: "encaisse",
      label: "encaisse_non",
      checked: "0",
      Tofetch: "encaisse",
      rep: "non",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
        </svg>
      ),
    },
  ];
  const optionsSatutsReport = [
    {
      value: "is_report_check",
      label: "is report_oui",
      checked: "1",
      Tofetch: "is_report",
      rep: "oui",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      ),
    },
    {
      value: "is_report",
      label: "is report_non",
      checked: "0",
      Tofetch: "is_report",
      rep: "non",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
        </svg>
      ),
    },
  ];
  const optionsSatutsAnnul = [
    {
      value: "is_canceled_check",
      label: "is canceled_oui",
      checked: "1",
      Tofetch: "is_canceled",
      rep: "oui",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg>
      ),
    },
    {
      value: "is_canceled",
      label: "is canceled_non",
      checked: "0",
      Tofetch: "is_canceled",
      rep: "non",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-square"
          viewBox="0 0 16 16"
        >
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
        </svg>
      ),
    },
  ];
  const limitOptions = [
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 200, label: "200" },
    { value: "", label: "All" },
  ];

  const pspSelect = psp?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const brandSelect = brandsToAdmin?.map((brand) => ({
    value: brand,
    label: brand,
  }));
  const deskSelect = desksToAdmin?.map((brand) => ({
    value: brand,
    label: brand,
  }));
  const neobankSelect = neobank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const customerBankSelect = customerBank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const optionsSupport = listSupport?.map((support) => ({
    value: support.idusers,
    label: support.pseudo,
    profil:
      support.profil === "agt_supp"
        ? "Support"
        : support.profil === "mng_supp"
        ? "MgSupport"
        : support.profil === "boss_supp"
        ? "BossSupport"
        : support.profil,
  }));
  const optionsPseudo = listSellAndTlead?.map((pseudo) => {
    return {
      value: pseudo.idusers,
      label: pseudo.pseudo,
      profil:
        pseudo.profil === "sell"
          ? "Vendeur"
          : pseudo.profil === "tlead"
          ? "TeamLeader"
          : pseudo.profil,
    };
  });

  useEffect(() => {
    if (props.showFilters === true) {
      FetchFiltersGet("/psp/read_all", setPsp);
      FetchFiltersGet("/neobanks/read_all", setNeoBank);
      FetchFiltersGet("/customerbanks/read_all", setCustomerBank);
      FetchFiltersPost("/users/all_agt_supp_and_mng_supp", setListSupport);
      FetchFiltersPost("/users/all_sell_and_tlead", setListSellAndTlead, {
        desk: desksToAdmin,
      });
    }
    // eslint-disable-next-line
  }, [props.showFilters]);

  const FetchFiltersGet = (url, fonction) => {
    axios
      .get(`${config.apiAdmin}/sadmin${url}`, Header)
      .then((response) => {
        fonction(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const FetchFiltersPost = (url, fonction, obj) => {
    axios
      .post(`${config.apiAdmin}/sadmin${url}`, obj, Header)
      .then((response) => {
        fonction(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const SearchContrats = (options) => {
    let IsInit;
    if (options.isReinit) {
      IsInit = {
        filters: {
          desk: "",
          seller_id: [idToAdmin],
        },
        filters_date: {},
        sorts: {},
        limit: "",
      };
    } else {
      IsInit = filters;
    }
    setLoaderStart(true);
    axios
      .post(
        `${config.apiAdmin}/sadmin/contracts/search_and_sort`,
        IsInit,
        Header
      )
      .then((response) => {
        dispatch(setAllContracts(response.data.data.results));
        setLoaderStart(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoaderStart(false);
      });
  };

  const reinitFilters = () => {
    setShowBtnFilter(false);
    // setLoaderStart(true);
    dispatch(
      setFilters({
        filters: {
          desk: "",
          seller_id: [idToAdmin],
        },
        filters_date: {},
        sorts: {},
        limit: "",
      })
    );
    setObjToFetch({
      filters: {
        desk: "",
        seller_id: [idToAdmin],
      },
      filters_date: {},
      sorts: {},
      limit: "",
    });
    setInputSearchCustomerfname("");
    setInputSearchCustomerlname("");
    setInputSearchCustomerlnamefname("");
    setInputSearchSeller("");
    setInputSearchSupport("");
    setDropAnnul(null);
    setDropDispo(null);
    setDropPreuve(null);
    setDropEncaisse(null);
    setDropReport(null);
    // setPsp([]);
    // setNeoBank([]);
    // setCustomerBank([]);
    SearchContrats({ isReinit: true });
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    dispatch(setFilters(ObjToFetch));
    // eslint-disable-next-line
  }, [ObjToFetch]);

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.data.rep}
        </div>
      </components.Option>
    );
  };

  const CustomDrop = (props) => {
    return (
      <components.Option {...props}>
        <div className="d-flex justify-content-between align-items-center text-black">
          <span>{props.data.label}</span>
          <span
            style={{ color: "white" }}
            className={`badge badge-${props.data.profil}`}
          >
            {props.data.profil}
          </span>
        </div>
      </components.Option>
    );
  };

  const handleDateChange = (dates) => {
    setShowBtnFilter(true);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const formatDateToYYYYMMDD = (date) => {
      if (!date) return null;
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    let arrayToSend;
    if (start && end) {
      arrayToSend = [formatDateToYYYYMMDD(start), formatDateToYYYYMMDD(end)];
    } else if (start) {
      arrayToSend = [formatDateToYYYYMMDD(start)];
    }

    // Update the filters in your state or object to fetch data
    setObjToFetch((prevState) => ({
      ...prevState,
      filters_date: {
        created_at: arrayToSend,
      },
    }));
  };

  const styleFilters = () => ({
    container: (provided) => ({
      ...provided,
      // width: "162px !important",
      fontSize: "0.8rem",
    }),
    control: (provided) => ({
      ...provided,
      minHeight: "30px",
      height: "auto", // Ajuste automatiquement la hauteur en fonction du contenu
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "auto",
      padding: "2px 12px",
      display: "flex",
      flexWrap: "wrap", // Permet le wrapping des valeurs sélectionnées
      overflow: "visible", // Assure que le contenu est visible
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
      padding: "auto",
    }),
    multiValue: (provided) => ({
      ...provided,
      // backgroundColor: "#e0e0e0", // Arrière-plan pour les valeurs sélectionnées
      borderRadius: "5px",
      // maxWidth: "100%", // S'assurer que chaque valeur ne dépasse pas la largeur disponible
      // overflow: "hidden", // Cacher le dépassement de texte
      // textOverflow: "ellipsis", // Ajouter "..." si le texte est trop long
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: "0.5rem",
      // color: "#333", // Couleur du texte pour les valeurs sélectionnées
      whiteSpace: "nowrap", // Empêche le wrapping du texte
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "0.8rem",
    }),
    menu: (provided) => ({
      ...provided,
      width: 220,
      zIndex: 300,
      maxHeight: "200px", // Limite la hauteur du menu pour garantir qu'il est entièrement visible
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px", // Limite la hauteur de la liste des options
      overflowY: "auto", // Ajoute un défilement si nécessaire
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "30px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: "normal",
    }),
  });

  const datePickerStyle = {
    paddingRight: "30px", // Pour laisser de l'espace à droite pour une icône
    border: "1px solid #ced4da",
    borderRadius: "4px",
    height: "38px", // Ajuster la hauteur selon vos besoins
    width: "100%", // Pour s'assurer qu'il prend tout l'espace disponible
    boxSizing: "border-box", // Pour inclure le padding et la border dans la largeur totale
    paddingLeft: "10px", // Pour donner un peu d'espace à gauche aussi
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setObjToFetch((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        is_active: isChecked ? 0 : 1,
      },
    }));
    console.log(`Checkbox is_active set to ${isChecked}`);
  };

  return (
    <div className="my-2">
      {LoaderStart ? (
        <div className="sweet-loading" style={{ marginTop: "100px" }}>
          <CircleLoader
            color="#36d7b7"
            loading="true"
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <div className="mt-2 mb-2 trait-filtre"></div>
          <div className="d-flex">
          <div className="d-flex flex-column mx-2 justify-content-center">
              <label htmlFor="" className="mt-1">
                Client
              </label>
              <input
                type="text"
                value={inputSearchCustomerfname}
                onChange={(e) => {
                  setShowBtnFilter(true);
                  setInputSearchCustomerfname(e.target.value);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      cust_fname: e.target.value,
                    },
                  }));
                }}
                className="rounded-1 ps-2 mb-2"
                id="input-search"
                placeholder="Prenom"
                style={{
                  height: "36px",
                  border: "1px solid #CCCCCC",
                  color: "#808080",
                  fontSize: "0.9rem",
                }}
              />
              <input
                type="text"
                value={inputSearchCustomerlname}
                onChange={(e) => {
                  setShowBtnFilter(true);
                  setInputSearchCustomerlname(e.target.value);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      cust_lname: e.target.value,
                      // cust_fname: e.target.value,
                    },
                  }));
                }}
                className="rounded-1 ps-2"
                id="input-search"
                placeholder="Nom"
                style={{
                  height: "36px",
                  border: "1px solid #CCCCCC",
                  color: "#808080",
                  fontSize: "0.9rem",
                }}
              />
              <input
                type="text"
                value={inputSearchCustomerlnamefname}
                onChange={(e) => {
                  setShowBtnFilter(true);
                  setInputSearchCustomerlnamefname(e.target.value);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      cust_lname: e.target.value,
                      cust_fname: e.target.value,
                    },
                  }));
                }}
                className="rounded-1 ps-2 mt-2"
                id="input-search"
                placeholder="Nom ou Prenom"
                style={{
                  height: "36px",
                  border: "1px solid #CCCCCC",
                  color: "#808080",
                  fontSize: "0.9rem",
                }}
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-center">
              <label htmlFor="">Pseudo</label>
              {/* <ReactSelect
                options={optionsPseudo}
                className="drop-search my-1"
                placeholder="Seller"
                value={
                  optionsPseudo.filter(
                    (option) =>
                      ObjToFetch.filters.pseudo_seller &&
                      ObjToFetch.filters.pseudo_seller.includes(option.value)
                  ) || undefined
                }
                onChange={(selectedOptions) => {
                  setShowBtnFilter(true);
                  setObjToFetch((prevState) => {
                    const updatedFilters = { ...prevState.filters };
                    if (selectedOptions && selectedOptions.length > 0) {
                      // Si des options sont sélectionnées, mettre à jour la clé pseudo_seller avec un tableau des valeurs sélectionnées
                      updatedFilters.pseudo_seller = selectedOptions.map(
                        (option) => option.value
                      );
                    } else {
                      // Si aucune option n'est sélectionnée, supprimer la clé pseudo_seller des filtres
                      delete updatedFilters.pseudo_seller;
                    }
                    return {
                      ...prevState,
                      filters: updatedFilters,
                    };
                  });
                }}
                components={{ Option: CustomDrop }}
                styles={styleFilters()}
                isSearchable={true}
                isClearable={true}
                isMulti={true} // Permet la sélection multiple
              /> */}

              <ReactSelect
                options={optionsSupport}
                className="mt-1"
                placeholder="Support"
                value={
                  optionsSupport.filter(
                    (option) =>
                      ObjToFetch.filters.pseudo_agt_supp &&
                      ObjToFetch.filters.pseudo_agt_supp.includes(option.value)
                  ) || undefined
                }
                styles={styleFilters()}
                onChange={(selectedOptions) => {
                  setShowBtnFilter(true);
                  setObjToFetch((prevState) => {
                    const updatedFilters = { ...prevState.filters };
                    if (selectedOptions && selectedOptions.length > 0) {
                      // Si des options sont sélectionnées, mettre à jour la clé pseudo_agt_supp avec un tableau des valeurs sélectionnées
                      updatedFilters.pseudo_agt_supp = selectedOptions.map(
                        (option) => option.value
                      );
                    } else {
                      // Si aucune option n'est sélectionnée, supprimer la clé pseudo_agt_supp des filtres
                      delete updatedFilters.pseudo_agt_supp;
                    }
                    return {
                      ...prevState,
                      filters: updatedFilters,
                    };
                  });
                }}
                components={{ Option: CustomDrop }}
                isSearchable={true}
                isClearable={true}
                isMulti={true} // Permet la sélection multiple
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <label htmlFor="">Contrat</label>
              <ReactSelect
                options={pspSelect}
                className="drop-search my-1"
                classNamePrefix="psp"
                placeholder="PSP"
                value={
                  pspSelect.filter(
                    (option) =>
                      ObjToFetch.filters.psp &&
                      ObjToFetch.filters.psp.includes(option.value)
                  ) || undefined
                }
                styles={styleFilters()}
                onChange={(selectedOptions) => {
                  setShowBtnFilter(true);
                  setObjToFetch((prevState) => {
                    const updatedFilters = { ...prevState.filters };
                    if (selectedOptions && selectedOptions.length > 0) {
                      // Si des options sont sélectionnées, mettre à jour la clé psp avec un tableau des valeurs sélectionnées
                      updatedFilters.psp = selectedOptions.map(
                        (option) => option.value
                      );
                    } else {
                      // Si aucune option n'est sélectionnée, supprimer la clé psp des filtres
                      delete updatedFilters.psp;
                    }
                    return {
                      ...prevState,
                      filters: updatedFilters,
                    };
                  });
                }}
                isClearable={true}
                isMulti={true} // Permet la sélection multiple
              />

              {/* <ReactSelect
                  options={pspSelect}
                  className="mb-2"
                  classNamePrefix="psp"
                  placeholder="PSP"
                  value={
                    pspSelect.find(
                      (option) => option.value === ObjToFetch.filters.psp
                    ) || undefined
                  }
                  styles={styleFilters()}
                  onChange={(selectedOption) => {
                    setShowBtnFilter(true);
                    setObjToFetch((prevState) => {
                      const updatedFilters = { ...prevState.filters };
                      if (selectedOption) {
                        // If an option is selected, update the psp key with the selected value
                        updatedFilters.psp = selectedOption.value;
                      } else {
                        // If no option is selected, remove the psp key from filters
                        delete updatedFilters.psp;
                      }
                      return {
                        ...prevState,
                        filters: updatedFilters,
                      };
                    });
                  }}
                  isClearable={true}
                /> */}
              <ReactSelect
                options={neobankSelect}
                className="mt-1"
                classNamePrefix="neobank"
                placeholder="Plateforme"
                value={
                  neobankSelect.filter(
                    (option) =>
                      ObjToFetch.filters.neobank &&
                      ObjToFetch.filters.neobank.includes(option.value)
                  ) || undefined
                }
                styles={styleFilters()}
                onChange={(selectedOptions) => {
                  setShowBtnFilter(true);
                  setObjToFetch((prevState) => {
                    const updatedFilters = { ...prevState.filters };
                    if (selectedOptions && selectedOptions.length > 0) {
                      // Si des options sont sélectionnées, mettre à jour la clé neobank avec un tableau des valeurs sélectionnées
                      updatedFilters.neobank = selectedOptions.map(
                        (option) => option.value
                      );
                    } else {
                      // Si aucune option n'est sélectionnée, supprimer la clé neobank des filtres
                      delete updatedFilters.neobank;
                    }
                    return {
                      ...prevState,
                      filters: updatedFilters,
                    };
                  });
                }}
                isClearable={true}
                isMulti={true} // Permet la sélection multiple
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
                <ReactSelect
                  options={customerBankSelect}
                  className="mb-2"
                  classNamePrefix="customerBank"
                  placeholder="Bank Client"
                  value={
                    customerBankSelect.filter(
                      (option) =>
                        ObjToFetch.filters.customerbank &&
                        ObjToFetch.filters.customerbank.includes(option.value)
                    ) || undefined
                  }
                  styles={styleFilters()}
                  onChange={(selectedOptions) => {
                    setShowBtnFilter(true);
                    setObjToFetch((prevState) => {
                      const updatedFilters = { ...prevState.filters };

                      if (selectedOptions && selectedOptions.length > 0) {
                        // Si des options sont sélectionnées, mettre à jour la clé customerBank avec un tableau des valeurs sélectionnées
                        updatedFilters.customerbank = selectedOptions.map(
                          (option) => option.value
                        );
                      } else {
                        // Si aucune option n'est sélectionnée, supprimer la clé customerBank des filtres
                        delete updatedFilters.customerbank;
                      }

                      return {
                        ...prevState,
                        filters: updatedFilters,
                      };
                    });
                  }}
                  isClearable={true}
                  isMulti={true} // Permet la sélection multiple
                />

                <ReactSelect
                  options={brandSelect}
                  className=""
                  classNamePrefix="Brand"
                  placeholder="Brand"
                  value={
                    brandSelect.filter(
                      (option) =>
                        ObjToFetch.filters.brand &&
                        ObjToFetch.filters.brand.includes(option.value)
                    ) || undefined
                  }
                  styles={styleFilters()}
                  onChange={(selectedOptions) => {
                    setShowBtnFilter(true);
                    setObjToFetch((prevState) => {
                      const updatedFilters = { ...prevState.filters };

                      if (selectedOptions && selectedOptions.length > 0) {
                        // Si des options sont sélectionnées, mettre à jour la clé brand avec un tableau des valeurs sélectionnées
                        updatedFilters.brand = selectedOptions.map(
                          (option) => option.value
                        );
                      } else {
                        // Si aucune option n'est sélectionnée, supprimer la clé brand des filtres
                        delete updatedFilters.brand;
                      }

                      return {
                        ...prevState,
                        filters: updatedFilters,
                      };
                    });
                  }}
                  isClearable={true}
                  isMulti={true} // Permet la sélection multiple
                />
            </div>

            <div className="d-flex flex-column mx-2 justify-content-end">
              <label htmlFor="">Status</label>
              <ReactSelect
                options={optionsSatutsDispo}
                className="mb-2"
                classNamePrefix="status"
                placeholder="Argent dispo"
                value={dropDispo}
                styles={styleFilters()}
                onChange={(selectedOption) => {
                  setShowBtnFilter(true);
                  setDropDispo(selectedOption);
                  setObjToFetch((prevState) => {
                    const updatedFilters = { ...prevState.filters };

                    if (selectedOption) {
                      // If an option is selected, update the key with selectedOption.Tofetch and value with selectedOption.checked
                      updatedFilters[selectedOption.Tofetch] =
                        selectedOption.checked;
                    } else {
                      // If no option is selected, remove the key based on selectedOption.Tofetch
                      delete updatedFilters[dropDispo?.Tofetch]; // Remove the previous key if clearing the selection
                    }

                    return {
                      ...prevState,
                      filters: updatedFilters,
                    };
                  });
                }}
                components={{ Option: CustomOption }}
                isClearable={true}
              />
              <ReactSelect
                options={optionsSatutsPreuve}
                className=""
                classNamePrefix="status"
                placeholder="Preuve"
                value={dropPreuve}
                styles={styleFilters()}
                onChange={(selectedOption) => {
                  setShowBtnFilter(true);
                  setDropPreuve(selectedOption);
                  setObjToFetch((prevState) => {
                    const updatedFilters = { ...prevState.filters };

                    if (selectedOption) {
                      // If an option is selected, update the key with selectedOption.Tofetch and value with selectedOption.checked
                      updatedFilters[selectedOption.Tofetch] =
                        selectedOption.checked;
                    } else if (dropPreuve) {
                      // If no option is selected and dropPreuve has a previous value, remove the key based on dropPreuve.Tofetch
                      delete updatedFilters[dropPreuve.Tofetch];
                    }

                    return {
                      ...prevState,
                      filters: updatedFilters,
                    };
                  });
                }}
                components={{ Option: CustomOption }}
                isClearable={true}
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <ReactSelect
                options={optionsSatutsReport}
                className="mb-2"
                classNamePrefix="status"
                placeholder="Report"
                value={dropReport}
                styles={styleFilters()}
                onChange={(selectedOption) => {
                  setShowBtnFilter(true);
                  setDropReport(selectedOption);
                  setObjToFetch((prevState) => {
                    const updatedFilters = { ...prevState.filters };

                    if (selectedOption) {
                      // If an option is selected, update the key with selectedOption.Tofetch and value with selectedOption.checked
                      updatedFilters[selectedOption.Tofetch] =
                        selectedOption.checked;
                    } else if (dropReport) {
                      // If no option is selected and dropReport has a previous value, remove the key based on dropReport.Tofetch
                      delete updatedFilters[dropReport.Tofetch];
                    }

                    return {
                      ...prevState,
                      filters: updatedFilters,
                    };
                  });
                }}
                components={{ Option: CustomOption }}
                isClearable={true}
              />
              <ReactSelect
                options={optionsSatutsAnnul}
                className=""
                classNamePrefix="status"
                placeholder="Annul"
                value={dropAnnul}
                styles={styleFilters()}
                onChange={(selectedOption) => {
                  setShowBtnFilter(true);
                  setDropAnnul(selectedOption);
                  setObjToFetch((prevState) => {
                    const updatedFilters = { ...prevState.filters };

                    if (selectedOption) {
                      // If an option is selected, update the key with selectedOption.Tofetch and value with selectedOption.checked
                      updatedFilters[selectedOption.Tofetch] =
                        selectedOption.checked;
                    } else if (dropAnnul) {
                      // If no option is selected and dropAnnul has a previous value, remove the key based on dropAnnul.Tofetch
                      delete updatedFilters[dropAnnul.Tofetch];
                    }

                    return {
                      ...prevState,
                      filters: updatedFilters,
                    };
                  });
                }}
                components={{ Option: CustomOption }}
                isClearable={true}
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <ReactSelect
                options={optionsSatutsEncaisse}
                className=""
                classNamePrefix="status"
                placeholder="Encaisse"
                value={dropEncaisse}
                styles={styleFilters()}
                onChange={(selectedOption) => {
                  setShowBtnFilter(true);
                  setDropEncaisse(selectedOption);
                  setObjToFetch((prevState) => {
                    const updatedFilters = { ...prevState.filters };

                    if (selectedOption) {
                      // If an option is selected, update the key with selectedOption.Tofetch and value with selectedOption.checked
                      updatedFilters[selectedOption.Tofetch] =
                        selectedOption.checked;
                    } else if (dropEncaisse) {
                      // If no option is selected and dropEncaisse has a previous value, remove the key based on dropEncaisse.Tofetch
                      delete updatedFilters[dropEncaisse.Tofetch];
                    }

                    return {
                      ...prevState,
                      filters: updatedFilters,
                    };
                  });
                }}
                components={{ Option: CustomOption }}
                isClearable={true}
              />
            </div>
            <div className="d-flex flex-column mx-2 justify-content-end">
              <div className="mx-4 mb-1">Contrats archivés</div>
              <div className="d-flex align-items-center ms-4 border-bot-filter me-3">
                <Form.Check
                  type="switch"
                  className="custom-switch-drop py-1"
                  style={{ transform: "scale(1)" }}
                  checked={ObjToFetch.filters.is_active === 0}
                  onChange={handleCheckboxChange}
                />
                <div className="m-0 text-archived">Non / oui</div>
              </div>
              <div className="d-flex align-items-center col-2 text-end mt-2 ">
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                  }}
                >
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    isClearable
                    placeholderText="Dates"
                    className="input_date_filter ms-2 pointer"
                    popperClassName="custom-datepicker-popper"
                    style={datePickerStyle} // Appliquer le style à l'input
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-calendar"
                    viewBox="0 0 16 16"
                    style={{
                      position: "absolute",
                      right: "-160px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                    }}
                  >
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-3a1 1 0 0 0-1 1v1h14V2a1 1 0 0 0-1-1H2z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex mt-2 justify-content-end align-items-center">
            <div className="d-flex align-items-center col-3 justify-content-end">
              {showBtnFilter && (
                <Button onClick={reinitFilters} className="p-2 me-5">
                  reinit.
                </Button>
              )}
              <Button
                onClick={() => SearchContrats({ isReinit: false })}
                className="p-2 px-3"
              >
                Search
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TableauCrmFilters;
