import Svgdown from "../../images/arrow-down-svgrepo-com.svg"
import Svgup from "../../images/arrow-up-svgrepo-com.svg"
import Svg from "../../images/filter-svgrepo-com.svg"


function arrowSort(NumberPositionArrow) {
  // console.log("NumberPositionArrow", NumberPositionArrow);

  let response;

  switch (NumberPositionArrow) {
    case 1:
      response = (
        <img src={Svgdown}></img>
        // <svg
        //   xmlns="http://www.w3.org/2000/svg"
        //   width="16"
        //   height="16"
        //   fill="currentColor"
        //   className="bi bi-caret-down-fill"
        //   viewBox="0 0 16 16"
        // >
        //   <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
        // </svg>
      );
      break;
    case 2:
      response = (
        <img src={Svgup}></img>
        // <svg
        //   xmlns="http://www.w3.org/2000/svg"
        //   width="16"
        //   height="16"
        //   fill="currentColor"
        //   className="bi bi-caret-up-fill"
        //   viewBox="0 0 16 16"
        // >
        //   <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
        // </svg>
      );
      break;
    case 3:
      response = (<img src={Svg}></img>);
      break;

    default:
      response = "";
  }

  return response;
}

export default arrowSort;
