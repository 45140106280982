import React, { useEffect, useState } from "react";
import { SVGICON } from "../../jsx/constant/theme";
import ExpensesBarChart from "./Graph/ExpensesBarChart";
import NewExpChart from "./Graph/NewExpChart2";
import avatar from "../../images/avataaars.svg";
import avatar1 from "../../images/avataaars (1).svg";
import avatar2 from "../../images/avataaars (2).svg";
import FondPodiumOk from "../../images/photo_2024-04-30_18-39-37.jpg";
import ImpressionBar from "./Graph/ImpressionBar2";
import TableauConvertion from "./Graph/TableauConvertion2";
import { useQuery, gql } from "@apollo/client";
import GraphRatioByAgent from "./Graph/GraphRatioByAgent2"
import ProgressBarDesk from "./Graph/ProgressBarDesk";
import { getSockets } from "../Redux/slices/notifSocket.slice";
import { getToken } from "../Redux/slices/admin.slice";
import { useSelector } from "react-redux";
import { Col, Card } from "react-bootstrap";
import GraphDernierContrat from "./Graph/GraphDernierContrat3";
import GraphNombreContrat from "./Graph/GraphNombreContrat4";
import TableauGeneral from "./Graph/TableauGeneral";

const GET_SIGNED_NUMBERS_TABLE = gql`
  query GetSignedNumbersTable {
    getSignedNumbersTable {
      active_sellers_count
      average_amount_seller
      total_amount
    }
  }
`;

const GET_ENCAISSER_TABLE = gql`
  query GetEncaisserTable {
    getEncaisserTable {
      average_per_agent
    percentage_increase
    total_active_agents
    total_amount
    total_amount_last_month
    }
}`;

const GET_RATION_AND_CONVERSION = gql`
  query GetRationAndConversion {
    getRationAndConversion {
      conversion_count
      desk
      total_amount
      total_amount_encaisse
    }
}`;

const GET_PODIUM = gql`
  query GetPodium {
    getPodium {
      pseudo
      total_amount
    }
}`;

const WEEKLY_DATA = gql`
  query WeeklyData {
    weeklyData {
      totalGeneral

      totalsByDay {
        jeudi
        lundi
        mardi
        mercredi
        vendredi
      }

      weeklyDataArray {
        jeudi
        lundi
        mardi
        mercredi
        semaine
        vendredi
        totaux
      }
    
    }
  }
`;

const DeskDetail = () => {
  const GET_SOCKETS = useSelector(getSockets);
  const GET_TOKEN = useSelector(getToken);


  const HelpRequest = {
    context: {
      headers: {
        Authorization: `Bearer ${GET_TOKEN}`,
      }
    }
  }


  const { data, refetch } = useQuery(GET_SIGNED_NUMBERS_TABLE, HelpRequest);
  const { data: dataEncaisser, refetch: refetchEncaisser } = useQuery(GET_ENCAISSER_TABLE, HelpRequest);
  const { data: dataRatioConversion, refetch: refetchRatioConversion } = useQuery(GET_RATION_AND_CONVERSION, HelpRequest);
  const { data: dataPodium, refetch: refetchPodium } = useQuery(GET_PODIUM, HelpRequest);
  const { data: dataWeeklyData, refetch: refetchWeeklyData } = useQuery(WEEKLY_DATA, HelpRequest);

  const [stockDataSigned, setStockDataSigned] = useState();
  const [stockDataEncaisser, setStockDataEncaisser] = useState();
  const [stockDataRatioConversion, setDataRatioConversion] = useState();

  const [flash, setFlash] = useState(false);
  const [flashEncaisser, setFlashEncaisser] = useState(false);

  useEffect(() => {

    refetch();
    refetchEncaisser();
    refetchRatioConversion();
    refetchPodium();
    refetchWeeklyData();
    // Relance la requête GET_SIGNED_NUMBERS_TABLE
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GET_SOCKETS]);

  useEffect(() => {
    if (data) {
      console.log(data?.getSignedNumbersTable)
      setStockDataSigned(data.getSignedNumbersTable);
      setFlash(true);
      setTimeout(() => setFlash(false), 100);
    }
  }, [data]);

  useEffect(() => {
    if (dataEncaisser) {
      setStockDataEncaisser(dataEncaisser.getEncaisserTable);
      setFlashEncaisser(true);
      setTimeout(() => setFlashEncaisser(false), 100);
    }
  }, [dataEncaisser]);

  useEffect(() => {
    console.log("dataRatioConversion", dataRatioConversion?.getRationAndConversion)
    if (dataRatioConversion) {
      const results = dataRatioConversion.getRationAndConversion;
      const desks = results?.map(result => "Desk " + result.desk);
      const total_amounts = results?.map(result => result.total_amount);
      const total_amounts_encaisse = results?.map(result => result.total_amount_encaisse);
      const conversion_counts = results?.map(result => result.conversion_count);

      const transformedResults = {
        desk: desks,
        total_amount: total_amounts,
        total_amount_encaisse: total_amounts_encaisse,
        conversion_count: conversion_counts
      };
      setDataRatioConversion(transformedResults)
    }
  }, [dataRatioConversion]);


  function formatNumber(num) {
    let response;
    num = parseFloat(num); // Convert string to number

    if (isNaN(num)) {
      response = num;
      console.log(response);
      return response;
    }

    response = num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return response;
  }

  return (
    <>

      <div className="row">

        {stockDataEncaisser &&
          <ProgressBarDesk
            data={stockDataEncaisser}
          />
        }
        

        <div className="col-6">

          <div className="row">
            <div className="col-6">
            { dataWeeklyData &&
            <TableauGeneral
              dataWeeklyData={dataWeeklyData?.weeklyData}
            />
          }
            </div>



            <div className="col-6">
              <div className="card crm-cart">
                <div className="card-body h-50">
                  <div className="crm-cart-data">
                    <h5 className="d-block mb-1 text-black">
                      Moyenne chiffre par Agent (signé)
                    </h5>
                    <p  > <span style={{ backgroundColor: flash ? 'yellow' : 'transparent' }} >{(formatNumber(stockDataSigned?.average_amount_seller))}</span>  €</p>
                  </div>
                </div>
                {/* <NewExpChart color={colorMap['green']}/> */}
                <NewExpChart color={"green"} />
              </div>
              <div className="card crm-cart">
                <div className="card-header border-0 pb-0 diposit-bg">
                  <h5 className="d-block text-black">Total (Signé)</h5>
                  <div className="icon-box">{SVGICON.DollerBlack}</div>
                </div>
                <div className="card-body py-0">
                  <div className="crm-cart-data d-flex align-items-center">
                    <p > <span style={{ backgroundColor: flash ? 'yellow' : 'transparent' }}>{stockDataSigned && formatNumber(stockDataSigned?.total_amount)}</span>    €</p>
                    <span className={`fs-20 fw-bold ${stockDataSigned?.percentage_change >= 0 ? 'text-green' : 'text-red'}`}>
                      ({stockDataSigned?.percentage_change >= 0 ? '+' : '-'}{stockDataSigned?.percentage_change}%)
                    </span>
                  </div>
                  <ImpressionBar />
                </div>
              </div>
            </div>


          </div>


        </div>

        <div className="col-6">
          <div className="row">
            <div className="col-6">
              <div className="card crm-cart">
                <div className="card-body">
                  <div className="crm-cart-data">
                    <h5 className="d-block mb-1 text-black">
                      Moyenne par Agent (Encaissement)
                    </h5>
                    <p>
                      <span style={{ backgroundColor: flashEncaisser ? 'yellow' : 'transparent' }} >
                        {stockDataEncaisser && formatNumber(stockDataEncaisser?.average_per_agent)}
                      </span> €
                    </p>
                  </div>
                </div>
                {/* <NewExpChart color={colorMap['blue']}/> */}
                <NewExpChart color={"blue"} />
              </div>
              <div className="card crm-cart">
                <div className="card-header border-0 pb-0 diposit-bg">
                  <h5 className="d-block text-black">Total (encaissé)</h5>
                  <div className="icon-box">{SVGICON.DollerBlack}</div>
                </div>
                <div className="card-body py-0">
                  <div className="crm-cart-data d-flex align-items-center">
                    {<p> <span style={{ backgroundColor: flashEncaisser ? 'yellow' : 'transparent' }} >{stockDataEncaisser && formatNumber(stockDataEncaisser?.total_amount)}</span>  €</p>}
                    <span className={`fs-20 fw-bold ${stockDataEncaisser?.percentage_increase >= 0 ? 'text-green' : 'text-red'}`}>
                      ({stockDataEncaisser?.percentage_increase >= 0 ? '+' : '-'}{stockDataEncaisser?.percentage_increase}%)
                    </span>
                  </div>
                  <ExpensesBarChart />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div
                className="card crm-cart"
                style={{
                  backgroundImage: `url(${FondPodiumOk})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  className="d-flex text-white fs-5 justify-content-around align-items-end mx-5 px-2"
                  style={{ height: "65%" }}
                >
                  <div className="mb-2">
                    <img
                      src={avatar1}
                      title={dataPodium?.getPodium && dataPodium?.getPodium[2]?.pseudo + "\n " + formatNumber(dataPodium?.getPodium[2]?.total_amount) + " €"}
                      alt=""
                      style={{
                        height: "70px",
                        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <img
                      src={avatar}
                      alt=""
                      title={dataPodium?.getPodium && dataPodium?.getPodium[0]?.pseudo + "\n " + formatNumber(dataPodium?.getPodium[0]?.total_amount) + " €"}
                      style={{
                        height: "90px",
                        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="">
                    <img
                      src={avatar2}
                      title={dataPodium?.getPodium && dataPodium?.getPodium[1]?.pseudo + "\n " + formatNumber(dataPodium?.getPodium[1]?.total_amount) + " €"}
                      alt=""
                      style={{
                        height: "60px",
                        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="" style={{ height: 220 }}>
                <Card>
                  <Card.Header className="fw-bold text-black fs-4">
                    Nb de converstions
                  </Card.Header>
                  {stockDataRatioConversion &&
                    <TableauConvertion
                      horizontal={false}
                      data={stockDataRatioConversion?.conversion_count}
                      arrCategories={stockDataRatioConversion?.desk}
                    />
                  }
                </Card>
              </div>
            </div>
          </div>
        </div>

      </div>


      <div className="d-flex">
        <Card className="col-6">
          <Card.Header>
            <Card.Title>Ratio desks </Card.Title>
          </Card.Header>
          {stockDataRatioConversion &&
            <GraphRatioByAgent
              height={"400px"}
              arrEncaisse={stockDataRatioConversion?.total_amount_encaisse}
              arrSigned={stockDataRatioConversion?.total_amount}
              arrCategories={stockDataRatioConversion?.desk}
            />
          }
        </Card>
        <Col sm={3} >
          <Card>
            <Card.Header>
              <h4 className="card-title">Nombre Contrats(jour)</h4>
            </Card.Header>
            <Card.Body className="p-0 d-flex align-items-end">
              <GraphDernierContrat />
            </Card.Body>
          </Card>
        </Col>
        
        <Col sm={3} >
          <Card>
            <Card.Header>
              <h4 className="card-title">Nombre contrats (mois)</h4>
            </Card.Header>
            <Card.Body className="p-0">
              <GraphNombreContrat />
            </Card.Body>
          </Card>
        </Col>
      </div>
      
    </>
  );
};

export default DeskDetail;