import React from "react";
import ReactApexChart from "react-apexcharts";

class GraphNombreContrat extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);

    // Combiner arrData et arrCategorie
    const combinedData = props.arrData.map((data, index) => ({
      data,
      category: props.arrCategorie[index],
    }));

    // Trier les données combinées par data du plus grand au plus petit
    combinedData.sort((a, b) => b.data - a.data);

    // Séparer les données triées en arrData et arrCategorie
    const sortedArrData = combinedData.map(item => item.data);
    const sortedArrCategorie = combinedData.map(item => item.category);

    this.state = {
      series: [
        {
          name: "contracts en nombre",
          data: sortedArrData,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 230,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 3,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["var(--bs-purple)"],
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: "12px",
            colors: ["black"],
          },
        },
        stroke: {
          show: false,
        },
        yaxis: {
          lines: {
            show: false,
          },
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          },
        },
        xaxis: {
          show: false,
          categories: sortedArrCategorie,
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          },
        },
      },
    };
  }

  componentDidUpdate(prevProps) {
    // Vérifiez si arrData ou arrCategorie a changé
    if (this.props.arrData !== prevProps.arrData || this.props.arrCategorie !== prevProps.arrCategorie) {
      // Combiner arrData et arrCategorie
      const combinedData = this.props.arrData.map((data, index) => ({
        data,
        category: this.props.arrCategorie[index],
      }));

      // Trier les données combinées par data du plus grand au plus petit
      combinedData.sort((a, b) => b.data - a.data);

      // Séparer les données triées en arrData et arrCategorie
      const sortedArrData = combinedData.map(item => item.data);
      const sortedArrCategorie = combinedData.map(item => item.category);

      // Mettez à jour l'état avec les nouvelles props triées
      this.setState({
        series: [
          {
            name: "contracts en nombre",
            data: sortedArrData,
          },
        ],
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: sortedArrCategorie,
          },
        },
      });
    }
  }

  render() {
    return (
      <div id="chart" className="line-chart-style bar-chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={400}
        />
      </div>
    );
  }
}

export default GraphNombreContrat;