import React from "react";
import icon from '../../../images/browser/10596559.png';

import ReactApexChart from "react-apexcharts";

class GraphDernierContrat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "jours",
          data: [0, 0, 0, 0, 2, 2, 4, 5, 10, 17],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 230,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 3,
            dataLabels: {
              position: "top",
            },
          },
        },
        // colors: ["#ff7c7c"],var(--primary)
        colors: ["var(--bs-danger)"],
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: "12px",
            colors: ["black"],
          },
        },
        stroke: {
          show: false,
        },
        yaxis: {
          lines: {
            show: false,
          },
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          },
        },
        xaxis: {
          show: false,
          categories: ['Desk1', 'Desk2', 'Desk3','Desk4','Desk5','Desk6','Desk7','Desk8','Desk9'],
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          },
        },
      },
    };
  }

  render() {
    return (
      // <div id="chart" className="line-chart-style bar-chart"  style={{
      //   backgroundImage: `url(${icon})`,
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "160px 160px",
      //   backgroundPosition: "top", 
      //   backgroundBlendMode: "overlay",
      //   width: '600px',
      // }}>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={300}
          className="w-100"
        />
      // </div>
    );
  }
}
export default GraphDernierContrat;
