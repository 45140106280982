function sortTableSupp(arg, type, data, statuSort, dispatch, setter) {
  const dataCopy = [...data];
  
  switch (type) {
    case "int":
      if (statuSort === 1) {
        // Sort descending
        dataCopy.sort((a, b) => {
          if (a[arg] === null) return 1;
          if (b[arg] === null) return -1;
          return b[arg] - a[arg];
        });
      } else if (statuSort === 2) {
        // Sort ascending
        dataCopy.sort((a, b) => {
          if (a[arg] === null) return 1;
          if (b[arg] === null) return -1;
          return a[arg] - b[arg];
        });
      } else if (statuSort === 3) {
        // Special sorting for total_encaisse only when it's the argument
        dataCopy.sort((a, b) => {
          if (a['total_encaisse'] === null) return 1;
          if (b['total_encaisse'] === null) return -1;
          return b['total_encaisse'] - a['total_encaisse'];
        });
      }
      break;

    case "string":
      if (statuSort === 1) {
        // Sort descending
        dataCopy.sort((a, b) => {
          if (a[arg] === null) return 1;
          if (b[arg] === null) return -1;
          return b[arg].localeCompare(a[arg]);
        });
      } else if (statuSort === 2) {
        // Sort ascending
        dataCopy.sort((a, b) => {
          if (a[arg] === null) return 1;
          if (b[arg] === null) return -1;
          return a[arg].localeCompare(b[arg]);
        });
      } else if (statuSort === 3) {
        // Special sorting for total_encaisse only when it's the argument
        dataCopy.sort((a, b) => {
          if (a['total_encaisse'] === null) return 1;
          if (b['total_encaisse'] === null) return -1;
          return b['total_encaisse'] - a['total_encaisse'];
        });
      }
      break;

    default:
      break;
  }

  dispatch(setter(dataCopy));
}

export default sortTableSupp;