import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import UpdateTargetAdmin from "../../Components/Modals/UpdateTargetAdmin";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import axios from "axios";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Targetperso = () => {
  // const [checkedItems, setCheckedItems] = useState(0);
  // const [isAnyChecked, setIsAnyChecked] = useState(false);
  const [showUpdateTarget, setShowUpdateTarget] = useState(false);
  const [ListByDesk, setListByDesk] = useState([]);
  const [listAdminSelected, setListAdminSelected] = useState([]);

  const AdminDetails = useSelector(getAdminDetails);
  const Desk = AdminDetails?.desk;
  const { desk } = useParams();

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const initializeTargets = (members) => {
    return members.map((member) => {
      while (member.targets.length < 3) {
        member.targets.push({
          objectif: 0,
          cash: 0,
          idtargets: "",
          desk: member.desk,
          user_id: member.idusers,
        });
      }
      return member;
    });
  };

  const fetchListByDesk = () => {
    let obj = {
      desk: desk || Desk.join(", "),
      categorieProfil: "SUPPORT",
    };
    axios
      .post(`${config.apiAdmin}/sadmin/targets/read_by_desk_month`, obj, Header)
      .then((response) => {
        console.log(response.data);
        const initializedData = initializeTargets(response.data);
        setListByDesk(initializedData);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    fetchListByDesk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const member = JSON.parse(event.target.value);
    if (event.target.checked) {
      setListAdminSelected((prevList) => [...prevList, member]);
    } else {
      setListAdminSelected((prevList) =>
        prevList.filter((m) => m.pseudo !== member.pseudo)
      );
    }
  };

  const ShowModalUpdateAdmin = () => {
    setShowUpdateTarget(true);
  };

  const CloseModalUpdateAdmin = () => {
    setShowUpdateTarget(false); // Mettre à jour l'état pour afficher le modal
    fetchListByDesk();
  };


  return (
    <div>
      <div className="mt-4 pt-2 pb-2 border-bottom d-flex align-items-center justify-content-between">
        <div>
          <div className="ms-5 fw-bold text-black">Parametrage des targets</div>
          <div className="ms-5 text-black">
            Cette page vous permet d’ajouter des objectifs individuel.
          </div>
        </div>
        {listAdminSelected?.length > 0 && (
          <div
            className="border me-5 px-4 rounded-3 bg-green-target text-black fw-bold"
            onClick={ShowModalUpdateAdmin}
            style={{ cursor: "pointer" }}
          >
            Modifier
          </div>
        )}
      </div>
      <Row className="mt-5 ms-4">
        {ListByDesk?.map((member) => (
          <Col lg={3} md={6} sm={12} className="mb-4" key={member?.idusers}>
            <Card style={{ width: "18rem", borderRadius: "20px" }}>
              <div className="">
                <Form.Check
                  type="checkbox"
                  id={`checkbox-${member.idusers}`}
                  className="bg-white rounded mt-2 ms-3"
                  value={JSON.stringify(member)}
                  onChange={handleChange}
                />
              </div>
              <Card.Body className="p-0">
                <Card.Title className="text-center m-0">
                  {member?.pseudo}
                </Card.Title>
                <Card.Text className="col-12 d-flex justify-content-center">
                  <span
                    className={`badge-list-admin badge-${member?.profil} text-white fs-12`}
                  >
                    {member?.profil}
                  </span>
                </Card.Text>
                <div className="mx-2 mt-3">
                  <div className="d-flex justify-content-around mt-1 text-black">
                    {member?.targets.map((target, index) => (
                      <div key={index}>
                        {target?.objectif === 0
                          ? "no target"
                          : `${target?.objectif}$`}
                      </div>
                    ))}
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center my-1 suivi-target-perso mb-4"
                    style={{ height: "50px" }}
                  >
                    <div className="col-1"></div>
                    <div
                      className={`rounded-5 col-2 d-flex align-items-center justify-content-center fs-3 fw-bold text-black ${
                        member.targets[0]?.objectif &&
                        parseFloat(member.SumEncaiss) >
                          member.targets[0].objectif
                          ? "bg-green-target"
                          : "bg-grey-target"
                      }`}
                      style={{ height: "50px", width: "50px" }}
                    >
                      1
                    </div>
                    <div className="col-2 entre-deux"></div>
                    <div
                      className={`rounded-5 col-2 d-flex align-items-center justify-content-center fs-3 fw-bold text-black ${
                        member.targets[1]?.objectif &&
                        parseFloat(member.SumEncaiss) >
                          member.targets[1].objectif
                          ? "bg-green-target"
                          : "bg-grey-target"
                      }`}
                      style={{ height: "50px", width: "50px" }}
                    >
                      2
                    </div>
                    <div className="col-2 entre-deux"></div>
                    <div
                      className={`rounded-5 col-2 d-flex align-items-center justify-content-center fs-3 fw-bold text-black ${
                        member.targets[2]?.objectif &&
                        parseFloat(member.SumEncaiss) >
                          member.targets[2].objectif
                          ? "bg-green-target"
                          : "bg-grey-target"
                      }`}
                      style={{ height: "50px", width: "50px" }}
                    >
                      3
                    </div>
                    <div className="col-1"></div>
                  </div>
                  <div className="text-center text-black">
                    Somme encaissé : {member.SumEncaiss} $
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {showUpdateTarget && (
          <UpdateTargetAdmin
            show={showUpdateTarget}
            onHide={CloseModalUpdateAdmin}
            data={listAdminSelected}
          />
        )}
      </Row>
    </div>
  );
};

export default Targetperso;
