import React from "react";
// import { Dropdown, Modal, Col, Card, Table, Badge } from "react-bootstrap";
// import { SVGICON } from "../../../jsx/constant/theme";
// import GraphMomoDesk from "./Graph/GraphMomoDesk";
import formatNumber from "../../../utils/formatNumber";
import colorDesk from "../../../utils/colorDesk";

const RecapAllDesk = ({datAmountByDesk}) => {
 // console.log(colorDesk(1));
  //console.log(datAmountByDesk, "datAmountByDesk")
  
  return (
    <div className="card p-0">
      <div className="card-body">
        <div
          className="row "
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gridAutoRows: "auto",
            gridGap: "10px",
          }}
        >
          {datAmountByDesk?.statAmountByDesk?.map((item, ind) => (
            <div className="" key={ind}>
              <div className={`card ${colorDesk(item.desk)}`}>
                <div className=" p-2 border-0 fs-5 text-black fw-bold">
                  <div className="d-flex">
                    <div className="col-2 text-center fs-3 border rounded bg-white">
                      {ind+1}
                    </div>
                    <div className="fs-3 ms-2">Desk : {item.desk}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>Total :</div>
                    <div>     {item.amount_sum && formatNumber(item.amount_sum)  }</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>Nb contrats :</div>
                    <div> {item.contract_count}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>encaissement :</div>
                    <div>  $</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>encaissement % :</div>
                    <div> {item.encaisse_rate}  %</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>Nb convertion :</div>
                    <div> {item.conversion_sum}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>report/annul :</div>
                    <div> {item.mix_rate} % </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-red-desk1-bar"
                  style={{ width: "90%" }}
                ></div>
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="d-flex justify-content-between">
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-orange-desk2-bar"
                  style={{ width: "80%" }}
                ></div>
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="d-flex justify-content-between">
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-greenl-desk3-bar"
                  style={{ width: "70%" }}
                ></div>
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="d-flex justify-content-between">
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-blue-desk4-bar"
                  style={{ width: "65%" }}
                ></div>
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="d-flex justify-content-between">
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-yellow-desk5-bar"
                  style={{ width: "60%" }}
                ></div>
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="d-flex justify-content-between">
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-green-desk6-bar"
                  style={{ width: "55%" }}
                ></div>
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="d-flex justify-content-between">
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-purple-desk7-bar"
                  style={{ width: "40%" }}
                ></div>
              </div>
            </div>
          </div> */}
          {/* <TrafficUpdateBar /> */}
        </div>
      </div>
    </div>
  );
};

export default RecapAllDesk;
