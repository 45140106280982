import React from "react";
import ReactApexChart from "react-apexcharts";

class GraphRatioByAgent extends React.Component {
  constructor(props) {
    console.log("props-=-=-=-=-=-=-=-=-=-=->", props);
    super(props);

    const numberArray = this.props?.arrtotal_esperance;
    const stringArray = this.props?.arrCategories;
    const targets = this.props.TargetsPerso;

    console.log(stringArray);
    console.log(numberArray);
    console.log(targets);

    const generateYaxisAnnotations = (targets) => {
      const colors = ["#FEB019", "#4687dd", "#5cffa6"]; // Couleurs pour les annotations
      return targets?.map((target, index) => {
        if (target.objectif === 0) {
          return null;
        }
        return {
          y: index === 0 ? 0 : targets[index - 1].objectif,
          y2: target.objectif,
          borderColor: "#000",
          fillColor: colors[index % colors.length],
          label: {
            text: `T${index + 1}: ${target.objectif.toLocaleString("fr-FR")} $`,
            style: {
              fontWeight: "800",
            },
          },
          zIndex: 9999,
        };
      });
    };

    const yaxisAnnotations = generateYaxisAnnotations(targets);

    const annotations = {
      points: numberArray?.map((sum, index) => ({
        x: stringArray[index], // Position above the corresponding category
        y: sum, // Align the annotation above the bar
        marker: {
          size: 0, // No marker point
        },
        label: {
          style: {
            color: "black",
            background: "white",
          },
          text: `${Number(sum).toLocaleString("fr-FR")} $`,
          position: "top", // Position the label above the bar
          offsetY: -10, // Adjust the offset to place it neatly above the bar
        },
      })),
      yaxis: yaxisAnnotations,
    };

    this.state = {
      taille: this.props.height,
      series: [
        {
          name: "Encaisse",
          data: this.props?.arrEncaisse,
        },
        {
          name: "Preuve",
          data: this.props?.arrProof,
        },
        {
          name: "Signe",
          data: this.props?.arrSigned,
        },
        {
          name: "Reporte",
          data: this.props?.arrReported,
        },
        {
          name: "Annule",
          data: this.props?.arrCanceled,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 230,
          toolbar: {
            show: false,
          },
          stacked: true,
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            horizontal: false,
            dataLabels: {
              position: "top",
            },
            stacked: true,
          },
        },
        colors: ["#10d078", "#fcef73", "#ffa500", "#a569bd", "#5d6d7e"],
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val?.toLocaleString("fr-FR") + " $";
          },
          offsetX: 0,
          style: {
            fontSize: "12px",
            colors: ["black"],
          },
        },
        stroke: {
          show: false,
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return "$ " + val?.toLocaleString("fr-FR");
            },
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              // Modifiez la taille de la police des labels de l'axe x
            },
          },
          lines: {
            show: false,
          },
        },
        xaxis: {
          show: false,
          categories: this.props?.arrCategories,
          labels: {
            style: {
              fontSize: "14px",
              fontWeight: "bold",
            },
          },
        },
        annotations: annotations,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.arrEncaisse !== this.props.arrEncaisse ||
      prevProps.arrSigned !== this.props.arrSigned ||
      prevProps.arrProof !== this.props.arrProof ||
      prevProps.arrReported !== this.props.arrReported ||
      prevProps.arrCanceled !== this.props.arrCanceled ||
      prevProps.arrtotal_esperance !== this.props.arrtotal_esperance ||
      prevProps.arrCategories !== this.props.arrCategories ||
      prevProps.TargetsPerso !== this.props.TargetsPerso
    ) {
      const numberArray = this.props?.arrtotal_esperance;
      const stringArray = this.props?.arrCategories;
      const targets = this.props.TargetsPerso;

      const generateYaxisAnnotations = (targets) => {
        const colors = ["#FEB019", "#4687dd", "#5cffa6"]; // Couleurs pour les annotations
        return targets?.map((target, index) => {
          if (target.objectif === 0) {
            return null;
          }
          return {
            y: index === 0 ? 0 : targets[index - 1].objectif,
            y2: target.objectif,
            borderColor: "#000",
            fillColor: colors[index % colors.length],
            label: {
              text: `T${index + 1}: ${target.objectif.toLocaleString("fr-FR")} $`,
              style: {
                fontWeight: "800",
              },
            },
            zIndex: 9999,
          };
        });
      };

      const yaxisAnnotations = generateYaxisAnnotations(targets);

      const annotations = {
        points: numberArray?.map((sum, index) => ({
          x: stringArray[index], // Position above the corresponding category
          y: sum, // Align the annotation above the bar
          marker: {
            size: 0, // No marker point
          },
          label: {
            style: {
              color: "black",
              background: "white",
            },
            text: `${Number(sum).toLocaleString("fr-FR")} $`,
            position: "top", // Position the label above the bar
            offsetY: -10, // Adjust the offset to place it neatly above the bar
          },
        })),
        yaxis: yaxisAnnotations,
      };

      this.setState({
        series: [
          {
            name: "Encaisse",
            data: this.props?.arrEncaisse,
          },
          {
            name: "Preuve",
            data: this.props?.arrProof,
          },
          {
            name: "Signe",
            data: this.props?.arrSigned,
          },
          {
            name: "Reporte",
            data: this.props?.arrReported,
          },
          {
            name: "Annule",
            data: this.props?.arrCanceled,
          },
        ],
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: this.props?.arrCategories,
          },
          annotations: annotations,
        },
      });
    }
  }

  render() {
    return (
      <div id="chart" className="line-chart-style bar-chart h-100">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={this.state.taille}
        />
      </div>
    );
  }
}

export default GraphRatioByAgent;