import React from 'react'
import TableauSupport from './Graph/TableauSupport'



const Dashboard = () => {
  return (
    <>
      <TableauSupport/>
    </>
  )
}

export default Dashboard
