import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import ConvNotes from "./ConvNotes";
import svgSend from "../../../images/send-svgrepo-com.svg";

const Notes = (data) => {
  const [notesByContrat, setNotesByContrat] = useState([]);
  const [newNotes, setNewNotes] = useState([]);

  const detailContrat = data?.contrat;
  const IdContrat = detailContrat?.idcontracts;
  const deskContrat = detailContrat?.desk;
  const customer = detailContrat?.customer_id
  const seller_id = detailContrat?.seller_id;


  const detailUser = useSelector(getAdminDetails);
  const id_id = detailUser.idusers
  const agt_supp_id = detailUser?.agt_supp_id;
  const categorie_id = detailUser?.categorie;

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const fetchData = () => {
    let obj = {
      id_contract: IdContrat,
    };
    axios
      .post(
        `${config.apiAdmin}/sadmin/comments/read_all_by_contracts_with_pseudo`,
        obj,
        Header
      )
      .then((response) => {
        setNotesByContrat(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // let obj = {
    //   content: newNotes,
    //   contrat: detailContrat,
    // };
    let obj = {
      content: newNotes,
      user_id: id_id,
      contract_id: IdContrat,
      customer_id: customer,
      mng_supp_id: "",
      agt_supp_id: "",
      tlead_id: "",
      sell_id: "",
    };
    axios
      .put(`${config.apiAdmin}/sadmin/comments/create`, obj, Header)
      .then((response) => {
        // setComsByContrat([...comsByContrat, response.data]);
        fetchData();
      })
      .catch((error) => {
        console.log(error);
      });
    setNewNotes("");
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMessageChange = (event) => {
    const value = event.target.value;
    setNewNotes(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // Permet d'utiliser Shift+Enter pour sauter une ligne
      e.preventDefault(); // Empêche le saut de ligne dans le textarea
      handleSubmit(e); // Appelle la fonction de soumission du formulaire
    }
  };

  return (
    <div
      className="h-100 d-flex flex-column justify-content-end  text-white"
      style={{ overflow: "hidden" }}
    >
      <div
        className="d-flex flex-column justify-content-end"
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        <ConvNotes data={notesByContrat} fetchData={fetchData} />
        {categorie_id === "SUPPORT" && (
          <div className="d-flex align-items-center bg-white mx-3 my-3 rounded">
            <textarea
              value={newNotes}
              onChange={handleMessageChange}
              onKeyPress={handleKeyPress}
              className="auto-resizing-textarea rounded border-0 p-2"
              placeholder="Tapez votre message"
            />
            <div
              onClick={handleSubmit}
              className="col-3 h-100 d-flex align-items-center justify-content-end pointer pe-3"
            >
              <img src={svgSend} alt="" className="rounded" />
            </div>
          </div>
         )}
      </div>
    </div>
  );
};

export default Notes;
