import React, { useState } from "react";
import UtilisateursList from "./UtilisateursList";
import UtilisateursActive from "./UtilisateursActive";

const Params = () => {
  const [activeComponent, setActiveComponent] = useState('UtilisateursList');
  const [activeButton, setActiveButton] = useState('Utilisateurs');

  return (
    <div className="" style={{height:"700px"}}>
      <div className="d-flex border-bottom">
        <div 
          className={`col-auto mx-5 text-center cursor-pointer fs-16 h-100 ${activeButton === 'Utilisateurs' ? 'underline' : ''}`} 
          onClick={() => {setActiveComponent('UtilisateursList'); setActiveButton('Utilisateurs');}}
        >
          Utilisateurs
        </div>
        <div 
          className={`col-auto mx-5 text-center cursor-pointer fs-16 h-100 ${activeButton === 'Activer des Utilisateurs' ? 'underline' : ''}`} 
          onClick={() => {setActiveComponent('UtilisateursActive'); setActiveButton('Activer des Utilisateurs');}}
        >
          Activer des Utilisateurs
        </div>
      </div>
      {activeComponent === 'UtilisateursList' && <UtilisateursList />}
      {activeComponent === 'UtilisateursActive' && <UtilisateursActive />}
    </div>
  );
};

export default Params;