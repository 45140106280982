import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config/config";
import ReactSelect from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { setAllContracts } from "../../Redux/slices/contract.slice";
import { useParams } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DropAddContrat = ({ isVisible, onHide, data }) => {
  const [nomClient, setNomClient] = useState("");
  const [prenomClient, setPrenomClient] = useState("");
  const [brandClient, setBrandClient] = useState("");
  const [amountClient, setAmountClient] = useState("");
  // const [IsConvertion, setIsConvertion] = useState(0);
  const [dateEcheance, setDateEcheance] = useState("");
  const [pspClient, setPspClient] = useState("");
  const [neoClient, setNeoClient] = useState("");
  const [bankClient, setBankClient] = useState("");
  const [argent_dispoClient, setArgent_dispoClient] = useState(0);
  const [preuveClient, setPreuveClient] = useState(0);
  const [next_callClient, setNext_callClient] = useState("");
  const [hoursClient, setHoursClient] = useState(null);
  const [encaisserClient, setEncaisserClient] = useState(0);
  const [reportClient, setReportClient] = useState(0);
  const [annulClient, setAnnulClient] = useState(0);

  const [psp, setPsp] = useState([]);
  const [neoBank, setNeoBank] = useState([]);
  const [customerBank, setCustomerBank] = useState([]);

  const [listPseudo, setListPseudo] = useState([]);
  const [pseudoSel, setPseudoSel] = useState("");
  console.log(pseudoSel)
  const [customer_id, setCustomer_id] = useState("");

  const [error, setError] = useState(false);

  useEffect(() => {
    if (isVisible) {
      handleShowFilters();
      setError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleShowFilters = () => {
    FetchFiltersGet("/psp/read_all", setPsp);
    FetchFiltersGet("/neobanks/read_all", setNeoBank);
    FetchFiltersGet("/customerbanks/read_all", setCustomerBank);
  };
  const FetchFiltersGet = (url, fonction) => {
    axios
      .get(`${config.apiAdmin}/sadmin${url}`, Header)
      .then((response) => {
        fonction(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  let DesksAdmin = useParams().desk;
  let Desk = (DesksAdmin = DesksAdmin || null);
  console.log(Desk)
  const AdminDetails = useSelector(getAdminDetails);
  console.log(AdminDetails)
  const desksToAdmin = AdminDetails?.desk;

  const dispatch = useDispatch();

  let deskFinal = {};
  let deskUpdate = {};

  if (Desk === null) {
    // Si Desk est null, deskFinal sera desksToAdmin qui est un array
    deskFinal = desksToAdmin;
    deskUpdate = desksToAdmin.join(",");
  } else if (typeof Desk === "string") {
    // Si Desk est une chaîne de caractères, convertissez-la en tableau
    deskFinal = [Desk];
    deskUpdate = Desk;
  } else if (Array.isArray(Desk)) {
    // Si Desk est déjà un tableau, ne le modifiez pas
    deskFinal = Desk;
  }


  const profilToAdmin = AdminDetails?.profil;
  const IdToAdmin = AdminDetails?.idusers;
  const brandToAdmin = AdminDetails?.brand;
  const tleadToAdmin = AdminDetails?.tlead_id;
  const agt_suppToAdmin = AdminDetails?.agt_supp_id;
  // const pseudoToAdmin = AdminDetails?.payload?.pseudo;
  const agt_supp_idToAdmin = AdminDetails?.agt_supp_id;


  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    if (isVisible) {
      let obj = {
        profil: "sell",
        desk: deskFinal,
      };
      axios
        .post(
          `${config.apiAdmin}/sadmin/users/users_by_profil_desk`,
          obj,
          Header
        )
        .then((response) => {
          setListPseudo(response.data);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const CreateContract = () => {

    let seller ;

    seller = profilToAdmin === "sell" ? AdminDetails.idusers : pseudoSel;

    let next_call ;

    next_call = next_callClient === "" ? null : new Date(`${next_callClient}T00:00:00`);

    let seller_id = "";
    let agt_supp_id = "";
    
    if (profilToAdmin === "sell") {
        seller_id = IdToAdmin;
        agt_supp_id = agt_supp_idToAdmin;
    } else if (profilToAdmin === "agt_supp") {
        agt_supp_id = IdToAdmin;
        seller_id = pseudoSel
    }
    // Desk
    
    let obj = {
      amount: amountClient,
      desk: deskUpdate,
      psp: pspClient,
      brand: brandClient,
      customerbank: bankClient,
      neobank: neoClient,

      // conversion: IsConvertion,
      paymentdudate: dateEcheance,
      argent_dispo: argent_dispoClient,
      preuve: preuveClient,
      encaisse: encaisserClient,
      is_report: reportClient,
      is_canceled: annulClient,
      next_call: next_call,
      hours: hoursClient,

      seller_id: seller,
      tlead_id: tleadToAdmin,
      agt_supp_id: agt_suppToAdmin,

      is_active: 1,
      customer: {
        firstname: prenomClient,
        lastname: nomClient,
        crm_id: customer_id,
      },
      desk_filter: deskUpdate,
      limit: '',
      seller_id_filter: seller_id,
      agt_supp_id_filter: agt_supp_id,
      user: profilToAdmin
    };
    console.log(obj)
    axios
      .put(
        `${config.apiAdmin}/sadmin/contracts/create-contract-and-customer-integrate`,
        obj,
        Header
      )
      .then((response) => {
        setError(false);
        dispatch(setAllContracts(response.data.allContracts.results))
        onHide();
        resetStates();
      })
      .catch((error) => {
        console.log("je suis la")
        // onHide();
        setError(true);
        // resetStates();
        console.error("There was an error!", error);
      });
  };

  const optionsBrands = brandToAdmin?.map((brand) => ({
    value: brand,
    label: brand,
  }));
  const optionsPsps = psp?.map((psp) => ({
    value: psp.name,
    label: psp.name,
  }));
  const optionsNeos = neoBank?.map((neo) => ({
    value: neo.name,
    label: neo.name,
  }));
  const optionsBankClients = customerBank?.map((bank) => ({
    value: bank.name,
    label: bank.name,
  }));
  const optionsPseudo = listPseudo?.map((pseudo) => {
    return {
      value: pseudo.idusers,
      label: pseudo.pseudo,
    };
  });

  function resetStates() {
    setPrenomClient("");
    setNomClient("");
    setBrandClient("");
    setAmountClient("");
    // setIsConvertion(0);
    setDateEcheance("");
    setPspClient("");
    setNeoClient("");
    setBankClient("");
    setArgent_dispoClient(0);
    setPreuveClient(0);
    setNext_callClient("");
    setHoursClient(null);
    setEncaisserClient(0);
    setReportClient(0);
    setAnnulClient(0);
    setListPseudo([]);
  }

  return (
    <Offcanvas
      show={isVisible}
      onHide={() => {
        onHide();
        resetStates();
      }}
      placement="end"
    >
      <Offcanvas.Header closeButton className="pb-0">
        <Offcanvas.Title>Creer Contrat</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="bg-info-light p-1">
          {profilToAdmin !== "sell" && (
            <div className="form-group mb-1">
              <ReactSelect
                options={optionsPseudo}
                className="basic-single"
                classNamePrefix="select"
                name="alias"
                 placeholder="Selectionnez pseudo du seller"
                onChange={(selectedOption) =>
                  setPseudoSel(selectedOption.value)
                }
              />
            </div>
          )}
          <div className="form-group mb-1">
            <input
              type="text"
              name="firstName"
              className="form-control"
              placeholder="Prénom du Client"
              required
              onChange={(e) => setPrenomClient(e.target.value)}
            />
            <input
              type="text"
              name="lastName"
              className="form-control"
              placeholder="Nom du Client"
              required
              onChange={(e) => setNomClient(e.target.value)}
            />
            <input
              type="text"
              name="Customer ID"
              className="form-control"
              placeholder="Customer ID"
              required
              onChange={(e) => setCustomer_id(e.target.value)}
            />
          </div>
          <div className="form-group mb-1">
            <ReactSelect
              options={optionsBrands}
              className="basic-single"
              classNamePrefix="select"
              name="brand"
              placeholder="Selectionnez votre Brand"
              onChange={(selectedOption) =>
                setBrandClient(selectedOption.value)
              }
            />
          </div>
          <div className="form-group mb-1">
            <input
              type="number"
              className="form-control"
              placeholder="MONTANT €"
              required
              onChange={(e) => setAmountClient(e.target.value)}
            />
          </div>

          <div className="">
            <DatePicker
              className="p-2 rounded border"
              placeholderText="Date d'échéance"
              selected={dateEcheance}
              onChange={(date) => setDateEcheance(date)}
            />
          </div>
        </div>
        {profilToAdmin !== "sell" &&
          profilToAdmin !== "ass_mng" &&
          profilToAdmin !== "manager" && (
            <>
              <div className="bg-info-light p-1">
                <div className="form-group mb-1">
                  <ReactSelect
                    options={optionsPsps}
                    className="basic-single"
                    classNamePrefix="select"
                    name="brand"
                    placeholder="Selectionnez votre Psp"
                    onChange={(selectedOption) =>
                      setPspClient(selectedOption.value)
                    }
                  />
                </div>
                <div className="form-group mb-1">
                  <ReactSelect
                    options={optionsNeos}
                    className="basic-single"
                    classNamePrefix="select"
                    name="neo"
                    placeholder="Selectionnez votre platforme"
                    onChange={(selectedOption) =>
                      setNeoClient(selectedOption.value)
                    }
                  />
                </div>
                <div className="form-group mb-1">
                  <label className="text-label d-flex align-items-center ms-2">
                    <input
                      type="checkbox"
                      name="checkboxName"
                      required
                      style={{ transform: "scale(1.5)" }}
                      onChange={(e) =>
                        setArgent_dispoClient(e.target.checked ? 1 : 0)
                      }
                    />
                    <p className="m-0 ps-2 fs-5">Argent chez le client</p>
                  </label>
                </div>
                <div className="form-group mb-1">
                  <ReactSelect
                    options={optionsBankClients}
                    className="basic-single"
                    classNamePrefix="select"
                    name="bank"
                    placeholder="Selectionnez la banque du client"
                    onChange={(selectedOption) =>
                      setBankClient(selectedOption.value)
                    }
                  />
                </div>
              </div>
              <div className="bg-info-light p-1">
                <div className="form-group mb-1">
                  <label className="text-label d-flex align-items-center ms-2">
                    <input
                      type="checkbox"
                      name="checkboxName"
                      required
                      style={{ transform: "scale(1.5)" }}
                      onChange={(e) =>
                        setPreuveClient(e.target.checked ? 1 : 0)
                      }
                    />
                    <p className="m-0 ps-2 fs-5">Preuve de virement</p>
                  </label>
                </div>
                <div className="form-group mb-1 d-flex">
                  <label htmlFor="" className="mt-2 col-3">
                    Prochain appel
                  </label>
                  <input
                    type="date"
                    name="date"
                    className="form-control"
                    required
                    onChange={(e) => setNext_callClient(e.target.value)}
                  />
                </div>
                <div className="form-group mb-1 d-flex align-items-center">
                  <label htmlFor="" className="mt-2 col-3">
                    Heure
                  </label>
                  <input
                    type="time"
                    name="time"
                    className="form-control"
                    required
                    onChange={(e) => setHoursClient(e.target.value)}
                  />
                </div>
              </div>
              <div className="bg-info-light p-1">
                <div className="form-group">
                  <label className="text-label d-flex align-items-center ms-2">
                    <input
                      type="checkbox"
                      name="checkboxName"
                      required
                      style={{ transform: "scale(1.5)" }}
                      onChange={(e) =>
                        setEncaisserClient(e.target.checked ? 1 : 0)
                      }
                    />
                    <p className="m-0 ps-2 fs-5">Encaisser</p>
                  </label>
                </div>
                <div className="form-group">
                  <label className="text-label d-flex align-items-center ms-2">
                    <input
                      type="checkbox"
                      name="checkboxName"
                      required
                      style={{ transform: "scale(1.5)" }}
                      onChange={(e) =>
                        setReportClient(e.target.checked ? 1 : 0)
                      }
                    />
                    <p className="m-0 ps-2 fs-5">Report mois pro.</p>
                  </label>
                </div>
                <div className="form-group">
                  <label className="text-label d-flex align-items-center ms-2">
                    <input
                      type="checkbox"
                      name="checkboxName"
                      required
                      style={{ transform: "scale(1.5)" }}
                      onChange={(e) => setAnnulClient(e.target.checked ? 1 : 0)}
                    />
                    <p className="m-0 ps-2 fs-5">Annulation</p>
                  </label>
                </div>
              </div>
            </>
          )}
        <div className="bg-info-light p-1 d-flex">
          <div className="border btn btn-info" onClick={() => CreateContract()}>
            envoi
          </div>
          {error && (
            <div className="text-red fs-16 m-2">Une erreur est survenue</div>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default DropAddContrat;
