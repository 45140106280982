import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactSelect from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {  useForm } from "react-hook-form";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import axios from "axios";
import config from "../../../config/config";
import { setPopup } from "../../Redux/slices/actions.slice";

const UpdateAdminByBossSupp = ({ show, closeModal, user }) => {
  const [profilsDB, setProfilsDB] = useState([]);
  const [brandUpdated, setBrandUpdated] = useState(false);
  const [deskUpdated, setDeskUpdated] = useState(false);
  const [profilUpdated, setProfilUpdated] = useState(false);

  const {  handleSubmit, setValue, getValues } = useForm({});

  const adminDetails = useSelector(getAdminDetails);
  const cat = adminDetails?.categorie


  const dispatch = useDispatch() 

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
 }

  useEffect(() => {
    // Set values for users
    setValue("users", {
      pseudo: user.pseudo,
      pwd: "",
      profil: user.profil,
      tlead_id: user.tlead_id,
      mng_supp_id: user.mng_supp_id,
      agt_supp_id: user.agt_supp_id,
    });

    // Create alias array
    const alias = [];
    for (let i = 0; i < user.lname.length; i++) {
      if (user.lname[i] && user.fname[i] && user.brand[i] && user.idalias[i]) {
        alias.push({
          lname: user.lname[i],
          fname: user.fname[i],
          brand: user.brand[i],
          idalias: user.idalias[i],
        });
      }
    }
    setValue("alias", alias);

    setValue("brandarr", user.brand);
    setValue("deskarr", user.desk);
  }, [user, setValue]);

  const onSubmit = (data) => {
    console.log(data);
    // Faites votre requête axios ici pour mettre à jour l'utilisateur
  };

  useEffect(() => {
    axios
      .post(`${config.apiAdmin}/sadmin/profils/read_by_categories`,{categorie : cat}, Header)
      .then((response) => {
        setProfilsDB(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const brandOptions = adminDetails?.payload?.admin?.details?.brand.map(
    (brand) => ({
      value: brand,
      label: `${brand}`,
    })
  );

  const brandOptionsUser = user?.brand?.map((brand) => ({
    value: brand,
    label: brand,
  }));

  const deskOptionsUser = user?.desk?.map((desk) => ({
    value: desk,
    label: desk,
  }));

  const deskOptionsAdmin = adminDetails?.payload?.admin?.details?.desk?.map(
    (desk) => ({
      value: desk,
      label: desk,
    })
  );

  const profilsDBOptions = profilsDB?.map((profil) => ({
    value: profil?.name,
    label: profil?.name,
  }));

  const userProfil = {
    value: user?.profil,
    label: user?.profil,
  };

  const UpdateInUsers = () => {
    const profil = getValues("users.profil");
    let obj = {
      idusers: user?.idusers,
      profil: profil,
    };
    axios
      .patch(
        `${config.apiAdmin}/sadmin/users/update_pseudo_profil`,
        obj,
        Header
      )
      .then((response) => {
        setProfilUpdated(true);
        SendPopup({ message: 'confirm', info: 'update_pseudo_profil.', isActive: true })
        // console.log(response.data);
      })
      .catch((error) => {
        SendPopup({ message: 'error', info: 'update_pseudo_profil.', isActive: true })
        console.error("There was an error!", error);
      });
  };


  const deleteDesk = (value) => {
    console.log("value", value)
    console.log("user", user)

    let obj = {
      user_id: user.idusers,
      name: value,
    };

    console.log(obj)


    let objTotal = { data: obj, headers: Header.headers }
    console.log(objTotal)

    axios
      .delete(
        `${config.apiAdmin}/sadmin/deskarr/delete_by_userid_and_Deskname`,
        objTotal
      )
      .then((response) => {
        SendPopup({ message: 'confirm', info: 'delete_by_userid_and_Deskname.', isActive: true })
        setDeskUpdated(true)
      })
      .catch((error) => {
        SendPopup({ message: 'error', info: 'delete_by_userid_and_Deskname.', isActive: true })
        console.error("There was an error!", error);
      });
  };

  const addDesk = (value) => {
    let obj = {
      name: value,
      user_id: user.idusers,
    };
    axios
      .post(`${config.apiAdmin}/sadmin/deskarr/search_and_insert`, obj, Header)
      .then((response) => {
        setDeskUpdated(true) ;
        SendPopup({ message: 'confirm', info: 'search_and_insert.', isActive: true })}
      )
      .catch((error) => {
        SendPopup({ message: 'error', info: 'search_and_insert.', isActive: true })
        console.error("There was an error!", error);
      });
  };

  const handleDeskChange = (selectedOptions, actionMeta) => {
    // retourne l array des desks selectionnes
    // const selectedValues = selectedOptions ? selectedOptions.map(opt => opt.value) : [];

    if (
      actionMeta.action === "remove-value" ||
      actionMeta.action === "pop-value"
    ) {
      deleteDesk(actionMeta.removedValue.value);
    } else if (actionMeta.action === "select-option") {
      addDesk(actionMeta.option.value);
    }
  };

  const deleteBrand = (value) => {
    let obj = {
      user_id: user.idusers,
      name: value,
    };
    axios
      .delete(
        `${config.apiAdmin}/sadmin/brandarr/delete_by_userid_and_brandname`,
        { data: obj, headers: Header }
      )
      .then((response) => {
        SendPopup({ message: 'confirm', info: 'delete_by_userid_and_brandname.', isActive: true })
        setBrandUpdated(true);
      })
      .catch((error) => {
        SendPopup({ message: 'error', info: 'delete_by_userid_and_brandname.', isActive: true })
        console.error("There was an error!", error);
      });
  };

  const addbrand = (value) => {
    let obj = {
      name: value,
      user_id: user.idusers,
    };
    axios
      .post(`${config.apiAdmin}/sadmin/brandarr/search_and_insert`, obj, Header)
      .then((response) => {
        SendPopup({ message: 'confirm', info: 'search_and_insert.', isActive: true })
        setBrandUpdated(true);
      })
      .catch((error) => {
        SendPopup({ message: 'error', info: 'search_and_insert.', isActive: true })
        console.error("There was an error!", error);
      });
  };

  const handlebrandChange = (selectedOptions, actionMeta) => {
    // retourne l array des desks selectionnes
    // const selectedValues = selectedOptions ? selectedOptions.map(opt => opt.value) : [];

    if (
      actionMeta.action === "remove-value" ||
      actionMeta.action === "pop-value"
    ) {
      deleteBrand(actionMeta.removedValue.value);
    } else if (actionMeta.action === "select-option") {
      addbrand(actionMeta.option.value);
    }
  };

  return (
    <>
      <Modal className="modal fade" show={show} onHide={closeModal} centered>
        <Modal.Body
          // style={{ backgroundColor: "#F4F4F5" }}
          className="border rounded w-100"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="my-2 p-2 d-flex justify-content-between align-items-center border rounded-3 p-2 bg-light">
              <div className="fs-20 ms-5 text-black">
                {getValues("users.pseudo")}
              </div>
              <ReactSelect
                options={profilsDBOptions}
                className="col-4"
                defaultValue={userProfil}
                onChange={(option) => {
                  const newProfil = option ? option.value : "";
                  setValue("users.profil", newProfil);
                  UpdateInUsers()
                }}
              />
             {profilUpdated &&(
              <div className="h-50 p-2 bg-white border-0 text-info rounded">Updated</div>
             )} 
            </div>
            {/* <div className="border p-2">
              <input {...register("tlead_id")} placeholder="Tlead ID" />
              <input
                {...register("mng_supp_id")}
                placeholder="Manager Support ID"
              />
              <input
                {...register("agt_supp_id")}
                placeholder="Agent Support ID"
              />
            </div> */}

            <div className="my-2 p-2 d-flex align-items-end justify-content-between border rounded-3 p-2 bg-light">
              <div>
                <label htmlFor="" className="text-black fs-14 ms-2">
                  Desks selectionnes
                </label>
                <ReactSelect
                  isMulti
                  options={deskOptionsAdmin}
                  defaultValue={deskOptionsUser}
                  onChange={handleDeskChange}
                  classNamePrefix="select"
                />
              </div>
             {deskUpdated &&(
              <div className="h-50 p-2 bg-white border-0 text-info rounded">Updated</div>
             )} 
            </div>
            <div className="my-2 p-2 d-flex align-items-end justify-content-between border rounded-3 p-2 bg-light">
              <div>
                <label htmlFor="" className="text-black fs-14 ms-2">
                  Brands selectionnes
                </label>
                <ReactSelect
                  isMulti
                  options={brandOptions}
                  defaultValue={brandOptionsUser}
                  onChange={handlebrandChange}
                  classNamePrefix="select"
                />
              </div>
              {brandUpdated && (
                <div className="h-50 p-2 bg-white border-0 text-info rounded">
                  Updated
                </div>
              )}
            </div>
            {/* <Button type="submit">Update</Button> */}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateAdminByBossSupp;
