import React, {  useEffect, useState } from "react";
import ActiveProjects from "../ActiveProjects";
import CardsSupport from "./CardsSupport";
import { useQuery, gql } from "@apollo/client";
import { getSockets } from "../../Redux/slices/notifSocket.slice";
import { useSelector } from "react-redux";
import { getToken } from "../../Redux/slices/admin.slice";
import { useParams } from 'react-router-dom';

const GET_REPORT_CONTRACT = gql`
query GetReportContractByProfilDesk($desks: [String]) {
  GetReportContractByProfilDesk(desks: $desks) {
    total_amount
    total_amount_with_preuve
    total_amount_with_encaisse
    total_amount_reported_not_encashed
  }
}
`;

const AGT_SUPP_lIST_BY_AGT = gql`
  query AgtSuppListRecapSupportByAgt {
    agtSuppListRecapSupportByAgt {
      idcontracts
      pseudo
      fname
      lname
      argent_dispo
      preuve
      encaisse
      is_report
      is_canceled
      paymentdudate
    }
  }
`;

const TableauSupport = () => {

  const GET_TOKEN = useSelector(getToken);
  const GET_SOCKETS = useSelector(getSockets);

  const Header = {
    context: {
      headers: {
        Authorization: `Bearer ${GET_TOKEN}`,
      }
    }
  }


 
  // const GET_ADMIN_DESKS = useSelector(getAdminDesks);


  const { data: dataReportContract, refetch: refetchReportContract } = useQuery(
    GET_REPORT_CONTRACT,{
      context: {
        headers: {
          Authorization: `Bearer ${GET_TOKEN}`,
        },
      },
      variables: { desk: null },
    } );
  const { data: dataAgtSuppListByAgt, refetch: refetchAgtSuppListByAgt } = useQuery(AGT_SUPP_lIST_BY_AGT, Header)

  useEffect(() => {
    refetchReportContract()
    // refetchListRecapSupport()
    refetchAgtSuppListByAgt()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GET_SOCKETS]);

  console.log("dataReportContract", dataReportContract)

  return (
    <div className="row">
      <div className="col-12">
    
       { dataReportContract?.GetReportContractByProfilDesk && (
          <CardsSupport
            dataReportContract={dataReportContract.GetReportContractByProfilDesk}
          />)
       }
    
          { dataAgtSuppListByAgt?.agtSuppListRecapSupportByAgt &&
            <ActiveProjects 
              dataListRecapSupport={dataAgtSuppListByAgt?.agtSuppListRecapSupportByAgt}
            />            
          }

      </div>
      {/* <div className="col-3 p-0">
        <Calendrier />
      </div> */}
    </div>
  );
};

export default TableauSupport;
