const objRowTable = {
  created_at: {
    type: "date",
    key: "created_at",
  },
  amount: {
    type: "int",
    key: "amount",
  },
  desk: {
    type: "int",
    key: "desk",
  },
  psp: {
    type: "string",
    key: "psp",
  },
  brand: {
    type: "string",
    key: "brand",
  },
  customerbank: {
    type: "string",
    key: "customerbank",
  },
  neobank: {
    type: "string",
    key: "neobank",
  },
  paymentdudate: {
    type: "date",
    key: "paymentdudate",
  },
  date_vir: {
    type: "date",
    key: "date_vir",
  },
  hours: {
    type: "time",
    key: "hours",
  },
  next_call: {
    type: "date",
    key: "next_call",
  },
  pseudo_agt_supp: {
    type: "string",
    key: "pseudo_agt_supp",
  },
  pseudo_seller: {
    type: "string",
    key: "pseudo_seller",
  },
  encash_usd: {
    type: "int",
    key: "encash_usd",
  },
  date_encaisse: {
    type: "date",
    key: "date_encaisse",
  },
  cust_lname: {
    type: "string",
    key: "cust_lname",
  },
  alias_lname: {
    type: "string",
    key: "alias_lname",
  },
};

export default objRowTable;
