
const updateCard = [
    {
        desk: "1",        
        title: "Desk 1",
        cardcolor: "bg-yellow-desk5",
      },
    {
      desk: "2",        
      title: "Desk 2",
      cardcolor: "bg-green-desk6",
    },

    {
      desk: "3",        
      title: "Desk 3",
      cardcolor: "bg-greenl-desk3",
    },

    {
      desk: "4",        
      title: "Desk 4",
      cardcolor: "bg-blue-desk4",
    },

    {
      desk: "5",        
      title: "Desk 5",
      cardcolor: "bg-orange-desk2",
    },
    {
      desk: "6",        
      title: "Desk 6",
      cardcolor: "bg-purple-desk7",
    },    
    {
      desk: "7",        
      title: "Desk 7",
      cardcolor: "bg-red-desk1",
    },
  ];


function colorDesk(desk) {

    const findColor = updateCard?.find( ( obj ) => obj.desk === desk   )
    // console.log("findColor", findColor) 
    let cardColor = findColor?.cardcolor;   
    return cardColor;

}

export default colorDesk;

