import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPopup, setPopup } from "../Redux/slices/actions.slice";

const Popup = () => {
  const dispatch = useDispatch();

  const Popup = useSelector(getPopup);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setPopup(false));
    }, 2500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
<div className={`notification-popup border show-popup ${Popup.message === 'confirm' ? 'bg-confirm' : Popup.message === 'error' ? 'bg-error' : ''}`}>
  <div className="message-popup">{Popup.message}</div>
  <div className="info-popup">{Popup.info}</div>
</div>
  );
};

export default Popup;
