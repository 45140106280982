import React from "react";
import formatNumber from "../../../utils/formatNumber";
import { Col, Card, Table } from "react-bootstrap";
const TableauGeneral = ({ dataWeeklyData }) => {

  // console.log("dataWeeklyData", dataWeeklyData.totalsByDay);
  const daysOfWeek = ["lundi", "mardi", "mercredi", "jeudi", "vendredi"];

  const getBgColor = (value) => {
    value = parseInt(value.replace(/[^0-9.]/g, ""), 10); // convertit la chaîne de valeur en nombre
    if (value >= 0 && value <= 100000) {
      return "rgba(93, 161, 48, 0.2)"; // Vert menthe
    } else if (value > 100000 && value <= 200000) {
      return "rgba(93, 161, 48, 0.4)"; // Vert pistache
    } else if (value > 200000 && value <= 300000) {
      return "rgba(93, 161, 48, 0.6)"; // Vert tilleul
    } else if (value > 300000 && value <= 400000) {
      return "rgba(93, 161, 48, 0.8)"; // Vert prairie
    } else if (value > 400000) {
      return "rgba(93, 161, 48, 1)"; // Vert sapin
    }
    return "defaultColor"; // couleur par défaut si aucune condition n'est remplie
  };

  const getBgColorWeek = (value) => {
    value = parseInt(value.replace(/[^0-9.]/g, ""), 10); // convertit la chaîne de valeur en nombre
    if (value >= 0 && value <= 300000) {
      return "rgb(255, 190, 11, 0.2)"; // Vert menthe
    } else if (value > 300000 && value <= 600000) {
      return "rgb(255, 190, 11, 0.4)"; // Vert pistache
    } else if (value > 600000 && value <= 900000) {
      return "rgb(255, 190, 11, 0.6)"; // Vert tilleul
    } else if (value > 900000 && value <= 1200000) {
      return "rgb(255, 190, 11, 0.8)"; // Vert prairie
    } else if (value > 1200000) {
      return "rgb(255, 190, 11, 1)"; // Vert sapin
    }
    return "defaultColor"; // couleur par défaut si aucune condition n'est remplie
  };

  const data = dataWeeklyData?.weeklyDataArray;

  function calculatePercentageChange(current, previous) {
    if (current === null || previous === null) return null;
    if (previous === 0) return current === 0 ? 0 : 100; // Pour éviter la division par zéro
    return Math.round(((current - previous) / previous) * 100);
  }

  function calculateVariations(data) {
    // return obj.map((week, index) => {
    return dataWeeklyData?.weeklyDataArray.map((week, index) => {
      if (index === 0) {
        return {
          ...week,
          lundi_variation: 0,
          mardi_variation: 0,
          mercredi_variation: 0,
          jeudi_variation: 0,
          vendredi_variation: 0,
          totaux_variation: 0,
        };
      }

      const previousWeek = data[index - 1];
      // const previousWeek = weeklyDataArray[index - 1];

      return {
        ...week,
        lundi_variation: calculatePercentageChange(
          week.lundi,
          previousWeek.lundi
        ),
        mardi_variation: calculatePercentageChange(
          week.mardi,
          previousWeek.mardi
        ),
        mercredi_variation: calculatePercentageChange(
          week.mercredi,
          previousWeek.mercredi
        ),
        jeudi_variation: calculatePercentageChange(
          week.jeudi,
          previousWeek.jeudi
        ),
        vendredi_variation: calculatePercentageChange(
          week.vendredi,
          previousWeek.vendredi
        ),
        totaux_variation: calculatePercentageChange(
          week.totaux,
          previousWeek.totaux
        ),
      };
    });
  }

  const dataWithVariations = calculateVariations(data);
  // const dataWithVariations = calculateVariations(dataWeeklyData?.weeklyDataArray);

  return (
    <Col className="ms-2">
      <Card>
        <Card.Body className="d-flex align-items-center justify-content-center p-0">
          <Table
            responsive
            bordered
            style={{ tableLayout: "fixed", overflow: "hidden" }}
            className="verticle-middle m-0 rounded border"
          >
            <thead>
              <tr>
                <th className="p-2">
                  <div className="row justify-content-between mt-3 mx-3">
                    <div
                      className="col-2 d-none"
                      title={` 
                          SELECT 
                            DATE(created_at) AS jour,
                            WEEK(DATE(created_at), 3) AS semaine,
                            DAYOFWEEK(DATE(created_at)) AS jour_semaine,
                            SUM(amount) AS montant
                          FROM contracts
                          WHERE contracts.is_active = 1
                            AND created_at >= DATE_FORMAT(NOW(), '%Y-%m-01')
                            AND amount > 1
                            AND contracts.is_canceled != 1 
                            AND desk in (goodDesk)
                          GROUP BY DATE(created_at), WEEK(DATE(created_at), 3), DAYOFWEEK(DATE(created_at))
                    
                    `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-code-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                        <path d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0m2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0" />
                      </svg>
                    </div>

                    <div
                      className="col-2 "
                      title={`
                        Nous incluons : toutes les commandes actives du mois en cours.
                        Nous excluons : les commandes annulées, les retraits.
                      `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-info-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                      </svg>
                    </div>
                  </div>
                </th>
                <th className="p-2 fw-bold text-black">lundi</th>
                <th className="p-2 fw-bold text-black">mardi</th>
                <th className="p-2 fw-bold text-black">mercredi</th>
                <th className="p-2 fw-bold text-black">jeudi</th>
                <th className="p-2 fw-bold text-black">vendredi</th>
                <th className="p-2 fw-bold text-black">totaux</th>
              </tr>
            </thead>
            <tbody>
              {dataWithVariations?.map((weekData, index) => (
                <tr key={index} className="fw-bold text-black">
                  <th>S. {weekData?.semaine}</th>
                  <td
                    style={{
                      backgroundColor: getBgColor(weekData?.lundi?.toString()),
                    }}
                    className="p-0 h-100"
                  >
                    {weekData?.lundi_variation != 0 &&
                      weekData.lundi_variation != -100 && (
                        <div className="d-flex justify-content-end">
                          <div
                            className={`circle-tableau mb-1 bg-white ${
                              weekData?.lundi_variation < 0
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            {weekData?.lundi_variation > 0
                              ? `+${weekData.lundi_variation}%`
                              : `${weekData.lundi_variation}%`}
                          </div>
                        </div>
                      )}
                    <div className="text-center">
                      {formatNumber(weekData?.lundi)} €
                    </div>
                    <div className=""></div>
                  </td>
                  <td
                    style={{
                      backgroundColor: getBgColor(weekData?.mardi?.toString()),
                    }}
                    className="p-0 h-100"
                  >
                    {weekData?.mardi_variation != 0 &&
                      weekData.mardi_variation != -100 && (
                        <div className="d-flex justify-content-end">
                          <div
                            className={`circle-tableau mb-1 bg-white ${
                              weekData?.mardi_variation < 0
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            {weekData?.mardi_variation > 0
                              ? `+${weekData.mardi_variation}%`
                              : `${weekData.mardi_variation}%`}
                          </div>
                        </div>
                      )}
                    <div className="text-center">
                      {formatNumber(weekData?.mardi)} €
                    </div>
                    <div className=""></div>
                  </td>
                  <td
                    style={{
                      backgroundColor: getBgColor(
                        weekData?.mercredi?.toString()
                      ),
                    }}
                    className="p-0 h-100"
                  >
                    {weekData?.mercredi_variation != 0 &&
                      weekData.mercredi_variation != -100 && (
                        <div className="d-flex justify-content-end">
                          <div
                            className={`circle-tableau mb-1 bg-white ${
                              weekData?.mercredi_variation < 0
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            {weekData?.mercredi_variation > 0
                              ? `+${weekData.mercredi_variation}%`
                              : `${weekData.mercredi_variation}%`}
                          </div>
                        </div>
                      )}
                    <div className="text-center">
                      {formatNumber(weekData?.mercredi)} €
                    </div>
                    <div className=""></div>
                  </td>
                  <td
                    style={{
                      backgroundColor: getBgColor(weekData?.jeudi?.toString()),
                    }}
                    className="p-0 h-100"
                  >
                    {weekData?.jeudi_variation != 0 &&
                      weekData.jeudi_variation != -100 && (
                        <div className="d-flex justify-content-end">
                          <div
                            className={`circle-tableau mb-1 bg-white ${
                              weekData?.jeudi_variation < 0
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            {weekData?.jeudi_variation > 0
                              ? `+${weekData.jeudi_variation}%`
                              : `${weekData.jeudi_variation}%`}
                          </div>
                        </div>
                      )}
                    <div className="text-center">
                      {formatNumber(weekData?.jeudi)} €
                    </div>
                    <div className=""></div>
                  </td>
                  <td
                    style={{
                      backgroundColor: getBgColor(
                        weekData?.vendredi?.toString()
                      ),
                    }}
                    className="p-0 h-100"
                  >
                    {weekData?.vendredi_variation != 0 &&
                      weekData.vendredi_variation != -100 && (
                        <div className="d-flex justify-content-end">
                          <div
                            className={`circle-tableau mb-1 bg-white ${
                              weekData?.vendredi_variation < 0
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            {weekData?.vendredi_variation > 0
                              ? `+${weekData.vendredi_variation}%`
                              : `${weekData.vendredi_variation}%`}
                          </div>
                        </div>
                      )}
                    <div className="text-center">
                      {formatNumber(weekData?.vendredi)} €
                    </div>
                    <div className=""></div>
                  </td>
                  <td
                    style={{
                      backgroundColor: getBgColorWeek(
                        weekData?.totaux?.toString()
                      ),
                    }}
                    className="p-0 h-100"
                  >
                    {weekData.totaux_variation != 0 &&
                      weekData.totaux_variation != -100 && (
                        <div className="d-flex justify-content-end">
                          <div
                            className={`circle-tableau mb-1 bg-white ${
                              weekData?.totaux_variation < 0
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            {weekData?.totaux_variation > 0
                              ? `+${weekData.totaux_variation}%`
                              : `${weekData.totaux_variation}%`}
                          </div>
                        </div>
                      )}
                    {formatNumber(weekData?.totaux)} €
                  </td>
                </tr>
              ))}

              <tr>
                <td>total</td>
                {daysOfWeek?.map((day) => (
                  <td key={day}>
                    {dataWeeklyData?.totalsByDay[day] !== undefined
                      ? formatNumber(dataWeeklyData.totalsByDay[day])
                      : "N/A"}{" "}
                    €
                  </td>
                ))}
                <td>{formatNumber(dataWeeklyData?.totalGeneral)} €</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default TableauGeneral;
