import React from "react";
import formatNumber from "../../../utils/formatNumber";
import { Col, Card, Table } from "react-bootstrap";
const TableauResultat = ({dataStatBrutNet}) => {
  //console.log("dataStatBrutNet", dataStatBrutNet);
  return (
      <Col className="me-2">
        <Card>
          <Card.Body className="d-flex flex-column justify-content-between p-0">
            <Table responsive striped>
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{ textAlign: "center", verticalAlign: "middle" }} className="rounded"
                  >
                    TOTAL BRUT 
                    <br />
                    <span style={{fontSize: 6}}>(ce qu'ils ont signE)</span>
                    

                  </th>
                  <th
                    scope="col"
                    className="fw-bold text-dark fs-3"
                    style={{ backgroundColor: "var(--bs-info)" }}
                  >
                     $ { formatNumber(dataStatBrutNet?.total_amount_brut)     }
                  </th>
                  <th
                    scope="col"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    TOTAL NET
                    <br />
                    <span style={{fontSize: 6}}>(tout le signer moins les retraits)</span>
                  </th>
                  <th
                    scope="col"
                    className="fw-bold text-dark fs-3"
                    style={{ backgroundColor: "#7da214", borderTopRightRadius: "8px" }}
                  >
                    $ { formatNumber(dataStatBrutNet?.total_amount_net)     }
                  </th>
                </tr>
              </thead>
            </Table>
            <Table responsive bordered className="verticle-middle">
              <thead>
                <tr>
                  <th scope="col">Nb Contrat</th>
                  <th scope="col">Moyenne</th>
                  <th scope="col">preuve</th>
                  <th scope="col">Report</th>
                  <th scope="col" className="text-center bg-light">
                    EUR\USD
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="fs-3 fw-bold">{dataStatBrutNet?.total_contracts}</td>
                  <td className="fs-3 fw-bold">{dataStatBrutNet?.moyenne}</td>
                  <td className="fs-3 fw-bold"> {dataStatBrutNet?.sum_preuve}</td>
                  <td className="fs-3 fw-bold">{dataStatBrutNet?.sum_is_report}</td>
                  <td className="fs-3 text-center bg-light">1.04</td>
                </tr>
                <tr>
                  <td className="fs-3 fw-bold"></td>
                  <td className="fs-4"></td>
                  <td className="fs-4 text-danger">Annulations</td>
                  <th scope="col" className="fs-4 text-danger text-center">
                  {dataStatBrutNet?.percent_canceled}%
                  </th>
                  <td className="fs-5 text-center">Sans annul/report</td>
                </tr>
                <tr>
                  <td className="fs-3 fw-bold"></td>
                  <td className="fs-4"></td>
                  <td className="fs-4" style={{ color: "var(--bs-purple)" }}>
                    Reports
                  </td>
                  <th
                    scope="col"
                    style={{ color: "var(--bs-purple)" }}
                    className="fs-4 text-center"
                  >
                    {dataStatBrutNet?.percent_reported}%
                  </th>
                  <td className="fs-4 text-center bg-danger-light text-black fw-bold">
                  <span style={{fontSize: 6}}>(total net encaissE)</span>
                  <br />
                  $ { formatNumber(dataStatBrutNet?.total_amount_encaisse)     }

                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
  );
};

export default TableauResultat;
