import { createSlice } from "@reduxjs/toolkit";

export const contractReducer = createSlice({
  name: "contract",
  initialState: {
    contracts: [],
    IsUpdated: false,
    IsFiltered: false,
    reinitFilters: false,
    filters: {},
    IsAdded: false,
    listSupport: []
  },
  reducers: {
    setAllContracts: (state, { payload }) => {
      // console.log(payload, "je suis payload")
      state.contracts = payload;
    },
    setContractUpdated: (state, { payload }) => {
      state.IsUpdated = payload;
    },
    setContractfiltered: (state, { payload }) => {
      state.IsFiltered = payload;
    },
    setReinitFilters: (state, { payload }) => {
      state.reinitFilters = payload;
    },
    setFilters: (state, { payload }) => {
      state.filters = payload;
    }, 
    setContractAdded: (state, { payload }) => {
      state.IsAdded = payload;
    },
    setListSupport: (state, { payload }) => {
      state.listSupport = payload;
    },
  },
});

export const { setAllContracts, setContractUpdated, setContractfiltered, setReinitFilters, setFilters, setContractAdded, setListSupport } = contractReducer.actions;

export const getAllContracts = (state) => {
  // console.log(state.contract.contracts, "je suis la ")
  return state.contract.contracts;
};

export const getContractUpdated = (state) => {
  return state.contract.IsUpdated
};

export const getContractFiltered = (state) => {
  return state.contract.IsFiltered
}

export const getReinitFilters = (state) => {
  return state.contract.reinitFilters
}

export const getFilters = (state) => {
  return state.contract.filters
}

export const getContractAdded = (state) => {
  return state.contract.IsAdded
}

export const getListSupport = (state) => {
  return state.contract.listSupport
}


export default contractReducer.reducer;
