import { lazy, Suspense, useEffect } from "react";
import Index from "./jsx";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import {  getAdminAuthentified } from "./PagesCrm/Redux/slices/admin.slice";
import Popup from "./PagesCrm/Components/Popup";
import { getPopup, getInterdit } from "./PagesCrm/Redux/slices/actions.slice";
import Interdit from "./PagesCrm/Components/Interdit";
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./other/swiper/css/swiper-bundle.min.css";
import "./css/style.css";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

function App() {
  const navigate = useNavigate(); 
  
  const popup = useSelector(getPopup);
  const interdit = useSelector(getInterdit);
  // console.log(popup.isActive)
  const adminAuthentified = useSelector(getAdminAuthentified);

  let routeblog = (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/page-register" element={<SignUp />} />
    </Routes>
  );

  useEffect(() => {
    if (!adminAuthentified) {
      navigate('/login');
    }
  }, [adminAuthentified, navigate]);

  return (
    <div className="vh-100">
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        {adminAuthentified ? <Index /> : routeblog}
        {popup.isActive === true && <Popup/>}
        {interdit === true && <Interdit/>}
      </Suspense>
    </div>
  );
}

export default App;