import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config/config";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { CircleLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import {
  // getContractFiltered,
  setAllContracts,
  setFilters,
  getFilters,
  // getContractUpdated
} from "../../Redux/slices/contract.slice";
import {   Button } from "react-bootstrap";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const TableauCrmFilters = (props) => {
  const AdminDetails = useSelector(getAdminDetails);
  // const IdToAdmin = AdminDetails?.idusers;
  const desksToAdmin = AdminDetails?.desk;
  // const profilToAdmin = AdminDetails?.profil;

  let DesksAdmin = useParams().desk;
  let Desk = (DesksAdmin = DesksAdmin || desksToAdmin);

  const dispatch = useDispatch();

  // const contractFiltered = useSelector(getContractFiltered);
  const filters = useSelector(getFilters);
  // const contractUpdated = useSelector(getContractUpdated);

  const initialObjToFetch = {
    filters: {
      desk: DesksAdmin || Desk.join(', '),
    },
  };

  useEffect(() => {
    console.log(filters);
    dispatch(setFilters(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const [inputSearchAliasfname, setInputSearchAliasfname] = useState("");
  const [inputSearchAliaslname, setInputSearchAliaslname] = useState("");
  const [inputSearchCustomerfname, setInputSearchCustomerfname] = useState("");
  const [inputSearchCustomerlname, setInputSearchCustomerlname] = useState("");
  const [ObjToFetch, setObjToFetch] = useState(initialObjToFetch);
  const [psp, setPsp] = useState([]);
  const [neobank, setNeoBank] = useState([]);
  const [customerBank, setCustomerBank] = useState([]);
  const [inputSearchSeller, setInputSearchSeller] = useState("");
  const [inputSearchSupport, setInputSearchSupport] = useState("");

  const [dropDispo, setDropDispo] = useState(null);
  const [dropPreuve, setDropPreuve] = useState(null);
  const [dropEncaisse, setDropEncaisse] = useState(null);
  const [dropReport, setDropReport] = useState(null);
  const [dropAnnul, setDropAnnul] = useState(null);

  const [LoaderStart, setLoaderStart] = useState(false);

  // const [selectedDates, setSelectedDates] = useState([]);
  // const [sortByWhat, setSortByWhat] = useState("created_at");
  // const [limit, setLimit] = useState("");
  // const [sortOrder, setSortOrder] = useState("");
  // const [AllStatus, setAllStatus] = useState(null);
  // const [selectedPsp, setSelectedPsp] = useState(null);

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const optionsSatutsDispo = [
    {
      value: "argent_dispo_check",
      label: "argent_dispo_check",
      checked: "1",
      Tofetch: "argent_dispo",
    },
    {
      value: "argent_dispo",
      label: "argent_dispo",
      checked: "0",
      Tofetch: "argent_dispo",
    },
  ];
  const optionsSatutsPreuve = [
    {
      value: "preuve_check",
      label: "preuve_check",
      checked: "1",
      Tofetch: "preuve",
    },
    { value: "preuve", label: "preuve", checked: "0", Tofetch: "preuve" },
  ];
  const optionsSatutsEncaisse = [
    {
      value: "encaisse_check",
      label: "encaisse_check",
      checked: "1",
      Tofetch: "encaisse",
    },
    { value: "encaisse", label: "encaisse", checked: "0", Tofetch: "encaisse" },
  ];
  const optionsSatutsReport = [
    {
      value: "is_report_check",
      label: "is_report_check",
      checked: "1",
      Tofetch: "is_report",
    },
    {
      value: "is_report",
      label: "is_report",
      checked: "0",
      Tofetch: "is_report",
    },
  ];
  const optionsSatutsAnnul = [
    {
      value: "is_canceled_check",
      label: "is_canceled_check",
      checked: "1",
      Tofetch: "is_canceled",
    },
    {
      value: "is_canceled",
      label: "is_canceled",
      checked: "0",
      Tofetch: "is_canceled",
    },
  ];
  const limitOptions = [
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 200, label: "200" },
    { value: "", label: "All" },
  ];

  const pspSelect = psp?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const neobankSelect = neobank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const customerBankSelect = customerBank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));


  useEffect(() => {
    if (props.showFilters === true) {
      FetchFiltersGet("/psp/read_all", setPsp);
      FetchFiltersGet("/neobanks/read_all", setNeoBank);
      FetchFiltersGet("/customerbanks/read_all", setCustomerBank);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showFilters]);

  const FetchFiltersGet = (url, fonction) => {
    axios
      .get(`${config.apiAdmin}/sadmin${url}`, Header)
      .then((response) => {
        fonction(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };


  const SearchContrats = (options) => {
    console.log(options.isReinit)
    let IsInit;
    if (options.isReinit) {
      IsInit = {
        filters: {
          desk: DesksAdmin || Desk.join(', '),
        },
      }
    } else {
      IsInit = filters
    }
  
    setLoaderStart(true);
    dispatch(setFilters(initialObjToFetch));
    axios
      .post(
        `${config.apiAdmin}/sadmin/contracts/search_and_sort`,
        IsInit,
        Header
      )
      .then((response) => {
        dispatch(setAllContracts(response.data.data.results));
        // dispatch(setContractfiltered(false));
        // dispatch(setContractUpdated(false));
        setLoaderStart(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoaderStart(false);
      });
  };

  const customSelectStyles = {
    option: (provided) => ({
      ...provided,
      fontSize: "12px",
    }),
    // Ajoutez d'autres styles personnalisés ici si nécessaire
  };

  const reinitFilters = () => {
    setLoaderStart(true);
    dispatch(setFilters({
      filters: {
        desk: DesksAdmin || Desk,
      },
      filters_date: {},
      sorts: {
      },
      limit: "",
    }));
    setObjToFetch({
      filters: {
        desk: DesksAdmin || Desk,
      },
      filters_date: {},
      sorts: {
      },
      limit: "",
    });
    setInputSearchAliasfname("");
    setInputSearchAliaslname("");
    setInputSearchCustomerfname("");
    setInputSearchCustomerlname("");
    setInputSearchSeller("");
    setInputSearchSupport("");
    // setLimit("");
    // setSortOrder("");
    // setAllStatus(null);
    // setSelectedDates([]);
    // setSortByWhat("created_at");
    setDropAnnul(null);
    setDropDispo(null);
    setDropPreuve(null);
    setDropEncaisse(null);
    setDropReport(null);
    setPsp([]);
    setNeoBank([]);
    setCustomerBank([]);

    // setSelectedPsp(null);
    SearchContrats({isReinit: true});
  };

  useEffect(() => {
    // console.log(ObjToFetch)
    dispatch(setFilters(ObjToFetch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ObjToFetch]);

  return (
    <div className="my-2">
      {LoaderStart ? (
        <div className="sweet-loading" style={{ marginTop: "100px" }}>
          <CircleLoader
            color="#36d7b7"
            loading="true"
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          {" "}
          <div className="d-flex">
            <div className="d-flex flex-column mx-2">
              <label htmlFor="">Client</label>
              <input
                type="text"
                value={inputSearchCustomerfname}
                onChange={(e) => {
                  setInputSearchCustomerfname(e.target.value);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      cust_fname: e.target.value,
                    },
                  }));
                }}
                className="rounded-2 ps-2"
                id="input-search"
                placeholder="Prenom"
                style={{
                  height: "42px",
                  marginTop: "5px",
                  border: "1px solid #CCCCCC",
                  color: "#808080",
                  fontSize: "1rem",
                  fontWeight: "700",
                }}
              />
              <input
                type="text"
                value={inputSearchCustomerlname}
                onChange={(e) => {
                  setInputSearchCustomerlname(e.target.value);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      cust_lname: e.target.value,
                    },
                  }));
                }}
                className="rounded-2 ps-2"
                id="input-search"
                placeholder="Nom"
                style={{
                  height: "42px",
                  marginTop: "5px",
                  border: "1px solid #CCCCCC",
                  color: "#808080",
                  fontSize: "1rem",
                  fontWeight: "700",
                }}
              />
            </div>
            <div className="d-flex flex-column mx-2">
              <label htmlFor="">Alias</label>
              <input
                type="text"
                value={inputSearchAliasfname}
                onChange={(e) => {
                  setInputSearchAliasfname(e.target.value);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      alias_fname: e.target.value,
                    },
                  }));
                }}
                className="rounded-2 ps-2"
                id="input-search"
                placeholder="Prenom"
                style={{
                  height: "42px",
                  marginTop: "5px",
                  border: "1px solid #CCCCCC",
                  color: "#808080",
                  fontSize: "1rem",
                  fontWeight: "700",
                }}
              />
              <input
                type="text"
                value={inputSearchAliaslname}
                onChange={(e) => {
                  setInputSearchAliaslname(e.target.value);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      alias_lname: e.target.value,
                    },
                  }));
                }}
                className="rounded-2 ps-2"
                id="input-search"
                placeholder="Nom"
                style={{
                  height: "42px",
                  marginTop: "5px",
                  border: "1px solid #CCCCCC",
                  color: "#808080",
                  fontSize: "1rem",
                  fontWeight: "700",
                }}
              />
            </div>
            <div className="d-flex flex-column mx-2">
              <label htmlFor="">Pseudo</label>
              <input
                type="text"
                value={inputSearchSeller}
                onChange={(e) => {
                  setInputSearchSeller(e.target.value);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      pseudo_seller: e.target.value,
                    },
                  }));
                }}
                className="rounded-2 ps-2"
                id="input-search"
                placeholder="Seller"
                style={{
                  height: "42px",
                  marginTop: "5px",
                  border: "1px solid #CCCCCC",
                  color: "#808080",
                  fontSize: "1rem",
                  fontWeight: "700",
                }}
              />
              <input
                type="text"
                value={inputSearchSupport}
                onChange={(e) => {
                  setInputSearchSupport(e.target.value);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      pseudo_agt_supp: e.target.value,
                    },
                  }));
                }}
                className="rounded-2 ps-2"
                id="input-search"
                placeholder="Support"
                style={{
                  height: "42px",
                  marginTop: "5px",
                  border: "1px solid #CCCCCC",
                  color: "#808080",
                  fontSize: "1rem",
                  fontWeight: "700",
                }}
              />
            </div>
            {/* <div>gg</div> */}
          </div>
          <div className="d-flex align-items-center mt-4 col-12">
            <div className="d-flex h-100 align-items-center">
 

              <ReactSelect
                options={pspSelect}
                className=""
                classNamePrefix="psp"
                placeholder="PSP"
                styles={customSelectStyles}
                onChange={(selectedOption) => {
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      psp: selectedOption.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="d-flex h-100 align-items-center">
              <ReactSelect
                options={neobankSelect}
                className=""
                classNamePrefix="neobank"
                placeholder="Plateforme"
                styles={customSelectStyles}
                onChange={(selectedOption) => {
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      neobank: selectedOption.value,
                    },
                  }));
                }}
              />
              <ReactSelect
                options={customerBankSelect}
                className=""
                classNamePrefix="customerBank"
                placeholder="Bank Client"
                styles={customSelectStyles}
                onChange={(selectedOption) => {
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      customerBank: selectedOption.value,
                    },
                  }));
                }}
              />
              <ReactSelect
                options={optionsSatutsDispo}
                className=""
                classNamePrefix="status"
                placeholder="Argent dispo"
                value={dropDispo}
                styles={customSelectStyles}
                onChange={(selectedOption) => {
                  setDropDispo(selectedOption);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      [selectedOption.Tofetch]: selectedOption.checked,
                    },
                  }));
                }}
              />
              <ReactSelect
                options={optionsSatutsPreuve}
                className=""
                classNamePrefix="status"
                placeholder="Preuve"
                value={dropPreuve}
                styles={customSelectStyles}
                onChange={(selectedOption) => {
                  setDropPreuve(selectedOption);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      [selectedOption.Tofetch]: selectedOption.checked,
                    },
                  }));
                }}
              />
              <ReactSelect
                options={optionsSatutsEncaisse}
                className=""
                classNamePrefix="status"
                placeholder="Encaisse"
                value={dropEncaisse}
                styles={customSelectStyles}
                onChange={(selectedOption) => {
                  setDropEncaisse(selectedOption);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      [selectedOption.Tofetch]: selectedOption.checked,
                    },
                  }));
                }}
              />
              <ReactSelect
                options={optionsSatutsReport}
                className=""
                classNamePrefix="status"
                placeholder="Report"
                value={dropReport}
                styles={customSelectStyles}
                onChange={(selectedOption) => {
                  setDropReport(selectedOption);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      [selectedOption.Tofetch]: selectedOption.checked,
                    },
                  }));
                }}
              />
              <ReactSelect
                options={optionsSatutsAnnul}
                className=""
                classNamePrefix="status"
                placeholder="Annul"
                value={dropAnnul}
                styles={customSelectStyles}
                onChange={(selectedOption) => {
                  setDropAnnul(selectedOption);
                  setObjToFetch((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      [selectedOption.Tofetch]: selectedOption.checked,
                    },
                  }));
                }}
              />
            </div>
          </div>
          <div className="d-flex mt-4 justify-content-end">
            <Button onClick={reinitFilters} className="mx-1">
              reinit. filtres
            </Button>
            <ReactSelect
              options={limitOptions}
              className="mx-1"
              classNamePrefix="limit"
              placeholder="Limit Aff."
              styles={customSelectStyles}
              onChange={(selectedOption) => {
                setObjToFetch((prevState) => ({
                  ...prevState,
                  limit: selectedOption.value,
                }));
              }}
            />
            <Button onClick={SearchContrats} className="mx-1">
              Search
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default TableauCrmFilters;
