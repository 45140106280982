import React from "react";

const Footer = (props) => {
	var d = new Date();
	return (
		<div className={`footer ${props.changefoot}`}>
			<div className="copyright">
				<p> Copyright © Developed by Team Dev {" "}{d.getFullYear()} </p>
			</div>
		</div>
	);
};

export default Footer;
