import { useState } from "react";

export default function Badge({ index, list, rounded, note }) {
  const [isFirstPlace, setIsFirstPlace] = useState(true);

  // Déterminer la classe de couleur en fonction de l'index
  const getBadgeClass = (index, list) => {
    switch (index) {
      case 0:
        return list ? "badge-white-list" : "badge-gold";
      case 1:
        return list ? "badge-white-list" : "badge-silver";
      case 2:
        return list ? "badge-white-list" : "badge-bronze";
      default:
        return list ? "badge-white-list " : "badge-white";
    }
  };

  // Déterminer la classe de couleur pour badge-inner en fonction de l'index
  const getBadgeInnerClass = (index, list) => {
    switch (index) {
      case 0:
        return list ? "badge-inner-white-list" : "badge-inner-gold";
      case 1:
        return list ? "badge-inner-white-list" : "badge-inner-silver";
      case 2:
        return list ? "badge-inner-white-list" : "badge-inner-bronze";
      default:
        return list ? "badge-inner-white-list" : "badge-inner-white";
    }
  };

  // Déterminer la classe de couleur pour le texte en fonction de l'index
  const getBadgeTextClass = (list, note) => {
    return note ? "text-white" : "text-black";
  };

  // Déterminer la classe de marge supérieure en fonction de l'index et de list
  const getMarginTopClass = (index, list, rounded, note) => {
    if ((index === 3 || index === 4) && note) {
      return "mt-5";
    } else {
      return "mt-3";
    }
  };

  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center bg-transparent ms-3 ${getMarginTopClass(
        index,
        list,
        rounded,
        note
      )}`}
    >
      <style>
        {`
          .badge-gold,
          .badge-silver,
          .badge-bronze,
          .badge-white {
            width: 24px;
            height: 24px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
            cursor: pointer;
            transition: transform 0.3s ease;
          }
          .badge-gold {
            background-color: #ffd700;
            border-color: #ffdb58;
          }
          .badge-silver {
            background-color: #c0c0c0;
            border-color: #d3d3d3;
          }
          .badge-bronze {
            background-color: #cd7f32;
            border-color: #b87333;
          }
          .badge-white {
            background-color: #ffffff;
            border-color: #f0f0f0;
          }
          .badge-gold-list,
          .badge-silver-list,
          .badge-bronze-list,
          .badge-white-list {
            width: 20px;
            height: 20px;
            border: 4px solid;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
          }
          .badge-gold-list {
            background-color: #ffd700;
            border-color: #ffdb58;
          }
          .badge-silver-list {
            background-color: #c0c0c0;
            border-color: #d3d3d3;
          }
          .badge-bronze-list {
            background-color: #cd7f32;
            border-color: #b87333;
          }
          .badge-white-list {
            background-color: #ffffff;
            border-color: #f0f0f0;
          }
          .badge-gold:hover,
          .badge-silver:hover,
          .badge-bronze:hover,
          .badge-white:hover,
          .badge-gold-list:hover,
          .badge-silver-list:hover,
          .badge-bronze-list:hover,
          .badge-white-list:hover {
            transform: scale(1.05);
          }
          .badge-inner-gold,
          .badge-inner-silver,
          .badge-inner-bronze,
          .badge-inner-white {
            background-color: inherit;
          }
          .badge-inner-gold-list,
          .badge-inner-silver-list,
          .badge-inner-bronze-list,
          .badge-inner-white-list {
            background-color: inherit;
          }
          .text-white {
            color: #ffffff;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
          }
          .text-black {
            color: #000000;
          }
          .bg-black {
            background-color: #000000;
          }
        `}
      </style>
      <div
        className={`${getBadgeClass(
          index,
          list
        )} d-flex justify-content-center align-items-center ${rounded && index === 3 ? "mt-5" : ""} ${list ? "ms-4" : ""} ${rounded ? "rounded-5 p-1" : ""} ${note ? "bg-black" : ""}`}
        onClick={() => setIsFirstPlace(!isFirstPlace)}
      >
        <div className="text-center">
          <span className={`fw-bold fs-16 ${getBadgeTextClass(list, note)}`}>
            {note ? note : list ? index + 6 : index + 1}
          </span>
        </div>
      </div>
    </div>
  );
}