import React from "react";
import { Card } from "react-bootstrap";
import formatNumber from "../../../utils/formatNumber";
import { getEuroUsd } from "../../Redux/slices/taux.slice";
import { useSelector } from "react-redux";

const ProgressBarDesk = ({ data, totalencaisse, totalnetprev }) => {
  // const highestObjective = data.res.reduce((max, target) =>
  //     target.objectif > max ? target.objectif : max, 0);

  const data1 = data.res;

  const total =
    totalencaisse > data.mostBigTarget ? totalencaisse : data.mostBigTarget;

  const milestones = data1?.map((item) => ({
    objectif: item.objectif,
    pourcentage: (item.objectif * 100) / total,
  }));

  const encaissePercentage = Math.ceil(((totalencaisse || 1) / total) * 100);
  const netPrevPercentage = Math.ceil(((totalnetprev || 1) / total) * 100);

  const t1Percentage = Math.ceil(((milestones[0].objectif || 1) / total) * 100);
  const t2Percentage = Math.ceil(
    ((milestones[1]?.objectif || 1) / total) * 100
  );
  const t3Percentage = Math.ceil(
    ((milestones[2]?.objectif || 1) / total) * 100
  );

  const isTargetCompleted =
    (parseFloat(totalnetprev) || parseFloat(1)) >= parseFloat(total);

  const segments = [
    {
      label: "Net Prev",
      percentage: netPrevPercentage,
      color: "#10d078",
      //   color: "transparent",
      value: formatNumber(totalnetprev || 1),
    },
    {
      label: "encaisseMoinsPourcent",
      percentage: encaissePercentage,
      color: "#ffd55866",
      //   color: "transparent",
      value: formatNumber(totalencaisse || 1),
    },

    {
      label: "t1",
      percentage: t1Percentage,
      color: "transparent",
      value: formatNumber(milestones[0]?.objectif || 1),
    },
    {
      label: "t2",
      percentage: t2Percentage,
      color: "transparent",
      value: formatNumber(milestones[1]?.objectif || 1),
    },
    {
      label: "t3",
      percentage: t3Percentage,
      color: "transparent",
      value: formatNumber(milestones[2]?.objectif || 1),
    },
  ];

  const sortedSegments = [...segments].sort(
    (a, b) => a.percentage - b.percentage
  );

  const ProgressBar = ({ segments }) => (
    <div
      style={{
        height: "24px",
        backgroundColor: "#e9ecef",
        borderRadius: "12px",
        // position:"relative"
      }}
    >
      {sortedSegments?.map(
        (segment, index) =>
          segment.value != 0 && (
            <div
              key={index}
              style={{
                width: `${segment.percentage}%`,
                backgroundColor: `${segment.color}`,
                height: "100%",
                textAlign: "end",
                position: "absolute",
                color: "black",
                fontWeight: "bold",
                borderRadius: "12px",
                // left: "0",
                zIndex: index,
              }}
            >
              {/* <div style={{ position: "absolute" }}></div> */}
              <div
                style={{
                  position: "relative",
                  bottom: ["t1", "t2", "t3"].includes(segment.label)
                    ? "32px"
                    : "0px",
                  fontSize: ["t1", "t2", "t3"].includes(segment.label)
                    ? "12px"
                    : "14px",
                }}
              >
                <div
                  style={{ padding: "2px 4px 0 0" }}
                  className={`${
                    segment.label === "NetPrev"
                      ? "color-vert-progress"
                      : segment.label === "encaisseMoinsPourcent"
                      ? "mt-4 color-orange-progress"
                      : ""
                  }`}
                >{`${segment.value} $`}</div>
                {["t1", "t2", "t3"].includes(segment.label) ? <div>|</div> : ""}
              </div>
            </div>
          )
      )}
    </div>
  );

  return (
    <div className="row">
      {isTargetCompleted && (
        <div className="d-flex col-12 align-items-center justify-content-center">
          <h5 className="ms-3 py-2 text-center">
            🎉 Toutes les targets ont étés atteintes. 🎉
          </h5>
        </div>
      )}
      <Card.Body>
        <div style={{ position: "relative" }}>
          <ProgressBar now={netPrevPercentage} segments={sortedSegments} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "20px",
          }}
        >
          <div className="d-flex justify-content-center">
            <span className="text-black"></span>
          </div>
          <div className="d-flex justify-content-center">
            <span className="fs-12 text-black">
              {formatNumber(totalnetprev)} $ soit {netPrevPercentage}% de
              l'object total
            </span>
          </div>
          <div className="d-flex justify-content-center">
            {/* <span className="fs-12 text-black">{formatNumber(total)}$</span> */}
          </div>
        </div>
      </Card.Body>
    </div>
  );
};

export default ProgressBarDesk;
