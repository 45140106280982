function sortTable(arg, type, data, statuSort, dispatch, setter) {
  const dataCopy = [...data];
  switch (type) {
    case "date":
      if (statuSort === 1) {
        dataCopy.sort((a, b) => {
          if (a[arg] === null) return 1;
          if (b[arg] === null) return -1;
          return new Date(b[arg]) - new Date(a[arg]);
        });
      } else if (statuSort === 2) {
        dataCopy.sort((a, b) => {
          if (a[arg] === null) return 1;
          if (b[arg] === null) return -1;
          return new Date(a[arg]) - new Date(b[arg]);
        });
      } else if (statuSort === 3) {
        dataCopy.sort((a, b) => {
          if (a["created_at"] === null) return 1;
          if (b["created_at"] === null) return -1;
          return new Date(b["created_at"]) - new Date(a["created_at"]);
        });
      }
      break;

    case "int":
      if (statuSort === 1) {
        dataCopy.sort((a, b) => {
          if (a[arg] === null) return 1;
          if (b[arg] === null) return -1;
          return b[arg] - a[arg];
        });
      } else if (statuSort === 2) {
        dataCopy.sort((a, b) => {
          if (a[arg] === null) return 1;
          if (b[arg] === null) return -1;
          return a[arg] - b[arg];
        });
      } else if (statuSort === 3) {
        dataCopy.sort((a, b) => {
          if (a["created_at"] === null) return 1;
          if (b["created_at"] === null) return -1;
          return new Date(b["created_at"]) - new Date(a["created_at"]);
        });
      }
      break;

    case "string":
      if (statuSort === 1) {
        dataCopy.sort((a, b) => {
          if (a[arg] === null) return 1;
          if (b[arg] === null) return -1;
          return b[arg].localeCompare(a[arg]);
        });
      } else if (statuSort === 2) {
        dataCopy.sort((a, b) => {
          if (a[arg] === null) return 1;
          if (b[arg] === null) return -1;
          return a[arg].localeCompare(b[arg]);
        });
      } else if (statuSort === 3) {
        dataCopy.sort((a, b) => {
          if (a["created_at"] === null) return 1;
          if (b["created_at"] === null) return -1;
          return new Date(b["created_at"]) - new Date(a["created_at"]);
        });
      }
      break;

      case "time":
        if (statuSort === 2) {
          dataCopy.sort((a, b) => {
            if (a[arg] === null) return 1;
            if (b[arg] === null) return -1;
            return new Date(`1970-01-01T${b[arg]}Z`) - new Date(`1970-01-01T${a[arg]}Z`);
          });
        } else if (statuSort === 1) {
          dataCopy.sort((a, b) => {
            if (a[arg] === null) return 1;
            if (b[arg] === null) return -1;
            return new Date(`1970-01-01T${a[arg]}Z`) - new Date(`1970-01-01T${b[arg]}Z`);
          });
        } else if (statuSort === 3) {
          dataCopy.sort((a, b) => {
            if (a["created_at"] === null) return 1;
            if (b["created_at"] === null) return -1;
            return new Date(b["created_at"]) - new Date(a["created_at"]);
          });
        }
        break;
      

    default:
      break;
  }

  dispatch(setter(dataCopy));
}

export default sortTable;
