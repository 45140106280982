import React, { useState, useEffect } from "react";
import { Card, Table, Form } from "react-bootstrap";
import ava1 from "../../images/avataaars (1).svg";
import ava2 from "../../images/avataaars (2).svg";
import ava3 from "../../images/avataaars (3).svg";
import axios from "axios";
import config from "../../config/config";

const UtilisateursActive = () => {
  const [listUser, setListUser] = useState([]);

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    axios
      .get(`${config.apiAdmin}/sadmin/users/read_all_direction`, Header)
      .then((response) => {
        setListUser(response.data.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mt-4 border-top pt-2 pb-2 border-bottom">
        <div className="ms-5 fw-bold text-black">
          Activer/desactiver des utilisateurs
        </div>
        <div className="ms-5 text-black">
          Cette page vous permet d’activer et de désactiver des utilisateurs.
        </div>
        <div>
          
        </div>
      </div>
      <Card className="mt-5" style={{ height: '90%', overflowY: 'auto' }}>
        <Table bordered className="table-no-vertical-borders">
          <thead>
            <tr>
              <th>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="black"
                  className="bi bi-person-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                </svg>
              </th>
              <th>Pseudo</th>
              <th>Profil</th>
              <th>Statut de l’utilisateur</th>
            </tr>
          </thead>
          <tbody>
            {listUser
  ?.sort((a, b) => a.pseudo.localeCompare(b.pseudo))
  .map((member) => {
              const avatars = [ava1, ava2, ava3];
              const randomAvatar =
                avatars[Math.floor(Math.random() * avatars.length)];

              return (
                <tr key={member?.idusers}>
                  <td>
                    <img src={member.avatar || randomAvatar} alt="" style={{ height: "40px" }} />
                  </td>
                  <td>{member?.pseudo}</td>
                  <td className="d-flex justify-content-center align-items-center m-2">
                    <div
                      className={`badge-list-admin badge-${member?.profil} text-white fs-12`}
                    >
                      {member?.profil}
                    </div>
                  </td>
                  <td>
                    <Form.Check
                      type="switch"
                      id={`switch-${member?.id}`}
                      className="custom-switch"
                      checked={member?.is_active === 1}
                      onChange={() => {
                        // Mettez à jour l'état ici
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default UtilisateursActive;
