import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config/config";
import { CircleLoader } from "react-spinners";
import { Offcanvas } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";


const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const DropComments = ({ isVisible, onHide, data }) => {

  const [listComments, setListComments] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [Nodata, setNoData] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [listPseudo, setListPseudo] = useState([]);
  // const [listPseudo, setListPseudo] = useState([]);

  const contractSel = data;

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    if (!isVisible) return;
    setLoading(true);
    let obj = {
      id_contract: contractSel?.idcontracts,
    };
    
    axios
      .post(
        `${config.apiAdmin}/sadmin/comments/read_all_by_contracts`,
        obj,
        Header
      )
      .then((response) => {
        setListComments(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setNoData(true);
        setLoading(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const resetStates = () => {
    setListComments([]);
    setNoData(false);
    setLoading(false);
  };

  return (
    <Offcanvas
      show={isVisible}
      onHide={() => {
        onHide();
        resetStates();
        setNoData(false);
      }}
      placement="end"
    >
      <Offcanvas.Header closeButton className="pb-0">
        <Offcanvas.Title>Commentaires</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0 mt-2">
        {loading ? (
          <div className="sweet-loading bg" style={{ marginTop: "300px" }}>
            <CircleLoader
              color="#36d7b7"
              loading={loading}
              cssOverride={override}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div
            className="h-100 d-flex flex-column justify-content-end pb-3 text-white"
            style={{
              maxHeight: "100%",
              backgroundColor: "rgb(238, 238, 238)",
              // overflow: "auto",
              // backgroundImage: `url(${imgBlue})`, // Utilisez des littéraux de gabarits pour insérer la variable
              // backgroundSize: "cover", // Assure que l'image de fond couvre entièrement le div
              // backgroundPosition: "center", // Centre l'image de fond
            }}
          >
            <div
              className="h-100 scrollable-div"
              style={{ overflowY: "scroll" }}
            >
              {listComments?.length > 0 ? (
                listComments?.map((comment) => (
                  <div
                    key={comment.idcomments}
                    className="border rounded p-2 m-3 bg-white color_bluefonce my-5"
                    style={{
                      boxShadow: "0px 2px 8px 0 rgba(255, 255, 255, 0.5)",
                      position: "relative",
                    }}
                  >
                    <p className="py-2">{comment.content}</p>
                    <div
                      style={{ position: "absolute", top: -20, right: 0 }}
                      className="border bg-info-top text-white rounded p-1 fw-bold"
                    >
                      {comment?.user_id?.slice(0, 10)}
                    </div>
                    <div className="d-flex align-items-center col-12 justify-content-between">
                      <div className="text-end fs-12 border rounded px-2 col-12">
                        {new Date(comment.timestamp).toLocaleString()}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className="fs-3 d-flex justify-content-center align-items-center"
                  style={{ height: "100%" }}
                >
                  Pas de commentaires
                </div>
              )}
            </div>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default DropComments;
