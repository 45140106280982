import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import formatNumber from "../../../utils/formatNumber";

const DeskDetail = ({ dataPrevVsCurrentMonthByAgent }) => {
  const [groupedByDesk, setGroupedByDesk] = useState(undefined);
  const [groupedByDeskAndTlead, setGroupedByDeskAndTlead] = useState(undefined);

  useEffect(() => {
    if (dataPrevVsCurrentMonthByAgent) {
      // Trier les données par desk
      const sortedData = dataPrevVsCurrentMonthByAgent?.sort(
        (a, b) => a.desk - b.desk
      );

      // Grouper les données par desk
      const grouped = sortedData?.reduce((acc, item) => {
        if (!acc[item.desk]) {
          acc[item.desk] = [];
        }
        acc[item.desk]?.push(item);
        return acc;
      }, {});

      setGroupedByDesk(grouped);

      // Grouper les données par tlead_id à l'intérieur de chaque groupe de desk
      const groupedTlead = Object.keys(grouped).reduce((acc, desk) => {
        acc[desk] = grouped[desk].reduce((deskAcc, item) => {
          if (!deskAcc[item.tlead_id]) {
            deskAcc[item.tlead_id] = [];
          }
          deskAcc[item.tlead_id].push(item);
          return deskAcc;
        }, {});
        return acc;
      }, {});

      const reversedGroupedTlead = Object.keys(groupedTlead).reduce((acc, desk) => {
        acc[desk] = Object.fromEntries(Object.entries(groupedTlead[desk]).reverse());
        return acc;
      }, {});

      setGroupedByDeskAndTlead(reversedGroupedTlead);
    }
  }, [dataPrevVsCurrentMonthByAgent]);

  function uuidToColor(uuid) {
    if (uuid) {
      let color = "#" + uuid?.slice(0, 6);
      return color;
    }
    return;
  }

  return (
    <div className="">
      {groupedByDeskAndTlead && Object.keys(groupedByDeskAndTlead).length > 0 ? (
        Object.keys(groupedByDeskAndTlead)?.map((desk) => (
          <Card key={desk} className="col-12 p-0">
            <Card.Header>
              <div className="card-title col-12">
                <div className="d-flex align-items-center">
                  <div className="text-start col-6">
                    Stats agents par desk {desk}
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="d-flex flex-wrap p-0 m-2">
                {Object.keys(groupedByDeskAndTlead[desk])?.map((tlead_id) =>
                  groupedByDeskAndTlead[desk][tlead_id]
                    .filter((item) => item.month_total_curr_and_prev > 0)
                    .sort(
                      (a, b) =>
                        b.month_total_curr_and_prev - a.month_total_curr_and_prev
                    )
                    .map((item, index) => (
                      <div
                        key={index}
                        className={`border pointer mb-3 ${
                          index === 0 ? "first-div" : ""
                        } ${
                          index ===
                          groupedByDeskAndTlead[desk][tlead_id].length - 1
                            ? "last-div"
                            : ""
                        }`}
                        style={{
                          flex: "1 0 10%",
                          maxWidth: "10%",
                          fontSize: "13px",
                        }}
                      >
                        <div className="d-flex text-center input-adapt text-black p-0">
                          <div
                            className={`col-6 border-bottom border-end bg-encaisse-stats-agent  p-0 ${
                              index === 0 ? "first-div-top" : ""
                            }`}
                          >
                            {formatNumber(
                              item?.month_encashed + item?.prev_month_encashed
                            )}{" "}
                            €
                          </div>
                          <div className="col-6 border-bottom p-0">
                            {Math.round(
                              ((item?.month_encashed +
                                item?.prev_month_encashed) *
                                100) /
                                item?.month_total_curr_and_prev
                            ) || 0}
                            %
                          </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center input-adapt p-0">
                          <div
                            className={`col-12 text-center fw-bold ${
                              item.tlead_id === "" || item.tlead_id === null
                                ? "text-black"
                                : "text-white"
                            }`}
                            style={{
                              backgroundColor: uuidToColor(item.tlead_id),
                            }}
                          >
                            {item?.pseudo?.charAt(0).toUpperCase() +
                              item?.pseudo?.slice(1)}
                          </div>
                        </div>
                        <div className="d-flex text-end bg-signer-stats-agent p-1 input-adapt text-black p-0 p-0">
                          <div className="col-6 text-black p-0">
                            {formatNumber(item?.month_signed)} €
                          </div>
                          <div className="col-6 border-start p-0">
                            {formatNumber(item?.prev_month_signed)} €
                          </div>
                        </div>
                        <div className="d-flex text-end bg-preuve-stats-agent p-1 input-adapt text-black p-0">
                          <div className="col-6 p-0">
                            {formatNumber(item?.month_proof)}€
                          </div>
                          <div className="col-6 border-start p-0">
                            {formatNumber(item?.prev_month_proof)} €
                          </div>
                        </div>
                        <div className="d-flex text-end bg-encaisse-stats-agent p-1 input-adapt text-black p-0">
                          <div className="col-6 p-0">
                            {formatNumber(item?.month_encashed)}€
                          </div>
                          <div className="col-6 border-start p-0">
                            {formatNumber(item?.prev_month_encashed)} €
                          </div>
                        </div>
                        <div className="d-flex justify-content-center bg-dark input-adapt p-0">
                          <div className="text-white fw-bold p-0">
                            {formatNumber(item?.month_total_curr_and_prev)} €
                          </div>
                        </div>
                        <div className="d-flex justify-content-center input-adapt p-0">
                          <div>
                            $ {formatNumber(item.month_total_usd_curr_and_prev)}
                          </div>
                        </div>
                      </div>
                    ))
                )}
              </div>
            </Card.Body>
          </Card>
        ))
      ) : (
        <div>No data available</div> // Optionally display a fallback message if groupedData doesn't exist or is empty
      )}
    </div>
  );
};

export default DeskDetail;
