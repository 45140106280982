import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config/config";

const Activite = (user) => {
  const [responses, setResponses] = useState([]);


  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    const ids = ["4482", "4483"];
    ids.forEach((id) => GetNotifByContrat(id));
    // Douglas ici code tres etrange , semble chelou
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetNotifByContrat = (id) => {
    let obj = { id };
    axios
      .post(`${config.apiAdmin}/sadmin/notifs/read_one`, obj, Header)
      .then((response) => {
        setResponses((prevResponses) => {
          // Créer un nouvel ensemble avec les idnotifs existants pour vérification rapide
          const existingIds = new Set(
            prevResponses.map((notif) => notif.idnotifs)
          );
          // Filtrer les réponses en double en vérifiant si l'idnotifs existe déjà
          const newResponses = response.data.filter(
            (notif) => !existingIds.has(notif.idnotifs)
          );
          // Retourner les réponses précédentes combinées avec les nouvelles réponses filtrées
          return [...prevResponses, ...newResponses];
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className="h-100 p-2"
      style={{
        maxHeight: "100%",
        backgroundColor: "rgb(238, 238, 238)",
        // overflow: "auto",
        // backgroundImage: `url(${imgBlue})`, // Utilisez des littéraux de gabarits pour insérer la variable
        // backgroundSize: "cover", // Assure que l'image de fond couvre entièrement le div
        // backgroundPosition: "center", // Centre l'image de fond
      }}
    >
      {responses?.map((notif, index) => {
        return (
          <div
            key={index}
            className="bg-white p-2 mb-2 my-5 rounded"
            style={{
              position: "relative",
              boxShadow: "0px 2px 8px 0 rgba(255, 255, 255, 0.5)",
            }}
          >
            <div className="d-flex justify-content-between2">
              <div className="mt-2">
                <h6>{notif.type}</h6>
                <p>{notif.content}</p>
              </div>
            </div>
            <div
              style={{ position: "absolute", top: -20, right: 0 }}
              className="border bg-info-top text-white rounded p-1 fw-bold"
            >
              {" "}
              {notif.user_id}
              {/* Ajouté cette ligne */}
              {/* Contenu de votre nouvelle div ici */}
            </div>
          </div>
        );
      })}
      <div></div>
    </div>
  );
};

export default Activite;
