import React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SVGICON } from "../../constant/theme";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function LogoutPage(props) {
  return (
      <a className="logout-side-btn" href={"/login"}>
        {SVGICON.Power} <span>Logout</span>
      </a>
  );
}

export default withRouter(connect()(LogoutPage));
