import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setInterdit } from "../Redux/slices/actions.slice";
import GifFrobidden from "../../images/you-cant-do-that-kim-jong-un.gif";

const Interdit = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setInterdit(false));
    }, 2500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white' // Ajout d'un fond blanc
  };

  const gifStyle = {
    maxWidth: '100%',
    maxHeight: '100%'
  };

  return (
    <div style={containerStyle}>
      <img src={GifFrobidden} alt="Forbidden" style={gifStyle} />
    </div>
  );
};

export default Interdit;