import React, { useEffect, useState } from "react";
import { Table, Form, Card } from "react-bootstrap";
import useHeaderRest from "../../../utils/useHeader";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import config from "../../../config/config";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from 'react-router-dom';

const Calls = () => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [filter, setFilter] = useState("");
  const [allCalls, setAllCalls] = useState([]);
  const [loader, setLoader] = useState(false);

  const useHeader = useHeaderRest();

  const { desk } = useParams();

  const AdminDetail = useSelector(getAdminDetails);
  const deskAdmin = AdminDetail.desk;

  const deskValue = desk !== undefined ? [desk] : deskAdmin;

  const FetchCalls = () => {
    setLoader(true);
    axios
      .post(
        `${config.apiAdmin}/sadmin/whsheet/read_call`,
        {
          desk: deskValue,
        },
        useHeader
      )
      .then((response) => {
        setAllCalls(response.data);
        setLoader(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setAllCalls([]);
        setLoader(false);
      });
  };

  useEffect(() => {
    FetchCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedData = React.useMemo(() => {
    let sortableData = [...allCalls];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        const aValue =
          sortConfig.key === "total_duration"
            ? Number(a[sortConfig.key])
            : a[sortConfig.key];
        const bValue =
          sortConfig.key === "total_duration"
            ? Number(b[sortConfig.key])
            : b[sortConfig.key];
        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData.filter((item) =>
      Object.values(item).some((val) =>
        val.toString().toLowerCase().includes(filter.toLowerCase())
      )
    );
  }, [allCalls, sortConfig, filter]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig.key) {
      return <i className="bi bi-arrow-down-up"></i>; // Icône par défaut pour le tri
    }
    if (sortConfig.key === name) {
      if (sortConfig.direction === "ascending") {
        return <i className="bi bi-sort-up"></i>; // Icône pour le tri ascendant
      } else {
        return <i className="bi bi-sort-down"></i>; // Icône pour le tri descendant
      }
    }
    return <i className="bi bi-arrow-down-up"></i>; // Icône par défaut pour les autres colonnes
  };

  return (
    <Card>
      <Card.Header className="fw-bold text-black fs-4">Calls</Card.Header>
      <div className="d-flex justify-content-between">
        <input
          placeholder="Recherche"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="my-3 col-3 ms-5 p-2 rounded border"
        />
        <button
          className="btn btn-dark p-2 my-3 border-rounded fs-14 me-5"
          onClick={() => FetchCalls()}
        >
          {loader ? "Loading" : "Refresh"}
        </button>
      </div>
      <Table bordered hover>
        <thead className="pointer">
          <tr>
            <th
              onClick={() => requestSort("idsheet_calls")}
              className={getClassNamesFor("idsheet_calls")}
            >
              idsheet_calls {getClassNamesFor("idsheet_calls")}
            </th>
            <th
              onClick={() => requestSort("brand_name")}
              className={getClassNamesFor("brand_name")}
            >
              brand_name {getClassNamesFor("brand_name")}
            </th>
            <th
              onClick={() => requestSort("desk")}
              className={getClassNamesFor("desk")}
            >
              desk {getClassNamesFor("desk")}
            </th>
            <th
              onClick={() => requestSort("agent")}
              className={getClassNamesFor("agent")}
            >
              agent {getClassNamesFor("agent")}
            </th>
            <th
              onClick={() => requestSort("agent_type")}
              className={getClassNamesFor("agent_type")}
            >
              agent_type {getClassNamesFor("agent_type")}
            </th>
            <th
              onClick={() => requestSort("clients_called")}
              className={getClassNamesFor("clients_called")}
            >
              client_called {getClassNamesFor("clients_called")}
            </th>
            <th
              onClick={() => requestSort("total_calls")}
              className={getClassNamesFor("total_calls")}
            >
              total_calls {getClassNamesFor("total_calls")}
            </th>
            <th
              onClick={() => requestSort("avg_duration")}
              className={getClassNamesFor("avg_duration")}
            >
              avg_duration {getClassNamesFor("avg_duration")}
            </th>
            <th
              onClick={() => requestSort("total_duration")}
              className={getClassNamesFor("total_duration")}
            >
              total_duration {getClassNamesFor("total_duration")}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index}>
              <td>{item.idsheet_calls}</td>
              <td>{item.brand_name}</td>
              <td>{item.desk}</td>
              <td>{item.agent}</td>
              <td>{item.agent_type}</td>
              <td>{item.clients_called}</td>
              <td>{item.total_calls}</td>
              <td>{Number(item.avg_duration).toFixed(2)}</td>
              <td>{Number(item.total_duration).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center pointer p-1">
        <Link to="/pes"> +
        </Link>
      </div>
    </Card>
  );
};

export default Calls;
