import React from "react";
import TableauChefSupportHorizontal from "./Stats/GraphChefSupportHorizontal";
import {
  Row,
  Col,
} from "react-bootstrap";
const PageStatistiques = () => {
  return (
    <>
      <Row>
        <Col lg={12}>
          <TableauChefSupportHorizontal />
        </Col>
      </Row>
    </>
  );
};

export default PageStatistiques;
