import React, { useState } from "react";

import { Offcanvas } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Chat from "../../Global/Table/Chat";
import Activite from "../../Global/Table/Activite";
import Notes from "../../Global/Table/Notes";
import { useParams } from "react-router-dom";


const DropUpdateContrat = ({ isVisible, onHide, data }) => {

  const [selectedTitle, setSelectedTitle] = useState("Chat");
  const handleTitleClick = (title) => {
    setSelectedTitle(title);
  };

  return (
    <Offcanvas
      show={isVisible}
      onHide={() => {
        onHide();
      }}
      placement="end"
      style={{ width: "600px" }}
      className="bg-light"
    >
      <Offcanvas.Header>
      <div className="d-flex justify-content-around col-12 title-Modif border-bottom text-black fw-bold">
          <div
            style={{
              borderBottom:
                selectedTitle === "Notes" ? "3px solid #E3B637" : "none",
            }}
            onClick={() => handleTitleClick("Notes")}
            className="col-3 text-center pb-2"
          >
            Notes
          </div>
          <div
            style={{
              borderBottom:
                selectedTitle === "Chat" ? "3px solid #25e87f" : "none",
            }}
            onClick={() => handleTitleClick("Chat")}
            className="col-3 text-center pb-2"
          >
            Chat
          </div>
          <div
            style={{
              borderBottom:
                selectedTitle === "Activite" ? "3px solid #25e87f" : "none",
            }}
            onClick={() => handleTitleClick("Activite")}
            className="col-3 text-center pb-2"
          >
            Activite
          </div>
        </div>
      </Offcanvas.Header>

      {selectedTitle === "Activite" && <Activite contrat={data} />}
      {selectedTitle === "Notes" && <Notes contrat={data} />}
      {selectedTitle === "Chat" && <Chat contrat={data} />}
    </Offcanvas>
  );
};

export default DropUpdateContrat;
