import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import RadarPes from "./RadarPes";
import { getAdminDesks } from "../../Redux/slices/admin.slice";
import useHeaderRest from "../../../utils/useHeader";
import axios from "axios";
import config from "../../../config/config";
import TerrainFoot from "../../../images/terrainfc25.webp";
import fondPerfo from "../../../images/fondpes1.webp";
import cardfifa from "../../../images/badgefifa.png";
import cardfifaor from "../../../images/cardfifaOr.webp";
import cardfifaorange from "../../../images/cardfifaorange.webp";
import cardfifablancbleu from "../../../images/cardfifablancbleu.webp";
import cardfifafluo from "../../../images/cardfifafluo.webp";
import cardfifatech from "../../../images/cardfifatech.webp";
import carfifa4e from "../../../images/carfifa4e.webp";
import cardfifa1er from "../../../images/cardfifa1er.webp";
import cardfifa2 from "../../../images/cardfifa2.webp";
import { data } from "jquery";
// import cardfifa2 from "../../../images/cardfifa2.webp";
import formatNumber from "../../../utils/formatNumber";
import Badge from "./Badge";
import RankDesk from "./RankDesk";
import RankDeskDay from "./RankDeskDay";
import RankSellerDay from "./RankSellerDay";

const PagesPes = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const useHeader = useHeaderRest();

  const GET_ADMIN_DESKS = useSelector(getAdminDesks);
  const { desk } = useParams();
  const deskIdAsNumber = parseInt(desk, 10);

  const [combinedData, setCombinedData] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [personneHover, setPersonneHover] = useState(null);
  const [goodDesk, setGoodDesk] = useState();
  const [loading, setLoading] = useState(true); // État pour le loader
  const [dataToShow, setDataToShow] = useState({});

  const [showCompare, setShowCompare] = useState(false);

  const DataMonthlyContracts = () => {
    return axios.post(
      `${config.apiAdmin}/statsNode/manager/card_monthly_contracts`,
      { desk: goodDesk },
      useHeader
    );
  };

  const AverageRetap = () => {
    return axios.post(
      `${config.apiAdmin}/statsNode/manager/graph_average_retap_fifa`,
      { desk: [goodDesk] },
      useHeader
    );
  };

  const GraphFifa = () => {
    return axios.post(
      `${config.apiAdmin}/statsNode/manager/graph_fifa`,
      { desk: goodDesk },
      useHeader
    );
  };

  useEffect(() => {
    setGoodDesk(deskIdAsNumber ? [deskIdAsNumber] : GET_ADMIN_DESKS);
  }, [desk, GET_ADMIN_DESKS, deskIdAsNumber]);

  useEffect(() => {
    if (goodDesk) {
      Promise.all([
        // DataConversion(),
        DataMonthlyContracts(),
        // GraphMoyenneAgent(),
        AverageRetap(),
        // ContratMax(),
        GraphFifa(),
      ])
        .then((responses) => {
          console.log("API Responses:", responses); // Ajoute un log pour vérifier les données
          const combined = responses.flatMap((response) => {
            if (response.data.message === "message : no-data") {
              return [];
            }
            return response.data.data;
          });
          const groupedByPseudo = combined.reduce((acc, item) => {
            if (!acc[item.pseudo]) {
              acc[item.pseudo] = [];
            }
            acc[item.pseudo].push(item);
            return acc;
          }, {});
          setCombinedData(groupedByPseudo);
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des données :", error);
        });
    }
  }, [goodDesk]);

  useEffect(() => {
    if (combinedData && Object.keys(combinedData).length > 0) {
      const transformedData = Object.entries(combinedData).map(
        ([pseudo, items]) => {
          // console.log("items",items)

          const conversionTableau = Number(
            items.find((item) => item.conversion_count)?.conversion_count || 0
          );
          const conversionGraph = Number(
            items.find((item) => item.percentage_of_max_conversion)
              ?.percentage_of_max_conversion || 0
          );

          const contratTable = Number(
            items.find((item) => item.total_contracts)?.total_contracts || 0
          );
          const contratGraph = Number(
            items.find((item) => item.noteContracts)?.noteContracts || 7
          );

          const retapTable = Number(
            items.find((item) => item.avg_days_between_contracts)
              ?.avg_days_between_contracts || 5
          );
          const retapGraph = Number(
            items.find((item) => item.mote_retap)?.mote_retap || 5
          );

          const totalTable = Number(
            items.find((item) => item.total_encash_usd)?.total_encash_usd || 10
          );
          const totalGraph = Number(
            items.find((item) => item.percentage_of_max_total)
              ?.percentage_of_max_total || 10
          );

          const moyenneTable = Number(
            items.find((item) => item.average_encash_usd)?.average_encash_usd ||
              7
          );
          const moyenneGraph = Number(
            items.find((item) => item.percentage_of_max_average)
              ?.percentage_of_max_average || 7
          );

          const maxTable = Number(
            items.find((item) => item.max_encash_usd)?.max_encash_usd || 5
          );
          const maxGraph = Number(
            items.find((item) => item.percentage_of_max_encash)
              ?.percentage_of_max_encash || 5
          );

          const note = Number(items.find((item) => item.note)?.note || 20);

          const noteFinal = note + retapGraph + contratGraph;

          const transformedItem = {
            name: pseudo,

            conversionTableau,
            conversionGraph,
            contratTable,
            contratGraph,
            retapTable,
            retapGraph,
            totalTable,
            totalGraph,
            moyenneTable,
            moyenneGraph,
            maxTable,
            maxGraph,

            avatar: items.find((item) => item.avatar_url)?.avatar_url || 0,
            // note: Math.round(Note), // Ajouter la clé Note avec la moyenne des statistiques
            note: noteFinal,
          };

          return transformedItem;
        }
      );
      // setSelectedPerson(transformedData[0]);
      transformedData.sort((a, b) => b.note - a.note);
      // setSelectedPerson(transformedData[0]);
      setDataToShow(transformedData);

      setLoading(false); // Désactiver le loader après la transformation des données
    }
  }, [combinedData]);

  if (loading) {
    return (
      <div className="bg-dark d-flex justify-content-center align-items-center vh-100">
        <div
          className="spinner-border text-light"
          role="status"
          style={{ width: "5rem", height: "5rem" }}
        >
          {/* <span className="sr-only">Loading...</span> */}
        </div>
      </div>
    ); // Afficher le loader pendant le chargement
  }

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-start"
        //  style={{ backgroundImage: `url(${fondPerfo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        style={{ height: "69vh", width: "100%" }}
      >
        <div
          className="rounded position-relative"
          style={{ height: "100%", width: "100%" }}
        >
          <img
            src={TerrainFoot}
            alt="Terrain de foot"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              opacity: 0.8,
              // borderRadius: "10px",
            }}
          />
          <div>
            <div>
              {dataToShow?.slice(0, 5).map((item, index) => {
                const positions = [
                  { top: "20%", left: "50%" },
                  { top: "40%", left: "30%" },
                  { top: "40%", left: "70%" },
                  { top: "70%", left: "40%" },
                  { top: "70%", left: "60%" },
                ];
                const positionsAvec = [
                  { top: "20%", left: "35%" },
                  { top: "40%", left: "20%" },
                  { top: "40%", left: "50%" },
                  { top: "70%", left: "27%" },
                  { top: "70%", left: "42%" },
                ];
                return (
                  <div
                    key={item.name}
                    className={`position-absolute d-flex flex-column align-items-center justify-content-center rounded p-2 px-4
`}
                    style={{
                      ...(showCompare
                        ? positionsAvec[index]
                        : positions[index]),
                      transform: "translate(-50%, -50%)",
                      cursor: "pointer",
                      backgroundImage: `url(${
                        index === 0
                          ? cardfifa1er
                          : index === 1
                          ? cardfifa2
                          : index === 2
                          ? cardfifatech
                          : index === 3
                          ? carfifa4e
                          : index === 4
                          ? cardfifafluo
                          : cardfifa
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "200px",
                      width: "150px",
                    }}
                    // onClick={() => {
                    //   setSelectedPerson(item);
                    //   setShowCompare(true);
                    // }}
                    // onMouseEnter={() => setPersonneHover(item)}
                    // onMouseLeave={() => setPersonneHover(null)}
                  >
                    {/* Badge en haut à droite */}
                    <div
                      className={`position-absolute top-0 start-0 ${
                        index === 3 || index === 4 ? "mt-4" : ""
                      }`}
                      style={{
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        transform: "translate(50%, -50%)",
                      }}
                    >
                      <Badge index={index} list={false} rounded={false} />
                    </div>
                    <div
                      className={`position-absolute top-0 start-50 ${
                        index === 3 || index === 4 ? "mt-4" : ""
                      }`}
                      style={{
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        transform: "translate(50%, -50%)",
                      }}
                    >
                      <Badge note={item?.note} list={false} rounded={true} />
                    </div>

                    {item.avatar === 0 ||
                    item.avatar === "default_avatar_url" ? (
                      <div
                        className="mt-1 mb-1"
                        style={{
                          height: "80px",
                          width: "80px",
                          borderRadius: "50%",
                          backgroundColor: "#dae0e5",
                        }}
                      />
                    ) : (
                      <img
                        className="mt-1 mb-1"
                        src={item?.avatar}
                        alt={item?.pseudo}
                        style={{ height: "80px", borderRadius: "50%" }}
                      />
                    )}
                    <div className="col-12 p-0">
                      <p
                        className={`text-center p-0 mb-1 ${
                          index === 0
                            ? "text-card-1"
                            : index === 1
                            ? "text-card-2"
                            : index === 2
                            ? "text-card-3"
                            : index === 3
                            ? "text-card-4"
                            : index === 4
                            ? "text-card-5"
                            : "text-white"
                        } ${
                          index === 0
                            ? "p-fifa-1"
                            : index === 1
                            ? "p-fifa-2"
                            : index === 2
                            ? "p-fifa-3"
                            : index === 3
                            ? "p-fifa-4"
                            : index === 4
                            ? "p-fifa-5"
                            : "border-bottom"
                        }`}
                      >
                        {item?.name?.slice(0, 8)}
                      </p>
                      <div className={`d-flex justify-content-between pb-1`}>
                        <div
                          className={`col-6 d-flex justify-content-center ${
                            index === 0
                              ? "text-card-1"
                              : index === 1
                              ? "text-card-2"
                              : index === 2
                              ? "text-card-3"
                              : index === 3
                              ? "text-card-4"
                              : index === 4
                              ? "text-card-5"
                              : "text-white"
                          } ${
                            index === 0
                              ? "border-right-1"
                              : index === 1
                              ? "border-right-2"
                              : index === 2
                              ? "border-right-3"
                              : index === 3
                              ? "border-right-4"
                              : index === 4
                              ? "border-right-5"
                              : ""
                          }`}
                        >
                          <div className="me-1">{item?.conversionTableau}</div>
                          <div className="ms-1 fw-normal">CV</div>
                        </div>
                        <div
                          className={`col-6 d-flex justify-content-center  ${
                            index === 0
                              ? "text-card-1"
                              : index === 1
                              ? "text-card-2"
                              : index === 2
                              ? "text-card-3"
                              : index === 3
                              ? "text-card-4"
                              : index === 4
                              ? "text-card-5"
                              : "text-white"
                          }`}
                        >
                          <div className="me-1">{item?.contratTable}</div>
                          <div className="ms-1 fw-normal">CR</div>
                        </div>
                      </div>
                      <p
                        className={`text-center p-0 m-0 ${
                          index === 0
                            ? "text-card-1"
                            : index === 1
                            ? "text-card-2"
                            : index === 2
                            ? "text-card-3"
                            : index === 3
                            ? "text-card-4"
                            : index === 4
                            ? "text-card-5"
                            : "text-white"
                        } ${
                          index === 0
                            ? "m-fifa-1"
                            : index === 1
                            ? "m-fifa-2"
                            : index === 2
                            ? "m-fifa-3"
                            : index === 3
                            ? "m-fifa-4"
                            : index === 4
                            ? "m-fifa-5"
                            : "border-top"
                        }`}
                      >
                        $ {formatNumber(item?.totalTable)}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* {showCompare && (
              <div
                className="position-absolute animate-slide-in"
                style={{
                  left: "55%",
                  transform: "translateY(0%)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowCompare(false);
                  setSelectedPerson(null);
                }}
              >
                <h5 className="text-center bg-transparent text-white border rounded-5 px-2 mt-2">
                  Fermer
                </h5>
              </div>
            )} */}
            <div>
              <div
                className="position-absolute"
                style={{ height: "100%", width: "20%" }}
              >
                <RankDeskDay />
              </div>
            </div>
            <div>
              <div
                className="position-absolute"
                style={{ height: "100%", width: "20%", right:"0px" }}
              >
                <RankSellerDay />
              </div>
            </div>
            {showCompare && (
              <div
                className="position-absolute h-100"
                style={{
                  top: "50%",
                  right: "0px",
                  width: "600px",
                  transform: "translateY(-50%)",
                }}
              >
                <div
                  className="animate-slide-in"
                  style={{
                    height: "100%",
                    width: "100%",
                    background:
                      "linear-gradient(to right, rgba(0, 0, 0, 0.005), rgba(0, 0, 0, 0.7))",
                  }}
                >
                  <div className="d-flex justify-content-center align-items-center h-50 col-12">
                    <div className="col-12 h-100 d-flex align-items-center justify-content-center">
                      <RadarPes
                        personneSel={selectedPerson}
                        personneHover={personneHover}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center h-50 col-12 fs-16">
                    <div className="col-6 p-0 mb-5">
                      <div className="text-end me-2 fs-24 fw-bold text-sel-fifa text-capitalize">
                        {selectedPerson?.name}
                      </div>
                      <div className="d-flex align-items-center justify-content-center mb-3">
                        {selectedPerson?.avatar === 0 ? (
                          <div
                            style={{
                              height: "60px",
                              width: "60px",
                              borderRadius: "50%",
                              backgroundColor: "#dae0e5",
                            }}
                          />
                        ) : (
                          <img
                            src={selectedPerson?.avatar}
                            alt={selectedPerson?.name}
                            style={{ height: "60px" }}
                          />
                        )}
                        <h3 className="text-center fw-bold text-white">
                          {selectedPerson?.note}
                        </h3>
                      </div>
                      <ul className="list-pes text-white">
                        <li>
                          <div>Conversion:</div>
                          <div
                            className={
                              !personneHover ||
                              selectedPerson?.conversionTableau ===
                                personneHover?.conversionTableau
                                ? "text-white"
                                : selectedPerson?.conversionTableau >
                                  personneHover?.conversionTableau
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {selectedPerson?.conversionTableau}
                          </div>
                        </li>
                        <li>
                          <div>Nb contrats: </div>
                          <div
                            className={
                              !personneHover ||
                              selectedPerson?.contratTable ===
                                personneHover?.contratTable
                                ? "text-white"
                                : selectedPerson?.contratTable >
                                  personneHover?.contratTable
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {selectedPerson?.contratTable}
                          </div>
                        </li>
                        <li>
                          <div>Retap:</div>
                          <div
                            className={
                              !personneHover ||
                              selectedPerson?.retapTable ===
                                personneHover?.retapTable
                                ? "text-white"
                                : selectedPerson?.retapTable <
                                  personneHover?.retapTable
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {selectedPerson?.retapTable} j
                          </div>
                        </li>
                        <li>
                          <div>Encaissement:</div>
                          <div
                            className={
                              !personneHover ||
                              selectedPerson?.totalTable ===
                                personneHover?.totalTable
                                ? "text-white"
                                : selectedPerson?.totalTable >
                                  personneHover?.totalTable
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {formatNumber(selectedPerson?.totalTable)} $
                          </div>
                        </li>
                        <li>
                          <div>Encaissement le plus haut:</div>
                          <div
                            className={
                              !personneHover ||
                              selectedPerson?.maxTable ===
                                personneHover?.maxTable
                                ? "text-white"
                                : selectedPerson?.maxTable >
                                  personneHover?.maxTable
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {formatNumber(selectedPerson?.maxTable)} $
                          </div>
                        </li>
                        <li>
                          <div>Moyenne encaissement:</div>
                          <div
                            className={
                              !personneHover ||
                              selectedPerson?.moyenneTable ===
                                personneHover?.moyenneTable
                                ? "text-white"
                                : selectedPerson?.moyenneTable >
                                  personneHover?.moyenneTable
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {formatNumber(selectedPerson?.moyenneTable)} $
                          </div>
                        </li>
                      </ul>
                    </div>
                    {personneHover && (
                      <div className="col-6 p-0 mb-5">
                        <div className="text-end me-2 fs-24 fw-bold text-hover-fifa text-capitalize">
                          {personneHover?.name}
                        </div>
                        <div className="d-flex align-items-center justify-content-center mb-3 text-white">
                          {personneHover?.avatar === 0 ? (
                            <div
                              style={{
                                height: "60px",
                                width: "60px",
                                borderRadius: "50%",
                                backgroundColor: "#dae0e5",
                              }}
                            />
                          ) : (
                            <img
                              src={personneHover?.avatar}
                              alt={personneHover?.name}
                              style={{ height: "60px" }}
                            />
                          )}
                          <h3 className="text-center fw-bold text-white">
                            {personneHover?.note}
                          </h3>
                        </div>
                        <ul className="list-pes-hover text-white text-pes-sel-2">
                          <li>
                            <div>Conversion:</div>
                            <div
                              className={
                                !selectedPerson ||
                                personneHover?.conversionTableau ===
                                  selectedPerson?.conversionTableau
                                  ? "text-white"
                                  : personneHover?.conversionTableau >
                                    selectedPerson?.conversionTableau
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {personneHover?.conversionTableau}
                            </div>
                          </li>
                          <li>
                            <div>Nb contrats: </div>
                            <div
                              className={
                                !selectedPerson ||
                                personneHover?.contratTable ===
                                  selectedPerson?.contratTable
                                  ? "text-white"
                                  : personneHover?.contratTable >
                                    selectedPerson?.contratTable
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {personneHover?.contratTable}
                            </div>
                          </li>
                          <li>
                            <div>Retap j:</div>
                            <div
                              className={
                                !selectedPerson ||
                                personneHover?.retapTable ===
                                  selectedPerson?.retapTable
                                  ? "text-white"
                                  : personneHover?.retapTable <
                                    selectedPerson?.retapTable
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {personneHover?.retapTable} j
                            </div>
                          </li>
                          <li>
                            <div>Encaissement:</div>
                            <div
                              className={
                                !selectedPerson ||
                                personneHover?.totalTable ===
                                  selectedPerson?.totalTable
                                  ? "text-white"
                                  : personneHover?.totalTable >
                                    selectedPerson?.totalTable
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {formatNumber(personneHover?.totalTable)} $
                            </div>
                          </li>
                          {/* <li>
                            <div>NumContract:</div>
                            <div
                              className={
                                !selectedPerson ||
                                personneHover?.NumContract ===
                                  selectedPerson?.NumContract
                                  ? "text-white"
                                  : personneHover?.NumContract >
                                    selectedPerson?.NumContract
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {personneHover?.NumContract}
                            </div>
                          </li> */}
                          <li>
                            <div>Encaissement le plus haut:</div>
                            <div
                              className={
                                !selectedPerson ||
                                personneHover?.maxTable ===
                                  selectedPerson?.maxTable
                                  ? "text-white"
                                  : personneHover?.maxTable >
                                    selectedPerson?.maxTable
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {formatNumber(personneHover?.maxTable)} $
                            </div>
                          </li>
                          <li>
                            <div>Moyenne encaissement:</div>
                            <div
                              className={
                                !selectedPerson ||
                                personneHover?.moyenneTable ===
                                  selectedPerson?.moyenneTable
                                  ? "text-white"
                                  : personneHover?.moyenneTable >
                                    selectedPerson?.moyenneTable
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {formatNumber(personneHover?.moyenneTable)} $
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        <div
          className="h-25 card-container bg-list-remplacent"
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "flex-start", // Aligner les cartes au début
            overflowX: "auto", // Scroll horizontal si nécessaire
            padding: "10px", // Marges internes autour des cartes
            minHeight: "125px",
            scrollPaddingLeft: "10px", // Ajoute un padding lors du scroll
            whiteSpace: "nowrap", // Empêche le contenu de se briser sur plusieurs lignes
          }}
        >
          {Array.isArray(dataToShow) &&
            dataToShow.slice(5, 100).map((card, index) => (
              <React.Fragment key={index}>
                {index === 0 && (
                  <div>
                    <h4 className="text-vertical-fifa m-0 text-white text-uppercase">
                      Remplacents
                    </h4>
                  </div>
                )}
                {index === 5 && (
                  <div>
                    <h4 className="text-vertical-fifa m-0 text-white text-uppercase">
                      Reserve
                    </h4>
                  </div>
                )}
                <div
                  className={`d-flex flex-column align-items-center justify-content-around position-relative`}
                  style={{
                    cursor: "pointer",
                    backgroundImage: `url(${
                      index >= 0 && index <= 4
                        ? cardfifaorange
                        : cardfifablancbleu
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "150px", // Height of the card
                    minWidth: "120px", // Width of the card
                    marginRight: "10px", // Spacing between the cards
                    flexShrink: 0.8, // Prevents cards from shrinking
                  }}
                  // onClick={() => {
                  //   setSelectedPerson(card);
                  //   setShowCompare(true);
                  // }}
                  // onMouseEnter={() => setPersonneHover(card)}
                  // onMouseLeave={() => setPersonneHover(null)}
                >
                  <div
                    className="card-container-fifa"
                    style={{ position: "relative" }}
                  >
                    <div className="badge-top-right">
                      <Badge index={index} list={true} />
                    </div>
                    <div
                      className="position-absolute top-0 start-50"
                      style={{
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        transform: "translate(50%, -50%)",
                        paddingRight: "15px",
                        paddingTop: "5px",
                      }}
                    >
                      <Badge note={card?.note} list={false} rounded={true} />
                    </div>

                    {card.avatar === 0 ||
                    card.avatar === "default_avatar_url" ? (
                      <div
                        style={{
                          height: "60px",
                          width: "60px",
                          borderRadius: "50%",
                          backgroundColor: "#dae0e5",
                        }}
                      />
                    ) : (
                      <img
                        src={card?.avatar}
                        alt={card?.pseudo}
                        style={{ height: "60px", borderRadius: "50%" }}
                      />
                    )}
                  </div>
                  <div className="col-8 p-0">
                    <p
                      className={`text-center p-fifa p-0 mb-1 ${
                        index <= 4
                          ? "text-card-remplacent"
                          : "text-card-reserve"
                      }`}
                    >
                      {card?.name?.slice(0, 8)}
                    </p>
                    <div className="d-flex justify-content-between m-fifa pb-1">
                      <div
                        className={`col-6 d-flex justify-content-center border-right ${
                          index <= 4
                            ? "text-card-remplacent"
                            : "text-card-reserve"
                        }`}
                      >
                        <div className="">{card?.conversionTableau}</div>
                        <div className="ms-1 fw-normal">CV</div>
                      </div>
                      <div
                        className={`col-6 d-flex justify-content-center ${
                          index <= 4
                            ? "text-card-remplacent"
                            : "text-card-reserve"
                        }`}
                      >
                        <div className="">{card?.contratTable}</div>
                        <div className="ms-1 fw-normal">CR</div>
                      </div>
                    </div>
                    <p
                      className={`text-center p-0 m-0 mb-2 ${
                        index <= 4
                          ? "text-card-remplacent"
                          : "text-card-reserve"
                      }`}
                    >
                      $ {formatNumber(card?.totalTable)}
                    </p>
                  </div>
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
    </>
  );
};

export default PagesPes;
