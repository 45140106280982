import React, { useEffect, useState } from "react";
import { Card, Form, Table } from "react-bootstrap";
import axios from "axios";
import config from "../../config/config";

const Psp = () => {
  const [allPsp, setAllPsp] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(null);
  const [PspActivate, setPspkActivate] = useState(true);

  const handleClick = () => {
    setAllPsp((prevPsp) => {
      const newPsp = [
        ...prevPsp,
        {
          idpsp: "",
          name: "",
          is_active: "",
          isEditing: true,
          created_at: "", // Ajoutez cette ligne
        },
      ];

      return newPsp;
    });
    window.location.href = "#lastElement";
  };

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const fetchPsp = () => {
    axios
      .get(`${config.apiAdmin}/sadmin/psp/read_all_admin`, Header)
      .then((response) => {
        setAllPsp(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const CreatePsp = (inputValue) => {
    const obj = {
      name: inputValue,
      is_active: "1",
    };
    axios
      .put(`${config.apiAdmin}/sadmin/psp/create`, obj, Header) // Remplacez ceci par votre URL et vos paramètres
      .then((response) => {
        fetchPsp();
      })
      .catch((error) => {
        console.error("There was an error! create Brand", error);
      });
  };
  useEffect(() => {
    fetchPsp(); // Appeler la fonction lors du montage du composant
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deletePsp = (Psp) => {
    axios
      .delete(
        `${config.apiAdmin}/sadmin/psp/delete`,
        { data: { id: Psp } },
        Header
      )
      .then((response) => {
        fetchPsp();
      })
      .catch((error) => {
        console.error("There was an error! delete customerbanks", error);
      });
  };

  const UpadtePsp = (Psp) => {
    let isActive;

    if (PspActivate) {
      isActive = 1;
    } else {
      isActive = 0;
    }
    setUpdateIndex(null);
    axios
      .patch(
        `${config.apiAdmin}/sadmin/psp/toggleActivation`,
        { id: Psp, is_active: isActive },
        Header
      )
      .then((response) => {
        fetchPsp();
      })
      .catch((error) => {
        console.error("There was an error! delete desk", error);
      });
  };

  return (
    <div>
      <div className="d-flex border-bottom">
        <div
          className={`col-auto mx-5 text-center cursor-pointer fs-16 h-100 ${"underline"}`}
        >
          PSP
        </div>
      </div>
      <div className="mt-4 pt-2 pb-2 border-bottom d-flex align-items-center justify-content-between">
        <div>
          <div className="ms-5 fw-bold text-black">
            Ajouter, Activer ou desactiver des psp
          </div>
          <div className="ms-5 text-black">
            Cette page vous permet d’ajouter, d’activer et de désactiver des
            psp.
          </div>
        </div>
        <div
          className="border me-5 px-4 rounded-3 bg-green-target text-black fw-bold"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          + Ajouter
        </div>
      </div>
      <Card className="mt-5">
        <Table bordered className="table-no-vertical-borders">
          <thead className="border-0">
            <tr className="border-0">
              <th className="col-1 border-0 rounded"></th>
              <th className="col-2">Date Ajout</th>
              <th className="col-6 text-start">Nom</th>
              <th className="col-2">Statut</th>
              <th className="col-1 border-0 rounded">Action</th>
            </tr>
          </thead>
          <tbody>
            {allPsp?.map((member, index) => (
              <tr
                key={member?.id}
                style={{ height: "80px" }}
                id={index === allPsp?.length - 1 ? "lastElement" : undefined}
              >
                <td className="col-1 text-end"></td>
                <td className="col-2 fs-12">
                  {member?.created_at && !isNaN(Date.parse(member?.created_at))
                    ? new Date(member?.created_at).toISOString().split("T")[0]
                    : new Date().toISOString().split("T")[0]}
                </td>
                <td className="text-start text-black fw-bold col-6">
                  {member.isEditing ? (
                    <input
                      style={{ height: "40px" }}
                      className="bg-green-input rounded-3"
                      type="text"
                      value={member.lastName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setInputValue(value);
                        setAllPsp((Psps) =>
                          Psps?.map((Psp) =>
                            Psp?.idpsp === member?.idpsp
                              ? { ...Psp, inputValue: value }
                              : Psp
                          )
                        );
                      }}
                    />
                  ) : (
                    member.name
                  )}
                </td>
                <td className="col-2">
                  {member?.isEditing ? (
                    <>
                      <Form.Check
                        type="switch"
                        className="custom-switch"
                        defaultChecked={member?.is_active !== 0}
                        disabled
                      />
                    </>
                  ) : (
                    <>
                      <Form.Check
                        type="switch"
                        // id={`switch-${member?.name}`}
                        className={`custom-switch ${
                          updateIndex === index ? "bg-green-input" : ""
                        } rounded-3 pb-1`}
                        defaultChecked={member?.is_active !== 0}
                        disabled={updateIndex !== index}
                        onChange={() => {
                          setPspkActivate(!member?.is_active);
                        }}
                      />
                    </>
                  )}
                </td>
                {member.isEditing ? (
                  <td>
                    <button
                      type="button"
                      className="px-4 py-2 rounded-3 bg-green-target text-black fw-bold border-0"
                      onClick={() => CreatePsp(inputValue)}
                    >
                      Valider
                    </button>
                  </td>
                ) : (
                  <td
                    className="d-flex justify-content-center col-12 align-items-center"
                    style={{ height: "80px" }}
                  >
                    {isClicked === index ? (
                      <>
                        <div
                          className="mx-1 bg-green-target border-0"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            deletePsp(member?.idpsp)
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            className="bi bi-check-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                            <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                          </svg>
                        </div>
                        <div
                          className="mx-1 bg-danger"
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsClicked(false)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            className="bi bi-x-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                          </svg>
                        </div>
                      </>
                    ) : updateIndex === index ? (
                      <>
                        <div
                          className="mx-1 border-0 "
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            UpadtePsp(member?.idpsp)
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            className="bi bi-check-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                            <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                          </svg>
                        </div>
                        <div
                          className="mx-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => setUpdateIndex(false)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            className="bi bi-x-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                          </svg>
                        </div>
                      </>
                    ) : (
                      <>
                        <div onClick={() => setUpdateIndex(index)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-pencil-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                          </svg>
                        </div>
                        <div onClick={() => setIsClicked(index)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="red"
                            className="bi bi-trash3 ms-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                          </svg>
                        </div>
                      </>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};

export default Psp;
