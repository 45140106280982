import React, { useState, useEffect, useContext } from "react";
import { Dropdown, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IMAGES } from "../../constant/theme";
import { ThemeContext } from "../../../context/ThemeContext";
import axios from "axios";
import config from "../../../config/config";
import { useSelector, useDispatch } from "react-redux";
import {
  getToken,
  getPseudo,
} from "../../../PagesCrm/Redux/slices/admin.slice";
import {
  getNotifs,
  setNotifs,
  getChats,
  setChats,
  getSockets,
  deleteSockets,
  getSocketsDrings
} from "../../../PagesCrm/Redux/slices/notifSocket.slice";
import {
  setFilters,
  setAllContracts,
} from "../../../PagesCrm/Redux/slices/contract.slice";
import { getAdminDetails } from "../../../PagesCrm/Redux/slices/admin.slice";
import { useNavigate } from "react-router-dom";


// import LogoutPage from './Logout';
import AlertsBar from "../../../PagesCrm/Components/AlertsBar";

// const NotificationBlog = () => {

// 	return (
// 		<>
// 			<li>
// 				<div className="timeline-panel">
// 					<div className="media me-2">
// 						<img alt="images" width={50} src={IMAGES.Avatar} />
// 					</div>
// 					<div className="media-body">
// 						<h6 className="mb-1">ici doivente etre mis les futur notifs</h6>
// 						<small className="d-block">29 July 2022 - 02:26 PM</small>
// 					</div>
// 				</div>
// 			</li>
// 		</>
// 	)

// }

const Header = () => {
  const [headerFix, setheaderFix] = useState(false);
  // const [chats, setChats] = useState([]);
  const GET_TOKEN = useSelector(getToken);
  const GET_NOTIFS = useSelector(getNotifs);
  const GET_CHATS = useSelector(getChats);
  const GET_SOCKETS = useSelector(getSockets);
  const GET_SOCKETS_DRINGS = useSelector(getSocketsDrings);
  // const GET_FILTERS = useSelector(getFilters);

  const GET_ = useSelector(getAdminDetails);
  const pseudo = GET_?.pseudo;
  const profile = GET_?.profil;
  const avatar = GET_?.avatar;
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  const handleClick = () => {
    console.log("clicked");
  };

  const updateNotifsForSocket = (notifs) =>
    notifs.map((notif) => {
      if (notif.isSocket === true) {
        return { ...notif, isSocket: false };
      }
      return notif;
    });

  const handleDeleteNotif = () => {
    // remise a zero de isSocket
    dispatch(setNotifs(updateNotifsForSocket(GET_NOTIFS)));

    // Suppression de l'array contenant les sockets
    dispatch(deleteSockets());
  };

  const [showDropdownChat, setShowDropdownChat] = useState(false);

  const [activeDrop, setActiveDrop] = useState(false);

  const [countChat, setCountChat] = useState(0);
  const { background, changeBackground } = useContext(ThemeContext);
  function ChangeColor() {
    if (background.value === "light") {
      changeBackground({ value: "dark", label: "Dark" });
    } else {
      changeBackground({ value: "light", label: "Light" });
    }
  }

  ///// CHAT

  useEffect(() => {
    const fetchDataChat = async () => {
      const url = `${config.apiAdmin}/sadmin/notifs/read_chat`;
      const headers = {
        Authorization: `Bearer ${GET_TOKEN}`,
      };
      try {
        const response = await axios.get(url, { headers });
        const dataChat = response.data.data;
        const dataCount = response.data.unreadCount;
        dispatch(setChats(dataChat));
        setCountChat(dataCount);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchDataChat();
  }, [GET_SOCKETS]);

  const ChatIsRead = (notif) => {
    console.log(notif);
    const routeContract = notif.contract_id;
    const headers = {
      Authorization: `Bearer ${GET_TOKEN}`,
    };
    const obj = {
      ids: [notif.idnotifs],
      // ids : [31325]
    };
    axios
      .patch(`${config.apiAdmin}/sadmin/notifs/desactivate_read`, obj, {
        headers,
      })
      .then((res) => {
        dispatch(setChats(res.data.data));
        const dataCount = res.data.unreadCount;
        setCountChat(dataCount);
        const obj = {
          filters: {
            desk: "",
            idcontracts: routeContract,
          },
          filters_date: {},
          sorts: {},
          limit: "",
        };
        axios
          .post(`${config.apiAdmin}/sadmin/contracts/search_and_sort`, obj, {
            headers,
          })
          .then((response) => {
            console.log(response.data.data.results);
            dispatch(setAllContracts(response.data.data.results));
            // setLoaderStart(false);
          })
          .catch((error) => {
            console.error("There was an error!", error);
            // setLoaderStart(false);
          });
        let route = "";
        switch (profile) {
          case "sell":
            route = `/sell_tab/2/${routeContract}/Chat`;
            break;
          case "tlead":
            route = `/tl_tab/2/${routeContract}/Chat`;
            break;
          case "ass_mng":
            route = `/s_tab/2/${routeContract}/Chat`;
            break;
          case "manager":
            route = `/m_tab/2/${routeContract}/Chat`;
            break;
          case "agt_supp":
            route = `/supp_tab/2/${routeContract}/Chat`;
            break;
          case "mng_supp":
            route = `/ms_tab/2/${routeContract}/Chat`;
            break;
          case "boss_supp":
            route = `/cs_tab/2/${routeContract}/Chat`;
            break;
          case "admin":
            route = `/admin_tab/2/${routeContract}/Chat`;
            break;
          default:
            console.log("Unknown profile");
        }

        if (route) {
          navigate(route); // Navigate to the determined route
        }
        setShowDropdownChat(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const IsReadForAll = () => {
    const headers = {
      Authorization: `Bearer ${GET_TOKEN}`,
    };
    axios
      .get(
        `${config.apiAdmin}/sadmin/notifs/desactivate_all_read_notif_by_userid`,
        { headers }
      )
      .then((res) => {
        dispatch(setChats(res.data.data));
        const dataCount = res.data.unreadCount;
        setCountChat(dataCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleToggle = () => {
    setShowDropdownChat(!showDropdownChat);
  };

  ////// Notif

  useEffect(() => {
    const fetchDataNotif = async () => {
      const url = `${config.apiAdmin}/sadmin/notifs/read_all`;
      const headers = {
        Authorization: `Bearer ${GET_TOKEN}`,
      };
      try {
        const response = await axios.get(url, { headers });

        const updatedNotifs = response.data.data.map((notif) => {
          const hasSocket = GET_SOCKETS_DRINGS.some(
            (socket) => notif.idnotifs == socket.idnotifs
          );

          return {
            ...notif,
            isSocket: hasSocket,
          };
        });
        dispatch(setNotifs(updatedNotifs));
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchDataNotif();
  }, [GET_SOCKETS, GET_SOCKETS_DRINGS]);

  const socketNotifs =
    GET_NOTIFS?.filter((notif) => notif.isSocket === true) || [];
  // Obtenir le nombre de notifications
  const socketNotifsCount = socketNotifs.length;
const imgScal = () => {
  const img = document.getElementById("avatars");
  const outerBody = document.querySelector(".outer-body");
  const imgContainer = img.parentElement; // Assumant que l'image est dans une div conteneur

  if (img && outerBody && imgContainer) {
    img.addEventListener("click", () => {
      // Ajout de la transition pour le changement de couleur de l'outer-body
      outerBody.style.transition = "background-color 1s ease";
      
      // Début de l'animation
      img.style.transition = "transform 1s ease";
      img.style.transform = "scale(10) rotate(360deg)";
      outerBody.style.backgroundColor = "#000000"; // Couleur de fond très contrastée

      // Centrer la div contenant l'image
      imgContainer.style.position = "fixed";
      imgContainer.style.top = "50%";
      imgContainer.style.left = "50%";
      imgContainer.style.transform = "translate(-50%, -50%)";

      // Réinitialisation après l'animation
      setTimeout(() => {
        img.style.transform = "scale(1) rotate(0deg)";
        outerBody.style.backgroundColor = ""; // Réinitialise à la couleur d'origine

        // Réinitialiser la position de la div contenant l'image
        imgContainer.style.position = "";
        imgContainer.style.top = "";
        imgContainer.style.left = "";
        imgContainer.style.transform = "";
      }, 3000);
    });
  }
};

const dateCrea = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleString('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
};
  
  // Appelez la fonction pour ajouter le gestionnaire d'événements

  return (
    <div className={`header ${headerFix ? "is-fixed" : ""} `}>
      <div className="header-content p-0">
        <div className="d-flex justify-content-between align-items-center col-12">
          <div className=" col-8">
            <AlertsBar />
          </div>
          <div className="d-flex col-4 ps-2 justify-content-end pe-5 align-items-center">
            <div className="d-flex align-items-center">
              <div className="text-black">{pseudo}</div>
              <div className={` badge-list-admin badge-${profile} text-white`}>
                {profile}
              </div>
            </div>
            <div className="mx-3 btn-header p-2 rounded pointer">
              <Dropdown
                as="li"
                className="nav-item dropdown notification_dropdown"
              >
                <Dropdown.Toggle
                  className="nav-link i-false c-pointer position-relative"
                  variant=""
                  as="a"
                >
                  <div className="position-relative">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 8.5C18 6.9087 17.3679 5.38258 16.2426 4.25736C15.1174 3.13214 13.5913 2.5 12 2.5C10.4087 2.5 8.88258 3.13214 7.75736 4.25736C6.63214 5.38258 6 6.9087 6 8.5C6 15.5 3 17.5 3 17.5H21C21 17.5 18 15.5 18 8.5Z"
                        stroke="#111828"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.73 21.5C13.5542 21.8031 13.3019 22.0547 12.9982 22.2295C12.6946 22.4044 12.3504 22.4965 12 22.4965C11.6496 22.4965 11.3054 22.4044 11.0018 22.2295C10.6982 22.0547 10.4458 21.8031 10.27 21.5"
                        stroke="#111828"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    {socketNotifsCount > 0 && (
                      <div
                        className="position-absolute top-0 start-100 translate-middle rounded d-flex justify-content-center align-items-center bg-danger fw-bold text-white"
                        style={{
                          height: "16px",
                          width: "16px",
                          fontSize: "10px",
                        }}
                      >
                        {socketNotifsCount}
                      </div>
                    )}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="end"
                  className="mt-2 dropdown-menu dropdown-menu-end"
                >
                  <div className="widget-media dz-scroll p-3 height380">
                    <ul className="timeline">
                      {GET_NOTIFS &&
                        GET_NOTIFS.map((notif, index) => (
                          <li key={index}>
                            <div className="timeline-panel">
                              <div className="media-body">
                                <h6 className="mb-1">
                                  {notif.type} {notif.isSocket && "🔴"}
                                </h6>
                                <div className="date-notif">{dateCrea(notif.timestamp)}</div>
                                <small
                                  className="d-block"
                                  dangerouslySetInnerHTML={{
                                    __html: notif.content,
                                  }}
                                />
                              </div>
                            </div>
                            
                          </li>
                        ))}
                    </ul>
                    <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                      <div
                        className="ps__thumb-x"
                        tabIndex={0}
                        style={{ left: 0, width: 0 }}
                      />
                    </div>
                    <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                      <div
                        className="ps__thumb-y"
                        tabIndex={0}
                        style={{ top: 0, height: 0 }}
                      />
                    </div>
                  </div>
                  <Link
                    onClick={() => handleClick()}
                    className="all-notification"
                    to="/notifs"
                  >
                    Voir toutes les notifs <i className="ti-arrow-right" />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                    </svg>
                  </Link>
                  <div
                    onClick={() => handleDeleteNotif()}
                    style={{ cursor: "pointer" }}
                    className="all-notification"
                    to="/notifs"
                  >
                    Masquer les notifs <i className="ti-arrow-right" />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                      <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                    </svg>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="text-black btn-header p-2 rounded pointer  ">
              {" "}
              <Dropdown
                as="li"
                className="nav-item dropdown notification_dropdown"
                show={showDropdownChat}
                onToggle={handleToggle}
              >
                <Dropdown.Toggle
                  className="nav-link i-false c-pointer position-relative"
                  variant=""
                  as="a"
                  // onClick={closeChats}
                >
                  <div className="position-relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="currentColor"
                      className="bi bi-chat-left-text"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                    </svg>

                    {countChat > 0 && (
                      <div
                        className="position-absolute top-0 start-100 translate-middle rounded d-flex justify-content-center align-items-center bg-danger fw-bold text-white"
                        style={{
                          height: "16px",
                          width: "16px",
                          fontSize: "10px",
                        }}
                      >
                        {countChat}
                      </div>
                    )}
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  align="end"
                  className="mt-2 dropdown-menu dropdown-menu-end"
                >
                  <div className="widget-media dz-scroll p-3 height380">
                    <ul className="timeline">
                      {GET_CHATS &&
                        GET_CHATS.map((notif, index) => (
                          <li key={index}>
                            <div
                              className="timeline-panel"
                              onClick={() => ChatIsRead(notif)}
                            >
                              <div className="media-body text-black">
                                <div
                                  className={`mb-1 ${
                                    (notif.is_read === 0 && "fw-bold") || ""
                                  }`}
                                >
                                  {(notif.is_read === 0 && "Nouveau chat 🔴") ||
                                    "Chat"}
                                </div>
                                <small
                                  className={`d-block ${
                                    (notif.is_read === 0 && " fw-bold") || ""
                                  }`}
                                  dangerouslySetInnerHTML={{
                                    __html: notif.content,
                                  }}
                                />
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                    <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                      <div
                        className="ps__thumb-x"
                        tabIndex={0}
                        style={{ left: 0, width: 0 }}
                      />
                    </div>
                    <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                      <div
                        className="ps__thumb-y"
                        tabIndex={0}
                        style={{ top: 0, height: 0 }}
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => IsReadForAll()}
                    style={{ cursor: "pointer" }}
                    className="all-notification"
                    to="/notifs"
                  >
                    Masquer les notifs <i className="ti-arrow-right" />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                      <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                    </svg>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="text-black btn-header p-2 rounded pointer ">
              <img src={avatar} width={45} onClick={()=> imgScal()} id="avatars" alt="" />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
