import React, { useEffect, useState } from "react";
import { SVGICON } from "../../../jsx/constant/theme";
import ExpensesBarChart from "../Graph/ExpensesBarChart";
import NewExpChart from "../Graph/NewExpChartAdmin";
import avatar from "../../../images/avataaars.svg";  
import avatar1 from "../../../images/avataaars (1).svg";
import avatar2 from "../../../images/avataaars (2).svg";
import FondPodiumOk from "../../../images/photo_2024-04-30_18-39-37.jpg";
import ImpressionBar from "../Graph/ImpressionBarAdmin";
import { Card } from "react-bootstrap";
import TableauConvertion from "../Graph/TableauConvertion";
import { useQuery, gql } from "@apollo/client";
import GraphRatioByAgent from "../Graph/GraphRatioByAgent"
import ProgressBarDesk from "../Graph/ProgressBarDeskAdmin";
import { getSockets } from "../../Redux/slices/notifSocket.slice";
import { getToken } from "../../Redux/slices/admin.slice";
import { useSelector } from "react-redux";
import formatNumber from "../../../utils/formatNumber";

const GET_SIGNED_NUMBERS_TABLE = gql`
  query GetSignedNumbersTable {
    getSignedNumbersTable {
      active_sellers_count
      average_amount_seller
      total_amount
    }
  }
`;

const GET_ENCAISSER_TABLE = gql`
  query GetEncaisserTable {
    getEncaisserTable {
      average_per_agent
    percentage_increase
    total_active_agents
    total_amount
    total_amount_last_month
    }
  }
`;

const GET_CONVERSION_BY_DESK = gql`
  query getConversionByDesk {
    getConversionByDesk {
      sum_conversion
      desk
    }
  }
`;

const GET_RATION_AND_CONVERSION = gql`
  query GetRationAndConversion {
    getRationAndConversion {
    desk
    total_amount_signed
    total_amount_encaisse
    preuve_count
  }
}`;

const GET_PODIUM = gql`
  query GetPodium {
    getPodium {
      pseudo
      total_amount
    }
}`;

const CardsManager = () => {

  const GET_TOKEN = useSelector(getToken);

  const HelpRequest = {
    context: {
      headers: {
        Authorization: `Bearer ${GET_TOKEN}`,
      }
    }
  }

  const GET_SOCKETS = useSelector(getSockets);
  const { data, refetch } = useQuery(GET_SIGNED_NUMBERS_TABLE, HelpRequest);
  const { data: dataEncaisser, refetch: refetchEncaisser } = useQuery(GET_ENCAISSER_TABLE, HelpRequest);
  const { data: dataRatioConversion, refetch: refetchRatioConversion } = useQuery(GET_RATION_AND_CONVERSION, HelpRequest);
  const { data: dataPodium, refetch: refetchPodium } = useQuery(GET_PODIUM, HelpRequest);
  const { data: dataConversionByDesk, refetch: refetchConversionByDesk } = useQuery(GET_CONVERSION_BY_DESK, HelpRequest);

  console.log("data?.getSignedNumbersTable?.average_amount_seller", data)
  const [flash, setFlash] = useState(false);

  useEffect(() => {
    setFlash(true);
    setTimeout(() => setFlash(false), 100);
    refetch();
    refetchEncaisser();
    refetchRatioConversion();
    refetchPodium();
    refetchConversionByDesk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GET_SOCKETS]);


  return (
    <div className="row">


      {/* cards */}
      <div className="col-xl-12 col-xxl-12 col-sm-6">

        <div className="row">
          {dataEncaisser?.getEncaisserTable &&
            <ProgressBarDesk
              data={dataEncaisser?.getEncaisserTable}
            />
          }

          {/* moyenne chiffre par agent */}
          <div className="col-xl-4 col-xxl-4 col-sm-6">
            <div className="card crm-cart">
              <div className="card-body h-50">
                <div className="crm-cart-data">
                  <h5 className="d-block mb-1 text-black">
                  Moyenne chiffre par Agent (signé)
                  </h5>
                  <p> <span style={{ backgroundColor: flash ? 'yellow' : 'transparent' }} >{( data?.getSignedNumbersTable?.average_amount_seller && formatNumber(data?.getSignedNumbersTable?.average_amount_seller))}</span>  €</p>
                </div>
              </div>
              <NewExpChart color={"green"} />
            </div>
          </div>

          <div className="col-xl-4 col-xxl-4 col-sm-6">
            <div className="card crm-cart">
              <div className="card-body">
                <div className="crm-cart-data">
                  <h5 className="d-block mb-1 text-black">
                    Moyenne par Agent (Encaissement)
                  </h5>
                  <p>
                    <span style={{ backgroundColor: flash ? 'yellow' : 'transparent' }} >
                      {  dataEncaisser?.getEncaisserTable?.average_per_agent && formatNumber(dataEncaisser?.getEncaisserTable?.average_per_agent)  }
                    </span> €
                  </p>
                </div>
              </div>
              <NewExpChart color={"blue"} />
            </div>
          </div>

          {/* Podium agent */}
          <div className="col-xl-4 col-xxl-4 col-sm-6">
            <div
              className="card crm-cart"
              style={{
                backgroundImage: `url(${FondPodiumOk})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                className="d-flex text-white fs-5 justify-content-around align-items-end mx-5 px-2"
                style={{ height: "65%" }}
              >
                <div className="mb-2">
                  <img
                    src={avatar1}
                    title={dataPodium?.getPodium && dataPodium?.getPodium[2]?.pseudo + "\n " + formatNumber(dataPodium?.getPodium[2]?.total_amount) + " €"} 
                    alt=""
                    style={{
                      height: "70px",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="mb-3">
                  <img
                    src={avatar}
                    alt=""
                    title={dataPodium?.getPodium && dataPodium?.getPodium[0]?.pseudo + "\n " + formatNumber(dataPodium?.getPodium[0]?.total_amount) + " €"} 
                    style={{
                      height: "90px",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="">
                  <img
                    src={avatar2}
                    title={dataPodium?.getPodium && dataPodium?.getPodium[1]?.pseudo + "\n " + formatNumber(dataPodium?.getPodium[1]?.total_amount) + " €"} 
                    alt=""
                    style={{
                      height: "60px",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          

        </div>

        <div className="row">

          <div className="col-xl-4 col-xxl-4 col-sm-6">
            <div className="card crm-cart">
              <div className="card-header border-0 pb-0 diposit-bg">
              <h5 className="d-block text-black">Total (Signé)</h5>
                <div className="icon-box">{SVGICON.DollerBlack}</div>
              </div>
              <div className="card-body py-0">
                <div className="crm-cart-data d-flex align-items-center">
                  <p > <span style={{ backgroundColor: flash ? 'yellow' : 'transparent' }}>{data?.getSignedNumbersTable && formatNumber(data?.getSignedNumbersTable?.total_amount)}</span>    €</p>
                  <span className={`fs-20 fw-bold ${data?.getSignedNumbersTable?.percentage_change >= 0 ? 'text-green' : 'text-red'}`}>
                    ({data?.getSignedNumbersTable?.percentage_change >= 0 ? '+' : '-'}{data?.getSignedNumbersTable?.percentage_change}%)
                  </span>
                </div>
                <ImpressionBar />
              </div>
            </div>
          </div>

          {/* total encaisser */}
          <div className="col-xl-4 col-xxl-4 col-sm-6">
            <div className="card crm-cart">
              <div className="card-header border-0 pb-0 diposit-bg">
                <h5 className="d-block text-black">Total (encaissé)</h5>
                <div className="icon-box">{SVGICON.DollerBlack}</div>
              </div>
              <div className="card-body py-0">
                <div className="crm-cart-data d-flex align-items-center">
                  {<p> <span style={{ backgroundColor: flash ? 'yellow' : 'transparent' }} >{ dataEncaisser?.getEncaisserTable &&  formatNumber(dataEncaisser?.getEncaisserTable?.total_amount) }</span>  €</p>}
                  <span className={`fs-20 fw-bold ${dataEncaisser?.getEncaisserTable?.percentage_increase >= 0 ? 'text-green' : 'text-red'}`}>
                    ({dataEncaisser?.getEncaisserTable?.percentage_increase >= 0 ? '+' : '-'}{dataEncaisser?.getEncaisserTable?.percentage_increase}%)
                  </span>
                </div>
                <ExpensesBarChart />
              </div>
            </div>
          </div>

          {/* total converstions */}
          <div className="col-xl-4 col-xxl-4 col-sm-6">
            <Card>
              <Card.Header className="fw-bold text-black fs-4">
                Nb de converstions
              </Card.Header>
                {dataConversionByDesk?.getConversionByDesk &&
                <TableauConvertion 
                  horizontal={false}
                  data={dataConversionByDesk?.getConversionByDesk?.map((item) => item.sum_conversion)}
                  arrCategories={dataConversionByDesk?.getConversionByDesk?.map((item) => "desk "+item.desk)}
                />
              } 
            </Card>
          </div>

        </div>

        <div className="d-flex">
          <Card className="col-12">
            <Card.Header>
              <Card.Title>Ratio deskss </Card.Title>
            </Card.Header>
            {dataRatioConversion?.getRationAndConversion &&
              <GraphRatioByAgent
                height={"400px"}
                arrEncaisse={dataRatioConversion?.getRationAndConversion?.map((item) => item.total_amount_encaisse)}
                arrSigned={dataRatioConversion?.getRationAndConversion?.map((item) => item.total_amount_signed)}
                arrCategories={dataRatioConversion?.getRationAndConversion?.map((item) => "desk "+item.desk)}
                arrPreuve={dataRatioConversion?.getRationAndConversion?.map((desk) => desk.preuve_count)}
              />            
            }
          </Card>
        </div>

      </div>

    </div>
  );
};

export default CardsManager;