import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config/config";
import ReactSelect, {components} from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { setAllContracts } from "../../Redux/slices/contract.slice";
import { setPopup } from "../../Redux/slices/actions.slice";
import { useParams } from "react-router-dom";
import { Offcanvas, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";

const DropAddContrat = ({ isVisible, onHide, GetAllContrats }) => {
  const [nomClient, setNomClient] = useState("");
  const [prenomClient, setPrenomClient] = useState("");
  const [brandClient, setBrandClient] = useState("");
  const [amountClient, setAmountClient] = useState("");
  const [IsConvertion, setIsConvertion] = useState(0);
  const [dateEcheance, setDateEcheance] = useState("");
  const [pspClient, setPspClient] = useState("");
  const [neoClient, setNeoClient] = useState("");
  const [bankClient, setBankClient] = useState("");
  const [argent_dispoClient, setArgent_dispoClient] = useState(0);
  const [preuveClient, setPreuveClient] = useState(0);
  const [next_callClient, setNext_callClient] = useState("");
  const [hoursClient, setHoursClient] = useState(null);
  const [encaisserClient, setEncaisserClient] = useState(0);
  const [reportClient, setReportClient] = useState(0);
  const [annulClient, setAnnulClient] = useState(0);

  const [psp, setPsp] = useState([]);
  const [neoBank, setNeoBank] = useState([]);
  const [customerBank, setCustomerBank] = useState([]);

  const [pseudoSel, setPseudoSel] = useState("");
  const [customer_id, setCustomer_id] = useState("");
  const [supportSel, setSupportSel] = useState("");
  const [listSupport, setListSupport] = useState([]);
  const [listSellAndTlead, setListSellAndTlead] = useState([]);

  const [catchErrors, setCatchErrors] = useState([]);

  const [loaderButton, setLoaderButton] = useState(false);

  const [errorVirgule, setErrorVirgule] = useState(false);

  const [error, setError] = useState(false);

  const AdminDetails = useSelector(getAdminDetails);
  let DesksAdmin = useParams().desk;
  let Desk = DesksAdmin || AdminDetails?.desk[0];

  const desksToAdmin = AdminDetails?.desk;

  const customerSchema = yup.object().shape({
    firstname: yup.string().min(3).max(36).required(),
    lastname: yup.string().min(3).max(36).required(),
    crm_id: yup.string().min(3).max(36).required(),
  });

  const contractSchema = yup.object().shape({
    amount: yup.number().min(1).required(),
    desk: yup.string().min(1).max(36).required(),
    psp: yup.string(),
    brand: yup.string().min(2).max(36),
    customerbank: yup.string(),
    neobank: yup.string(),
    paymentdudate: yup.date().required(),
    argent_dispo: yup.boolean(),
    preuve: yup.boolean().required(),
    encaisse: yup.boolean().required(),
    is_report: yup.boolean().required(),
    is_canceled: yup.boolean().required(),
    next_call: yup.date().nullable(),
    hours: yup.string().nullable(true),
    seller_id: yup.string().min(3).max(36).required(),
    tlead_id: yup.string(),
    agt_supp_id: yup.string().min(3).max(36).required(),
    is_active: yup.boolean().default(true),
    customer: customerSchema.required(),
    limit: yup.string().nullable(),
    conversion: yup.boolean(),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isVisible) {
      handleShowFilters();
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleShowFilters = () => {
    FetchFiltersGet("/psp/read_all", setPsp);
    FetchFiltersGet("/neobanks/read_all", setNeoBank);
    FetchFiltersGet("/customerbanks/read_all", setCustomerBank);
    FetchFiltersPost("/users/all_agt_supp_and_mng_supp", setListSupport);
    FetchFiltersPost("/users/all_sell_and_tlead", setListSellAndTlead, {
      desk: desksToAdmin,
    });
  };
  const FetchFiltersGet = (url, fonction) => {
    axios
      .get(`${config.apiAdmin}/sadmin${url}`, Header)
      .then((response) => {
        fonction(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const FetchFiltersPost = (url, fonction, obj) => {
    axios
      .post(`${config.apiAdmin}/sadmin${url}`, obj, Header)
      .then((response) => {
        fonction(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  // let deskFinal = {};
  let deskUpdate = {};

  if (Desk === null) {
    // Si Desk est null, deskFinal sera desksToAdmin qui est un array
    // deskFinal = desksToAdmin;
    deskUpdate = desksToAdmin.join(",");
  } else if (typeof Desk === "string") {
    // Si Desk est une chaîne de caractères, convertissez-la en tableau
    // deskFinal = [Desk];
    deskUpdate = Desk;
  }

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
  };

  const profilToAdmin = AdminDetails?.profil;
  const IdToAdmin = AdminDetails?.idusers;
  const brandToAdmin = AdminDetails?.brand;
  const tleadToAdmin = AdminDetails?.tlead_id;
  // const agt_suppToAdmin = AdminDetails?.agt_supp_id;
  // const pseudoToAdmin = AdminDetails?.payload?.pseudo;
  const agt_supp_idToAdmin = AdminDetails?.agt_supp_id;

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const CreateContract = async () => {
    setLoaderButton(true);
    let seller;

    seller =
      profilToAdmin === "sell" || profilToAdmin === "tlead"
        ? AdminDetails.idusers
        : pseudoSel;

    let next_call;

    next_call =
      next_callClient === "" ? null : new Date(`${next_callClient}T00:00:00`);

    let seller_id = "";
    let agt_supp_id = "";

    if (profilToAdmin === "sell") {
      seller_id = IdToAdmin;
      agt_supp_id = agt_supp_idToAdmin;
    } else if (profilToAdmin === "agt_supp") {
      agt_supp_id = IdToAdmin;
      seller_id = pseudoSel;
    }
    // Desk

    let obj = {
      amount: amountClient,
      desk: deskUpdate,
      psp: pspClient,
      brand: brandClient,
      customerbank: bankClient,
      neobank: neoClient,

      conversion: IsConvertion,
      paymentdudate: dateEcheance,
      argent_dispo: argent_dispoClient,
      preuve: preuveClient,
      encaisse: encaisserClient,
      is_report: reportClient,
      is_canceled: annulClient,
      next_call: next_call,
      hours: hoursClient,

      seller_id: seller,
      tlead_id: tleadToAdmin,
      agt_supp_id: supportSel,

      is_active: 1,
      customer: {
        firstname: prenomClient,
        lastname: nomClient,
        crm_id: customer_id,
      },
      desk_filter: deskUpdate,
      limit: "",
      seller_id_filter: seller_id,
      agt_supp_id_filter: agt_supp_id,
      user: profilToAdmin,
    };
    try {
      await contractSchema.validate(obj, { abortEarly: false });

      axios
        .put(
          `${config.apiAdmin}/sadmin/contracts/create-contract-and-customer-integrate`,
          obj,
          Header
        )
        .then((response) => {
          setError(false);
          setLoaderButton(false);
          GetAllContrats({ data: true })
          SendPopup({
            message: "confirm",
            info: "create-contract-and-customer-integrate.",
            isActive: true,
          });
          onHide();
          resetStates();
        })
        .catch((error) => {
          SendPopup({
            message: "error",
            info: "create-contract-and-customer-integrate.",
            isActive: true,
          });
          setLoaderButton(false);
          setError(true);
        });
    } catch (validationError) {
      console.log(validationError.errors);
      setCatchErrors(validationError.errors);
      SendPopup({
        message: "error",
        info: "Validation failed.",
        isActive: true,
      });
      setLoaderButton(false);
      setError(true);
    }
  };

  const dispatchAllContacts = (allContracts) => {
    dispatch(setAllContracts(allContracts));
  };

  const optionsBrands = brandToAdmin?.map((brand) => ({
    value: brand,
    label: brand,
  }));
  // const optionsPsps = psp?.map((psp) => ({
  //   value: psp.name,
  //   label: psp.name,
  // }));
  // const optionsNeos = neoBank?.map((neo) => ({
  //   value: neo.name,
  //   label: neo.name,
  // }));
  // const optionsBankClients = customerBank?.map((bank) => ({
  //   value: bank.name,
  //   label: bank.name,
  // }));
  const optionsSupport = listSupport?.map((support) => ({
    value: support.idusers,
    label: support.pseudo,
    profil: support.profil === 'agt_supp' ? 'Support' : support.profil === 'mng_supp' ? 'MgSupport' : support.profil === 'boss_supp' ? 'BossSupport' : support.profil
  }));
  const optionsPseudo = listSellAndTlead?.map((pseudo) => {
    return {
      value: pseudo.idusers,
      label: pseudo.pseudo,
      profil: pseudo.profil === 'sell' ? 'Vendeur' : pseudo.profil === 'tlead' ? 'TeamLeader' : pseudo.profil,
    };
  });

  function resetStates() {
    setPrenomClient("");
    setNomClient("");
    setBrandClient("");
    setAmountClient("");
    setIsConvertion(0);
    setDateEcheance("");
    setPspClient("");
    setNeoClient("");
    setBankClient("");
    setArgent_dispoClient(0);
    setPreuveClient(0);
    setNext_callClient("");
    setHoursClient(null);
    setEncaisserClient(0);
    setReportClient(0);
    setAnnulClient(0);
    setCatchErrors([]);
    // setListPseudo([]);
    setError(false);
    setLoaderButton(false)
  }

  const CustomDrop = (props) => {
    return (
      <components.Option {...props}>
        <div className="d-flex justify-content-between align-items-center text-black">
          <span>{props.data.label}</span>
          <span
            style={{ color: "white" }}
            className={`badge badge-${props.data.profil}`}
          >
            {props.data.profil}
          </span>
        </div>
      </components.Option>
    );
  };

  return (
    <Offcanvas
      show={isVisible}
      onHide={() => {
        onHide();
        resetStates();
      }}
      placement="end"
      style={{ width: "600px" }}
    >
      <Offcanvas.Header closeButton className="pb-0 bg-light-update">
        <Offcanvas.Title className="col-10">
          <div className="d-flex">
            <div
              className={`pointer fs-14 ms-2 me-3 p-2 b-bottom-green fw-bold`}
            >
              Nouveau Contrat
            </div>
          </div>
          <div className="border"></div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="bg-light-update">
        <div
          style={{ textDecoration: "underline" }}
          className="mb-3 fw-bold mt-1 text-black"
        >
          Informations Client
        </div>
        {catchErrors?.length > 0 && (
          <div>
            <ul
              style={{ listStyleType: "disc", paddingLeft: "20px" }}
              className="text-danger"
            >
              <li className="fw-bold">champs a remplir :</li>
              {catchErrors.includes(
                'amount must be a `number` type, but the final value was: `NaN` (cast from the value `""`).'
              ) && <li>montant</li>}
              {catchErrors.includes("brand must be at least 2 characters") && (
                <li>brand</li>
              )}
              {catchErrors.includes(
                'paymentdudate must be a `date` type, but the final value was: `Invalid Date` (cast from the value `""`).'
              ) && <li>date echeance</li>}
              {(catchErrors.includes(
                "agt_supp_id must be at least 3 characters"
              ) ||
                catchErrors.includes("agt_supp_id is a required field")) && (
                <li>support</li>
              )}
              {catchErrors.includes(
                "customer.firstname must be at least 3 characters"
              ) && <li>prenom client</li>}
              {catchErrors.includes(
                "customer.lastname must be at least 3 characters"
              ) && <li>nom client</li>}
              {catchErrors.includes(
                "customer.crm_id must be at least 3 characters"
              ) && <li>customer id</li>}
            </ul>
          </div>
        )}
        <div className="p-1 grid-container text-black">
          <div className="grid-item p-0 me-1">
            <div className="mx-2 mb-1">Sélection agent</div>
            <ReactSelect
              options={optionsPseudo}
              className="basic-single"
              classNamePrefix="select"
              name="alias"
              placeholder=""
              onChange={(selectedOption) =>
                setPseudoSel(selectedOption.value)
              }
              components={{Option: CustomDrop}}
            />
          </div>
          <div className="grid-item p-0 me-1">
            <div className="mx-2 mb-1">Sélection support</div>
            <ReactSelect
              options={optionsSupport}
              className="basic-single"
              classNamePrefix="select"
              name="support"
              placeholder=""
              onChange={(selectedOption) =>
                setSupportSel(selectedOption.value)
              }
              components={{Option: CustomDrop}}
            />
          </div>
          <div className="grid-item p-0 me-1">
            <div className="mx-2 mb-1">Prénom</div>
            <input
              type="text"
              name="firstName"
              className="form-control"
              placeholder=""
              required
              onChange={(e) => setPrenomClient(e.target.value.trimStart())}
            />
          </div>
          <div className="grid-item p-0 me-1">
            <div className="mx-2 mb-1">Nom</div>
            <input
              type="text"
              name="firstName"
              className="form-control"
              placeholder=""
              required
              onChange={(e) => setNomClient(e.target.value.trimStart())}
            />
          </div>
          <div className="grid-item p-0 me-1">
            <div className="mx-2 mb-1">Customer ID</div>
            <input
              type="text"
              name="Customer ID"
              className="form-control"
              placeholder=""
              required
              onChange={(e) => setCustomer_id(e.target.value.trimStart())}
            />
          </div>
          <div className="grid-item p-0 me-1">
            <div className="mx-2 mb-1">Brand</div>
            <ReactSelect
              options={optionsBrands}
              className="basic-single"
              classNamePrefix="select"
              name="brand"
              placeholder=""
              onChange={(selectedOption) =>
                setBrandClient(selectedOption ? selectedOption.value : null)
              }
              isClearable={true}
            />
          </div>
          <div className="grid-item p-0 me-1">
            <div className="mx-2 mb-1">Montant €</div>
            <input
              type="number"
              className="form-control"
              placeholder=""
              required
              onChange={(e) => {
                const value = e.target.value;
                if (value.includes(",") || value.includes(".")) {
                  setErrorVirgule(true);
                } else {
                  setErrorVirgule(false);
                }
                setAmountClient(value);
              }}
            />
            {errorVirgule && (
              <div className="text-danger fs-16">uniquement un nombre</div>
            )}
          </div>
          <div className="grid-item p-0 me-1">
            <div className="mx-2 mb-1">Date d'échéance</div>
            <DatePicker
              className="p-2 rounded border input-add"
              placeholderText=""
              selected={dateEcheance}
              onChange={(date) => setDateEcheance(date)}
            />
          </div>
          <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Convertion</div>
                <div className="d-flex m-2 align-items-center">
                  <div className="me-3 mt-2">NON / OUI</div>
                  <Form.Check
                    type="switch"
                    className="custom-switch-drop ms-3"
                    required
                    style={{ transform: "scale(1.5)" }}
                    onChange={(e) =>
                      setIsConvertion(e.target.checked ? 1 : 0)
                    }
                  />
                </div>
              </div>
        </div>
        <div className="p-1">
              {error && (
                <div className="text-red fs-16 m-2">
                  Une erreur est survenue
                </div>
              )}
              <button
                disabled={loaderButton}
                className="border btn btn-green col-10 text-black h-50 me-2"
                onClick={() => CreateContract()}
              >
                Valider
              </button>
            </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default DropAddContrat;
