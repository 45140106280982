import React from "react";
import ReactApexChart from "react-apexcharts";
import formatNumber from "../../../utils/formatNumber";
import axios from "axios"; // Importer Axios
import { Spinner, Button } from "react-bootstrap"; // Importer Button si nécessaire
import { useParams } from "react-router-dom";
import { getAdminDesks } from "../../Redux/slices/admin.slice";
import { useSelector, useDispatch } from "react-redux";
import { setAllContracts } from "../../Redux/slices/contract.slice";

class GraphRatioByAgent extends React.Component {


  
  constructor(props) {
    super(props);
    this.state = {
      ...this.getChartStateFromProps(props),
      loading: true, // Initialiser l'état de chargement
      clickedData: null, // Pour stocker les données du segment cliqué
    };
  }
  handleClick = (filter, user) => {
    this.props.handlePageGraph(filter, user);
  };



  componentDidMount() {
    // Simuler un délai de chargement pour les données, vous pouvez remplacer ceci par un appel de données réel
    setTimeout(() => {
      this.setState({ loading: false }); // Une fois que le composant est monté et que les données sont prêtes, mettre loading à false
    }, 1000); // Vous pouvez ajuster le délai selon vos besoins ou supprimer ce délai si vos données sont prêtes immédiatement
  }

  getChartStateFromProps(props) {

    // Extraction des pseudos et des valeurs
    const extractedNames = props.arrCategories.map((item) => item.agent.pseudo);

    const arrEncaisse = props.arrEncaisse.map(Number);
    const arrProof = props.arrProof.map(Number);
    const arrSigned = props.arrSigned.map(Number);
    const arrReported = props.arrReported.map(Number);
    const arrCanceled = props.arrCanceled.map(Number);

    return {
      series: [
        {
          name: "Encaisse",
          data: arrEncaisse || [],
        },
        {
          name: "Preuve",
          data: arrProof || [],
        },
        {
          name: "Signe",
          data: arrSigned || [],
        },
        {
          name: "Report",
          data: arrReported || [],
        },
        // Vous avez mentionné "Canceled" dans les props, mais il n'était pas utilisé dans les séries.
        // Si vous souhaitez l'ajouter :
        // {
        //   name: "Canceled",
        //   data: arrCanceled || [],
        // },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          events: {
            // Configurer l'événement de clic sur les barres
            dataPointSelection: this.handleBarClick.bind(this),
          },
        },
        colors: [
          "rgb(16, 208, 120)",
          "rgb(254, 254, 174)",
          "rgb(255, 165, 0)",
          "rgb(165, 105, 189)",
          // Ajoutez une couleur si vous avez "Canceled"
          // "rgb(255, 99, 132)",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "14px",
                  fontWeight: 600,
                  colors: ["#000"],
                },
                formatter: function (val) {
                  return `${formatNumber(val)}$`;
                },
              },
            },
          },
        },
        xaxis: {
          categories: extractedNames,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#000"], // Couleur noire pour les données sur les barres
          },
          formatter: function (val) {
            return `${formatNumber(val)}$`;
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${formatNumber(val)}$`;
            },
          },
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.arrEncaisse !== this.props.arrEncaisse ||
      prevProps.arrSigned !== this.props.arrSigned ||
      prevProps.arrProof !== this.props.arrProof ||
      prevProps.arrReported !== this.props.arrReported ||
      prevProps.arrCanceled !== this.props.arrCanceled
    ) {
      this.setState(this.getChartStateFromProps(this.props));
    }
  }
    
  // Définir le gestionnaire de clic comme une fonction fléchée pour lier 'this' correctement
  handleBarClick = (event, chartContext, config) => {
    // Extraire les informations pertinentes du config
    const { seriesIndex, dataPointIndex } = config;
    const extractedNames = this.props.arrCategories.map((item) => item.agent.idusers);
    // Obtenir le nom de la série et la catégorie basée sur le segment cliqué
    const filter = this.state.series[seriesIndex].name;
    const user = extractedNames[dataPointIndex];
    // Mettre à jour l'état avec les données cliquées (pour afficher dans un bouton ou ailleurs)
    this.setState({
      clickedData: {
        filter,
        user,
      },
    });
    console.log(filter, user)
    console.log(this.props)

    this.handleClick(filter, user);
  };

  

  render() {
    // Afficher un loader tant que loading est true
    if (this.state.loading) {
      return (
        <div className="chargement-graph-agent">
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      ); // Utilisez votre propre composant ou classe CSS pour styliser le loader
    }

    return (
      <div id="chart" className="line-chart-style bar-chart h-100">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}

export default GraphRatioByAgent;
