function formatNumber(num) {

  if (num === null) {
    return 0; // Ou toute autre valeur par défaut pour indiquer une entrée invalide
  }
    let response;
    num = parseFloat(num); // Convert string to number

    if (isNaN(num)) {
      response = num;
      // console.log(response);
      return response;
    }

    response = num.toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    // console.log(response);
    return response;
  }

export default formatNumber;
