import React from "react";
import objRowTable from "./ObjRowTable";
import arrowSort from "./ArrowSort";

const TrTableSupport = ({ sortRow, stateRow }) => {
  return (
    <tr className="rounded">
      <th
        className="fw-bold text-black fs-5 rounded"
        onClick={() => {
          stateRow(objRowTable.pseudo.key, objRowTable.pseudo.type);
        }}
      >
        Desk <br /> {arrowSort(sortRow[objRowTable.pseudo.key].etat)}
      </th>
      <th
        className="fw-bold text-black fs-5"
        onClick={() => {
          stateRow(
            objRowTable.total_sum_amount.key,
            objRowTable.total_sum_amount.type
          );
        }}
      >
        Total <br /> {arrowSort(sortRow[objRowTable.total_sum_amount.key].etat)}
      </th>
      <th
        className="fw-bold text-black fs-5"
        onClick={() => {
          stateRow(objRowTable.total_count.key, objRowTable.total_count.type);
        }}
      >
        Nb lignes <br /> {arrowSort(sortRow[objRowTable.total_count.key].etat)}
      </th>
      <th
        className="fw-bold text-orange fs-4"
        onClick={() => {
          stateRow(
            objRowTable.sum_amount_argent_dispo.key,
            objRowTable.sum_amount_argent_dispo.type
          );
        }}
      >
        Signer <br />{" "}
        {arrowSort(sortRow[objRowTable.sum_amount_argent_dispo.key].etat)}
      </th>
      <th
        className="fw-bold text-purple fs-4"
        onClick={() => {
          stateRow(
            objRowTable.sum_amount_report.key,
            objRowTable.sum_amount_report.type
          );
        }}
      >
        Report <br />{" "}
        {arrowSort(sortRow[objRowTable.sum_amount_report.key].etat)}
      </th>
      <th
        className="fw-bold text-warning fs-4"
        onClick={() => {
          stateRow(
            objRowTable.sum_amount_preuve.key,
            objRowTable.sum_amount_preuve.type
          );
        }}
      >
        Preuve <br />{" "}
        {arrowSort(sortRow[objRowTable.sum_amount_preuve.key].etat)}
      </th>
      <th
        className="fw-bold text-success fs-4"
        onClick={() => {
          stateRow(
            objRowTable.sum_amount_encaisse.key,
            objRowTable.sum_amount_encaisse.type
          );
        }}
      >
        Encaisser <br />{" "}
        {arrowSort(sortRow[objRowTable.sum_amount_encaisse.key].etat)}
      </th>
      <th
        className="fw-bold text-black fs-5"
        onClick={() => {
          stateRow(
            objRowTable.canceled_count.key,
            objRowTable.canceled_count.type
          );
        }}
      >
        Mort <br /> {arrowSort(sortRow[objRowTable.canceled_count.key].etat)}
      </th>
      <th
        className="fw-bold text-black fs-5 rounded"
        onClick={() => {
          stateRow(
            objRowTable.total_encaisse.key,
            objRowTable.total_encaisse.type
          );
        }}
      >
        {" "}
        <br /> {arrowSort(sortRow[objRowTable.total_encaisse.key].etat)}
      </th>
    </tr>
  );
};

export default TrTableSupport;
