import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import { getSockets } from "../../Redux/slices/notifSocket.slice";
import ReactSelect, { components } from "react-select";
import svgSend from "../../../images/send-svgrepo-com.svg";
import svgfond from "../../../images/chatwhat.jpg";
import Conv from "./Conv";
import useHeaderRest from "../../../utils/useHeader";

const Chat = (user) => {
  const [comsByContrat, setComsByContrat] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [showReactSelect, setShowReactSelect] = useState(false);
  const [ListUserAssigned, setListUserAssigned] = useState([]);
  const textareaRef = useRef(null);
  const lastMessageRef = useRef(newMessage);
  const GETSOCKET = useSelector(getSockets);
  const useHeader = useHeaderRest();

  const detailContrat = user?.contrat;
  const IdContrat = detailContrat?.idcontracts;

  const fetchData = () => {
    let obj = {
      idcontracts: IdContrat,
    };
    axios
      .post(
        `${config.apiAdmin}/sadmin/chats/read_all_by_contracts_with_pseudo`,
        obj,
        useHeader
      )
      .then((response) => {
        setComsByContrat(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log('Moteur chat lancé');
    const intervalId = setInterval(fetchData, 3000);

    return () => {
      console.log('moteur chat closed');
      clearInterval(intervalId);
    };
  }, [IdContrat]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GETSOCKET, IdContrat]);// IL FAUDRA modifier pour mettre une socket specifique

  useEffect(() => {
    lastMessageRef.current = newMessage;
  }, [newMessage]);

  const handleMessageChange = (event) => {
    const value = event.target.value;
    setNewMessage(value);

    const lastCharAdded = value.substr(-1);
    const wasAtSymbolAdded =
      lastCharAdded === "@" && lastMessageRef.current !== value;

    if (wasAtSymbolAdded) {
      setShowReactSelect(true);
    } else {
      setShowReactSelect(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const valuesArray = ListUserAssigned?.map((item) => item.value);
    const obj = {
      hashtag: valuesArray,
      contract: user.contrat,
      content: newMessage,
    };
    axios
      .put(`${config.apiAdmin}/sadmin/chats/create`, obj, useHeader)
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.log(error);
      });
      setListUserAssigned([])
      setNewMessage("");
  };

  const handleSelectChange = (selectedOption) => {
    // Supposons que ReactSelect est configuré pour ne sélectionner qu'une seule option
    const selectedValue = selectedOption ? selectedOption.label : "";
    // setSelectedLabel(selectedValue);
    setNewMessage(newMessage + selectedValue);
    setShowReactSelect(false);
    setListUserAssigned((prevList) => {
      const isOptionAlreadySelected = prevList?.some(
        (option) => option.value === selectedOption.value
      );
      if (!isOptionAlreadySelected) {
        return [...prevList, selectedOption];
      }
      return prevList;
    });
  };

  const roles = [
    { label: "manager", value: "manager", profil: "SELLER" },
    { label: "secretaire", value: "ass_mng", profil: "SELLER" },
    { label: "team leader", value: "tlead", profil: "SELLER" },
    { label: "vendeur", value: "sell", profil: "SELLER" },
    { label: "boss support", value: "boss_supp", profil: "SUPPORT" },
    { label: "manager support", value: "mng_supp", profil: "SUPPORT" },
    { label: "agent support", value: "agt_supp", profil: "SUPPORT" },
  ];

  const ListUserToSelect = roles?.map((item) => ({
    value: item.value,
    label: item.label,
    profil: item.label,
    pseudo: item.value,
  }));

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div className="d-flex justify-content-between align-items-center col-12 text-black pointer rounded-3">
          <span
            style={{ color: "white" }}
            className={`text-black badge badge-${props.data.value}`}
          >
            {props.data.profil}
          </span>
        </div>
      </components.Option>
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // Permet d'utiliser Shift+Enter pour sauter une ligne
      e.preventDefault(); // Empêche le saut de ligne dans le textarea
      handleSubmit(e); // Appelle la fonction de soumission du formulaire
    }
  };

  return (
    <div
      className="h-100 d-flex flex-column justify-content-end  text-white"
      style={{ overflow: "hidden" }} // Overflow hidden pour éviter les conflits
    >
      <div
        className="d-flex flex-column justify-content-end text-white border"
        style={{
          height: "90%",
          // backgroundColor: "rgb(238, 238, 238)",
          backgroundImage: { svgfond },
          backgroundImage: `url(${svgfond})`, // Set the background image using template literals
          backgroundSize: "cover", // Ensure the image covers the div properly
          backgroundPosition: "center", // Optional: Center the image
          position: "relative",
        }}
      >
        <Conv data={comsByContrat} fetchData={fetchData} />
        {showReactSelect && (
          <div className="px-3" style={{ zIndex: 3 }}>
            <ReactSelect
              options={ListUserToSelect}
              placeholder="Par cette action, vous envoyez une notification au profil sélectionné du contrat."
              onChange={handleSelectChange}
              components={{ Option: CustomOption }}
              menuIsOpen={true}
              menuPlacement="top"
              isSearchable={false}
              styles={{
                menu: (base) => ({
                  ...base,
                  backgroundColor: "#F0F2F5", // Couleur de fond du menu
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? "#dddee0" : "#F0F2F5",
                  ":active": {
                    backgroundColor: state.isSelected ? "#A0A0A0" : "#B0B0B0",
                  },
                }),
                // Vous pouvez ajouter d'autres personnalisations ici
              }}
            />
          </div>
        )}
      </div>
      <div className="d-flex align-items-center bg-white mx-3 my-3 rounded">
        <textarea
          ref={textareaRef}
          value={newMessage}
          onChange={handleMessageChange}
          onKeyPress={handleKeyPress}
          className="auto-resizing-textarea rounded border-0 p-2"
          placeholder="Tapez votre message"
        />
        <div
          onClick={handleSubmit}
          className="col-3 h-100 d-flex align-items-center justify-content-end pointer pe-3"
        >
          <img src={svgSend} alt="" className="rounded" />
        </div>
      </div>
    </div>
  );
};

export default Chat;
