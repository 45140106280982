import React, { useState } from "react";
import axios from "axios";
import config from "../../../config/config";
import ScrollToBottom from "react-scroll-to-bottom";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { useSelector } from "react-redux";

const Conv = ({ data, fetchData }) => {
  // Correction pour recevoir les props correctement

  console.log(data)

  const detailUser = useSelector(getAdminDetails);
  const pseudo_id = detailUser?.pseudo;
  const userId = detailUser?.idusers;

  const detailContrat = data?.contrat;
  const IdContrat = detailContrat?.idcontracts;
  const customer_id = detailContrat?.customer_id;

  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [editedContent, setEditedContent] = useState("");

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  // const UpdateMessage = (event) => {
  //   event.stopPropagation();
  //   let obj = {
  //     idcomments: selectedCommentId,
  //     content: editedContent,
  //     user_id: userId,
  //     contract_id: IdContrat,
  //     customer_id: customer_id,
  //   };
  //   axios
  //     .post(`${config.apiAdmin}/sadmin/chat/update_all`, obj, Header)
  //     .then((response) => {
  //       // setComsByContrat(
  //       //   comsByContrat?.map((comment) =>
  //       //     comment.idcomments === selectedCommentId
  //       //       ? { ...comment, content: editedContent }
  //       //       : comment
  //       //   )
  //       // );
  //       fetchData()
  //       setSelectedCommentId(null);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const DesactivateMessage = () => {
  //   let obj = {
  //     id: selectedCommentId,
  //   };
  //   axios
  //     .patch(`${config.apiAdmin}/sadmin/chats/desactivate`, obj, Header)
  //     .then((response) => {
  //       // setComsByContrat(
  //       //   comsByContrat?.filter(
  //       //     (comment) => comment.idcomments !== selectedCommentId
  //       //   )
  //       // );
  //       fetchData()
  //       setSelectedCommentId(null);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  return (
    <div
      className=" messages-container"
      id="messages-container"
      style={{
        zIndex: 1,
        position: "relative",
        margin: "0 30px 15px 30px",
        overflowY: "auto",
      }}
    >
      <ScrollToBottom className="conv-container">
        {data?.map((comment) => (
          <div
            key={comment?.idcomments}
            className={`message rounded text-black fs-16 col-5 my-3 ${
              comment.pseudo === pseudo_id
                ? "bg-vert-whatsapp ms-auto"
                : "bg-white"
            }`}
            onClick={() => {
              setSelectedCommentId(comment?.idcomments);
              setEditedContent(comment?.content);
            }}
          >
            {comment.pseudo !== pseudo_id && (
              <div className={`px-2 fs-16 fw-bold color-${comment.profil}`}>{comment.pseudo}</div>
            )}
            {/* {selectedCommentId === comment?.idcomments ? (
              <>
                <div className="d-flex justify-content-end">
                  <div className="d-flex m-1">
                    <div
                      className="rounded-5 border text-center px-1 bg-success"
                      onClick={UpdateMessage}
                      style={{ cursor: "pointer" }}
                    >
                      v
                    </div>
                  </div>
                  <div className="d-flex m-1">
                    <div
                      className="rounded-5 border text-center px-1 bg-danger"
                      onClick={DesactivateMessage}
                      style={{ cursor: "pointer" }}
                    >
                      x
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  value={editedContent}
                  onChange={(e) => handleEditChange(e)}
                  className="form-control"
                />
              </>
            ) : ( */}
              <p className="p-2 m-0 comment-content">{comment?.content}</p>
            {/* )} */}
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-end fs-12 px-2 col-12">
                {new Date(comment?.timestamp).toLocaleString()}
              </div>
            </div>
          </div>
        ))}
      </ScrollToBottom>
    </div>
  );
};

export default Conv;
