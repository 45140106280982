const objRowTable = {
    pseudo: {
        type: "string",
        key: "pseudo"
    },
    total_sum_amount: {
        type: "int",
        key: "total_sum_amount"
    },
    total_count: {
        type: "int",
        key: "total_count"
    },
    sum_amount_argent_dispo: {
        type: "int",
        key: "sum_amount_argent_dispo"
    },
    sum_amount_report: {
        type: "int",
        key: "sum_amount_report"
    },
    sum_amount_preuve: {
        type: "int",
        key: "sum_amount_preuve"
    },
    sum_amount_encaisse: {
        type: "int",
        key: "sum_amount_encaisse"
    },
    canceled_count: {
        type: "int",
        key: "canceled_count"
    },
    total_encaisse: {
        type: "int",
        key: "total_encaisse"
    },
};

export default objRowTable;