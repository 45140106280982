import React from 'react'
import DeskVsDesk from '../Manager/PageDashboard'

const PageDashboard = () => {
  // Comme il nous a ete demander un copie strict du manager, ici nous avons prit l'exact tableau du manager
  return (
    <>
    <DeskVsDesk />
    </>
  )
}

export default PageDashboard
