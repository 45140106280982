import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../src/PagesCrm/Redux/store/store';
import ThemeContext from "./context/ThemeContext";

import { ApolloClient, InMemoryCache, ApolloProvider, split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import config from "./config/config";

const httpLink = new HttpLink({
  uri: `${config.apiGraphQL}/graphql`
});

const wsLink = new GraphQLWsLink(createClient({
  url: `${config.apiGraphqlWs}/graphql`,
  connectionParams: () => ({
    headers: {
      // on envoi le token dans le header, attention , a la toute premier fois ou l'on se connecte,
      // le token n'est pas encore enregistré dans le localstorage
      Authorization: `Bearer ${localStorage.getItem('tokenadmin')}`,
    }
  })
}));

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <BrowserRouter basename='/'>
        <ThemeContext>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </ThemeContext>
      </BrowserRouter>
    </Provider>

);

reportWebVitals();