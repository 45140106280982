import React from "react";

import TableauCrm from "./Tableau/TableauCrm";

const PageTableau = () => {

  return (
      <TableauCrm/>
  );
};

export default PageTableau; 
