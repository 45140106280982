import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config/config";
import ReactSelect from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { setAllContracts, getFilters } from "../../Redux/slices/contract.slice";
import { Offcanvas } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Activite from "./Activite";
import Commentaire from "./Commentaire";
import { format, addMinutes } from "date-fns";
import { setPopup } from "../../Redux/slices/actions.slice";

const DropUpdateContrat = ({ isVisible, onHide, data }) => {
  console.log(data);

  const [psp, setPsp] = useState([]);
  const [neoBank, setNeoBank] = useState([]);
  const [customerBank, setCustomerBank] = useState([]);
  const [sellers, setSellers] = useState([]);
  // const [supports, setSupports] = useState([]);

  const [prenomC, setPrenomC] = useState(data?.cust_fname);
  const [nomC, setNomC] = useState(data?.cust_lname);
  const [brandC, setBrandC] = useState(data?.brand);
  const [amountC, setAmountC] = useState(data?.amount);
  // const [isConversionC, setIsConversionC] = useState(data?.conversion);
  const [pspC, setPspC] = useState(data?.psp);
  const [deskC, setDeskC] = useState(data?.desk);
  const [neoBankC, setNeoBankC] = useState(data?.neobank);
  const [customerbankC, setCustomerbankC] = useState(data?.customerbank);
  const [paymentdudateC, setPaymentdudateC] = useState(
    data?.paymentdudat?.slice(0, 10)
  );
  const [argent_dispoC, setArgent_dispoC] = useState(data?.argent_dispo);
  const [preuveC, setPreuveC] = useState(data?.preuve);
  const [date_virC, setDate_virC] = useState(
    data?.date_vir ? new Date(data?.date_vir) : null
  );
  const [encaisseC, setEncaisseC] = useState(data?.encaisse);

  const [next_callC, setNext_callC] = useState(
    data.next_call ? new Date(data?.next_call) : null
  );

  const [sellerC, setSellerC] = useState(data?.seller_id);
  const [is_reportC, setIs_reportC] = useState(data?.is_report);
  const [is_canceledC, setIs_canceledC] = useState(data?.is_canceled);

  const [selectedTitle, setSelectedTitle] = useState("Contrat");
  const [hoursCSel, setHoursCSel] = useState(data?.hours);

  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setPrenomC(data?.cust_fname);
    setNomC(data?.cust_lname);
    setBrandC(data?.brand);
    setAmountC(data?.amount);
    // setIsConversionC(data?.conversion);
    setPaymentdudateC(data?.paymentdudate);
    setArgent_dispoC(data?.argent_dispo);
    setPreuveC(data?.preuve);
    setEncaisseC(data?.encaisse);
    setDate_virC(data?.date_vir ? new Date(data?.date_vir) : null);
    setNext_callC(data.next_call ? new Date(data?.next_call) : null);
    // setHoursC(data?.hours);
    setIs_reportC(data?.is_report);
    setIs_canceledC(data?.is_canceled);
    setPspC(data?.psp);
    setNeoBankC(data?.neobank);
    setCustomerbankC(data?.customerbank);
    setSellerC(data?.seller_id);
    setDeskC(data?.desk);
    setHoursCSel(data?.hours);
    // setNext_callCDate(new Date(data.next_call));
    // setSelectedHour(data?.hours);
    // setSelectedDate(parse(hoursCSel, "HH:mm:ss", new Date()))
  }, [data]);

  const resetStates = () => {
    setPrenomC("");
    setNomC("");
    setBrandC("");
    setAmountC("");
    // setIsConversionC("");
    setPspC("");
    setNeoBankC("");
    setCustomerbankC("");
    setNeoBankC("");
    setPaymentdudateC("");
    setArgent_dispoC("");
    setPreuveC("");
    setEncaisseC("");
    setNext_callC("");
    // setHoursC("");
    setIs_reportC("");
    setIs_canceledC("");
    setDate_virC("");
    setSellerC("");
    setHoursCSel("");
    // Ajouter ici la réinitialisation pour les autres états si nécessaire
  };

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const AdminDetails = useSelector(getAdminDetails);
  const desksToAdmin = AdminDetails?.desk;
  // const profilToAdmin = AdminDetails?.profil;
  const brandToAdmin = AdminDetails?.brand;

  const filters = useSelector(getFilters);
  console.log(filters);

  useEffect(() => {
    if (isVisible) {
      FetchFiltersGet("/psp/read_all", setPsp);
      FetchFiltersGet("/neobanks/read_all", setNeoBank);
      FetchFiltersGet("/customerbanks/read_all", setCustomerBank);
      FetchFiltersPost("/users/users_by_profil_desk", setSellers, "sell");
      // FetchFiltersPost("/users/users_by_profil_desk", setSupports, "agt_supp");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  const FetchFiltersGet = (url, fonction) => {
    axios
      .get(`${config.apiAdmin}/sadmin${url}`, Header)
      .then((response) => {
        fonction(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const FetchFiltersPost = (url, fonction, profil) => {
    let obj = {
      profil: profil,
      desk: [desksToAdmin],
    };
    axios
      .post(`${config.apiAdmin}/sadmin${url}`, obj, Header)
      .then((response) => {
        // console.log(response.data);
        fonction(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
 }

  const optionsAlias = sellers?.map((item) => ({
    value: item?.idusers,
    label: item?.pseudo,
  }));

  const defaultValueAlias = {
    value: `${data?.alias_lname} ${data?.alias_fname}`,
    label: `${data?.alias_lname} ${data?.alias_fname}`,
  };

  const defaultValueBrand = {
    value: brandC,
    label: brandC,
  };

  const optionsBrands = brandToAdmin?.map((item) => ({
    value: item,
    label: item,
  }));

  const defaultValuePsp = {
    value: pspC,
    label: pspC,
  };

  const pspSelect = psp?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const defaultValueNeo = {
    value: neoBankC,
    label: neoBankC,
  };

  const neoSelect = neoBank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const defaultValueBank = {
    value: customerbankC,
    label: customerbankC,
  };

  const BankSelect = customerBank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const UpdateContract = () => {
    // datePicker ne prend que de new date, qui est une fonction, alors comme on besoin de la renvoyer en string,
    // on la retransforme a la fin, juste avant de l'envoyer
    let next_callCStr = next_callC?.toISOString();
    next_callCStr = next_callCStr?.slice(0, 10);

    // datePicker ne prend que de new date, qui est une fonction, alors comme on besoin de la renvoyer en string,
    // on la retransforme a la fin, juste avant de l'envoyer
    let date_virCStr = date_virC?.toISOString();
    date_virCStr = date_virCStr?.slice(0, 10);

    let obj = {
      idcontracts: data?.idcontracts,
      brand: brandC,
      desk: deskC,
      amount: amountC,
     conversion: data.conversion,
      end_contract: data?.end_contract?.slice(0, 10),
      psp: pspC,
      neobank: neoBankC,
      customerbank: customerbankC,
      customer_id: data?.customer_id,
      seller_id: sellerC,
      agt_supp_id: data?.agt_supp_id,
      paymentdudate: data?.paymentdudate?.slice(0, 10),
      argent_dispo: argent_dispoC,
      preuve: preuveC,
      date_vir: date_virCStr,
      encaisse: encaisseC,
      next_call: next_callCStr,
      hours: hoursCSel,
      is_report: is_reportC,
      is_canceled: is_canceledC,
      filters: Object.keys(filters).length === 0 ? { desk: [""] } : filters,
      customer: {
        firstname: prenomC,
        lastname: nomC,
        crm_id: data?.customer_id,
      },
    };
    // console.log(obj);
    axios
      .patch(`${config.apiAdmin}/sadmin/contracts/updateAll`, obj, Header)
      .then((response) => {
        // if (Object.keys(filters).length > 0) {
        dispatch(setAllContracts(response.data.data.results));
        SendPopup({ message: "confirm", info: "updateAll.", isActive: true });
        onHide();
        resetStates();
      })
      .catch((error) => {
        setError(true);
        SendPopup({ message: "error", info: "updateAll.", isActive: true });
      });
  };

  const handleTitleClick = (title) => {
    setSelectedTitle(title);
  };

  const handleHours = (date) => {
    const formattedDate = format(date, "HH:mm:ss");
    setHoursCSel(formattedDate);
  };

  const handleNextCall = (date) => {
    const selectedDate = new Date(date);
    const timezoneOffset = new Date().getTimezoneOffset();
    const dateInUTC = addMinutes(selectedDate, -timezoneOffset);
    setNext_callC(dateInUTC);
  };

  const handleDate_vir = (date) => {
    const selectedDate = new Date(date);
    const timezoneOffset = new Date().getTimezoneOffset();
    const dateInUTC = addMinutes(selectedDate, -timezoneOffset);
    setDate_virC(dateInUTC);
  };

  return (
    <Offcanvas
      show={isVisible}
      onHide={() => {
        onHide();
        resetStates();
      }}
      placement="end"
    >
      <Offcanvas.Header>
        <div className="d-flex justify-content-around col-12 title-Modif">
          <div
            className={selectedTitle === "Contrat" ? "titre-sel" : ""}
            onClick={() => handleTitleClick("Contrat")}
          >
            Contrat
          </div>
          <div
            className={selectedTitle === "Activite" ? "titre-sel" : ""}
            onClick={() => handleTitleClick("Activite")}
          >
            Activite
          </div>
          <div
            className={selectedTitle === "Commentaire" ? "titre-sel" : ""}
            onClick={() => handleTitleClick("Commentaire")}
          >
            Commentaire
          </div>
        </div>
      </Offcanvas.Header>
      {selectedTitle === "Contrat" && (
        <Offcanvas.Body className="bg-light">
          <div>
            <div className="form-group mb-1">
              <ReactSelect
                options={optionsAlias}
                className="basic-single"
                classNamePrefix="select"
                name="alias"
                   placeholder="Selectionnez pseudo du seller"
                defaultValue={defaultValueAlias}
                onChange={(selectedOption) => setSellerC(selectedOption.value)}
              />
            </div>
            <div className="form-group mb-1">
              <input
                type="text"
                name="firstName"
                className="form-control"
                placeholder="Prénom du Client"
                required
                defaultValue={prenomC}
                onChange={(e) => setPrenomC(e.target.value)}
              />
            </div>
            <div className="form-group mb-1">
              <input
                type="text"
                name="lastName"
                className="form-control"
                placeholder="Nom du Client"
                required
                defaultValue={nomC}
                onChange={(e) => setNomC(e.target.value)}
              />
            </div>
            <div className="form-group mb-1">
              <ReactSelect
                options={optionsBrands}
                className="basic-single"
                classNamePrefix="select"
                name="brand"
                placeholder="Selectionnez votre Brand"
                defaultValue={defaultValueBrand}
                onChange={(selectedOption) => setBrandC(selectedOption.value)}
              />
            </div>
            <div className="form-group mb-1">
              <input
                type="number"
                className="form-control"
                placeholder="MONTANT €"
                required
                defaultValue={amountC}
                onChange={(e) => setAmountC(e.target.value)}
              />
            </div>

            <div className="form-group mb-1">
              <label htmlFor="">Date d'échéance : </label>
              <DatePicker
                className="p-2 rounded border ms-2"
                placeholderText="Date d'échéance"
                selected={paymentdudateC ? new Date(paymentdudateC) : null}
                onChange={(date) => setPaymentdudateC(date)}
              />
            </div>
          </div>
          <div>
            <div className="form-group mb-1">
              <ReactSelect
                options={pspSelect}
                className="basic-single"
                classNamePrefix="select"
                name="brand"
                placeholder="Selectionnez votre Brand"
                defaultValue={defaultValuePsp}
                onChange={(selectedOption) => setPspC(selectedOption.value)}
              />
            </div>
            <div className="form-group mb-1">
              <ReactSelect
                options={neoSelect}
                className="basic-single"
                classNamePrefix="select"
                name="NeoBank"
                placeholder="Select Plateforme"
                defaultValue={defaultValueNeo}
                onChange={(selectedOption) => setNeoBankC(selectedOption.value)}
              />
            </div>
            <div className="form-group mb-1">
              <label className="text-label d-flex align-items-center ms-2">
                <input
                  type="checkbox"
                  name="checkboxName"
                  required
                  style={{ transform: "scale(1.5)" }}
                  checked={argent_dispoC}
                  onChange={(e) => setArgent_dispoC(e.target.checked ? 1 : 0)}
                />
                <p className="m-0 ps-2 fs-5">Argent chez le client</p>
              </label>
            </div>
            <div className="form-group mb-1">
              <ReactSelect
                options={BankSelect}
                className="basic-single"
                classNamePrefix="select"
                name="NeoBank"
                placeholder="Select Banque"
                defaultValue={defaultValueBank}
                onChange={(selectedOption) =>
                  setCustomerbankC(selectedOption.value)
                }
              />
            </div>
          </div>
          <div>
            <div className="form-group mb-1">
              <label className="text-label d-flex align-items-center ms-2">
                <input
                  type="checkbox"
                  name="checkboxName"
                  required
                  style={{ transform: "scale(1.5)" }}
                  checked={preuveC}
                  onChange={(e) => setPreuveC(e.target.checked ? 1 : 0)}
                />
                <p className="m-0 ps-2 fs-5">Preuve de virement</p>
              </label>
            </div>
            <div className="form-group mb-1">
              <label htmlFor="">Date du virement : </label>
              <DatePicker
                className="p-2 rounded border ms-2"
                onChange={(date) => {
                  handleDate_vir(date);
                }}
                selected={date_virC}
              />
            </div>{" "}
            <div className="form-group mb-1">
              <label htmlFor="">Prochain appel : </label>
              <DatePicker
                className="p-2 rounded border ms-2"
                onChange={(date) => {
                  handleNextCall(date);
                }}
                selected={next_callC}
              />
            </div>
            <div className="form-group mb-1 d-flex align-items-center justify-content-between">
              <label htmlFor="" className="m-0">
                Heure :{" "}
              </label>
              <div className="text-black">{hoursCSel}</div>
              <DatePicker
                onChange={(date) => {
                  console.log("date", date);
                  handleHours(date);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Heure"
                dateFormat="HH:mm:ss"
                className="form-control ms-2"
              />
            </div>
          </div>
          <div>
            <div className="form-group mb-1">
              <label className="text-label d-flex align-items-center ms-2">
                <input
                  type="checkbox"
                  name="checkboxName"
                  required
                  style={{ transform: "scale(1.5)" }}
                  checked={encaisseC}
                  onChange={(e) => setEncaisseC(e.target.checked ? 1 : 0)}
                />
                <p className="m-0 ps-2 fs-5">Encaisser</p>
              </label>
            </div>
            <div className="form-group mb-1">
              <label className="text-label d-flex align-items-center ms-2">
                <input
                  type="checkbox"
                  name="checkboxName"
                  required
                  style={{ transform: "scale(1.5)" }}
                  checked={is_reportC}
                  onChange={(e) => setIs_reportC(e.target.checked ? 1 : 0)}
                />
                <p className="m-0 ps-2 fs-5">Report mois pro.</p>
              </label>
            </div>
            <div className="form-group mb-1">
              <label className="text-label d-flex align-items-center ms-2">
                <input
                  type="checkbox"
                  name="checkboxName"
                  required
                  style={{ transform: "scale(1.5)" }}
                  checked={is_canceledC}
                  onChange={(e) => setIs_canceledC(e.target.checked ? 1 : 0)}
                />
                <p className="m-0 ps-2 fs-5">Annulation</p>
              </label>
            </div>
          </div>
          <div className="bg-info-light p-1 d-flex">
            <div
              className="border btn btn-info"
              onClick={() => UpdateContract()}
            >
              envoi
            </div>
            {error && (
              <div className="text-red fs-16 m-2">Une erreur est survenue</div>
            )}
          </div>
        </Offcanvas.Body>
      )}
      {selectedTitle === "Activite" && <Activite contrat={data} />}
      {selectedTitle === "Commentaire" && <Commentaire contrat={data} />}
    </Offcanvas>
  );
};

export default DropUpdateContrat;
