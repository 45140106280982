import { createSlice } from "@reduxjs/toolkit";

export const actionsReducer = createSlice({
  name: "actions",
  initialState: {
    authentified: false,
    deskNew: false,
    adminNew: false,
    popup: {
      message: "",
      info: "",
      isActive: false
    },
    deskSideBar: "",
    interdit: false
  },
  reducers: {
    setDeskNew: (state, { payload }) => {
      state.deskNew = payload;
    },
    setAdminNew: (state, { payload }) => {
      state.adminNew = payload;
    },
    setPopup: (state, { payload }) => {
      // Définir message, info, et isActive à true
      state.popup = {
        message: payload.message,
        info: payload.info,
        isActive: payload.isActive
      };
    },
    setDeskSidebar: (state, { payload }) => {
      state.deskSideBar = payload;
    },
    setInterdit: (state, { payload }) => {
      console.log("payload", payload) 
      state.interdit = payload;
    }
  },
});

export const { setDeskNew, setAdminNew, setPopup, setDeskSidebar, setInterdit } = actionsReducer.actions;

export const getDeskNew = (state) => {
  // console.log(state.actions.deskNew)
  return state.actions.deskNew;
};

export const getAdminNew = (state) => {
  // console.log(state.actions.adminNew)
  return state.actions.adminNew;
};

export const getPopup = (state) => {
  // console.log(state.actions.popup)
  return state.actions.popup;
};

export const getDeskSidebar = (state) => {
  return state.actions.deskSideBar;
}

export const getInterdit = (state) => {
  console.log(state)
  return state.actions.interdit;
}



export default actionsReducer.reducer;
