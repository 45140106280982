import React from "react";

import ReactApexChart from "react-apexcharts";

class GraphRatioByAgent extends React.Component {
  constructor(props) {
    console.log(props);
    super(props);

    this.state = {
      taille: this.props.height,
      series: [
        {
          name: "Encaisse",
          data: this.props?.arrEncaisse
        },
        {
          name: "Preuve",
          data: this.props?.arrSigned,
        },
        {
          name: "Signe",
          data: this.props?.arrSigned,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 230,
          toolbar: {
            show: false,
          },
          stacked: true,
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            horizontal: false,
            dataLabels: {
              position: "top",
            },
            stacked: true,
          },
        },
        colors: ["#10d078", "#fcef73", "#ffa500"],
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return  val?.toLocaleString('fr-FR') + " $";
          },
          offsetX: 0,
          style: {
            fontSize: '12px',
            colors: ['black'],
          },
        },
        stroke: {
          show: false,
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return "$ "+ val?.toLocaleString('fr-FR');
            },
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
               // Modifiez la taille de la police des labels de l'axe x
            },
          },
          lines: {
            show: false,
          },
        },
        xaxis: {
          show: false,
          categories: this.props?.arrCategories,
          labels: {
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
            },
          },
        },
      },
    };

  }

  componentDidUpdate(prevProps) {
    if (prevProps.arrEncaisse !== this.props.arrEncaisse || prevProps.arrSigned !== this.props.arrSigned) {
      this.setState({
        series: [
          {
            name: "Encaisse",
            data: this.props?.arrEncaisse
          },
          {
            name: "Preuve",
            data: this.props?.arrSigned,
          },
          {
            name: "Signe",
            data: [10,20,30,40,80,60,70,80],
          },
        ],
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: this.props?.arrCategories,
          },
        },
      });
    }
  }

  render() {
    return (
      <div id="chart" className="line-chart-style bar-chart h-100">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={this.state.taille}
        />
      </div>
    );
  }
}
export default GraphRatioByAgent;
