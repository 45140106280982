const config = {

  local: {
    apiAdmin: 'http://localhost:8000',
    apiGraphQL: 'http://localhost:4000',
    apiGraphqlWs: `ws://localhost:4000`,
  },

  prod: { 
    apiAdmin: 'https://p81n59iop4y96c.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
     apiGraphQL: 'https://ut6dj890m0je60.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
     apiGraphqlWs: `wss://ut6dj890m0je60.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space`,
  },

  proddub: { 
    apiAdmin: 'https://xe4jg44xo3elnx.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
     apiGraphQL: 'https://fmvezyc8470670.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space',
     apiGraphqlWs: `wss://fmvezyc8470670.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space`,
  },

  test : { 
    // il faudra les modifier des qu'on aura host
    apiAdmin: 'https://test9c7d9a084e42b9abcef462abfe8-q8k7q.ondigitalocean.app',
    apiGraphQL: 'https://ad99208c2fcb44c1b02b131582c3d657-xye2f.ondigitalocean.app',
    apiGraphqlWs: `wss://ad99208c2fcb44c1b02b131582c3d657-xye2f.ondigitalocean.app`,
  },

};

export default config[process.env.REACT_APP_CONFIG];