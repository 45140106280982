import React, { useState, useEffect } from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import formatNumber from "../../../utils/formatNumber";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import config from "../../../config/config";
import { setAllContracts } from "../../Redux/slices/contract.slice";
import { useNavigate } from "react-router-dom";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { useParams } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { getSockets } from "../../Redux/slices/notifSocket.slice"; 
import { getAdminDesks } from "../../Redux/slices/admin.slice";
import useHeaderRest from "../../../utils/useHeader";

const NewExpChart = loadable(() =>
  pMinDelay(
    import("../../../jsx/components/Dashboard/elements/NewExpChart"),
    1000
  )
);
const RevenueChartBar = loadable(() =>
  pMinDelay(
    import("../../../jsx/components/Dashboard/elements/RevenueChartBar"),
    1000
  )
);
const ExpensesBarChart = loadable(() =>
  pMinDelay(
    import("../../../jsx/components/Dashboard/elements/ExpensesBarChart"),
    1000
  )
);

const CardsChefSupport = ({ dataReportContract, dataContractTodo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const useHeader = useHeaderRest();

  const GET_SOCKETS = useSelector(getSockets);
  const GET_ADMIN_DESKS = useSelector(getAdminDesks);

  const [refreshKey, setRefreshKey] = React.useState(0);

  const [dataConversion, setDataConversion] = useState();
  const [dataSignedEncashed, setDataSignedEncashed] = useState();
  const [dataWeeklyTable, setDataWeeklyTable] = useState();
  const [dataBrutNetPrev, setDataBrutNetPrev] = useState();
  const [flash, setFlash] = useState(false);

  const [goodDesk, setGoodDesk] = useState();

  const { desk } = useParams();
  const deskIdAsNumber = parseInt(desk, 10);
  const admin = useSelector(getAdminDetails);
  const adminDesks = admin.desk;

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const handleChangePage = (mode) => {
    let obj = {
      filters: {
        desk: desk ? [desk] : adminDesks,
        encaisse: 0,
        is_report: 0,
        is_canceled: 0,
      },
      filters_date: {},
      sorts: {
        amount: "DESC",
      },
      limit: "",
      otherDateNull: mode,
    };
    axios
      .post(`${config.apiAdmin}/sadmin/contracts/search_and_sort`, obj, Header)
      .then((res) => {
        dispatch(setAllContracts(res.data.data.results));
        navigate(`/cs_tab${desk ? `/${desk}` : ""}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setRefreshKey((prevKey) => prevKey + 1);
    setGoodDesk(deskIdAsNumber ? [deskIdAsNumber] : GET_ADMIN_DESKS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desk]);





  const DataSignedEncashedByDesk = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/card_signed_encashed_by_desk`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        setDataSignedEncashed(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dataWeeklyTableByDesk = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/card_weekly_table_by_desk`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        setDataWeeklyTable(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const brutnetPrev = () => {
    axios
      .post(
        `${config.apiAdmin}/statsNode/manager/brut_net_prev`,
        { desk: goodDesk },
        useHeader
      )
      .then((response) => {
        setDataBrutNetPrev(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (goodDesk) {
      setFlash(true);
      setTimeout(() => setFlash(false), 100);
      DataSignedEncashedByDesk();
      dataWeeklyTableByDesk();
      brutnetPrev();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GET_SOCKETS, goodDesk]);





  return (
    <div className="row">
      <div className="col-sm-3">
        <div
          title=""
          className="card bg-grey-light pointer"
          onClick={() => handleChangePage("psp")}
        >
          <div className="card-body">
            <div className=" d-flex justify-content-between">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div className="font-w500 mb-0 text-black fs-4">
                    Contrats Sans PSP
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="text-black col-6 font-w800">
                    {dataContractTodo?.tot_without_psp}
                  </h3>
                  {/* <h3 className="border-start col-6">20</h3> */}
                </div>
              </div>
            </div>
            <div className="progress-box mt-0 custome-progress">
              <div className="d-flex justify-content-between">
                <div className="text-black p-1"></div>
              </div>
              <div className="progress"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-3">
        <div
          className="card bg-grey-light pointer"
          onClick={() => handleChangePage("neobank")}
        >
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div className="font-w500 mb-0 text-black fs-4">
                    Contrats sans plateformes
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="text-black col-6 font-w800">
                    {dataContractTodo?.tot_without_bank}
                  </h3>
                  {/* <h3 className="border-start col-6">20</h3> */}
                </div>
              </div>
            </div>
            <div className="progress-box mt-0 custome-progress">
              <div className="d-flex justify-content-between"></div>
              <div className="progress"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-3">
        <div
          className="card bg-grey-light pointer"
          onClick={() => handleChangePage("next_call")}
        >
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div className="font-w500 mb-0 text-black fs-4">
                    Contrats Sans RDV
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="text-black col-6 font-w800">
                    {dataContractTodo?.tot_without_rdv}
                  </h3>
                  {/* <h3 className="border-start col-6">20</h3> */}
                </div>
              </div>
            </div>
            <div className="progress-box mt-0 custome-progress">
              <div className="d-flex justify-content-between"></div>
              <div className="progress"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-3">
        <div className="card bg-grey-light">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div className="font-w500 mb-0 text-black fs-4">Reports</div>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <h3 className="text-black col-6 font-w800">
                    {dataContractTodo?.tot_report}
                  </h3>
                </div>
              </div>
            </div>
            <div className="progress-box mt-0 custome-progress">
              <div className="d-flex justify-content-between"></div>
              <div className="progress"></div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-sm-3"
        title="ce chiffre correspond a tous les contracts signes , en enlevant les reportes , et les annules"
      >
        <div className="card same-card-chart bg-danger-light diposit-bg">
          <div className="card-body p-0">
            <div className="d-flex justify-content-between p-4">
              <div className="col-6">
                <h5 className="d-block mb-1 text-black ">Brut Prev</h5>
                <span
                  style={{
                    backgroundColor: flash ? "yellow" : "transparent",
                    fontSize: "1.3rem",
                    color: "black",
                    fontWeight: "700",
                  }}
                >
                  {formatNumber(
                    dataBrutNetPrev?.brutNetPrev?.brut_previsionnel || 0
                  )}
                  ${" "}
                </span>
              </div>

              <div
                className="col-6 "
                title="update du net Prev , il ne s'agit plus que des contrats encaisses, moins les retraits effectues"
              >
                <h5 className="d-block mb-1 text-black">Net Prev</h5>
                <span
                  style={{
                    backgroundColor: flash ? "yellow" : "transparent",
                    fontSize: "1.3rem",
                    color: "black",
                    fontWeight: "700",
                  }}
                >
                  {formatNumber(
                    dataBrutNetPrev?.brutNetPrev?.net_previsionnel || 0
                  )}
                  $
                  <Badge bg="warning">
                    {Math.round(
                      ((dataBrutNetPrev?.brutNetPrev?.net_previsionnel || 0) *
                        100) /
                        (dataBrutNetPrev?.brutNetPrev?.brut_previsionnel ||
                          0) || 0
                    )}
                    % du Brut
                  </Badge>
                </span>
              </div>
              <div className="icon-box rounded-circle">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 12 20"
                  fill="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4642 13.7074C11.4759 12.1252 10.8504 10.8738 9.60279 9.99009C8.6392 9.30968 7.46984 8.95476 6.33882 8.6137C3.98274 7.89943 3.29927 7.52321 3.29927 6.3965C3.29927 5.14147 4.93028 4.69493 6.32655 4.69493C7.34341 4.69493 8.51331 5.01109 9.23985 5.47964L10.6802 3.24887C9.73069 2.6333 8.43112 2.21342 7.14783 2.0831V0H4.49076V2.22918C2.12884 2.74876 0.640949 4.29246 0.640949 6.3965C0.640949 7.87005 1.25327 9.03865 2.45745 9.86289C3.37331 10.4921 4.49028 10.83 5.56927 11.1572C7.88027 11.8557 8.81873 12.2813 8.80805 13.691L8.80799 13.7014C8.80799 14.8845 7.24005 15.3051 5.89676 15.3051C4.62786 15.3051 3.248 14.749 2.46582 13.9222L0.535522 15.7481C1.52607 16.7957 2.96523 17.5364 4.4907 17.8267V20.0001H7.14783V17.8735C9.7724 17.4978 11.4616 15.9177 11.4642 13.7074Z"
                    fill="#0077b6"
                  />
                </svg>
              </div>
            </div>
            <NewExpChart color={"white"} />
          </div>
        </div>
      </div>
      <div
        className="col-sm-3"
        title="En enlevant les reportes et les annules, ce chiffre correspond a tous les contrats dont la case preuve est cochE, mais dont la case encaissE n'est pas cochE. la case encash_usd doit etre egale a zero "
      >
        <div className="card same-card-chart bg-warning-light diposit-bg">
          <div className="card-body p-0">
            <div className=" d-flex justify-content-between p-4 pb-0">
              <div>
                <div className="mb-0 font-w500 text-black fs-4">
                Reste a encaissé
                </div>
                <h3 className="text-black font-w800">
                  {formatNumber(dataSignedEncashed?.signed.sum_signed || 0)} €
                </h3>
              </div>
              <div className="icon-box rounded-circle">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 12 20"
                  fill="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4642 13.7074C11.4759 12.1252 10.8504 10.8738 9.60279 9.99009C8.6392 9.30968 7.46984 8.95476 6.33882 8.6137C3.98274 7.89943 3.29927 7.52321 3.29927 6.3965C3.29927 5.14147 4.93028 4.69493 6.32655 4.69493C7.34341 4.69493 8.51331 5.01109 9.23985 5.47964L10.6802 3.24887C9.73069 2.6333 8.43112 2.21342 7.14783 2.0831V0H4.49076V2.22918C2.12884 2.74876 0.640949 4.29246 0.640949 6.3965C0.640949 7.87005 1.25327 9.03865 2.45745 9.86289C3.37331 10.4921 4.49028 10.83 5.56927 11.1572C7.88027 11.8557 8.81873 12.2813 8.80805 13.691L8.80799 13.7014C8.80799 14.8845 7.24005 15.3051 5.89676 15.3051C4.62786 15.3051 3.248 14.749 2.46582 13.9222L0.535522 15.7481C1.52607 16.7957 2.96523 17.5364 4.4907 17.8267V20.0001H7.14783V17.8735C9.7724 17.4978 11.4616 15.9177 11.4642 13.7074Z"
                    fill="#0077b6"
                  />
                </svg>
              </div>
            </div>
            <RevenueChartBar />
          </div>
        </div>
      </div>
      <div
        className="col-sm-3"
        title="cette case reprEsente la somme de tous les contracts encaissEs. Nous prenons la somme mis dans la casse encaissE, en ne cumulant pas les cases des contracts qui sont reportes ou annules"
      >
        <div className="card same-card-chart bg-turquoise-light diposit-bg">
          <div className="card-body p-0">
            <div className=" d-flex justify-content-between p-4 pb-0">
              <div>
                <div className="mb-0 font-w500 text-black fs-4">
                Total (encaissé)
                </div>
                <h3 className="text-black font-w800">
                  {formatNumber(dataSignedEncashed?.signed?.sum_encashed || 0)}{" "}
                  $
                </h3>
              </div>
              <div className="icon-box rounded-circle">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 12 20"
                  fill="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4642 13.7074C11.4759 12.1252 10.8504 10.8738 9.60279 9.99009C8.6392 9.30968 7.46984 8.95476 6.33882 8.6137C3.98274 7.89943 3.29927 7.52321 3.29927 6.3965C3.29927 5.14147 4.93028 4.69493 6.32655 4.69493C7.34341 4.69493 8.51331 5.01109 9.23985 5.47964L10.6802 3.24887C9.73069 2.6333 8.43112 2.21342 7.14783 2.0831V0H4.49076V2.22918C2.12884 2.74876 0.640949 4.29246 0.640949 6.3965C0.640949 7.87005 1.25327 9.03865 2.45745 9.86289C3.37331 10.4921 4.49028 10.83 5.56927 11.1572C7.88027 11.8557 8.81873 12.2813 8.80805 13.691L8.80799 13.7014C8.80799 14.8845 7.24005 15.3051 5.89676 15.3051C4.62786 15.3051 3.248 14.749 2.46582 13.9222L0.535522 15.7481C1.52607 16.7957 2.96523 17.5364 4.4907 17.8267V20.0001H7.14783V17.8735C9.7724 17.4978 11.4616 15.9177 11.4642 13.7074Z"
                    fill="#0077b6"
                  />
                </svg>
              </div>
            </div>
            <ExpensesBarChart />
          </div>
        </div>
      </div>
      <div
        className="col-sm-3"
        title="ici vous trouverez la sommes de tous les contracts ou la case reportEs est cochE. nous cumulons la case montant, donc ceci est en Euros"
      >
        <div className="card same-card-chart bg-purple-light diposit-bg">
          <div className="card-body p-0">
            <div className=" d-flex justify-content-between p-4 pb-0">
              <div>
                <div className="mb-0 font-w500 text-black fs-4">Total (en preuve)</div>
                <h3 className="text-black font-w800">
                  {formatNumber(
                    dataSignedEncashed?.sum_proof || 0
                  )}{" "}
                  €
                </h3>
              </div>
              <div className="icon-box rounded-circle">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 12 20"
                  fill="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4642 13.7074C11.4759 12.1252 10.8504 10.8738 9.60279 9.99009C8.6392 9.30968 7.46984 8.95476 6.33882 8.6137C3.98274 7.89943 3.29927 7.52321 3.29927 6.3965C3.29927 5.14147 4.93028 4.69493 6.32655 4.69493C7.34341 4.69493 8.51331 5.01109 9.23985 5.47964L10.6802 3.24887C9.73069 2.6333 8.43112 2.21342 7.14783 2.0831V0H4.49076V2.22918C2.12884 2.74876 0.640949 4.29246 0.640949 6.3965C0.640949 7.87005 1.25327 9.03865 2.45745 9.86289C3.37331 10.4921 4.49028 10.83 5.56927 11.1572C7.88027 11.8557 8.81873 12.2813 8.80805 13.691L8.80799 13.7014C8.80799 14.8845 7.24005 15.3051 5.89676 15.3051C4.62786 15.3051 3.248 14.749 2.46582 13.9222L0.535522 15.7481C1.52607 16.7957 2.96523 17.5364 4.4907 17.8267V20.0001H7.14783V17.8735C9.7724 17.4978 11.4616 15.9177 11.4642 13.7074Z"
                    fill="#0077b6"
                  />
                </svg>
              </div>
            </div>
            <ExpensesBarChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsChefSupport;
