import React from "react";
import CardsManager from "../Manager/DeskDetail/DeskDetail";
import GraphRatioByAgent from "../Components/Graph/GraphRatioByAgent";
import { Card } from "react-bootstrap";
import ProgressBarDesk from '../Components/ProgressBarDesk'

const Statistiques = () => {
  return (
    <div>
      <ProgressBarDesk />
      <CardsManager/>
      <Card>
        <GraphRatioByAgent height={"300px"} arrEncaisse={[]} arrSigned={[]} arrCategories={[]}  />
      </Card>
    </div>
  );
};

export default Statistiques;
