import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import { Modal } from "react-bootstrap";
import axios from "axios";
import config from "../../../config/config";
import { setCloseModalAddAdmin } from "../../Redux/slices/admin.slice";
import { useDispatch } from "react-redux";
import { setPopup } from "../../Redux/slices/actions.slice";
import generatePassword from "../../../utils/generatePassword";

const AddAdminByBossSupp = ({ show, closeModal }) => {
  const [divCount, setDivCount] = useState(1);
  const [listMng_supp, setListMng_supp] = useState([]);
  const [error, setError] = useState(false);
  const [listProfil, setListProfil] = useState([]);
  const [listBoss_supp, setListBoss_supp] = useState([]);
  const [creationSuccess, setCreationSuccess] = useState(false);
  const [responseCreationUser, setResponseCreationUser] = useState({});
  const [isPseudoAvailable, setIsPseudoAvailable] = useState(false);
  const [dataBrand, setDataBrand] = useState([]);
  const [dataDesk, setDataDesk] = useState([]);

  const dispatch = useDispatch();

  const [newUser, setNewUser] = useState({
    users: {
      pseudo: "",
      pwd: "",
      profil: "",
      mng_supp_id: "",
    },
    alias: [
      {
        lname: "",
        fname: "",
        brand: "",
      },
    ],
    brandarr: [],
    deskarr: [],
    mngarr: [
      {
        pseudo_mng: "",
        idusers_mng: "",
      },
    ],
  });

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };



  //  recuperation de data pour les dropdown de la crea d un user
  //////////////////////////////// brand
  const handleBrand = () => {
    axios
      .get(`${config.apiAdmin}/sadmin/brands/read_all_admin`, Header)
      .then((response) => {
        setDataBrand(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const BrandOptions = dataBrand?.map((brand) => ({
    value: brand?.name,
    label: brand?.name,
  }));
  ///////////////////////////// desk
  const handleDesk = () => {
    axios
      .get(`${config.apiAdmin}/sadmin/desks/read_all_admin`, Header)
      .then((response) => {
        setDataDesk(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const DeskOptions = dataDesk?.map((desk) => ({
    value: desk?.name,
    label: desk?.name,
  }));
  /////////////////////////// profil
  useEffect(() => {
    axios
      .get(`${config.apiAdmin}/sadmin/profils/read_all`, Header)
      .then((response) => {
        setListProfil(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    fetchListByProfil("mng_supp", setListMng_supp);
    fetchListByProfil("boss_supp", setListBoss_supp);
    handleBrand();
    handleDesk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /////////////////////////////




  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
  };

  const createNewUser = () => {
    newUser.users.pwd = generatePassword();
    if (newUser.users.pseudo === "") {
      alert("Veuillez renseigner le pseudo");
      setError(true);
      return;
    } else if (newUser.users.profil === "") {
      alert("Veuillez renseigner le profil");
      setError(true);
      return;
    } else if (newUser.mngarr[0].idusers_mng === "") {
      alert("Veuillez renseigner un manager");
      setError(true);
      return;
    } else if (newUser.brandarr.length === 0) {
      alert("Veuillez renseigner un manager");
      setError(true);
      return;
    } else if (newUser.deskarr.length === 0) {
      alert("Veuillez renseigner un desk");
      setError(true);
      return;
    } else if (isPseudoAvailable === false) {
      alert("Pseudo déjà utilisé");
      setError(true);
      return;
    } else {
      axios
        .put(
          `${config.apiAdmin}/sadmin/users/create-full-user`,
          newUser,
          Header
        )
        .then((response) => {
          console.log(response);
          // dispatch(setAdminNew(true));
          // closeModal();
          setError(false);
          SendPopup({
            message: "confirm",
            info: "create-full-user.",
            isActive: true,
          });
          setResponseCreationUser({
            pseudo: newUser.users.pseudo,
            pwd: newUser.users.pwd,
            authenticator: response.data.auth.authenticator,
          });
          setCreationSuccess(true);
          // fetchUsers()
          // setListFunction(response.data);
        })
        .catch((error) => {
          SendPopup({
            message: "error",
            info: "create-full-user.",
            isActive: true,
          });

          setError(true);
          closeModal();
          console.error("There was an error!", error);
        });
    }
  };

  const fetchListByProfil = (profil, setListFunction) => {
    axios
      .post(
        `${config.apiAdmin}/sadmin/users/users_by_profil`,
        { profil },
        Header
      )
      .then((response) => {
        setListFunction(
          response.data.length > 0
            ? response.data
            : [
                {
                  pseudo_mng: "maurice",
                  label: "maurice",
                  value: "maurice",
                  idusers_mng: "08737673-d81a-41ce-a805-69339ce6bde1",
                },
              ]
        );
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const MngArray = listBoss_supp?.map((mng) => ({
    value: mng,
    label: mng?.pseudo_mng,
  }));

  const Profil = listProfil
    ?.filter((profil) => profil.rang > 10 && profil.categories === "SUPPORT")
    .map((profil) => ({
      value: profil.name,
      label: profil.name,
    }));

  const mng_supp = listMng_supp?.map((profil) => ({
    value: profil?.idusers_mng,
    label: profil?.pseudo_mng,
  }));






  const copyToClipboard = () => {
    const textToCopy = `Pseudo: ${responseCreationUser?.pseudo}\nPassword: ${responseCreationUser?.pwd}\nAuthenticator: ${responseCreationUser?.authenticator}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("Copié dans le presse-papiers !");
        dispatch(setCloseModalAddAdmin(false));
        closeModal();
      })
      .catch((err) => {
        console.error("Erreur lors de la copie : ", err);
      });
  };

  const handleChangePseudo = (e) => {
    const modifiedValue = e.target.value.toLowerCase().replace(/\s+/g, "");
    setNewUser((prevState) => ({
      ...prevState,
      users: { ...prevState.users, pseudo: modifiedValue },
    }));

    axios
      .post(
        `${config.apiAdmin}/sadmin/users/check_Pseudo`,
        {
          pseudo: e.target.value,
        },
        Header
      )
      .then((res) => {
        setIsPseudoAvailable(res.data.isAvailable);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(agt_supp);
  // console.log(mng_supp);
  // console.log(Tlead);

  return (
    <>
      <Modal
        className="modal fade"
        show={show}
        onHide={closeModal}
        centered
        backdrop="static"
        keyboard={false}
      >
        {!creationSuccess ? (
          <Modal.Body
            style={{ backgroundColor: "#F4F4F5" }}
            className="border rounded"
          >
            <p className="fw-bold text-black fs-20">
              Ajouter un nouvel utilisateur (agt_supp, mng_supp)
            </p>
            <div className="d-flex align-items-center">
              <div className="fw-bold text-black fs-16">
                Identification de connexion
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="col-6 p-0 d-flex justify-content-center">
                <div className="form-group col-11">
                  <label htmlFor="pseudo" className="text-black">
                    Pseudo
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      isPseudoAvailable === true
                        ? "border border-success"
                        : "border border-danger"
                    }   `}
                    style={{ height: "45px !important" }}
                    id="pseudo"
                    onChange={(e) => handleChangePseudo(e)}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3 col-12 m-auto">
              <div className="fw-bold text-black fs-16">
                Information utilisateur
              </div>
              {divCount > 1 && (
                <div
                  className="border rounded-3 px-3 border-black text-black py-1 fw-bold  bg-danger"
                  onClick={() => setDivCount(divCount - 1)}
                  style={{ cursor: "pointer" }}
                >
                  <span className="pe-2 fs-4">-</span> Supprimer Alias
                </div>
              )}
              {/* <div
                className="border rounded-3 px-3 border-black text-black py-1 fw-bold"
                onClick={() => setDivCount(divCount + 1)}
                style={{ cursor: "pointer" }}
              >
                <span className="pe-2 fs-4">+</span> Nouvelle Alias
              </div> */}
            </div>
            {/* {Array(divCount)
              .fill()
              .map((_, i) => (
                <div
                  key={i}
                  className="d-flex mt-3 align-items-end col-12"
                  style={{ boxSizing: "border-box" }}
                >
                  <div className="col-4 px-1">
                    <label htmlFor={`pseudo${i}`} className="text-black">
                      Prénom
                    </label>
                    <input
                      // placeholder="Prénom"
                      style={{ minHeight: "45px !important" }}
                      type="text"
                      className="form-control"
                      id={`pseudo${i}`}
                      onChange={(event) => {
                        setNewUser((prevState) => {
                          const newAlias = [...prevState.alias];
                          newAlias[i] = {
                            ...newAlias[i],
                            fname: event.target.value,
                          };
                          return { ...prevState, alias: newAlias };
                        });
                      }}
                    />
                  </div>
                  <div className="col-4 px-1">
                    <label htmlFor={`pseudo${i}`} className="text-black">
                      Nom
                    </label>
                    <input
                      // placeholder="Nom"
                      type="text"
                      className="form-control pr-5"
                      id={`pseudo${i}`}
                      style={{ height: "45px !important" }}
                      onChange={(event) => {
                        setNewUser((prevState) => {
                          const newAlias = [...prevState.alias];
                          newAlias[i] = {
                            ...newAlias[i],
                            lname: event.target.value,
                          };
                          return { ...prevState, alias: newAlias };
                        });
                      }}
                    />
                  </div>
                  <div className="col-4 px-1">
                    <ReactSelect
                      options={dataBrand}
                      className="mt-1"
                      classNamePrefix="select"
                      placeholder="Selection brand"
                      onChange={(selectedOption) => {
                        setNewUser((prevState) => {
                          const newAlias = [...prevState.alias];
                          newAlias[i] = {
                            ...newAlias[i],
                            brand: selectedOption?.value,
                          };
                          return {
                            ...prevState,
                            alias: newAlias,
                            brandarr: [
                              ...prevState.brandarr,
                              selectedOption?.value,
                            ],
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              ))} */}
            <div className="d-flex mt-2">
              <div className="form-group ms-2 mt-3">
                <ReactSelect
                  options={Profil}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Selection profil"
                  onChange={(selectedOption) => {
                    setNewUser((prevState) => ({
                      ...prevState,
                      users: {
                        ...prevState.users,
                        profil: selectedOption?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="form-group ms-2 mt-3">
                <ReactSelect
                  isMulti
                  options={DeskOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Selection desks"
                  onChange={(selectedOption) => {
                    setNewUser((prevState) => ({
                      ...prevState,
                      deskarr: selectedOption?.map((option) => option.value),
                    }));
                  }}
                />
              </div>
              <div className="form-group ms-2 mt-3">
                <ReactSelect
                  isMulti
                  options={BrandOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Selection brand"
                  onChange={(selectedOption) => {
                    setNewUser((prevState) => ({
                      ...prevState,
                      brandarr: selectedOption?.map((option) => option.value),
                    }));
                  }}
                />
              </div>
            </div>
            <div className="d-flex mt-2">
              <div className="form-group ms-2 mt-3">
                <ReactSelect
                  options={mng_supp}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Selection mng-supp"
                  onChange={(selectedOption) => {
                    setNewUser((prevState) => ({
                      ...prevState,
                      users: {
                        ...prevState.users,
                        mng_supp_id: selectedOption?.value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="form-group ms-2 mt-3">
                {/* <ReactSelect
                options={agt_supp}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Selection support"
                onChange={(selectedOption) => {
                  setNewUser((prevState) => ({
                    ...prevState,
                    users: {
                      ...prevState.users,
                      agt_supp_id: selectedOption?.value,
                    },
                  }));
                }}
              /> */}
              </div>
            </div>
            <div className="form-group ms-2 mt-3">
              <ReactSelect
                isMulti
                options={MngArray}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Selection manager"
                onChange={(selectedOption) => {
                  setNewUser((prevState) => ({
                    ...prevState,
                    mngarr: selectedOption?.map((option) => option.value),
                  }));
                }}
              />
            </div>
            <div className="mt-3 ms-2 d-flex justify-content-end me-2 btn-modal-footer">
              <div
                className="border rounded-1 px-4 text-black fw-bold"
                onClick={closeModal}
              >
                Annuler
              </div>
              <div
                className="border-0 rounded-1 px-4 text-black fw-bold ms-3"
                style={{ backgroundColor: "#25E87F" }}
                onClick={createNewUser}
              >
                valider
              </div>
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body
            style={{ backgroundColor: "#F4F4F5" }}
            className="border rounded"
          >
            <p className="fw-bold text-black fs-20">
              Ajouter un nouvel utilisateur
            </p>
            <div className="d-flex align-items-center">
              <div className="fw-bold text-black fs-16">
                Identification de connexion a copier coller
              </div>
            </div>
            <p>{responseCreationUser?.pseudo}</p>
            <p>{responseCreationUser?.pwd}</p>
            <p>{responseCreationUser?.authenticator}</p>
            <button onClick={copyToClipboard}>Copier les informations</button>

            <div className="mt-3 ms-2 d-flex justify-content-end me-2 btn-modal-footer">
              {error && <div className="mx-1 text-red">Erreur survenue</div>}
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default AddAdminByBossSupp;
